import { Login } from '@components/login/login';
import { Secured } from '@components/login/secured';
import { AppModal } from '@components/modal';
import { Toast } from '@components/molecules';
import { LoadingIndicator } from '@components/organisms/loadingIndicator/loadingIndicator';
import { Account } from '@components/pages/account';
import { Home } from '@components/pages/home';
import { Mfa } from '@components/pages/mfa';
import { PasswordInit } from '@components/pages/passwordInit/passwordInit';
import { PasswordReset } from '@components/pages/passwordReset/passwordReset';
import { PlanogramDetail } from '@components/pages/planogramDetail/planogramDetail';
import { PlanogramEditor } from '@components/pages/planogramEditor/planogramEditor';
import { PlanogramPreview } from '@components/pages/planogramPreview/planogramPreview';
import { Planograms } from '@components/pages/planograms';
import { ProductList } from '@components/pages/productList/productList';
import { RealogramCreate } from '@components/pages/realogramCreate';
import { Realograms } from '@components/pages/realograms';
import { ScannerDetails } from '@components/pages/scannerDetails';
import { Users } from '@components/pages/users';
import { Sakura } from '@components/pages/sakura';
import { SakuraStoreSelect } from '@components/pages/sakuraStoreSelect';
import { SakuraEditor } from '@components/pages/sakuraEditor';
import { RemoveTrailingSlash } from '@components/removeTrailingSlash';
import { useAppSelector } from '@store/index';
import { paths } from '@utils/const';
import { NotFound } from 'components/NotFound';
import { FC, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { RedirectTo2dView } from './fragments/RedirectTo2dView';
import { Actuals } from './fragments/actuals';
import { Plans } from './fragments/plans';
import { Products } from './fragments/products';
import { StoreBays } from '@components/pages/storeBays';
import SakuraUnauthorized from 'pages/sakura403';
import { PrintDetails } from '@components/pages/scannerDetails/print';

export const App: FC = () => {
  const { pageTitle } = useAppSelector((state) => state.PageTitle);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      <Router>
        <RemoveTrailingSlash />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/password-init" element={<PasswordInit />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/mfa" element={<Mfa />} />
          <Route path="/" element={<Secured />}>
            <Route path="actuals" element={<Actuals />}>
              <Route
                index
                element={<Navigate to={paths.actuals.folders} replace />}
              />
              <Route path="folders">
                <Route index element={<Realograms />} />
                <Route path="root" element={<Realograms />} />
                <Route path=":id" element={<Realograms />} />
                <Route path="starred" element={<Realograms />} />
                <Route path="recently-scanned" element={<Realograms />} />
                <Route path="recently-viewed" element={<Realograms />} />
              </Route>
              <Route path="scan" element={<RealogramCreate />} />
              <Route path=":id" element={<ScannerDetails />} />
              <Route path="print/:id" element={<PrintDetails />} />
            </Route>
            <Route path="plans" element={<Plans />}>
              <Route
                index
                element={<Navigate to={paths.plans.folders} replace />}
              />
              <Route path="folders">
                <Route index element={<Planograms />} />
                <Route path="root" element={<Planograms />} />
                <Route path=":id" element={<Planograms />} />
                <Route path="starred" element={<Planograms />} />
                <Route path="recently-viewed" element={<Planograms />} />
              </Route>
              <Route path=":id" element={<PlanogramDetail />} />
              <Route path=":id/edit" element={<PlanogramEditor />} />
              <Route path=":id/view" element={<RedirectTo2dView />} />
              <Route
                path=":id/view/2d"
                element={<PlanogramPreview previewMode="2d" />}
              />
              <Route
                path=":id/view/3d"
                element={<PlanogramPreview previewMode="3d" />}
              />
              {/* 予約 */}
              <Route path="create" element={<NotFound />} />
            </Route>
            <Route path="products" element={<Products />}>
              <Route index element={<ProductList />} />
              <Route path=":id" element={<ProductList />} />
            </Route>
            <Route path="users">
              <Route index element={<Users />} />
              <Route path=":id" element={<Users />} />
            </Route>
            <Route path="account">
              <Route index element={<Account />} />
              <Route path="mfa" element={<Mfa />} />
            </Route>
            <Route
              path="settings"
              element={<Navigate to={paths.account.root} replace />}
            />

            <Route path="storebays" element={<Outlet />}>
              <Route
                index
                element={<Navigate to={paths.storeBays.folders} replace />}
              />
              <Route path="folders">
                <Route index element={<StoreBays />} />
                <Route path=":id" element={<StoreBays />} />
                <Route
                  path="starred"
                  element={<StoreBays sidebarValue="favorite" />}
                />
              </Route>
            </Route>
            <Route path="scan" element={<RealogramCreate />} />
            <Route path=":id" element={<ScannerDetails />} />

            <Route path="/" element={<Home />} />
            <Route path="/sakura/edit" element={<SakuraEditor />} />
            <Route path="/sakura/view" element={<SakuraStoreSelect />} />
            <Route
              path="/sakura/view/:zoCd/:doCd/:storeCd"
              element={<Sakura />}
            />
            <Route path="/sakura/403" element={<SakuraUnauthorized />} />
          </Route>

          <Route path="/*" element={<NotFound />} />
        </Routes>
        <AppModal />
        <Toast />
      </Router>
      <LoadingIndicator />
    </>
  );
};
