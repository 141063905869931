import { SidebarValue } from '@utils/const';
import { User } from 'types/user';

export const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (sidebarValue === 'latest') {
    return '最近のスキャン';
  } else if (isRoot) {
    return 'スキャン結果';
  } else {
    return directoryParentName;
  }
};

export const addMeToSelectItems = (users?: User[], user?: User) => {
  return user
    ? [
        {
          id: user?.id,
          label: `自分 (${user?.fullname})`,
        },
        ...(users?.map((user) => {
          return {
            id: user.id,
            label: user.fullname,
          };
        }) ?? []),
      ]
    : users?.map((user) => {
        return {
          id: user.id,
          label: user.fullname,
        };
      }) ?? [];
};
