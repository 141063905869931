import { Box, List, Typography } from '@mui/material';
import {
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import {
  MutableRefs,
  isCompartmentMode,
  isSelectedItem,
} from '@utils/realogram';
import { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import {
  AnalyticsProductData,
  RealogramCandidateFace,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { ShelfListHeader } from '../shelfListHeader/shelfListHeader';
import { ListHeaderOptionalItem } from './fragments';
import { CompartmentsList } from './fragments/compartmentsList';
import { SelectedViewListRow } from './fragments/selectedViewListRow';
import { useMemoizedHandlers } from './fragments/useMemoizedHandlers';

type Props = {
  refs: MutableRefs;
  products?: Product[];
  shelfBoards?: RealogramShelfBoard[];
  realogramDetailView: ShelfDetailView;
  selectedItem?: RealogramSelectedItem;
  handleClick: (item: RealogramSelectedItem) => void;
  handleClickDeleteShelfBoard: (shelfBoardId: string) => void;
  handleCorrectDrawerOpen: (item: RealogramSelectedItem) => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  handleClickDeleteFace: (item: RealogramSelectedItem) => void;
  handleClickOutSideRealogramBbox?: () => void;
  profitTab: ProfitTab;
  productsSalesReport?: AnalyticsProductData[];
  handleSort: () => void;
  isAscending?: boolean;
  isCanEditFace?: boolean;
  isCanDeleteFace?: boolean;
  isCanDeleteBaypart?: boolean;
  isBucketType?: boolean;
  handleAddFaceMode: (shelfBoardIndex: number) => void;
};

export const widthList = {
  eanCodeWidth: 89,
  scoreWidth: 89,
  menuWidth: 64,
  face: 73,
  profit: 103,
};
const defaultSortValue = 1;

export const RealogramCandidatesList: FC<Props> = ({
  refs,
  products,
  shelfBoards,
  selectedItem,
  realogramDetailView,
  handleClick,
  handleClickDeleteShelfBoard,
  handleClickDeleteFace,
  handleCorrectDrawerOpen,
  handleDivisionModalOpen,
  handleClickOutSideRealogramBbox,
  profitTab,
  productsSalesReport,
  handleSort,
  isAscending = false,
  isCanEditFace = false,
  isCanDeleteFace = false,
  isCanDeleteBaypart = false,
  isBucketType = false,
  handleAddFaceMode,
}) => {
  const { pathname } = useLocation();
  const key = getProfitValueKey(profitTab);
  const sortedReports = productsSalesReport
    ?.filter((el) => {
      if (!key) return false;
      return !!el[key];
    })
    .sort((a, b) => {
      // return defaultSortValue so it doesn't change order
      if (!key) return defaultSortValue;
      const valueA = a[key] ?? 0;
      const valueB = b[key] ?? 0;
      return valueA - valueB;
    })
    .map((el) => el.product_id);
  const flatten = shelfBoards?.flatMap((shelf) => {
    return shelf.compartments.flatMap((el, compartmentIndex) => {
      return {
        ...el,
        compartmentId: el.id,
        compartmentIndex,
        shelfBoardId: shelf.id,
        shotIndex: shelf.shot_index,
      };
    });
  });

  const reorderedShelfboards = flatten?.slice().sort((a, b) => {
    const indexA =
      sortedReports?.indexOf(a.faces[0].primary_candidate?.product_id ?? 0) ??
      0;
    const indexB =
      sortedReports?.indexOf(b.faces[0].primary_candidate?.product_id ?? 0) ??
      0;
    return isAscending ? indexA - indexB : indexB - indexA;
  });

  const groupBySKU = reorderedShelfboards?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.faces[0].primary_candidate?.product_id ===
          item.faces[0].primary_candidate?.product_id
      )
  );

  const shelfBoardsToDisplay = isBucketType ? groupBySKU : reorderedShelfboards;
  const {
    memoHandleClickDeleteShelfBoard,
    memoHandleCorrectDrawerOpen,
    memoHandleDivisionModalOpen,
    memoHandleClickDeleteFace,
    memoHandleClickOutSideRealogramBbox,
    memoHandleAddFaceMode,
    memoHandleSort,
    memoHandleClick,
  } = useMemoizedHandlers({
    handleClickDeleteShelfBoard,
    handleCorrectDrawerOpen,
    handleDivisionModalOpen,
    handleClickDeleteFace,
    handleSort,
    handleAddFaceMode,
    handleClickOutSideRealogramBbox:
      handleClickOutSideRealogramBbox ?? (() => void 0),
    handleClick,
  });

  return (
    <>
      <ShelfListHeader>
        <ListHeaderOptionalItem
          type={realogramDetailView}
          profitTab={profitTab}
          handleClick={handleSort}
          isAscending={isAscending}
          isBucket={isBucketType}
        />
      </ShelfListHeader>
      <List
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        subheader={<Box component="li" />}
      >
        {shelfBoards && !shelfBoards.length && (
          <Typography sx={{ color: '#999999', mt: 5, textAlign: 'center' }}>
            該当する区画がありません
          </Typography>
        )}

        {(realogramDetailView == 'profit' ||
          realogramDetailView == 'productFlag') && (
          <>
            {shelfBoardsToDisplay?.map(
              ({ shelfBoardId, faces, compartmentId, shotIndex }, index) => {
                return (
                  <Box
                    component="ul"
                    key={`shelf-board-${index}`}
                    sx={{ padding: 0 }}
                  >
                    {faces.map((face, faceIndex) => {
                      if (
                        isCompartmentMode(realogramDetailView, pathname) &&
                        faceIndex > 0
                      ) {
                        //show each compartment
                        return <Fragment key={face.id} />;
                      }

                      const productReport = productsSalesReport?.find(
                        (product) =>
                          product.product_id ===
                          face.primary_candidate?.product_id
                      );
                      const isSelected =
                        isBucketType && selectedItem?.isBboxClicked
                          ? false
                          : isSelectedItem(
                              realogramDetailView,
                              {
                                shelfBoardId,
                                compartmentId,
                                item: face,
                              },
                              selectedItem
                            );
                      return (
                        <SelectedViewListRow
                          key={face.id}
                          isSelected={isSelected}
                          shelfBoardId={shelfBoardId}
                          compartmentId={compartmentId}
                          facesLength={faces.length}
                          products={products}
                          selectedItem={
                            compartmentId !== selectedItem?.compartmentId
                              ? undefined
                              : selectedItem
                          }
                          handleClick={memoHandleClick}
                          refs={refs}
                          handleClickDeleteFace={memoHandleClickDeleteFace}
                          realogramDetailView={realogramDetailView}
                          handleCorrectDrawerOpen={memoHandleCorrectDrawerOpen}
                          profitTab={profitTab}
                          productReport={productReport}
                          handleDivisionModalOpen={memoHandleDivisionModalOpen}
                          isCanEditFace={isCanEditFace}
                          isBucketType={isBucketType}
                          face={face}
                          shotIndex={shotIndex ?? 1}
                        />
                      );
                    })}
                  </Box>
                );
              }
            )}
          </>
        )}

        {realogramDetailView === 'default' && (
          <>
            {shelfBoards?.map(
              (
                { id: shelfBoardId, compartments, shot_index },
                shelfBoardIndex
              ) => {
                return (
                  <CompartmentsList
                    key={`shelf-board-${shelfBoardIndex}`}
                    shelfBoardIndex={shelfBoardIndex}
                    refs={refs}
                    products={products}
                    shelfBoards={shelfBoards}
                    realogramDetailView={realogramDetailView}
                    selectedItem={
                      shelfBoardId !== selectedItem?.shelfBoardId
                        ? undefined
                        : selectedItem
                    }
                    handleClick={memoHandleClick}
                    handleClickDeleteShelfBoard={
                      memoHandleClickDeleteShelfBoard
                    }
                    handleCorrectDrawerOpen={memoHandleCorrectDrawerOpen}
                    handleDivisionModalOpen={memoHandleDivisionModalOpen}
                    handleClickDeleteFace={memoHandleClickDeleteFace}
                    handleClickOutSideRealogramBbox={
                      memoHandleClickOutSideRealogramBbox
                    }
                    profitTab={profitTab}
                    productsSalesReport={productsSalesReport}
                    handleSort={memoHandleSort}
                    isAscending={isAscending}
                    isCanEditFace={isCanEditFace}
                    isCanDeleteFace={isCanDeleteFace}
                    isCanDeleteBaypart={isCanDeleteBaypart}
                    isBucketType={isBucketType}
                    handleAddFaceMode={memoHandleAddFaceMode}
                    compartments={compartments}
                    shelfBoardId={shelfBoardId}
                    shotIndex={shot_index ?? 1}
                    pathname={pathname}
                  />
                );
              }
            )}{' '}
          </>
        )}
      </List>
    </>
  );
};

const getProfitValueKey = (profitTab: ProfitTab) => {
  switch (profitTab) {
    case profitTabSales:
      return 'gross_sales_price';
    case profitTabQuantity:
      return 'sales_count';
    case profitTabGrossProfit:
      return 'gross_profit_price';
    default:
      return undefined;
  }
};
