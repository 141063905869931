import { Stack, Typography } from '@mui/material';
import { SakuraModal, SectionDivider } from '../modal';
import { Product } from 'types/common';
import { ExternalInfo, StoreProductSale } from '../../types';
import { ProductBaseInfo } from './productBaseInfoSection';
import { ProductPerformanceSection } from './productPerformanceSection';
import { ModelGondolaSection } from './modelGondolasSection';
import { ModelGondolaProductAssignment } from '../../sakuraUtils';

type ProductDetailsModalProps = {
  product: Product;
  externalInfo: ExternalInfo;
  storeProductSale: StoreProductSale;
  modelGondolaProductAssignment: ModelGondolaProductAssignment | undefined;
  open: boolean;
  handleClose: () => void;
};

export const ProductDetailsModal = ({
  product,
  externalInfo,
  storeProductSale,
  modelGondolaProductAssignment,
  open,
  handleClose,
}: ProductDetailsModalProps) => {
  return (
    <>
      <SakuraModal
        title="商品の詳細"
        open={open}
        handleClose={handleClose}
        closeButton
      >
        <Stack>
          <ProductBaseInfo product={product} externalInfo={externalInfo} />
          <SectionDivider />

          <Typography variant="body3" fontWeight="bold">
            モデルゴンドラ
          </Typography>
          <ModelGondolaSection
            product={product}
            externalInfo={externalInfo}
            sale={storeProductSale}
            modelGondolaProductAssignment={modelGondolaProductAssignment}
          />
          <SectionDivider />

          <Typography variant="body3" fontWeight="bold">
            売上動向詳細
          </Typography>
          <ProductPerformanceSection
            product={product}
            sale={storeProductSale}
          />
        </Stack>
      </SakuraModal>
    </>
  );
};
