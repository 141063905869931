import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { getProfitTagName, productTags } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import { ProductCategory } from 'types/productCategories';
import { array, string } from 'yup';

type ProductsConditionsProps = {
  selectedProductTags: string[];
  selectedProductCategories?: ProductCategory;
  handleChangeSelectedProductTags: (name: string[]) => void;
  handleSelectCategory: () => void;
  handleRemoveSelectedCategory: () => void;
  clearConditions: () => void;
};

export const ProductsConditions: FC<ProductsConditionsProps> = ({
  selectedProductTags,
  selectedProductCategories,
  handleChangeSelectedProductTags,
  handleSelectCategory,
  handleRemoveSelectedCategory,
  clearConditions,
}) => {
  const buttonBackgroundColor = selectedProductCategories
    ? '#E5F0FF'
    : 'rgb(255, 255, 255)';
  return (
    <Box component="div" display="flex" gap={2}>
      <Button
        variant="outlined"
        onClick={handleSelectCategory}
        endIcon={
          selectedProductCategories && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleRemoveSelectedCategory();
              }}
              sx={{ padding: 0 }}
            >
              <CloseIcon
                sx={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: theme.palette.icons.primary,
                }}
              />
            </IconButton>
          )
        }
        sx={{
          color: theme.palette.textBlack.primary,
          borderColor: theme.palette.dividerBlack.dark,
          fontWeight: theme.typography.fontWeightLight,
          backgroundColor: buttonBackgroundColor,
          '&:hover': {
            backgroundColor: buttonBackgroundColor,
            borderColor: 'black',
          },
        }}
      >
        {selectedProductCategories
          ? `カテゴリ：${selectedProductCategories?.name}`
          : 'カテゴリを選択'}
      </Button>
      <FormControl sx={{ flex: 1, maxWidth: 160 }}>
        <InputLabel size="small">商品属性</InputLabel>
        <Select
          size="small"
          label="商品属性"
          multiple
          value={selectedProductTags}
          onChange={({ target: { value } }) => {
            if (Array.isArray(value)) {
              const filteredValue = value.filter((tag) => tag !== undefined);
              handleChangeSelectedProductTags(
                array(string().required()).validateSync(filteredValue) ?? []
              );
            }
          }}
          renderValue={(item) =>
            item.length === 1
              ? getProfitTagName(item[0])
              : `${item.length}件選択中`
          }
        >
          <MenuItem
            divider
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <Button
              disabled={productTags.length === selectedProductTags.length}
              onClick={(event) => {
                event.stopPropagation();
                handleChangeSelectedProductTags(
                  productTags.map((productTag) => getProfitTagName(productTag))
                );
              }}
            >
              すべて選択
            </Button>
            <Button
              disabled={selectedProductTags?.length === 0}
              onClick={(event) => {
                event.stopPropagation();
                handleChangeSelectedProductTags([]);
              }}
            >
              すべて解除
            </Button>
          </MenuItem>
          {productTags.map((productTag, index) => {
            const checked = selectedProductTags.includes(
              getProfitTagName(productTag)
            );
            return (
              <MenuItem
                divider
                key={index}
                value={getProfitTagName(productTag)}
              >
                <Checkbox checked={checked} />
                {getProfitTagName(productTag)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* Todo: バックエンドで絞り込み実装後コメント外す */}
      {/* <FormControlLabel
        sx={{ maxWidth: '106px', m: 0 }}
        control={<Checkbox />}
        label="3Dデータ"
      />
      <FormControlLabel
        sx={{ maxWidth: '106px', m: 0 }}
        control={<Checkbox />}
        label="商品認識" 
      />*/}
      {/* Todo: カテゴリ選択追加時にdisable変更 */}
      <Button
        disabled={!selectedProductTags.length && !selectedProductCategories}
        onClick={clearConditions}
      >
        すべて解除
      </Button>
    </Box>
  );
};
