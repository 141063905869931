import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../theme';

type Props = {
  name: string;
  backgroundColor?: string;
  textColor?: string;
};

export const ProductFlagLabel: FC<Props> = ({
  name,
  backgroundColor,
  textColor,
}) => {
  return (
    <Box
      component="div"
      sx={{
        height: 18,
        padding: '4px 8px',
        backgroundColor:
          backgroundColor || theme.palette.backgroundBlack.active,
        borderRadius: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: 10, color: textColor || '#444444' }}>
        {name}
      </Typography>
    </Box>
  );
};
