import {
  useGetSakuraStoresQuery,
  useGetSakuraUsersQuery,
} from '@reducers/shelfAppsApi/injections/sakuraApi';
import { SakuraAppBar } from '../sakura/fragments/sakuraAppBar';
import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetUserQuery } from '@reducers/shelfAppsApi';
import { filterAvailableDoZoStores } from '@utils/sakuraUsers';
import { usePageTitle } from '@hooks/usePageTitle';
import { useModelGondolaItems } from '../sakura/sakuraUtils';

export const SakuraStoreSelect: FC = () => {
  const navigate = useNavigate();

  const { data: meRes } = useGetUserQuery({ userId: 'me' });
  const { data: sakuraStoresRes } = useGetSakuraStoresQuery();
  const { data: sakuraUsersRes } = useGetSakuraUsersQuery();

  const sakuraUser =
    meRes &&
    sakuraUsersRes?.users.find((user) => user.email === meRes.user.email);

  const sakuraStoresFiltered = filterAvailableDoZoStores(
    sakuraStoresRes,
    sakuraUser
  );

  const [curZo, setCurZo] = useState<string>('');
  const [curDo, setCurDo] = useState<string>('');
  const [curStoreCd, setCurStoreCd] = useState<string>('');

  // 各選択肢1個しかなければデフォルトが出るようにする
  useEffect(() => {
    if (!sakuraStoresFiltered) {
      return;
    }
    if (sakuraStoresFiltered.zos.length === 1) {
      setCurZo(sakuraStoresFiltered.zos[0].zoCd);
    }
    if (sakuraStoresFiltered.dos.length === 1) {
      setCurDo(sakuraStoresFiltered.dos[0].doCd);
    }
    if (sakuraStoresFiltered.stores.length === 1) {
      setCurStoreCd(sakuraStoresFiltered.stores[0].storeCd);
    }
  }, [sakuraStoresFiltered]);

  usePageTitle(`モデルゴンドラ 店舗選択`);

  const store = sakuraStoresFiltered?.stores.find(
    (store) => store.storeCd === curStoreCd
  );
  const { modelGondolaItems, isLoading } = useModelGondolaItems(store);
  const currentModelGondolaItem =
    modelGondolaItems &&
    0 < modelGondolaItems.length &&
    modelGondolaItems[modelGondolaItems.length - 1];

  const CurrentModelGondolaIndicator = () => {
    if (curStoreCd.length === 0 || isLoading) {
      // 単に店舗選択が完了してない
      return <></>;
    } else if (currentModelGondolaItem) {
      return (
        <Typography variant="caption2">
          モデルゴンドラが{currentModelGondolaItem.week}に配信されています。
        </Typography>
      );
    } else {
      return (
        <Typography variant="caption2" color="red">
          配信されているモデルゴンドラがありません。
        </Typography>
      );
    }
  };

  return (
    <>
      <SakuraAppBar title="進化版モデルゴンドラ" />

      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: '400px',
            padding: '40px',
            textAlign: 'center',
          }}
        >
          <Stack spacing={4}>
            <Typography variant="h5">訪問店舗選択</Typography>

            <FormControl>
              <InputLabel id="zo-selector">ZO</InputLabel>
              <Select
                labelId="zo-selector"
                value={curZo}
                onChange={(e) => {
                  setCurZo(e.target.value as string);
                  setCurDo('');
                  setCurStoreCd('');
                }}
                disabled={!sakuraStoresFiltered}
              >
                {sakuraStoresFiltered?.zos.map((zo) => (
                  <MenuItem key={zo.zoCd} value={zo.zoCd}>
                    {zo.zoCd} {zo.name}ZO
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="do-selector">DO</InputLabel>
              <Select
                labelId="do-selector"
                value={curDo}
                onChange={(e) => {
                  setCurDo(e.target.value as string);
                  setCurStoreCd('');
                }}
                disabled={!sakuraStoresFiltered || !curZo}
              >
                {sakuraStoresFiltered?.dos
                  .filter((_do) => _do.zoCd === curZo)
                  .map((_do) => (
                    <MenuItem key={_do.doCd} value={_do.doCd}>
                      {_do.doCd} {_do.name}DO
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Stack>
              <FormControl>
                <InputLabel id="store-selector">店舗</InputLabel>
                <Select
                  labelId="store-selector"
                  value={curStoreCd}
                  onChange={(e) => {
                    setCurStoreCd(e.target.value as string);
                  }}
                  disabled={!sakuraStoresFiltered || !curZo || !curDo}
                >
                  {sakuraStoresFiltered?.stores
                    .filter((store) => store.doCd === curDo)
                    .map((store) => (
                      <MenuItem key={store.storeCd} value={store.storeCd}>
                        {store.storeCd} {store.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Box component="div" sx={{ height: '20px' }} textAlign="left">
                <CurrentModelGondolaIndicator />
              </Box>
            </Stack>

            <Button
              variant="contained"
              disabled={curStoreCd === undefined || !currentModelGondolaItem}
              onClick={() => {
                navigate(`/sakura/view/${curZo}/${curDo}/${curStoreCd}`);
              }}
            >
              モデルゴンドラを閲覧
            </Button>
          </Stack>
        </Paper>
      </Box>
    </>
  );
};
