import {
  CurrentSelectedType,
  setCurrentPlanogramDirectoryId,
  setCurrentRealogramDirectoryId,
  setCurrentSelectedItem,
  setCurrentTab,
  updateCurrentSelectedItemId,
  updateCurrentSelectedType,
  updatePlanogramSearch,
  updateRealogramSearch,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { closeModal } from '@reducers/modal';
import {
  updateDetailMode,
  updateDetailView,
} from '@reducers/planogramEditor/reducer';
import { realogramCandidatesApi } from '@reducers/shelfAppsApi';
import { planogramsApi } from '@reducers/shelfAppsApi/injections/planogramsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal } from '@utils/const';
import httpStatus from 'http-status';
import { useCallback, useState } from 'react';
import { SelectedItemType } from 'types/common';
import { Planogram } from 'types/planogram';
import { RealogramCandidateContainCurrentRole } from 'types/realogram';
import { useRerenderingDetails } from './rerenderingComponents';
import { useModal } from './useModal';
import { useUpdateUrlQueryParamsOfDetailPages } from './useUpdateUrlQueryParamsOfDetailPages';

export const useComparisonItemModal = (ShelfType?: string) => {
  const dispatch = useAppDispatch();
  const {
    currentTab,
    selectedItemId,
    currentSelectedItemId,
    currentPlanogramDirectoryId,
    currentRealogramDirectoryId,
  } = useAppSelector(selectComparisonItemModal);
  const { showModal } = useModal('comparisonSelectionModal', '');
  const [isLoading, setIsLoading] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [comparedPlanogram, setComparedPlanogram] = useState<Planogram>();
  const [comparedRealogram, setComparedRealogram] =
    useState<RealogramCandidateContainCurrentRole>();

  const { updateModeQueryParams } = useUpdateUrlQueryParamsOfDetailPages();
  const {
    selector: {
      modeQueryParams,
      viewQueryParams,
      attributeQueryParams,
      profitTab,
      evaluationQueryParams,
    },
  } = useRerenderingDetails();

  const fetchGetPlanogram = useCallback(
    async (id: number, type: CurrentSelectedType) => {
      const getDataPlanogram = async (id: number) => {
        const results = await dispatch(
          planogramsApi.endpoints.getPlanogram.initiate({
            planogramId: id,
          })
        );
        /**
         *  Get parent directoryId of item selected when reload the page or paste url in comparison mode
         */
        if (!currentPlanogramDirectoryId?.toString()) {
          dispatch(
            setCurrentPlanogramDirectoryId(
              results.data?.planogram?.directory_parent_id
            )
          );
        }
        dispatch(updateCurrentSelectedType('plan'));
        dispatch(
          setCurrentSelectedItem({
            parent_id: results.data?.planogram?.directory_parent_id,
            planogram_id: results.data?.planogram?.id,
          })
        );
        setComparedPlanogram(results.data?.planogram);
        setComparedRealogram(undefined);
        dispatch(setCurrentRealogramDirectoryId(undefined));
        setIsForbidden(
          !!(
            results.error &&
            'status' in results.error &&
            results.error.status === httpStatus.FORBIDDEN
          )
        );
      };

      const getDataRealogram = async (id: number) => {
        const results = await dispatch(
          realogramCandidatesApi.endpoints.getRealogramCandidate.initiate({
            realogramCandidateId: id,
          })
        );
        if (!currentRealogramDirectoryId?.toString()) {
          dispatch(
            setCurrentRealogramDirectoryId(
              results.data?.realogram_candidate?.directory_parent_id
            )
          );
        }
        dispatch(updateCurrentSelectedType('actual'));
        dispatch(
          setCurrentSelectedItem({
            parent_id: results.data?.realogram_candidate?.directory_parent_id,
            realogram_candidate_id: results.data?.realogram_candidate?.id,
          })
        );
        setComparedRealogram(results.data?.realogram_candidate);
        setComparedPlanogram(undefined);
        dispatch(setCurrentPlanogramDirectoryId(undefined));
        setIsForbidden(
          !!(
            results.error &&
            'status' in results.error &&
            results.error.status === httpStatus.FORBIDDEN
          )
        );
      };
      setIsLoading(true);
      try {
        if (type === 'plan') {
          if (ShelfType === 'actual') await getDataRealogram(id);
          else await getDataPlanogram(id);
        }
        if (type === 'actual') {
          await getDataRealogram(id);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [
      currentPlanogramDirectoryId,
      currentRealogramDirectoryId,
      dispatch,
      ShelfType,
    ]
  );

  const resetComparisonItemModalState = () => {
    dispatch(setCurrentTab(undefined));
    dispatch(updateSelectedItemId(undefined));
    dispatch(updateCurrentSelectedItemId(undefined));
    dispatch(setCurrentSelectedItem(undefined));
    dispatch(setCurrentPlanogramDirectoryId(undefined));
    dispatch(setCurrentRealogramDirectoryId(undefined));
    dispatch(updatePlanogramSearch(undefined));
    dispatch(updateRealogramSearch(undefined));
    dispatch(updateDetailView('default'));
    dispatch(updateDetailMode('default'));
  };

  const handleOpenComparisonItemModal = ({
    defaultTab,
    originPlanogramId,
    originRealogramId,
  }: {
    defaultTab?: ComparisonTabModal;
    originPlanogramId?: number;
    originRealogramId?: number;
  }) => {
    if (currentTab === undefined && selectedItemId === undefined) {
      dispatch(setCurrentTab(defaultTab));
    }

    showModal({
      handleChangeSelectedComparison: (
        selectedTab: ComparisonTabModal,
        selectedItem?: SelectedItemType
      ) => {
        if (selectedTab === ComparisonTabModal.REALOGRAM_TAB) {
          dispatch(setCurrentRealogramDirectoryId(selectedItem?.parent_id));
          dispatch(setCurrentPlanogramDirectoryId(undefined));
          dispatch(
            updateCurrentSelectedItemId(selectedItem?.realogram_candidate_id)
          );
          dispatch(updateCurrentSelectedType('actual'));
          updateModeQueryParams(
            modeQueryParams,
            viewQueryParams,
            attributeQueryParams,
            profitTab,
            evaluationQueryParams,
            {
              selectedItem: selectedItem?.realogram_candidate_id
                ? `actual-${selectedItem?.realogram_candidate_id}`
                : undefined,
            }
          );
        } else {
          dispatch(setCurrentRealogramDirectoryId(undefined));
          dispatch(setCurrentPlanogramDirectoryId(selectedItem?.parent_id));
          dispatch(updateCurrentSelectedItemId(selectedItem?.planogram_id));
          dispatch(updateCurrentSelectedType('plan'));
          updateModeQueryParams(
            modeQueryParams,
            viewQueryParams,
            attributeQueryParams,
            profitTab,
            evaluationQueryParams,
            {
              selectedItem: selectedItem?.planogram_id
                ? `plan-${selectedItem?.planogram_id}`
                : undefined,
            }
          );
        }
        dispatch(setCurrentSelectedItem(selectedItem));
        dispatch(setCurrentTab(selectedTab));
        dispatch(closeModal());
      },
      originPlanogramId,
      originRealogramId,
    });
  };

  return {
    isLoading,
    comparedPlanogram,
    comparedRealogram,
    currentSelectedItemId,
    fetchGetPlanogram,
    resetComparisonItemModalState,
    handleOpenComparisonItemModal,
    isForbidden,
  };
};
