import {
  ProductCandidatePlanogram,
  ZoomablePlanogram,
} from '@components/organisms';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { CompartmentsTable } from '@components/organisms/compartmentsTable/compartmentsTable';
import { HeatMap } from '@components/organisms/heatMap/heatMap';
import { PlanogramDetailHeader } from '@components/organisms/planogramDetailHeader/planogramDetailHeader';
import { PlanogramOverview } from '@components/organisms/planogramOverview/planogramOverview';
import { ShelfDetailTabs } from '@components/organisms/shelfDetailTabs/shelfDetailTabs';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useOrganizationStatuses } from '@hooks/useOrganizationStatuses';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { useZoomController } from '@hooks/useZoomController';
import { Box } from '@mui/material';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import {
  updateDetailMode,
  updateDetailView,
  updateProductTagTab,
  updateProfitTab,
  updateRateValue,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  embed,
  formatNumberToYen,
  fullHeight,
  planogramRightSideHeight,
  productTags,
  profitTabSales,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  calcPlanStatistics,
  getNextPlanogramItem,
  getPrevPlanogramItem,
  getProductsLayout,
  isSelectedCompartment,
} from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { Refs, scrollToCenterOfList } from '@utils/realogram';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { theme } from 'theme';
import {
  Product,
  ProductTag,
  ProfitTab,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { Planogram, PlanogramPlan, ProductPosition } from 'types/planogram';
import { RealogramSelectedItem } from 'types/realogram';
import { ProductTagValue } from 'types/statistics';
import { Comparison } from '../fragments/comparison';
import { PlanogramProductCompartmentExpand } from './types';
import {
  getCurrentCompartmentPosition,
  getProductsLayoutByView,
} from './utils/index';

type Props = {
  planogram?: Planogram;
  handleNavigateView: () => void;
  isForbidden?: boolean;
  embedParam: string;
};

export const ShelfPlanogramDetail: FC<Props> = ({
  planogram,
  handleNavigateView,
  isForbidden,
  embedParam,
}) => {
  const dispatch = useAppDispatch();
  const { manipulateQueryParameters } = useUrlQueryParams();
  const listItemRefs = useRef<Refs>(new Map());
  const {
    zoomScale,
    handleDecrementZoom,
    handleIncrementZoom,
    handleTriggerZoom,
  } = useZoomController();
  const [profitTab, setProfitTab] = useState<ProfitTab>(profitTabSales);
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const { rateValue } = useAppSelector(selectPlanogramEditorState);
  const { selectedDirectoryId } = useAppSelector((state) => state.Planogram);
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { plan, forget } = usePlanogramPlan(planogram?.plan);
  const {
    updateModeQueryParams,
    updateAnalyticsQueryParams,
    updateEvaluationQueryParams,
  } = useUpdateUrlQueryParamsOfDetailPages();

  const {
    detailView: view,
    detailMode: mode,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);

  const productIds = getProductsLayout(planogram?.plan)
    .flatMap(({ row }) => row.map(({ product_id }) => product_id))
    .join(',');
  const { data: productsBulk, isLoading } = useListProductsBulkQuery(
    { productIds: productIds, shape: true, detail: true },
    { skip: productIds === '' }
  );
  const { selectedProductCompartment, position } = useSelectedPlanogramItem({
    products: productsBulk?.products,
  });
  const productPosition = position as ProductPosition;

  const resetSelectedProduct = useCallback(() => {
    manipulateQueryParameters({
      removeQueries: ['item', 'compareItem', 'position'],
    });
  }, [manipulateQueryParameters]);

  const handleClickListItem = (
    position: ProductPosition,
    product?: Product
  ) => {
    if (isSelectedCompartment(position, productPosition)) {
      resetSelectedProduct();
      return;
    }

    manipulateQueryParameters({
      updateQueries: [
        {
          key: 'item',
          value: String(product?.id),
        },
        {
          key: 'position',
          value: JSON.stringify(position),
        },
      ],
    });
  };

  const statistic = useMemo(
    () => calcPlanStatistics(plan, view, productTag, productsBulk?.products),
    [plan, productTag, view, productsBulk]
  );

  const productTagValues: ProductTagValue =
    statistic?.totalFacesByProductTag ?? ({} as ProductTagValue);

  const {
    isViewQueryParams,
    selector,
    operate,
    data: { isLoadingTenant, canReadDemo, isTenantSalesAnalytics },
  } = useRerenderingDetails();

  const { planogramEstimatedData } = useEstimatedProfit({ planogram });
  const checkHasSelectedItem = useCallback(
    (view?: ShelfDetailView, productTag?: ProductTag) => {
      const filteredRows = plan.products_layout.flatMap(({ row }) =>
        row.map((compartment) => {
          const product = productsBulk?.products?.find(
            (product) => product.id === compartment.product_id
          );

          const shouldIncludeProduct =
            view !== 'productFlag' ||
            (productTag
              ? hasProductTag(productTag, product?.detail?.tags)
              : false);
          return shouldIncludeProduct ? product : null;
        })
      );

      const products = filteredRows?.filter(Boolean);
      const hasSelectedItem = (products || []).some(
        (product) => product?.id === selectedProductCompartment?.id
      );

      return hasSelectedItem;
    },
    [
      plan.products_layout,
      productsBulk?.products,
      selectedProductCompartment?.id,
    ]
  );

  // 初回描画：ヘッダータブ
  useEffect(() => {
    if (embedParam !== embed) {
      if (isLoading || isLoadingTenant) return;

      if (selector.modeQueryParams) {
        dispatch(updateDetailMode(selector.modeQueryParams));
      }
      // view=layout
      if (selector.viewQueryParams === 'default') {
        dispatch(updateDetailView(selector.viewQueryParams));
      }
      // view=attribute
      else if (selector.viewQueryParams === 'productFlag') {
        dispatch(updateDetailView(selector.viewQueryParams));
        dispatch(
          updateProductTagTab(
            selector.attributeQueryParams
              ? selector.attributeQueryParams
              : productTags[0]
          )
        );
      }
      // view=analytics
      else if (
        selector.viewQueryParams === 'profit' &&
        isTenantSalesAnalytics
      ) {
        const profitValue = selector.profitTab
          ? selector.profitTab
          : profitTabSales;
        dispatch(updateDetailView(selector.viewQueryParams));
        setProfitTab(profitValue);

        // Update profit value in comparison mode when back-browsing
        if (selector.modeQueryParams === 'comparison') {
          dispatch(updateProfitTab(profitValue));
        }
      }
      // view=evaluation
      else if (selector.viewQueryParams === 'rate' && canReadDemo) {
        dispatch(updateDetailView(selector.viewQueryParams));
        dispatch(
          updateRateValue(
            selector.evaluationQueryParams
              ? selector.evaluationQueryParams
              : '評価OK'
          )
        );
      }
    }
  }, [
    canReadDemo,
    dispatch,
    embedParam,

    isLoading,
    isLoadingTenant,
    isTenantSalesAnalytics,
    isViewQueryParams,
    operate,
    selector,
  ]);

  useEffect(() => {
    if (productPosition) {
      const key = JSON.stringify(productPosition);
      scrollToCenterOfList(listItemRefs, key);
    }
  }, [productPosition]);

  useEffect(() => {
    return () => {
      forget();
    };
  }, [forget]);

  // 通常・比較モードの切り替え
  const handleChangeMode = (mode: ShelfDetailMode) => {
    if (mode) {
      const originPlanogramId = planogram?.link?.origin_planogram_id;
      const originRealogramCandidateId =
        planogram?.link?.origin_realogram_candidate_id;
      const profitValue = mode === 'default' ? profitTab : profitTabSales;
      dispatch(updateDetailMode(mode));
      dispatch(updateSelectedRealogramItem(undefined));
      const queryString =
        currentSelectedItemId && currentSelectedType
          ? `${currentSelectedType}-${currentSelectedItemId}`
          : originPlanogramId
          ? `plan-${originPlanogramId}`
          : originRealogramCandidateId
          ? `actual-${originRealogramCandidateId}`
          : '';
      updateModeQueryParams(mode, view, productTag, profitValue, rateValue, {
        selectedItem: queryString,
      });
    }
  };

  // 表示内容の切り替え
  const handleChangeView = (view: ShelfDetailView) => {
    if (!view) return;

    const profitValue = mode === 'default' ? profitTab : profitTabSales;
    const hasSelectedItem = checkHasSelectedItem(view, productTag);

    dispatch(updateDetailView(view));
    operate.updateUrlQueryParams(
      {
        viewQueryParams: view,
        isProduct: !!hasSelectedItem,
        attributeQueryParams: productTag,
        profitTab: profitValue,
        evaluationQueryParams: rateValue,
      },
      {
        replace: false,
      }
    );
  };

  // 商品属性の中の項目を更新
  const handleChangeProductTag = (productTag: ProductTag) => {
    const profitValue = mode === 'default' ? profitTab : profitTabSales;
    const hasSelectedItem = checkHasSelectedItem(view, productTag);
    if (!hasSelectedItem) {
      resetSelectedProduct();
    }
    dispatch(updateProductTagTab(productTag));
    operate.updateUrlQueryParams(
      {
        viewQueryParams: view,
        isProduct: !!hasSelectedItem,
        attributeQueryParams: productTag,
        profitTab: profitValue,
        evaluationQueryParams: rateValue,
      },
      {
        replace: false,
      }
    );
  };

  const selectNext = (
    productsLayout: PlanogramPlan['products_layout'],
    position?: ProductPosition
  ) => {
    if (!position) {
      return;
    }

    const { subPosition, indexY } = position;
    const productLayout = productsLayout.at(indexY);
    const productsLayoutByTag = getProductsLayoutByView(
      plan,
      view,
      productTag,
      productsBulk?.products,
      planogramEstimatedData?.estimate.products
    );
    const updatedPosition = {
      indexX: 0,
      indexY,
      subPosition: {
        indexX: subPosition.indexX + 1,
        indexY: 0,
      },
    };
    let product: Product | undefined;

    if (productLayout?.row.length === subPosition.indexX + 1) {
      if (position.indexY === 0) return;
      const { indexY, indexX, subPosition } = getNextPlanogramItem(
        productsLayout,
        position
      );
      const nextProductLayout = productsLayout.at(indexY);
      if (!nextProductLayout) return;
      updatedPosition.indexX = indexX;
      updatedPosition.indexY = indexY;
      updatedPosition.subPosition = subPosition;
      product = {
        id: nextProductLayout.row.at(0)?.product_id,
      } as Product;
    } else {
      product = {
        id: productsLayout
          .at(position.indexY)
          ?.row.at(position.subPosition.indexX + 1)?.product_id,
      } as Product;
    }
    if (!product.id) return;
    const productIsInProductsLayoutByTag = !!productsLayoutByTag.find((shelf) =>
      shelf.row.find((compartment) => compartment.product_id === product?.id)
    );
    if (!productIsInProductsLayoutByTag) {
      // Keep finding the product until it is found in the productsLayoutByTag
      selectNext(productsLayout, updatedPosition);
      return;
    }

    manipulateQueryParameters({
      updateQueries: [
        { key: 'item', value: String(product.id) },
        { key: 'position', value: JSON.stringify(updatedPosition) },
      ],
    });
  };

  const selectPrev = (
    productsLayout: PlanogramPlan['products_layout'],
    position?: ProductPosition
  ) => {
    if (!position) {
      return;
    }
    const productsLayoutByTag = getProductsLayoutByView(
      plan,
      view,
      productTag,
      productsBulk?.products,
      planogramEstimatedData?.estimate.products
    );
    const updatedPosition = {
      indexX: 0,
      indexY: position.indexY,
      subPosition: {
        indexX: position.subPosition.indexX - 1,
        indexY: 0,
      },
    };
    let product: Product | undefined;

    if (position.subPosition.indexX === 0) {
      if (position.indexY === productsLayout.length - 1) return;
      const { indexY, indexX, subPosition } = getPrevPlanogramItem(
        productsLayout,
        position
      );
      const prevLastShelfBoard = productsLayout.at(indexY);
      const prevLastCompartmentIndex =
        (prevLastShelfBoard?.row.length ?? 0) - 1;
      updatedPosition.indexX = indexX;
      updatedPosition.indexY = indexY;
      updatedPosition.subPosition = subPosition;
      //次の段を跨ぐ場合
      product = {
        id: prevLastShelfBoard?.row.at(prevLastCompartmentIndex)?.product_id,
      } as Product;
    } else {
      product = {
        id: productsLayout
          .at(position.indexY)
          ?.row.at(position.subPosition.indexX - 1)?.product_id,
      } as Product;
    }
    const productIsInProductsLayoutByTag = !!productsLayoutByTag.find((shelf) =>
      shelf.row.find((compartment) => compartment.product_id === product?.id)
    );
    if (product.id && !productIsInProductsLayoutByTag) {
      // Keep finding the product until it is found in the productsLayoutByTag
      selectPrev(productsLayout, updatedPosition);
      return;
    }

    manipulateQueryParameters({
      updateQueries: [
        { key: 'item', value: String(product.id) },
        { key: 'position', value: JSON.stringify(updatedPosition) },
      ],
    });
  };

  const shouldButtonBeDisabled = (value: 'prev' | 'next') => {
    if (!plan.products_layout.length || !productPosition) return true;
    const productsLayout = getProductsLayoutByView(
      plan,
      view,
      productTag,
      productsBulk?.products,
      planogramEstimatedData?.estimate.products
    );
    const position = getCurrentCompartmentPosition(
      productsLayout,
      productPosition,
      selectedProductCompartment?.id
    ) || {
      indexX: 0,
      indexY: productPosition.indexY,
      subPosition: {
        ...productPosition.subPosition,
      },
    };
    const { indexY, subPosition } = position;
    if (value === 'prev') {
      const findLastIndexCustom = (
        arr: {
          row: PlanogramProductCompartmentExpand[];
        }[],
        predicate: (shelf: {
          row: PlanogramProductCompartmentExpand[];
        }) => boolean
      ) => {
        for (let i = arr.length - 1; i >= 0; i--) {
          if (predicate(arr[i])) {
            return i;
          }
        }
        return -1; // 見つからなかった場合
      };

      const lastRowWithProductIndex = findLastIndexCustom(
        productsLayout,
        (shelf: { row: PlanogramProductCompartmentExpand[] }) =>
          shelf.row.length > 0
      );
      // findLastIndexが使えない端末があったのでforループで代用
      // const lastRowWithProductIndex = productsLayout.findLastIndex(
      //   (shelf) => shelf.row.length
      // );
      if (lastRowWithProductIndex < indexY) {
        return true;
      }
      return lastRowWithProductIndex === indexY && subPosition.indexX === 0;
    } else {
      const firstRowWithProductIndex = productsLayout.findIndex(
        (shelf) => shelf.row.length
      );
      if (
        firstRowWithProductIndex === -1 ||
        firstRowWithProductIndex > indexY
      ) {
        return true;
      }
      return (
        firstRowWithProductIndex === indexY &&
        subPosition.indexX >= (productsLayout.at(indexY)?.row.length || 0) - 1
      );
    }
  };

  const handleClickAwayPlanogram = () => {
    if (selectedProductCompartment && productPosition) {
      resetSelectedProduct();
    }
  };
  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};
  const dateTerm = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  const { isLarger } = useBreakpoint();
  const { organizationStatus } = useOrganizationStatuses(
    planogram?.organization_status_id
  );

  return (
    <Box
      component="div"
      height={fullHeight}
      display="flex"
      flexDirection="column"
    >
      <PlanogramDetailHeader
        handleChangeView={handleChangeView}
        handleChangeMode={handleChangeMode}
        planogram={planogram}
        hiddenElement={embedParam === embed}
        isShowAnalyticsButton={isTenantSalesAnalytics}
        organizationStatus={organizationStatus}
      />
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        {mode === 'comparison' ? (
          <Comparison
            planogram={planogram}
            products={productsBulk?.products}
            plan={plan}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            compareQueryParams={selector.compareQueryParams}
          />
        ) : (
          <>
            <Box
              component="div"
              position="absolute"
              top={24}
              zIndex={5}
              sx={{ transform: `translate(0px, 24px)` }}
            >
              <PlanButtonGroup
                bboxEnabled={bboxEnabled}
                handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
                handleNavigateView={handleNavigateView}
                handleIncrementZoom={handleIncrementZoom}
                handleDecrementZoom={handleDecrementZoom}
                handleTriggerZoom={handleTriggerZoom}
                zoomScale={zoomScale}
                isOrientationModalOpen={false}
                isEditor={false}
              />
            </Box>
            <PlanogramProvider
              value={{
                products: productsBulk?.products,
                productSalesReport: planogramEstimatedData?.estimate,
              }}
            >
              <Box
                component="div"
                display="flex"
                flexDirection={{ xs: 'column', breakpoint: 'row' }}
                overflow="hidden"
                flex={1}
              >
                <Box
                  component="div"
                  sx={{
                    flex: { xs: 1, breakpoint: 5 },
                    position: 'relative',
                    overflow: 'auto',
                    backgroundColor: theme.palette.shelf.backgroundTana,
                  }}
                >
                  <ZoomablePlanogram
                    bboxEnabled={bboxEnabled}
                    plan={plan}
                    scale={zoomScale}
                    handleClickAway={handleClickAwayPlanogram}
                  />

                  {view === 'profit' && (
                    <Box
                      component="div"
                      sx={{ position: 'absolute', bottom: 24, left: 24 }}
                    >
                      <HeatMap
                        start={formatNumberToYen(0)}
                        // eslint-disable-next-line no-magic-numbers -- formatNumberToYen
                        end={formatNumberToYen(20000)}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: { breakpoint: 5.5 },
                    overflow: 'hidden',
                    borderLeft: `1px solid ${theme.palette.dividerBlack.light}`,
                    height: {
                      xs: planogramRightSideHeight,
                      breakpoint: 'auto',
                    },
                  }}
                >
                  {!selectedProductCompartment && (
                    <ShelfDetailTabs
                      productTag={productTag}
                      detailView={view}
                      handleChangeProductTag={handleChangeProductTag}
                      rateValue={rateValue}
                      handleChangeRateValue={(item) => {
                        dispatch(updateRateValue(item));
                        updateEvaluationQueryParams(item);
                      }}
                      rateValues={[]}
                      productTagValues={productTagValues}
                      hasSelected={!!selectedProductCompartment}
                      isPlan
                    />
                  )}

                  {selectedProductCompartment ? (
                    <Box component="div" px={2} py={isLarger ? '16px' : 0}>
                      <ProductCandidatePlanogram
                        realogramDetailView={view}
                        shouldButtonBeDisabled={shouldButtonBeDisabled}
                        selectNext={() =>
                          selectNext(plan.products_layout, productPosition)
                        }
                        selectPrevious={() =>
                          selectPrev(plan.products_layout, productPosition)
                        }
                        selectedItem={
                          {
                            item: {
                              in_stock: true,
                              primary_candidate: {
                                product_id: selectedProductCompartment.id,
                              },
                            },
                          } as RealogramSelectedItem //TODO: select状態のためにindexを追加する
                        }
                        products={productsBulk?.products}
                      />
                    </Box>
                  ) : (
                    <PlanogramOverview
                      term={dateTerm}
                      profitTab={profitTab}
                      setProfitTab={(profitTab: ProfitTab) => {
                        setProfitTab(profitTab);
                        updateAnalyticsQueryParams(profitTab);
                      }}
                      statistic={statistic}
                      isFlat={false}
                      displayAnalyticsData={planogramEstimatedData?.estimate}
                    />
                  )}
                  <CompartmentsTable
                    refs={listItemRefs}
                    products={productsBulk?.products}
                    productsLayout={plan.products_layout.slice().reverse()}
                    profitTab={profitTab}
                    handleClickListItem={handleClickListItem}
                    productSales={planogramEstimatedData?.estimate.products}
                    productPosition={productPosition}
                  />
                </Box>
              </Box>
            </PlanogramProvider>
          </>
        )}
      </DndProvider>
      <NotPermittedModal
        embedParam={embedParam ?? undefined}
        open={!!isForbidden}
        selectedDirectoryId={selectedDirectoryId}
        errorMessage="この棚割のアクセス権がありません"
        buttonMessage="棚割計画に戻る"
      />
    </Box>
  );
};
