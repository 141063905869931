import { Chip } from '@mui/material';
import { Product } from 'types/common';
import { ExternalInfo } from '../../types';

type Props = {
  product: Product | undefined;
  externalInfo: ExternalInfo;
};

export const InfoPackageChipIfNeeded = ({ product, externalInfo }: Props) => {
  if (!product) {
    return <></>;
  }
  const annotation = externalInfo.annotations.find(
    (annot) => annot.itemCd === product.detail!.organization_product_id!
  );
  if (!annotation || annotation.type !== '情報パッケージ') {
    return <></>;
  }
  return (
    <Chip
      key="本部情報あり"
      sx={{
        fontSize: '7pt',
        height: '10pt',
        margin: '1pt',
        '& .MuiChip-label': {
          padding: '4px',
        },
      }}
      label="本部情報あり"
      color="primary"
      variant="outlined"
    />
  );
};
