import { BoxWidthDivider, SortIconButtons } from '@components/organisms';
import { Box, Typography } from '@mui/material';
import { widthList, getProfitTabsValueByKey } from '@utils/const';
import { FC } from 'react';
import { t } from 'i18next';
import { ProfitTab } from 'types/common';

type Props = {
  profitTab: ProfitTab;
  handleClick: () => void;
  isAscending: boolean;
  isBucket?: boolean;
};

export const ProfitHeaderOptional: FC<Props> = ({
  profitTab,
  handleClick,
  isAscending,
  isBucket,
}) => {
  return (
    <Box component="div" display="flex">
      {!isBucket && (
        <BoxWidthDivider width={widthList.face}>
          <Typography variant="body2">フェイス</Typography>
        </BoxWidthDivider>
      )}
      <BoxWidthDivider width={widthList.profit} pr={1}>
        <Typography variant="body2" mr={1}>
          {getProfitTabsValueByKey(profitTab, t('gross_profit'))}
        </Typography>
        {/* todo ソート実装時にtrueにする */}
        <SortIconButtons handleClick={handleClick} isAscending={isAscending} />
      </BoxWidthDivider>
    </Box>
  );
};
