import { ReactNode, useState } from 'react';

import { Box, Stack, SxProps, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { ExternalInfo, StoreProductSale } from '../../types';
import { normalizeProductName } from '@utils/product';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { Product } from 'types/common';
import { ProductDetailsModal } from '../productDetailsModal/productDetailsModal';
import {
  getRecommendation,
  locateModelGondolaProduct,
} from '../../sakuraUtils';
import { useModelGondolasContext } from '../modelGondolasContext';
import { KihonShohinChipIfNeeded } from '../chips/kihonShohin';
import { InfoPackageChipIfNeeded } from '../chips/infoPackage';
import { SnsInfoChipIfNeeded } from '../chips/snsInfo';
import { NewItemChipIfNeeded } from '../chips/newItem';

export type UriageTableEntry = {
  product: Product;
  sale: StoreProductSale;
};

const TableTypography = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => (
  <Typography fontSize="8pt" sx={sx}>
    {children}
  </Typography>
);

type UriageTableItemProps = {
  entry: UriageTableEntry;
  externalInfo: ExternalInfo;
};

export const UriageTableItem = ({
  entry,
  externalInfo,
}: UriageTableItemProps) => {
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const chips = [
    <KihonShohinChipIfNeeded key="基本商品" product={entry.product} />,
    <NewItemChipIfNeeded
      key="新商品"
      product={entry.product}
      externalInfo={externalInfo}
    />,
    <InfoPackageChipIfNeeded
      key="本部情報"
      product={entry.product}
      externalInfo={externalInfo}
    />,
    <SnsInfoChipIfNeeded
      key="SNS情報"
      product={entry.product}
      externalInfo={externalInfo}
    />,
  ];
  const recom = getRecommendation(entry.product);
  const modelGondolas = useModelGondolasContext().value?.modelGondolas;
  const modelGondolaProductAssignment =
    modelGondolas &&
    locateModelGondolaProduct(
      entry.product.detail!.organization_product_id!,
      modelGondolas
    );

  return (
    <>
      <Box
        component="div"
        sx={{
          borderTop: '1px lightgrey solid',
          py: 1,
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!openProductDetails) {
            setOpenProductDetails(true);
          }
        }}
      >
        <Stack direction="row" spacing={2}>
          <Box component="div" width="160pt">
            <Stack direction="row" spacing={2}>
              {/* 商品画像の列 */}
              <ProductImage
                product={entry.product}
                width="40"
                height="40"
                objectFit="contain"
              />

              {/* 商品情報の列 */}
              <Stack>
                <Typography fontSize="6pt" color="darkgrey">
                  {entry.product.detail!.organization_product_id}
                </Typography>
                <TableTypography>
                  {normalizeProductName(entry.product.name)}
                </TableTypography>
                <Stack direction="row">{chips}</Stack>

                <Box component="div" height="1pt" />

                {/* 推奨開始日 */}
                <Typography fontSize="7pt" color="#666">
                  推奨開始 {recom.salesStartDate.getMonth() + 1}月
                  {recom.salesStartDate.getDate()}日
                  {!recom.salesEndDate &&
                    (4 <= recom.nWeeksSinceRecommendationStarted ? (
                      <span
                        style={{ color: 'red' }}
                      >{`（${recom.nWeeksSinceRecommendationStarted}週経過）`}</span>
                    ) : (
                      `（${recom.nWeeksSinceRecommendationStarted}週経過）`
                    ))}
                </Typography>
                {/* 推奨終了日 */}
                {recom.salesEndDate && (
                  <Typography fontSize="7pt" color="red">
                    推奨終了予定 {recom.salesEndDate.getMonth() + 1}月
                    {recom.salesEndDate.getDate()}日
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Box>

          {/* 売上パフォーマンスの列 */}
          <Stack>
            {/* 店舗取扱 */}
            <Stack direction="row" spacing={0.5}>
              <Typography fontSize="7pt">
                店舗取扱：
                {entry.sale.quantity > 0 ? 'あり' : 'なし'}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography
                fontSize="7pt"
                color={0 < entry.sale.quantity ? 'black' : '#666'}
              >
                前週販売数：{entry.sale.quantity}
              </Typography>
            </Stack>

            {/* DO導入率 */}
            <Stack direction="row" spacing={0.5}>
              <Typography fontSize="7pt">DO導入率：</Typography>
              {entry.sale.doAdoptionRate < 0.7 ? (
                <WarningIcon sx={{ fontSize: '8pt' }} color="warning" />
              ) : (
                <></>
              )}
              <Typography
                fontSize="8pt"
                color={entry.sale.doAdoptionRate < 0.7 ? 'red' : 'black'}
              >
                {Math.round(entry.sale.doAdoptionRate * 100)}%
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <ProductDetailsModal
        open={openProductDetails}
        handleClose={() => setOpenProductDetails(false)}
        product={entry.product}
        modelGondolaProductAssignment={modelGondolaProductAssignment}
        externalInfo={externalInfo}
        storeProductSale={entry.sale}
      />
    </>
  );
};
