import { FC } from 'react';
import { Box } from '@mui/material';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { Product } from '../../../../types/common';
import { useModelGondolaAndAllProductsContext } from '@components/pages/scannerDetails/utils/modelGondolaAndAllProductsProvider';
import { theme } from '../../../../theme';
import { t } from 'i18next';

type Props = {
  product?: Product;
};

export const ProductFlagView: FC<Props> = ({ product }) => {
  return (
    <Box component="div" display="inline-flex" alignItems="center" gap="4px">
      {product?.detail?.tags?.map((tag) => (
        <ProductFlagLabel key={tag} name={tag} />
      ))}
    </Box>
  );
};

type ProductFlagViewSakuraProps = {
  product?: Product;
  additionalChips?: React.ReactNode;
};

export const ProductFlagViewSakura: FC<ProductFlagViewSakuraProps> = ({
  product,
  additionalChips,
}) => {
  const externalInfo = useModelGondolaAndAllProductsContext()?.externalInfo;
  const tags = product?.detail?.tags || [];
  const isAlreadyCut = tags.includes(t('attributes.sales_ended'));
  const itemCd = product?.detail?.organization_product_id;
  const isToBeCut = !!(itemCd && externalInfo?.cutItems.includes(itemCd));
  return (
    <Box component="div" display="inline-flex" alignItems="center" gap="4px">
      {product?.detail?.tags?.map((tag) => {
        if (tag === t('attributes.sales_ended')) {
          const endDateStr = product.detail?.sales_end_date;
          const endDate = endDateStr && new Date(endDateStr);
          const mmdd =
            endDate && `${endDate.getMonth() + 1}月${endDate.getDate()}日`;
          const tagLabel = mmdd
            ? `${t('attributes.sales_ended')}済(${mmdd})`
            : tag;
          return (
            <ProductFlagLabel
              key={tag}
              name={tagLabel}
              backgroundColor={theme.palette.error.light}
              textColor={theme.palette.error.contrastText}
            />
          );
        }
        if (tag === t('attributes.not_3D_scanned')) {
          return null;
        }
        return <ProductFlagLabel key={tag} name={tag} />;
      })}

      {isToBeCut && !isAlreadyCut && (
        <ProductFlagLabel
          key={`${t('attributes.sales_ended')}予定`}
          name={`${t('attributes.sales_ended')}予定`}
          backgroundColor={theme.palette.warning.light}
        />
      )}

      {additionalChips || []}
    </Box>
  );
};
