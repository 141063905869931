import {
  ContentCopy,
  Create,
  Delete,
  Folder,
  PersonAdd,
  Star,
  StarBorderOutlined,
} from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';
import { Status } from 'types/common';
import { PlanogramDirectory } from 'types/planogram';
import { t } from 'i18next';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  planogramDirectory?: PlanogramDirectory;
  handleOpenDeleteDialog: () => void;
  handleClonePlanogram: () => void;
  handleUpdatePlanogramName: () => void;
  handleUpdateDirectoryName: () => void;
  handleMoveDirectory?: () => void;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  status?: Status;
  isDirectory: boolean;
  isStarred?: boolean;
  isDisabledSharePermission?: boolean;
  handleFavoriteClick: () => void;
  handleSharePermission: () => void;
  isDisabledDuplicateButton?: boolean;
  isDisabledDeleteButton?: boolean;
  isDisabledMoveButton?: boolean;
  isDisabledEditButton?: boolean;
};

export const PlanogramsTableMenu: FC<Props> = ({
  handleOpenDeleteDialog,
  handleClonePlanogram,
  handleUpdatePlanogramName,
  handleUpdateDirectoryName,
  handleMoveDirectory,
  anchorEl,
  handleClose,
  status,
  isDirectory,
  handleFavoriteClick,
  handleSharePermission,
  isStarred = false,
  isDisabledSharePermission = false,
  isDisabledDeleteButton = false,
  isDisabledMoveButton = false,
  isDisabledEditButton = false,
  isDisabledDuplicateButton = false,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          display: 'flex',
          alignItems: 'center',
          marginTop: 9.8,
          marginLeft: 16,
        },
      }}
    >
      <MenuItem
        onClick={handleOpenDeleteDialog}
        disabled={isDisabledDeleteButton}
      >
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>削除</ListItemText>
      </MenuItem>
      {(status === 'processed' || !isDirectory) && (
        <MenuItem
          onClick={handleClonePlanogram}
          // NOTE: 権限とは別問題（フォルダコピー時に大量のデータがコピーされるため、再検討を行う必要がある）でフォルダの複製は無効にします。
          disabled={isDisabledDuplicateButton}
        >
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>複製</ListItemText>
        </MenuItem>
      )}
      <MenuItem onClick={handleFavoriteClick}>
        <ListItemIcon>
          {isStarred ? <Star /> : <StarBorderOutlined />}
        </ListItemIcon>
        <ListItemText>
          {isStarred ? 'スターを外す' : 'スターをつける'}
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={handleMoveDirectory} disabled={isDisabledMoveButton}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText>移動</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={
          isDirectory ? handleUpdateDirectoryName : handleUpdatePlanogramName
        }
        disabled={isDisabledEditButton}
      >
        <ListItemIcon>
          <Create />
        </ListItemIcon>
        <ListItemText>
          {isDirectory ? '名前を変更' : `名前・${t('shelf_type')}を変更`}
        </ListItemText>
      </MenuItem>
      <MenuItem
        onClick={handleSharePermission}
        disabled={isDisabledSharePermission}
      >
        <ListItemIcon>
          <PersonAdd />
        </ListItemIcon>
        <ListItemText>共有</ListItemText>
      </MenuItem>
    </Menu>
  );
};
