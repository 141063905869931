import { Marker } from '@components/organisms';
import { Box, keyframes } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC, Fragment, memo } from 'react';
import { theme } from 'theme';
import { RealogramCandidateFace, RealogramSelectedItem } from 'types/realogram';

type Props = {
  bboxEnabled: boolean;
  ratio: number;
  markerEnabled: boolean;
  handleClickBbox?: (
    selectedItem: RealogramSelectedItem,
    isComparingBox: boolean
  ) => void;
  maxThreshold?: number;
  comparisonSameIdsSet?: Set<number>;
  isUnknownProductsReviseMode?: boolean;
  isBucket?: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  isSelected: boolean;
  color: string;
  bgColor?: string;
  isShowBorder: boolean;
  face: RealogramCandidateFace;
  compartmentId: string;
  shelfBoardId: string;
  shotIndex: number;
  isComparingBox: boolean;
};

const selectedZIndex = 2;
const markersOffsetTop = 12;
const blink = keyframes`
  0%,100% {
    border-color:${theme.palette.white.primary};
  }
  50% {
    border-color:transparent;
  }
`;
export const Bbox: FC<Props> = memo(
  ({
    bboxEnabled,
    handleClickBbox,
    ratio,
    x,
    y,
    width,
    height,
    isSelected,
    color,
    bgColor,
    isShowBorder,
    isUnknownProductsReviseMode,
    markerEnabled,
    face,
    compartmentId,
    shelfBoardId,
    shotIndex,
    isComparingBox,
  }) => {
    const convertPxByRatio = (value: number) => value * ratio;

    return (
      <Fragment>
        {bboxEnabled && (
          <Box
            component="div"
            onClick={(event) => {
              event.stopPropagation();
              handleClickBbox &&
                handleClickBbox(
                  {
                    shelfBoardId,
                    compartmentId,
                    item: face,
                    shotIndex,
                    isBboxClicked: true,
                  },
                  isComparingBox
                );
            }}
            sx={{
              position: 'absolute',
              top: convertPxByRatio(y),
              left: convertPxByRatio(x),
              width: convertPxByRatio(width),
              height: convertPxByRatio(height),
              border: isSelected
                ? `2px solid ${theme.palette.white.primary}`
                : undefined,
              borderRadius: '7px',
              animation:
                isSelected && !isUnknownProductsReviseMode
                  ? `${blink} 1.2s infinite`
                  : '',
              zIndex: isSelected ? selectedZIndex : 1,
            }}
          >
            <Box
              component="div"
              sx={{
                height: '100%',
                width: '100%',
                border: isShowBorder ? `2px solid ${color}` : 'none',
                borderRadius: '5px',
                backgroundColor: bgColor,
              }}
            />
          </Box>
        )}
        {markerEnabled && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              top: convertPxByRatio(y) - markersOffsetTop,
              left: convertPxByRatio(x),
              width: convertPxByRatio(width),
              height: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Marker
              type="turned"
              sx={{
                width: '10px',
                height: '10px',
                color: CommonColors.deepRed,
              }}
            />
            <Marker
              type="planned"
              sx={{
                width: '10px',
                height: '10px',
                color: CommonColors.turbo,
              }}
            />
          </Box>
        )}
      </Fragment>
    );
  }
);

Bbox.displayName = 'Bbox';
