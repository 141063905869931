import React, { Fragment, useState } from 'react';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { NoteAltOutlined } from '@mui/icons-material';
import TableViewIcon from '@mui/icons-material/TableView';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

import {
  ActionMemoContextType,
  MemoItem,
  useActionMemoContext,
} from './storeActionMemoContext';
import { AvailableItems, ExternalInfo, StoreProductSale } from '../../types';
import { MemoViewItem } from './memoViewItem';
import { useModelGondolasContext } from '../modelGondolasContext';
import { Product } from 'types/common';
import { findPossibleGondolasForItem } from './utils';
import { useAllProductsContext } from '@utils/ProductsContext';

const itemToGondolaSortKey = (
  itemCd: string,
  availableItems: AvailableItems
) => {
  const possibleGondolas = findPossibleGondolasForItem(itemCd, availableItems);
  const names = possibleGondolas.map((gondola) => gondola.name);
  if (names.length === 0) {
    return 'その他';
  }
  return names.join('・');
};

type MemoListProps = {
  title: string;
  icon: React.ReactNode;
  items: MemoItem[];
  products: Product[];
  storeProductSales: StoreProductSale[];
  externalInfo: ExternalInfo;
  actionMemoContext: ActionMemoContextType;
  availableItems: AvailableItems;
  order: 'original' | 'gondola';
};

const MemoList = ({
  title,
  icon,
  items,
  products,
  storeProductSales,
  externalInfo,
  actionMemoContext,
  availableItems,
  order,
}: MemoListProps) => {
  const sortedItems =
    order === 'original'
      ? items
      : [...items].sort((a, b) => {
          const aKey = itemToGondolaSortKey(a.itemCd, availableItems);
          const bKey = itemToGondolaSortKey(b.itemCd, availableItems);
          return aKey.localeCompare(bKey);
        });

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon}
        <Typography variant="body2" color="#555">
          {title}
        </Typography>
      </Stack>
      <Divider sx={{ my: 1 }} />
      {sortedItems.length ? (
        sortedItems.map((item) => {
          const product = products.find(
            (p) => p.detail?.organization_product_id === item.itemCd
          );
          if (!product) {
            return <Fragment key={item.itemCd} />;
          }
          const sale = storeProductSales.find(
            (sale) => sale.itemCd === item.itemCd
          );

          // 見つからない場合は空のStoreProductSaleオブジェクトを作成
          const fallbackSale: StoreProductSale = {
            itemCd: item.itemCd,
            gondolaCodes: [],
            storeCd: '',
            zoAdoptionRate: 0,
            doAdoptionRate: 0,
            zoGondolaShare: 0,
            doGondolaShare: 0,
            quantity: 0,
          };

          return (
            <MemoViewItem
              key={item.itemCd}
              memoItem={item}
              product={product}
              externalInfo={externalInfo}
              removeMemoItem={
                actionMemoContext.isInAddItems(item.itemCd)
                  ? actionMemoContext.removeAddItem
                  : actionMemoContext.removeCutItem
              }
              storeProductSale={sale || fallbackSale}
              availableItems={availableItems}
            />
          );
        })
      ) : (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="40px"
        >
          <TableViewIcon sx={{ fontSize: '64', color: 'lightgrey' }} />
          <Typography variant="caption2" color="lightgrey">
            メモされている商品はありません。
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

type SlideTransitionProps = TransitionProps & {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any -- MUI Dialog components accepts this type of children
  children: React.ReactElement<any, any>;
};

const Transition = React.forwardRef(function TransitionSlide(
  props: SlideTransitionProps,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  handleClose: () => void;
  externalInfo: ExternalInfo;
  storeProductSales: StoreProductSale[];
  availableItems: AvailableItems;
};

export const MemoView = ({
  open,
  handleClose,
  externalInfo,
  storeProductSales,
  availableItems,
}: Props) => {
  const actionMemoContext = useActionMemoContext();
  const modelGondolasContext = useModelGondolasContext();

  const itemCds = [
    ...actionMemoContext.addItems,
    ...actionMemoContext.cutItems,
  ].map((item) => item.itemCd);
  const products = useAllProductsContext(itemCds).products;
  const addItems = actionMemoContext.addItems;
  const cutItems = actionMemoContext.cutItems;

  const [order, setOrder] = useState<'original' | 'gondola'>('original');
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          position: 'fixed',
          bottom: 80,
          right: 16,
          margin: 0,
        },
      }}
    >
      <DialogTitle>
        <Stack>
          <Stack direction="row" alignItems="center">
            <NoteAltOutlined />
            <Box component="div" width="5pt" />
            店舗での品揃え変更のメモ
          </Stack>
          <Box component="div" width="320pt">
            <Typography fontSize="8pt" sx={{ color: '#777' }}>
              {modelGondolasContext.value &&
                `${modelGondolasContext.value.modelGondolaWeek}配信版のモデゴンに関連してこの店舗で実施予定の品揃え変更をメモすることができます。`}
              メモはこの端末のみで保存されています。
            </Typography>
          </Box>
          <Box component="div">
            <FormControl>
              <RadioGroup
                row
                value={order}
                onChange={(e) =>
                  setOrder(e.target.value as 'original' | 'gondola')
                }
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <FormControlLabel
                    value="original"
                    control={<Radio sx={{ transform: 'scale(0.7)' }} />}
                    label={
                      <span style={{ fontSize: '9pt' }}>メモ追加順で整理</span>
                    }
                  />
                  <FormControlLabel
                    value="gondola"
                    control={<Radio sx={{ transform: 'scale(0.7)' }} />}
                    label={
                      <span style={{ fontSize: '9pt' }}>ゴンドラ順で整理</span>
                    }
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: '70vh',
          overflowY: 'auto',
          marginBottom: '20pt',
        }}
      >
        <Stack spacing={1}>
          <MemoList
            title={`店舗に導入する商品のメモ (${addItems.length})`}
            icon={<AddShoppingCartIcon />}
            items={addItems}
            products={products}
            storeProductSales={storeProductSales}
            externalInfo={externalInfo}
            actionMemoContext={actionMemoContext}
            availableItems={availableItems}
            order={order}
          />

          <Box component="div" height="20pt" />

          <MemoList
            title={`カット・発注停止する商品のメモ (${cutItems.length})`}
            icon={<RemoveShoppingCartIcon />}
            items={cutItems}
            products={products}
            storeProductSales={storeProductSales}
            externalInfo={externalInfo}
            actionMemoContext={actionMemoContext}
            availableItems={availableItems}
            order={order}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
