import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { SelectionModalResult } from '@components/molecules/selectionModalResult/selectionModalResult';
import { Box } from '@mui/material';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import { useAppSelector } from '@store/index';
import { AppAbility } from '@utils/abac';
import { FC, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import {
  RealogramDirectory,
  RealogramDirectoryBreadCrumbs,
  RealogramDirectoryParent,
  RealogramDirectorySearch,
} from 'types/realogram';
import { DirectoryListItem } from './directoryListItem';

type Props = {
  isLoading: boolean;
  isFetching: boolean;
  shouldFetch: boolean;
  realogramOffset: number;
  realogramDirectories?: RealogramDirectory[] | RealogramDirectorySearch[];
  realogramBreadcrumbs?: RealogramDirectoryBreadCrumbs;
  realogramDirectoryParent?: RealogramDirectoryParent;
  disabledSelect?: boolean;
  userId?: number;
  textSearch?: string;
  ability: AppAbility;
  hasNextPage?: boolean;
  setRealogramOffset: (value: number) => void;
  handleClickBreadcrumbs: (item: DirectoryType) => void;
  handleClickListItem: (item: RealogramDirectory) => void;
  fetchNextPage: VoidFunction;
};

export const SelectionRealograms: FC<Props> = ({
  isLoading,
  isFetching,
  shouldFetch,
  realogramOffset,
  realogramDirectories,
  realogramBreadcrumbs,
  realogramDirectoryParent,
  userId,
  textSearch,
  ability,
  hasNextPage,
  handleClickBreadcrumbs,
  handleClickListItem,
  fetchNextPage,
}) => {
  const { selectedItemId } = useAppSelector(selectionStoreModal);

  const handleEndReached = () => {
    const shouldSkip = !hasNextPage || isFetching || isLoading || !textSearch;

    if (shouldSkip) return;
    fetchNextPage();
  };

  const selectedItemIndex = useMemo(() => {
    const index = realogramDirectories?.findIndex(
      (d) => d.id === selectedItemId
    );

    if (index !== -1) return index;
  }, [selectedItemId, realogramDirectories]);

  return (
    <>
      <Box component="div" flex={1}>
        {!realogramDirectories?.length ? (
          <Box
            component="div"
            sx={{
              color: '#D9D9D9',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.white.primary,
              height: '100%',
            }}
          >
            <SelectionModalResult
              realogramOffset={realogramOffset}
              isLoading={isLoading || isFetching || shouldFetch}
              textSearch={textSearch}
              data={realogramDirectories}
              type="store"
            />
          </Box>
        ) : (
          <Virtuoso
            data={realogramDirectories}
            initialTopMostItemIndex={{
              index: selectedItemIndex ?? 0,
            }}
            endReached={handleEndReached}
            components={{
              // eslint-disable-next-line @typescript-eslint/naming-convention -- ライブラリの仕様のため許容する
              Footer: () => {
                if (isFetching || isLoading)
                  return (
                    <Box
                      component="div"
                      sx={{
                        color: '#D9D9D9',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.white.primary,
                      }}
                    >
                      <SelectionModalResult
                        isLoading={isLoading || isFetching}
                        textSearch={textSearch}
                        data={realogramDirectories}
                      />
                    </Box>
                  );
                return <></>;
              },
            }}
            itemContent={(_, directory) => {
              return (
                <DirectoryListItem
                  handleClickListItem={handleClickListItem}
                  directory={directory}
                  userId={userId}
                  ability={ability}
                />
              );
            }}
          />
        )}
      </Box>
      {!!realogramBreadcrumbs?.length &&
        realogramDirectoryParent &&
        !textSearch && (
          <Box
            component="div"
            padding="8px 16px"
            borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <BreadcrumbList
              breadcrumbs={realogramBreadcrumbs}
              parentDirectory={realogramDirectoryParent}
              screenType="Modal"
              fontSize="12px"
              directoryRootName="店舗一覧"
              modalMaxWidth={80}
              handleClickBreadcrumbs={handleClickBreadcrumbs}
            />
          </Box>
        )}
    </>
  );
};
