import { FC, useState } from 'react';
import { locateModelGondolaProduct } from '@components/pages/sakura/sakuraUtils';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { useModelGondolaAndAllProductsContext } from '../../utils/modelGondolaAndAllProductsProvider';
import { getProductDisplayValues } from '@utils/product';
import { MutableRefs, isSelectedItem } from '@utils/realogram';
import { Product, ShelfDetailView } from 'types/common';
import { RealogramSelectedItem } from 'types/realogram';
import { StyledListItemButton } from '@components/organisms/styledListItemButton/styledListItemButton';
import {
  ImageBox,
  NameBox,
  RequiredBox,
} from '@components/organisms/shelfListItem/fragments';
import { Box, Stack, Typography } from '@mui/material';
import { widthList } from '@components/organisms';
import { theme } from 'theme';
import {
  ProductFlagLabel,
  ProductFlagViewSakura,
} from '@components/organisms/realogramCandidatesList/fragments';
import { assortmentWidthList } from './constants';
import { RealogramFaceImage } from '@components/molecules/realogramFaceImage/realogramFaceImage';
import { t } from 'i18next';
import { ProductDetailsModal } from './productDetailsModal';

type AssortmentChipsProps = {
  realogramPresenseChip?: React.ReactNode;
};

const AssortmentChips = ({ realogramPresenseChip }: AssortmentChipsProps) => {
  return (
    <>
      <Box
        component="div"
        minWidth={assortmentWidthList.realogramPresense}
        display="flex"
        justifyContent="center"
      >
        {realogramPresenseChip}
      </Box>
    </>
  );
};

type Props = {
  realogramItem: RealogramSelectedItem;
  products?: Product[];
  realogramDetailView: ShelfDetailView;
  selectedItem?: RealogramSelectedItem;
  handleClick: (item: RealogramSelectedItem) => void;
  refs: MutableRefs;
  realogramPresenseChip?: React.ReactNode;
  secondaryText?: React.ReactNode; // 商品名の下にあるテキスト
  productNameSecondaryChip?: React.ReactNode; // 商品名の上につく追加チップ
};

const productFlagViewHeight = 84;
const defaultHeight = 64;

export const AssortmentListRow: FC<Props> = ({
  realogramItem,
  products,
  selectedItem,
  realogramDetailView,
  handleClick,
  refs,
  realogramPresenseChip,
  secondaryText,
  productNameSecondaryChip,
}) => {
  const { item, shelfBoardId, compartmentId } = realogramItem;
  const primaryCandidate = item.primary_candidate;
  const product = products?.find((p) => p.id === primaryCandidate?.product_id);
  const refKey = `${shelfBoardId}-${compartmentId}-${item.id}`;
  const { name, productCode } = getProductDisplayValues(item, product);
  const isSelected = isSelectedItem(
    realogramDetailView,
    realogramItem,
    selectedItem
  );

  const organizationProductId = undefined;

  return (
    <StyledListItemButton
      ref={(i) => refs.current.set(refKey, i)} //自動スクロールに必要
      component="li"
      sx={{
        height:
          realogramDetailView === 'productFlag'
            ? productFlagViewHeight
            : defaultHeight,
        p: 0,
        pr: 2,
      }}
      selected={isSelected}
    >
      <RequiredBox onClick={() => handleClick(realogramItem)} px={1} pl={2}>
        <Stack direction="column">
          <Typography
            variant="caption"
            minWidth={widthList.eanCodeWidth}
            fontSize="inherit"
            color={theme.palette.textBlack.table}
          >
            {productCode ?? organizationProductId ?? ''}
          </Typography>
        </Stack>
        <ImageBox>
          {item ? ( //スキャン詳細では欠品または不明な商品の時の表示がある
            <RealogramFaceImage face={item} product={product} baseSize={56} />
          ) : (
            <ProductImage product={product} layout="fill" objectFit="contain" />
          )}
        </ImageBox>
        <NameBox>
          {realogramDetailView === 'default' &&
            productCode &&
            product?.detail?.tags?.includes(t('sales_ended')) && (
              <Box component="div" sx={{ display: 'inline-flex', pb: 0.5 }}>
                <ProductFlagLabel name={t('sales_ended')} />
              </Box>
            )}
          {realogramDetailView === 'productFlag' && (
            <Box component="div" position="absolute" top="0">
              <ProductFlagViewSakura
                product={product}
                additionalChips={productNameSecondaryChip}
              />
            </Box>
          )}
          <Typography noWrap fontSize="inherit" width="100%">
            {name}
          </Typography>
          {secondaryText && <Box component="div">{secondaryText}</Box>}
        </NameBox>
      </RequiredBox>
      <AssortmentChips realogramPresenseChip={realogramPresenseChip} />
    </StyledListItemButton>
  );
};

type AssortmentListRowAbsentProductProps = {
  itemCd: string;
  products: Product[];
  realogramDetailView: ShelfDetailView;
  // refs: MutableRefs;
  modelGondolaPresenseChip?: React.ReactNode;
  realogramPresenseChip?: React.ReactNode;
  secondaryText?: React.ReactNode; // 商品名の下にあるテキスト
  productNameSecondaryChip?: React.ReactNode; // 商品名の上につく追加チップ
};

export const AssortmentListRowAbsentProduct = ({
  itemCd,
  products,
  realogramDetailView,
  // refs,
  realogramPresenseChip,
  secondaryText,
  productNameSecondaryChip,
}: AssortmentListRowAbsentProductProps) => {
  const productFlagViewHeight = 104;
  const defaultHeight = 84;
  const product = products.find(
    (p) => p.detail?.organization_product_id === itemCd
  );
  const isSelected = false;
  const view = realogramDetailView;
  const name = product?.name || '';

  // 商品詳細モーダルを表示するためのデータ準備
  const modelGondolaContext = useModelGondolaAndAllProductsContext();
  const externalInfo = modelGondolaContext?.externalInfo;
  const productSale = modelGondolaContext?.productSales.find(
    (ps) => ps.itemCd === itemCd
  );
  const modelGondolaProduct =
    modelGondolaContext?.relevantModelGondolas &&
    locateModelGondolaProduct(
      itemCd,
      modelGondolaContext.relevantModelGondolas
    );

  // 商品詳細モーダルを表示するデータが揃っている場合 true
  const showProductDetailModel = product && externalInfo && productSale;

  // 商品詳細モーダルの表示状態
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const clickAbsentProduct = () => {
    if (!openProductDetails) {
      setOpenProductDetails(true);
    }
  };

  return (
    <>
      {showProductDetailModel && (
        <ProductDetailsModal
          openProductDetails={openProductDetails}
          setOpenProductDetails={setOpenProductDetails}
          masterProduct={product}
          externalInfo={externalInfo}
          modelGondolaProductAssignment={modelGondolaProduct}
          productSale={productSale}
        />
      )}
      <StyledListItemButton
        // ref={(i) => refs.current.set(refKey, i)} //自動スクロールに必要
        component="li"
        sx={{
          height:
            view === 'productFlag' ? productFlagViewHeight : defaultHeight,
          p: 0,
          pr: 2,
        }}
        selected={isSelected}
      >
        <RequiredBox onClick={clickAbsentProduct} px={1} pl={2}>
          <Stack direction="column">
            <Typography
              variant="caption"
              minWidth={widthList.eanCodeWidth}
              fontSize="inherit"
              color={theme.palette.textBlack.table}
            >
              {itemCd}
            </Typography>
          </Stack>
          <ImageBox>
            <ProductImage product={product} layout="fill" objectFit="contain" />
          </ImageBox>
          <NameBox>
            <Box component="div" position="absolute" top="0">
              <ProductFlagViewSakura
                product={product}
                additionalChips={productNameSecondaryChip}
              />
            </Box>
            <Typography noWrap fontSize="inherit" width="100%">
              {name}
            </Typography>
            {secondaryText && <Box component="div">{secondaryText}</Box>}
          </NameBox>
        </RequiredBox>
        <AssortmentChips realogramPresenseChip={realogramPresenseChip} />
      </StyledListItemButton>
    </>
  );
};
