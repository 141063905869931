import { ProductEmptyResult } from '@components/molecules';
import { headerHeight } from '@components/organisms/header/header';
import { Bboxes } from '@components/organisms/realogramImageAndBbox/fragments/bboxes';
import { Box } from '@mui/material';
import { withOpacity } from '@utils/colors';
import Image from 'next/image';
// eslint-disable-next-line import/no-named-as-default -- ライブラリのため
import ReactCrop, { Crop } from 'react-image-crop';
import { theme } from 'theme';
import { RealogramShelfBoard } from 'types/realogram';

type Props = {
  realogramImage?: string;
  zoomScale: number;
  crop: Crop;
  height: number;
  width: number;
  shotBboxes?: RealogramShelfBoard[];
  ratio: number;
  canNotShowImage: boolean;
  isReady: boolean;
  dataImage: string;
  setCrop: (crop: Crop) => void;
};
const showOpacity = 1;
const bboxOpacity = 0.4;
const cropBgColor = 0.6;
export const CropImageAndBbox = ({
  realogramImage,
  zoomScale,
  crop,
  height,
  width,
  shotBboxes,
  ratio,
  canNotShowImage,
  isReady,
  dataImage,
  setCrop,
}: Props) => {
  return (
    <Box
      component="div"
      width="100%"
      height={`calc(100vh - ${headerHeight}px)`}
      sx={{
        backgroundColor: '#000',
        overflow: zoomScale > 1 ? 'scroll' : 'hidden',
      }}
    >
      {realogramImage && shotBboxes && !canNotShowImage ? (
        <Box
          component="div"
          height={`calc(100% * ${zoomScale})`}
          width={`calc(100% * ${zoomScale})`}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            '.ReactCrop__crop-mask': {
              fillOpacity: '0.5',
            },
            '.ReactCrop__crop-selection': {
              backgroundColor: `${withOpacity(
                theme.palette.white.primary,
                cropBgColor
              )} !important`,
            },
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            keepSelection
            minHeight={1}
            minWidth={1}
            style={{
              cursor: 'initial',
              opacity: isReady ? showOpacity : 0,
            }}
          >
            <Box
              component="div"
              height={height * zoomScale}
              width={width * zoomScale}
            >
              <Image
                data-image={dataImage}
                src={realogramImage}
                alt=""
                height={height * zoomScale}
                width={width * zoomScale}
              />
              {shotBboxes && isReady && (
                <Bboxes
                  markerEnabled={false}
                  ratio={ratio * zoomScale}
                  bboxEnabled
                  shelfBoards={shotBboxes}
                  defaultBboxColor={{
                    color: withOpacity('#FFFFFF', bboxOpacity),
                    bgColor: 'transparent',
                  }}
                  view="default"
                  mode="default"
                  isComparingBox={false}
                />
              )}
            </Box>
          </ReactCrop>
        </Box>
      ) : (
        <Box component="div" width="100%" height="100%">
          <ProductEmptyResult
            title="画像が表示できません"
            message="時間を置いて再度ご確認ください。"
          />
        </Box>
      )}
    </Box>
  );
};
