import { ProductCompartments } from '@components/organisms/productCompartments/productCompartments';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import {
  ManipulateQueriesData,
  useUrlQueryParams,
} from '@hooks/useUrlQueryParams';
import { Box } from '@mui/material';
import {
  addRecentProduct,
  setOpenOrientationMenu,
  updateIsShowProductDetail,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { selectProductState } from '@reducers/product/selector';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  calculateJustifyMargin,
  getProductOrigin,
  isClickableCompartment,
} from '@utils/planogram';
import { getProductDisplaySize } from '@utils/product';
import { getAttributeQueryParams } from '@utils/urlQueryParams';
import { isEqual } from 'lodash';
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { useSearchParams } from 'react-router-dom';
import {
  DndData,
  Product,
  ProfitTab,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import {
  BboxColors,
  JustifyContent,
  PlanogramProductCompartment,
  PlanogramShelvesDetail,
  ProductPosition,
} from 'types/planogram';
import { ItemTypes } from 'types/rack';
import { RealogramSelectedItem } from 'types/realogram';
import { CompartmentOrientationMenu } from '../compartmentOrientationMenu/compartmentOrientationMenu';
import { CompartmentMenu } from '../productsBay/fragments/compartmentMenu';
import { getProductReportValue } from '../realogramImageAndBbox/fragments/bboxes';

type Props = {
  shelf: PlanogramShelvesDetail['shelves'][0];
  row?: PlanogramProductCompartment[];
  rowIndex: number;
  shelfHeight: number;
  diffColor?: string;
  bboxColors: BboxColors;
  bboxEnabled?: boolean;
  detailView?: ShelfDetailView;
  detailMode?: ShelfDetailMode;
  justifyContent?: JustifyContent;
  isEditor?: boolean;
  isCompared?: boolean;
  selectedRealogramItem?: RealogramSelectedItem;
  selectedProductCompartment?: Product;
  productPosition?: ProductPosition;
  selectedBucketId?: number;
};

type DropResult = {
  hoverIndex: number;
  rowIndex: number;
};

type Item = {
  data: DndData | undefined;
  index: number;
  rowIndex: number;
};

export const DisplayShelf: FC<Props> = ({
  shelf,
  row,
  rowIndex,
  diffColor,
  shelfHeight,
  bboxColors,
  bboxEnabled,
  detailView,
  detailMode,
  justifyContent = 'start',
  isEditor = true,
  isCompared = false,
  selectedRealogramItem,
  selectedProductCompartment,
  productPosition,
  selectedBucketId,
}) => {
  const data = usePlanogramContext();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const profitTab = searchParams.get('index') as ProfitTab;
  const productTag = getAttributeQueryParams(searchParams);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const {
    mode,
    openOrientationMenu,
    comparedProductPosition,
    bucketMode,
    isDragProduct,
    selectedBucketIdClone,
  } = useAppSelector(selectPlanogramEditorState);
  const comparisonSameIds = useAppSelector(selectProductState);
  const isDecreaseOpacity =
    (selectedBucketId?.toString() || selectedBucketIdClone?.toString()) &&
    bucketMode === 'area';

  const { addProduct, moveProducts } = usePlanogramPlan();
  const { manipulateQueryParameters } = useUrlQueryParams();
  const isEditingBay = isEditor && mode === 'BayEditor';

  const [isAllChildRendered, setIsAllChildRenndered] = useState(false);

  const productsBulk = isCompared
    ? data?.comparisonProductsBulk
    : data?.productsBulk;

  const productSalesReport = isCompared
    ? data?.comparisonProductSalesReport
    : data?.productSalesReport;

  const handleClickRotate = () => {
    dispatch(setOpenOrientationMenu(true));
  };

  const handleClickGroup = (
    e: MouseEvent<HTMLElement>,
    position: ProductPosition,
    product: Product
  ) => {
    // debugger;
    if (mode === 'BayEditor' || mode === 'preview') return; //NOTE: 什器編集中は商品タップさせない
    e.stopPropagation(); //親要素のonClick(handleClickAwayPlanogram)発火を抑制する
    //NOTE: 商品属性タブ選択時、該当属性を持たない商品はタップさせない
    if (detailView && !isClickableCompartment(detailView, product, productTag))
      return;
    const productPos = productPosition;
    if (
      isEqual(productPos, position) ||
      (isEqual(productPosition, position) &&
        product.id === selectedProductCompartment?.id) ||
      (isEqual(comparedProductPosition, position) &&
        product.id === selectedProductCompartment?.id)
    ) {
      resetState();
      return;
    }

    const queryParams: ManipulateQueriesData = {
      updateQueries: [],
      removeQueries: [],
    };

    const itemKey = isCompared ? 'compareItem' : 'item';
    queryParams.removeQueries?.push(isCompared ? 'item' : 'compareItem');

    queryParams.removeQueries?.push('selectedBucketId');
    queryParams.updateQueries?.push({
      key: itemKey,
      value: String(product.id),
    });
    queryParams.updateQueries?.push({
      key: 'position',
      value: JSON.stringify(position),
    });

    dispatch(updateIsShowProductDetail(true));

    if (!isCompared) {
      setAnchorEl(e.currentTarget);
      dispatch(setOpenOrientationMenu(false));
    }

    manipulateQueryParameters(queryParams);
  };

  const resetState = () => {
    setAnchorEl(null);
    dispatch(setOpenOrientationMenu(false));
    dispatch(updateIsShowProductDetail(false));
    manipulateQueryParameters({
      removeQueries: ['item', 'compareItem', 'position', 'selectedBucketId'],
    });
  };

  const [{ item }, drop] = useDrop<Item, unknown, { item?: Item }>(
    () => ({
      accept: [ItemTypes.ITEM, ItemTypes.ITEM_GROUP],
      collect: (monitor) => ({
        item: monitor.getItem(),
      }),
      drop: (item, monitor) => {
        if (detailMode === 'comparison' && !isEditor) return;
        const dropResult = monitor.getDropResult<DropResult>();
        const hoverIndex = getHoverIndex(row, dropResult);
        const itemType = monitor.getItemType();
        const { index, rowIndex: shelfIndex } = item;

        if (itemType === ItemTypes.ITEM) {
          if (!item.data?.product) {
            return;
          }
          addProduct({
            to: {
              indexX: 0,
              indexY: rowIndex,
              subPosition: { indexX: hoverIndex, indexY: 0 },
            },
            product: item.data.product,
          });

          data?.addProductToBulk?.(item.data.product);
          dispatch(addRecentProduct(item.data.product));
        } else {
          resetState();
          moveProducts({
            from: {
              indexX: 0,
              indexY: shelfIndex,
              subPosition: { indexX: index, indexY: 0 },
            },
            to: {
              indexX: 0,
              indexY: rowIndex,
              subPosition: { indexX: hoverIndex, indexY: 0 },
            },
          });
        }
      },
    }),
    [row, rowIndex]
  );

  useEffect(() => {
    if (selectedProductCompartment && productPosition && isAllChildRendered) {
      const anchorElement = document.querySelector(
        `[data-group-position="group-position-${productPosition.indexY}-${productPosition.subPosition.indexX}"]`
      );
      if (anchorElement) {
        setAnchorEl(anchorElement as HTMLAnchorElement);
      }
    }
  }, [productPosition, isAllChildRendered, selectedProductCompartment]);

  const justifyMargin =
    row && justifyContent === 'space_evenly'
      ? calculateJustifyMargin(shelf.width, row, productsBulk)
      : 0;

  const handleClickGroupRef = useRef<
    (
      e: MouseEvent<HTMLElement>,
      position: ProductPosition,
      product: Product
    ) => void
  >(() => void 0);
  const memoizedHandleClickGroup = useCallback(
    (
      e: MouseEvent<HTMLElement>,
      position: ProductPosition,
      product: Product
    ) => {
      handleClickGroupRef.current(e, position, product);
    },
    []
  );
  useEffect(() => {
    handleClickGroupRef.current = handleClickGroup;
  });

  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
      }}
      ref={drop}
      height="100%"
      width="100%"
    >
      <Box
        component="div"
        sx={{
          height: '100%',
          display: 'flex',
        }}
      >
        {row && (
          <>
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems:
                  getProductOrigin(shelf) === 'top' ? 'flex-start' : 'flex-end',
                // eslint-disable-next-line no-magic-numbers -- 半透明にするため
                opacity: isEditingBay ? 0.2 : 1,
                width: '100%',
              }}
            >
              {row.map((compartment, index) => {
                const isPlanogramSelected =
                  selectedProductCompartment &&
                  selectedProductCompartment.id === compartment.product_id;
                const isRealogramSelected =
                  selectedRealogramItem &&
                  selectedRealogramItem?.item?.primary_candidate?.product_id ===
                    compartment.product_id;
                const isPositionSelected =
                  productPosition?.indexY === rowIndex &&
                  productPosition.subPosition.indexX === index;
                const isSelected = !!(detailMode === 'comparison'
                  ? !selectedRealogramItem?.item.is_unknown &&
                    (isPlanogramSelected || isRealogramSelected)
                  : isPlanogramSelected && isPositionSelected);

                const product = productsBulk
                  ?.get(compartment.product_id)
                  ?.at(0);
                const productReport = productSalesReport?.products.find(
                  (report) => product?.id === report.product_id
                );
                const productValue = getProductReportValue(
                  profitTab,
                  productReport
                );
                const left = row.slice(0, index).reduce((acc, c) => {
                  const product = productsBulk?.get(c.product_id)?.at(0);
                  if (!product) return acc;

                  const { width: w } = getProductDisplaySize(
                    product,
                    c.face_front,
                    c.orientation
                  );
                  const x = c.count?.x ?? 0;
                  return acc + (w + justifyMargin) * x;
                }, justifyMargin);

                const { width: w } = product
                  ? getProductDisplaySize(
                      product,
                      compartment.face_front,
                      compartment.orientation
                    )
                  : { width: 0 };
                const width =
                  w * (compartment.count?.x ?? 0) +
                  justifyMargin * ((compartment.count?.x ?? 1) - 1);
                const isComparisonCommonId =
                  product && comparisonSameIds.includes(product.id);
                return (
                  <ProductCompartments
                    key={index}
                    isSelected={isSelected}
                    productValue={productValue}
                    isComparisonCommonId={!!isComparisonCommonId}
                    isDecreaseOpacity={!!isDecreaseOpacity}
                    index={index}
                    compartment={compartment}
                    product={product}
                    left={left}
                    width={width}
                    productTag={productTag}
                    profitTab={profitTab}
                    productSalesReport={productSalesReport}
                    mode={mode}
                    selectedBucketId={selectedBucketId}
                    bucketMode={bucketMode}
                    isDragProduct={isDragProduct}
                    selectedBucketIdClone={selectedBucketIdClone}
                    products={productsBulk}
                    rowIndex={rowIndex}
                    row={row}
                    handleClickGroup={memoizedHandleClickGroup}
                    shelfHeight={shelfHeight}
                    shelfWidth={shelf.width}
                    //TODO: reduxから取得できるためpropsで渡す必要がない

                    selectedRealogramItem={selectedRealogramItem}
                    diffColor={diffColor}
                    bboxColors={bboxColors}
                    bboxEnabled={bboxEnabled}
                    detailView={detailView}
                    detailMode={detailMode}
                    justifyContent={justifyContent}
                    item={item}
                    isEditor={isEditor}
                    isCompared={isCompared}
                    setIsAllChildRenndered={setIsAllChildRenndered}
                  />
                );
              })}
            </Box>

            {productPosition &&
              mode === 'CompartmentEditor' &&
              !isCompared &&
              !comparedProductPosition && (
                //編集モードの時のみメニューをopen
                <>
                  <CompartmentMenu
                    products={productsBulk}
                    displayableHeight={shelfHeight}
                    anchorEl={anchorEl}
                    handleClose={resetState}
                    //TODO: reduxから取得できるためpropsで渡す必要がない
                    productPosition={productPosition}
                    open={
                      !!anchorEl &&
                      !openOrientationMenu &&
                      productPosition.indexY === rowIndex
                    }
                    handleClickRotate={handleClickRotate}
                  />
                  <CompartmentOrientationMenu
                    anchorEl={anchorEl}
                    open={
                      !!anchorEl &&
                      openOrientationMenu &&
                      productPosition.indexY === rowIndex
                    }
                    //TODO: reduxから取得できるためpropsで渡す必要がない
                    productPosition={productPosition}
                    handleClose={() => dispatch(setOpenOrientationMenu(false))}
                  />
                </>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

const getHoverIndex = (
  row?: PlanogramProductCompartment[],
  dropResult?: DropResult | null
) => {
  let hoverIndex = 0;
  if (dropResult) {
    hoverIndex = dropResult.hoverIndex;
  } else if (row?.length) {
    hoverIndex = row.length;
  }
  return hoverIndex;
};
