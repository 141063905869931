import { Product } from 'types/common';
import { SakuraModal } from '../modal';
import { PdfViewer } from '../pdf/pdfViewer';
import { normalizeProductName } from '@utils/product';
import { Stack, Typography } from '@mui/material';
import { ProductImage } from '@components/organisms/productImage/productImage';

type Props = {
  product: Product;
  open: boolean;
  handleClose: () => void;
  name: string;
  pdfS3Id: string;
  pdfPage: number;
  additionalInfo?: React.ReactNode;
};

export const PopupPdfViewerModal = ({
  product,
  pdfS3Id,
  pdfPage,
  name,
  open,
  handleClose,
  additionalInfo,
}: Props) => {
  const itemName = normalizeProductName(product.name);
  const itemCd = product.detail!.organization_product_id || '不明';

  const url = `/api/v1/sakura/assets/${pdfS3Id}.pdf`;
  return (
    <SakuraModal open={open} handleClose={handleClose} title={name} closeButton>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <ProductImage
            product={product}
            width="48"
            height="48"
            objectFit="contain"
            imageSizeType="tiny"
          />
          <Stack>
            <Typography variant="body2">
              {itemCd} 「{itemName}」に関する配信情報
            </Typography>
            {additionalInfo}
            <Typography variant="body3" color="#555">
              {name} {pdfPage}ページ目
            </Typography>
          </Stack>
        </Stack>
        <PdfViewer
          url={url}
          name={name}
          initialPage={pdfPage}
          width="50vw"
          height="60vh"
        />
      </Stack>
    </SakuraModal>
  );
};
