import { Product } from 'types/common';
import { StoreProductSale } from '../../types';
import { Stack, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

type Props = {
  product: Product;
  sale: StoreProductSale;
};

export const ProductPerformanceSection = ({ sale }: Props) => {
  return (
    <Stack spacing={0.5}>
      <Typography fontSize="9pt" color={0 < sale.quantity ? 'black' : '#666'}>
        前週合計店舗販売数：{sale.quantity}
      </Typography>

      {/* DO導入率 */}
      <Stack direction="row" spacing={0.5} alignContent="baseline">
        <Typography fontSize="9pt">DO導入率：</Typography>
        {sale.doAdoptionRate < 0.7 ? (
          <WarningIcon sx={{ fontSize: '12pt' }} color="warning" />
        ) : (
          <></>
        )}
        <Typography
          fontSize="9pt"
          color={sale.doAdoptionRate < 0.7 ? 'red' : 'black'}
        >
          {Math.round(sale.doAdoptionRate * 100)}%
        </Typography>
      </Stack>

      {/* ZO導入率 */}
      <Stack direction="row" spacing={0.5} alignContent="baseline">
        <Typography fontSize="9pt">ZO導入率：</Typography>
        {sale.zoAdoptionRate < 0.7 ? (
          <WarningIcon sx={{ fontSize: '12pt' }} color="warning" />
        ) : (
          <></>
        )}
        <Typography
          fontSize="9pt"
          color={sale.zoAdoptionRate < 0.7 ? 'red' : 'black'}
        >
          {Math.round(sale.zoAdoptionRate * 100)}%
        </Typography>
      </Stack>
    </Stack>
  );
};
