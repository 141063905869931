import { useBreakpoint } from '@hooks/useBreakpoint';
import { Box, Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { InfoRow } from './infoRow';
import { t } from 'i18next';

type Props = {
  eanCode: string;
  productCode: string;
};

export const ProfitInfo: FC<Props> = ({ eanCode, productCode }) => {
  const { isLarger } = useBreakpoint();
  const direction = isLarger ? 'column' : 'row';
  return (
    <Stack direction="column" divider={<Divider flexItem />} flex={1}>
      <InfoRow
        title="JAN"
        value={eanCode}
        sx={{
          flexDirection: direction,
          gap: 0,
          padding: '4px 8px 2px',
        }}
        subInfoStyle={{
          fontSize: '14px',
        }}
      />
      <InfoRow
        title={t('product_code')}
        value={productCode}
        sx={{
          flexDirection: direction,
          gap: 0,
          padding: '5px 8px 2px',
        }}
        subInfoStyle={{
          fontSize: '14px',
        }}
      />
      <InfoRow
        title={t('sales_price')}
        value="-"
        sx={{
          flexDirection: direction,
          gap: 0,
          padding: '5px 8px 2px',
        }}
        subInfoStyle={{
          fontSize: '14px',
        }}
      />
      <Box component="div" />
    </Stack>
  );
};
