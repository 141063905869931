import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

import {
  AvailableItem,
  ExternalInfo,
  ExternalInfoItem,
  ModelGondola,
  ModelGondolaItem,
  ModelGondolasPatternList,
  Store,
  WeekModelGondolas,
} from '@components/pages/sakura/types';
import {
  GetProductSaleResponse,
  useGetAvailableItemsQuery,
  useGetExternalInfoQuery,
  useGetProductSaleQuery,
  useGetSakuraStoresQuery,
  useGetSakuraUsersQuery,
  useListExternalInfoQuery,
} from '@reducers/shelfAppsApi/injections/sakuraApi';
import {
  useGetRealogramCandidatesQuery,
  useGetUserQuery,
  useListRealogramCandidatesQuery,
} from '@reducers/shelfAppsApi';
import { filterAvailableDoZoStores } from '@utils/sakuraUsers';
import {
  listModelGondolaProducts,
  useModelGondola,
  useModelGondolaItems,
} from '@components/pages/sakura/sakuraUtils';
import { gondolaPmas } from '@utils/gondolas.json';
import { AllProductsProvider } from '@utils/ProductsContext';
import { RealogramCandidate } from 'types/realogram';
import { IndexedPdfProvider } from '@components/pages/sakura/fragments/pdf/indexedPdf';

// 商品アノテーション（情報パッケージ由来など）をリストし、とりあえず最新のものを取ってくる
const useLatestExternalInfo = () => {
  const { data: listExternalInfoRes } = useListExternalInfoQuery();
  const [externalInfoItem, setExternalInfoItem] = useState<
    ExternalInfoItem | undefined
  >(undefined);
  const { data: externalInfo } = useGetExternalInfoQuery(
    {
      path: { name: externalInfoItem?.name || 'dummy' },
    },
    { skip: !externalInfoItem }
  );
  useEffect(() => {
    if (!listExternalInfoRes || listExternalInfoRes.items.length === 0) {
      setExternalInfoItem(undefined);
      return;
    }
    setExternalInfoItem(
      listExternalInfoRes.items[listExternalInfoRes.items.length - 1]
    );
  }, [listExternalInfoRes]);
  return externalInfo;
};

// 同じ店の同じ日のrealogramをすべて取得するフック
const useAllRealogramCandidatesForTheStore = (
  realogramCandidate: RealogramCandidate | undefined
): RealogramCandidate[] | undefined => {
  const storeId = realogramCandidate?.store_bay.store_id;
  const createdAt = realogramCandidate?.created_at;
  const createDate = createdAt ? new Date(createdAt) : undefined;
  const createdAtStart =
    createDate && new Date(createDate.setHours(0, 0, 0, 0));
  const createdAtEnd =
    createDate && new Date(createDate.setHours(23, 59, 59, 999));

  // 一覧を得る。
  // ※ これ、一覧APIで返ってくるRealogramCandidatesはdetailがnullなので注意
  const { data } = useListRealogramCandidatesQuery(
    {
      storeId: [storeId ?? 0],
      createdAtStart: createdAtStart && createdAtStart.toISOString(),
      createdAtEnd: createdAtEnd && createdAtEnd.toISOString(),
      limit: 400,
    },
    { skip: !storeId }
  );

  // detailを含むrealogramを一つ一つ（まとめて）取得する
  const realogramCandidatesIds = data?.realogram_candidates.map(
    (realogramCandidate) => realogramCandidate.id
  );
  const { data: realogramCandidatesRes } = useGetRealogramCandidatesQuery(
    realogramCandidatesIds?.map((id) => ({ realogramCandidateId: id })) || [],
    { skip: !realogramCandidatesIds }
  );
  return realogramCandidatesRes?.flatMap((res) => res.realogram_candidate);
};

export type PatternMeta = {
  pattern: string | undefined;
  patternIndex: number | undefined;

  // パターンのなかで、大きいものほど大きい数字になるようにする。
  // 具体的には、縦置き：平置きが2:1なら1、3:1なら2、3:2で3になる（実際はゴンドラの含む商品数）
  sizeIndicator: number;
};
export type ModelGondolaWithPatternLabel = ModelGondola & {
  patternMeta: PatternMeta;
};

const findRelevantModelGondolas = (
  gondolaCode: string,
  weekModelGondolas: WeekModelGondolas | undefined
): ModelGondolaWithPatternLabel[] | undefined => {
  if (!weekModelGondolas) {
    return undefined;
  }

  // 共通モデゴンにある場合
  const { commonGondolas, patternedGondolas } = weekModelGondolas;
  const foundCommonGondolas = commonGondolas.filter(
    (gondola) => gondola.gondolaCode === gondolaCode
  );
  if (0 < foundCommonGondolas.length) {
    return foundCommonGondolas.map((gondola) => ({
      ...gondola,
      gondolaName:
        gondolaPmas.find((g) => g.code === gondola.gondolaCode)?.name ||
        gondola.gondolaCode,
      patternMeta: {
        pattern: undefined,
        patternIndex: undefined,
        sizeIndicator: 0,
      },
    }));
  }

  // 冷凍パターンゴンドラにある場合
  const flattenPatternedGondolasWithLabel = (
    pattern: ModelGondolasPatternList
  ) =>
    pattern.choices.flatMap((choice, index) => {
      const patternAllItems = choice.gondolas.flatMap((gondola) =>
        listModelGondolaProducts(gondola)
      );
      return choice.gondolas.map((gondola) => ({
        ...gondola,
        patternMeta: {
          pattern: choice.name,
          patternIndex: index,
          // モデゴンの商品数でモデゴンのパターンの大きさを測る
          sizeIndicator: patternAllItems.length,
        },
      }));
    });
  const foundPatternedGondolas = patternedGondolas
    .flatMap((pattern) => flattenPatternedGondolasWithLabel(pattern))
    .filter(
      (gondolaWithPattern) => gondolaWithPattern.gondolaCode === gondolaCode
    );
  if (foundPatternedGondolas.length === 0) {
    return undefined;
  }
  return foundPatternedGondolas;
};

export type ModelGondolaContextType = {
  currentStore: Store;
  externalInfo: ExternalInfo;
  availableItems: AvailableItem[];
  gondolaPossibleItemCds: string[];
  // modelGondola: RegularModelGondola | FlatModelGondola;
  relevantModelGondolas: ModelGondolaWithPatternLabel[];
  realogramCandidatesForTheDay: RealogramCandidate[];
  productSales: GetProductSaleResponse['sales'];
};

const ModelGondolaContext = createContext<ModelGondolaContextType | undefined>(
  undefined
);

export const useLatestModelGondola = (
  storeCd: string | undefined,
  gondolaName: string | undefined,
  realogramCandidate: RealogramCandidate | undefined
): ModelGondolaContextType | undefined => {
  const { data: meRes } = useGetUserQuery({ userId: 'me' });
  const { data: sakuraStoresRes } = useGetSakuraStoresQuery();
  const { data: sakuraUsersRes } = useGetSakuraUsersQuery();
  const { data: availableItems } = useGetAvailableItemsQuery();
  const externalInfo = useLatestExternalInfo();

  const realogramCandidatesForTheDay =
    useAllRealogramCandidatesForTheStore(realogramCandidate);

  const sakuraUser =
    meRes &&
    sakuraUsersRes?.users.find((user) => user.email === meRes.user.email);

  const sakuraStoresFiltered = filterAvailableDoZoStores(
    sakuraStoresRes,
    sakuraUser
  );
  const store = sakuraStoresFiltered?.stores.find((s) => s.storeCd === storeCd);

  // この店に該当するモデゴン一覧を取得して最新を表示
  const [currentModelGondolaItem, setCurrentModelGondolaItem] = useState<
    ModelGondolaItem | undefined
  >(undefined);
  const { weekModelGondolas: currentWeekModelGondolas } = useModelGondola(
    currentModelGondolaItem
  );
  const { modelGondolaItems } = useModelGondolaItems(store);
  useEffect(() => {
    if (
      modelGondolaItems &&
      currentModelGondolaItem === undefined &&
      modelGondolaItems.length > 0
    ) {
      setCurrentModelGondolaItem(
        modelGondolaItems[modelGondolaItems.length - 1]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [modelGondolaItems]);

  const { data: productSalesRes } = useGetProductSaleQuery(
    { path: { storeCd: store?.storeCd || '' } },
    { skip: !store }
  );

  if (!storeCd || !gondolaName) {
    console.log('storeCd or gondolaName is undefined');
    return undefined;
  }

  if (
    !meRes ||
    !sakuraStoresRes ||
    !sakuraUsersRes ||
    !availableItems ||
    !externalInfo ||
    !productSalesRes
  ) {
    console.log('API response(s) undefined');
    return undefined;
  }

  if (!store) {
    return undefined;
  }

  // ゴンドラ名から、どのモデゴンに対応するか探す。だいぶ雑だけど。
  const gondolaCode = gondolaPmas.find((g) =>
    gondolaName.includes(g.origCode)
  )?.code;
  if (!gondolaCode) {
    console.log(`ゴンドラ名${gondolaName}はgondolas.jsonに対応しない`);
    return undefined;
  }
  const relevantGondolaWithLabels = findRelevantModelGondolas(
    gondolaCode,
    currentWeekModelGondolas
  );
  if (!relevantGondolaWithLabels) {
    console.log(`${gondolaCode}に対応するモデゴンがない`);
    return undefined;
  }
  const gondolaAvailableItems =
    availableItems.items.filter((item) =>
      item.gondolaCodes.includes(gondolaCode)
    ) || [];

  const possibleItemCds = [
    ...gondolaAvailableItems.map((item) => item.itemCd),
    ...relevantGondolaWithLabels.flatMap((gondola) =>
      listModelGondolaProducts(gondola as ModelGondola).map(
        (product) => product.productCode
      )
    ),
  ];

  const productSales = productSalesRes.sales.filter((sale) => {
    return sale.gondolaCodes.includes(gondolaCode);
  });

  return {
    currentStore: store,
    externalInfo: externalInfo,
    availableItems: availableItems?.items || [],
    gondolaPossibleItemCds: Array.from(new Set(possibleItemCds)),
    relevantModelGondolas: relevantGondolaWithLabels,
    realogramCandidatesForTheDay: realogramCandidatesForTheDay || [],
    productSales: productSales,
  };
};

type ModelGondolaProviderProps = {
  modelGondolaContext: ModelGondolaContextType | undefined;
  children: ReactNode;
};

export const ModelGondolaAndAllProductsProvider = ({
  modelGondolaContext,
  children,
}: ModelGondolaProviderProps) => {
  return (
    <AllProductsProvider
      itemCds={modelGondolaContext?.gondolaPossibleItemCds || []}
      skip={modelGondolaContext === undefined}
    >
      <IndexedPdfProvider>
        <ModelGondolaContext.Provider value={modelGondolaContext}>
          {children}
        </ModelGondolaContext.Provider>
      </IndexedPdfProvider>
    </AllProductsProvider>
  );
};

export const useModelGondolaAndAllProductsContext = () => {
  return useContext(ModelGondolaContext);
};
