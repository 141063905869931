import { Box, Button, Stack, Typography } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

import { Product } from 'types/common';
import { ExternalInfo } from '../../types';
import { normalizeProductName } from '@utils/product';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { KihonShohinChipIfNeeded } from '../chips/kihonShohin';
import { NewItemChipIfNeeded } from '../chips/newItem';
import { dateToMonthDate, getRecommendation } from '../../sakuraUtils';
import { useActionMemoContext } from '../storeActionMemo/storeActionMemoContext';
import { useModelGondolasContext } from '../modelGondolasContext';
import { t } from 'i18next';

type Props = {
  product: Product;
  externalInfo: ExternalInfo;
};

export const ProductImageAndName = ({ product, externalInfo }: Props) => {
  const recom = getRecommendation(product);
  const isSuishoTorikeshi = externalInfo.cutItems.find(
    (itemCd) => itemCd === product.detail!.organization_product_id!
  );

  let suishoTorikeshi: string | undefined = undefined;
  if (isSuishoTorikeshi) {
    suishoTorikeshi = `今週${t('attributes.sales_ended')}`;
  } else if (recom.salesEndDate) {
    suishoTorikeshi = `${t('attributes.sales_ended')}予定 ${
      recom.salesEndDate.getMonth() + 1
    }月${recom.salesEndDate.getDate()}日`;
  }
  return (
    <Stack direction="row" spacing={3}>
      <ProductImage
        product={product}
        width="70"
        height="70"
        objectFit="contain"
        imageSizeType="tiny"
      />
      <Stack>
        <Typography variant="body2">
          {product.detail!.organization_product_id ||
            `${t('product_code')}不明`}
        </Typography>
        <Typography variant="body1">
          {normalizeProductName(product.name)}
        </Typography>
        <Stack direction="row">
          <KihonShohinChipIfNeeded product={product} />
          <NewItemChipIfNeeded externalInfo={externalInfo} product={product} />
        </Stack>
        <Box component="div" sx={{ height: '5pt' }} />

        {/* 推奨開始日 */}
        <Typography fontSize="7pt" color="#666">
          {t('sales_start')} {recom.salesStartDate.getMonth() + 1}月
          {recom.salesStartDate.getDate()}日
          {!recom.salesEndDate &&
            !isSuishoTorikeshi &&
            (4 <= recom.nWeeksSinceRecommendationStarted ? (
              <span
                style={{ color: 'red' }}
              >{`（${recom.nWeeksSinceRecommendationStarted}週経過）`}</span>
            ) : (
              `（${recom.nWeeksSinceRecommendationStarted}週経過）`
            ))}
        </Typography>
        {/* 推奨終了日 */}
        {suishoTorikeshi && (
          <Typography
            fontSize="7pt"
            color="red"
            fontWeight="bold"
            sx={{
              textDecoration: 'underline',
            }}
          >
            {suishoTorikeshi}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const ProductBaseInfo = ({ product, externalInfo }: Props) => {
  const actionMemoContext = useActionMemoContext();
  const modelGondolasContext = useModelGondolasContext();
  const storeCd = modelGondolasContext.value?.storeCd;
  const modelGondolaWeek = modelGondolasContext.value?.modelGondolaWeek;
  const itemCd = product.detail!.organization_product_id!;

  const addMemo = actionMemoContext.addItems.find(
    (item) => item.itemCd === itemCd
  );
  const cutMemo = actionMemoContext.cutItems.find(
    (item) => item.itemCd === itemCd
  );

  return (
    <Stack spacing={2}>
      <ProductImageAndName product={product} externalInfo={externalInfo} />
      <Stack>
        <Stack spacing={1} direction="row">
          <Box component="div" width="50%" minWidth="200px">
            <Button
              fullWidth
              variant="contained"
              startIcon={<AddShoppingCartIcon />}
              onClick={() => {
                if (storeCd && modelGondolaWeek) {
                  if (addMemo) {
                    actionMemoContext.removeAddItem(itemCd);
                  } else {
                    actionMemoContext.addAddItem(itemCd);
                  }
                }
              }}
              disabled={cutMemo !== undefined}
              color={addMemo !== undefined ? 'secondary' : 'primary'}
            >
              <Typography fontSize="8pt">
                {addMemo !== undefined
                  ? '店舗導入メモから削除'
                  : '店舗導入メモに追加'}
              </Typography>
            </Button>
          </Box>

          <Box component="div" width="50%" minWidth="200px">
            <Button
              fullWidth
              variant="contained"
              startIcon={<RemoveShoppingCartIcon />}
              onClick={() => {
                if (storeCd && modelGondolaWeek) {
                  if (cutMemo) {
                    actionMemoContext.removeCutItem(itemCd);
                  } else {
                    actionMemoContext.addCutItem(itemCd);
                  }
                }
              }}
              disabled={addMemo !== undefined}
              color={cutMemo !== undefined ? 'secondary' : 'primary'}
            >
              <Typography fontSize="8pt">
                {cutMemo !== undefined
                  ? 'カットメモから削除'
                  : 'カットメモに追加'}
              </Typography>
            </Button>
          </Box>
        </Stack>
        <Typography color="grey" fontSize="8pt">
          {addMemo !== undefined
            ? `店舗導入のメモあり（${dateToMonthDate(
                addMemo.date
              )}にメモしました）`
            : ''}
          {cutMemo !== undefined
            ? `カットのメモあり（${dateToMonthDate(
                cutMemo.date
              )}にメモしました）`
            : ''}
        </Typography>
      </Stack>
    </Stack>
  );
};
