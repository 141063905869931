import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { removeArea, removeBayPart, splitArea } from '@reducers/flatPlan';
import {
  selectCanSplitHorizontal,
  selectCanSplitVertical,
} from '@reducers/flatPlan/selectors';
import {
  selectBayPartId,
  setIsTooltipOpen,
  setOpenOrientationMenu,
  setSelectedBucketIdClone,
  toggleIsSwappingBayPartMode,
  updateInitialBucket,
  updateIsShowProductDetail,
  updateSelectedProductCompartmentClone,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  maxChildrenOfArea,
  maxZoomScale,
  rotationAngleFlatPlanogram,
} from '@utils/const';
import {
  convertMeterToPixel,
  getAreaDimensions,
  isClickableCompartment,
} from '@utils/planogram';
import { isEqual } from 'lodash';
import { MouseEvent, useEffect, useState } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';
import { Bucket, BucketArea, Position, SplitAreaType } from 'types/planogram';
import { calculateTranslatePosition } from './calculateTranslatePosition';
type Props = {
  bucket: Bucket;
  initUpperRowIndexThreshold: boolean;
  index: number;
  isDisabledBuckets: boolean;
  zoomScale: number;
  translatePosition: number;
  isCompared: boolean;
  isEditor: boolean;
  setZoomScale: (zoomScale: number) => void;
  setTranslatePosition: (position: number) => void;
};

export const useDisplayBucketsData = ({
  bucket,
  initUpperRowIndexThreshold,
  index,
  isDisabledBuckets,
  zoomScale,
  translatePosition,
  isCompared,
  isEditor,
  setZoomScale,
  setTranslatePosition,
}: Props) => {
  const data = usePlanogramContext();
  const { selectedBucketId, selectedProductCompartment, position } =
    useSelectedPlanogramItem({
      products: isCompared ? data?.comparisonProducts : data?.products,
    });
  const bucketProductPosition = selectedProductCompartment
    ? (position as Position[])
    : undefined;
  const dispatch = useAppDispatch();
  // Areaと同じanchorを使ってるため分離するか検討
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const contentX = bucket.detail.content_max.x - bucket.detail.content_min.x;
  const contentZ = bucket.detail.content_max.z - bucket.detail.content_min.z;

  const [hightLightAreaPosition, setHightLightAreaPosition] =
    useState<Position[]>();
  const [isHightLightBucket, setHightLightBucket] = useState<boolean>(false);
  const [comparedBucketProductPos, setComparedBucketProductPos] = useState<
    Position[] | undefined
  >();
  const {
    mode,
    detailView,

    bucketMode,
    isTooltipOpen,
    selectedBucketIdClone,
    detailMode,
    selectedBayPartId,
    isDragProduct,
    isSwappingBayPartMode,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);

  const { padding } = bucket.detail;
  const bucketsRotateDeg = initUpperRowIndexThreshold
    ? rotationAngleFlatPlanogram
    : 0;
  const childrenHasData = bucket.detail.area.children?.length ?? 0;
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] =
    useState<boolean>(false);
  const isAreaMode = bucketMode === 'area';
  const isSelectedBucketId = selectedBucketId === index;

  const canSplitHorizontal = useAppSelector(selectCanSplitHorizontal);
  const canSplitVertical = useAppSelector(selectCanSplitVertical);
  const {
    removeQueryParameter,
    manipulateQueryParameters,
    updateQueryParameter,
  } = useUrlQueryParams();
  const isEditingBay = isEditor && mode === 'BayEditor';
  const resetSearchParams = () => {
    manipulateQueryParameters({
      removeQueries: [
        isCompared ? 'compareItem' : 'item',
        'position',
        'selectedBucketId',
      ],
    });
  };
  const resetState = () => {
    setAnchorEl(null);
    dispatch(setOpenOrientationMenu(false));
    dispatch(updateIsShowProductDetail(false));
    dispatch(setSelectedBucketIdClone(undefined));
    resetSearchParams();
  };

  const handleClickCompartment = (
    e: MouseEvent<HTMLElement>,
    position: Position[],
    product: Product,
    bucketIndex: number
  ) => {
    if (isTooltipOpen) {
      dispatch(setIsTooltipOpen(false));
    }
    e.stopPropagation();
    if (!isClickableCompartment(detailView, product, productTag)) return;
    // disabledのカゴの中にある商品は選択できない
    if (isDisabledBuckets) {
      dispatch(setIsTooltipOpen(true));
      return;
    }
    const productPosition = isCompared
      ? comparedBucketProductPos
      : bucketProductPosition;
    if (
      isEqual(productPosition, position) &&
      bucketIndex === selectedBucketId
    ) {
      resetState();
      dispatch(setSelectedBucketIdClone(undefined));
      return;
    }
    if (bucketMode !== 'area') {
      dispatch(updateIsShowProductDetail(true));
    }
    dispatch(setOpenOrientationMenu(false));
    dispatch(setSelectedBucketIdClone(index));

    manipulateQueryParameters({
      updateQueries: [
        {
          key: isCompared ? 'compareItem' : 'item',
          value: String(product.id),
        },
        {
          key: 'position',
          value: JSON.stringify(position),
        },
        {
          key: 'selectedBucketId',
          value: String(bucketIndex),
        },
      ],
      removeQueries: [isCompared ? 'item' : 'compareItem'],
    });
    if (isCompared) {
      setComparedBucketProductPos(position);
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (bucketProductPosition && selectedProductCompartment) {
      const anchorElement = document.querySelector(
        `[data-group-position=group-position-${index}--1]`
      );
      if (anchorElement) {
        setAnchorEl(anchorElement as HTMLAnchorElement);
      }
    }
  }, [bucketProductPosition, index, selectedProductCompartment]);

  useEffect(() => {
    if (selectedBucketId === undefined) {
      setAnchorEl(null);
      return;
    }

    if (!childrenHasData) {
      const anchorElement = document.querySelector(
        `[data-area-container=data-area-container-${selectedBucketId}]`
      );
      const planogramContainerWidth = document.querySelector(
        '[data-planogram-container=data-planogram-container]'
      )?.clientWidth;
      const planogramCenterPosition =
        planogramContainerWidth && planogramContainerWidth / 2; // eslint-disable-line no-magic-numbers -- 中心位置を計算するため
      const anchorElementRect = anchorElement?.getBoundingClientRect();
      setAnchorEl(anchorElement as HTMLAnchorElement);
      if (
        planogramCenterPosition &&
        anchorElementRect?.left &&
        mode !== 'BayEditor'
      ) {
        const position = calculateTranslatePosition(
          translatePosition,
          planogramCenterPosition,
          anchorElementRect.left,
          zoomScale
        );
        setTranslatePosition(position);
      }
    }
  }, [
    selectedBucketId,
    bucket,
    setTranslatePosition,
    zoomScale,
    translatePosition,
    childrenHasData,
    mode,
  ]);

  const handleBucketClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (
      isSelectedBucketId &&
      bucketProductPosition &&
      (!canSplitHorizontal || !canSplitVertical)
    ) {
      return;
    }
    if (mode === 'BayEditor') {
      e.stopPropagation();
      if (isSwappingBayPartMode) return;
      const isSelectedBucketId = selectedBucketId === index;

      if (selectedBayPartId && !isSwappingBayPartMode) {
        dispatch(selectBayPartId(undefined));
      }
      isSelectedBucketId
        ? removeQueryParameter('selectedBucketId')
        : updateQueryParameter('selectedBucketId', String(index));
    }

    if (mode !== 'CompartmentEditor') return;
    if (isTooltipOpen) {
      dispatch(setIsTooltipOpen(false));
    }
    e.stopPropagation();
    if (isDisabledBuckets) {
      dispatch(setIsTooltipOpen(true));
      return;
    }
    if (bucketMode === 'area' && selectedBucketIdClone) {
      removeQueryParameter('selectedBucketId');
    }
    // Click on the cart to zoom in on the planogram edit screen
    if (
      detailMode === 'default' &&
      !!selectedBucketId?.toString() &&
      (bucketMode === 'compartment' || bucketMode === 'area') &&
      zoomScale < maxZoomScale
    ) {
      setZoomScale(maxZoomScale);
    }
    if (zoomScale < maxZoomScale && !selectedBucketId?.toString()) {
      setZoomScale(maxZoomScale);
    }
    if (index === selectedBucketId && selectedBucketIdClone !== undefined)
      return;

    manipulateQueryParameters({
      updateQueries: [
        {
          key: 'selectedBucketId',
          value: String(index),
        },
      ],
      removeQueries: ['position', 'item'],
    });
    dispatch(setSelectedBucketIdClone(index));

    dispatch(updateIsShowProductDetail(false));

    dispatch(updateSelectedProductCompartmentClone(undefined));
  };

  useEffect(() => {
    if (bucketProductPosition !== undefined) {
      const areaAttributeKey = bucketProductPosition
        .map((el) => {
          if (el.indexX === 0 && el.indexY === 0) return 0;
          return 1;
        })
        .join('-');
      const anchorElement = document.querySelector(
        `[data-area-popper=area-popper-${selectedBucketId ?? ''}-${
          areaAttributeKey ?? ''
        }]`
      );
      if (anchorElement) {
        setAnchorEl(anchorElement as HTMLAnchorElement);
      }
      return;
    }
    if (selectedBucketId !== undefined) {
      const anchorElement = document.querySelector(
        `[data-area-container=data-area-container-${selectedBucketId}]`
      );
      setAnchorEl(anchorElement as HTMLAnchorElement);
    }
  }, [selectedBucketId, bucket, bucketProductPosition]);

  const handleSplitArea = (type: SplitAreaType) => {
    // check if bucket splitted or not
    const bucketPosition =
      bucket.detail.area.split_axis !== null
        ? bucketProductPosition
        : undefined;
    dispatch(
      splitArea({
        type,
        selectedBucketId,
        bucketProductPosition: bucketPosition,
      })
    );
    if (bucketPosition) {
      updateQueryParameter(
        'position',
        JSON.stringify([...bucketPosition, { indexX: 0, indexY: 0 }])
      );
    } else {
      updateQueryParameter(
        'position',
        JSON.stringify([{ indexX: 0, indexY: 0 }])
      );
    }
  };
  const onHandleClickArea = (
    e: MouseEvent<HTMLElement>,
    selectedBucketId?: number,
    path?: Position[]
  ) => {
    if (!isEditor && detailMode === 'comparison') return;
    if (mode !== 'CompartmentEditor' || bucketMode === 'compartment') return;
    if (isTooltipOpen) {
      dispatch(setIsTooltipOpen(false));
    }
    e.stopPropagation();

    if (isDisabledBuckets) {
      dispatch(setIsTooltipOpen(true));
      return;
    }
    if (selectedBucketIdClone && bucketProductPosition) {
      dispatch(setSelectedBucketIdClone(undefined));
      removeQueryParameter('position');
      setAnchorEl(null);
      return;
    }
    if (
      detailMode === 'default' &&
      !!selectedBucketId?.toString() &&
      zoomScale < maxZoomScale
    ) {
      setZoomScale(maxZoomScale);
    }
    manipulateQueryParameters({
      updateQueries: [
        {
          key: 'selectedBucketId',
          value: String(selectedBucketId),
        },
        {
          key: 'position',
          value: JSON.stringify(path),
        },
      ],
    });

    dispatch(setSelectedBucketIdClone(selectedBucketId));

    dispatch(updateSelectedProductCompartmentClone(undefined));
  };

  const checkParentHasMultipleChildren = (
    bucketProductPosition: Position[],
    area: BucketArea
  ) => {
    const indexArray = bucketProductPosition.map((el) => {
      if (el.indexX === 0 && el.indexY === 0) return 0;
      return 1;
    });
    let parent = area;

    for (const index of indexArray) {
      const children = parent?.children ?? [];
      if (Array.isArray(children) && children.length >= maxChildrenOfArea) {
        return true;
      }
      parent = children[index];
    }

    return false;
  };

  useEffect(() => {
    const { area } = bucket.detail;
    if (!bucketProductPosition) {
      const childrenHasData = area.children?.length ?? 0;
      setDeleteButtonDisabled(childrenHasData < maxChildrenOfArea);
      return;
    }

    const isDeleteEnabled = checkParentHasMultipleChildren(
      bucketProductPosition,
      area
    );
    setDeleteButtonDisabled(!isDeleteEnabled);
  }, [bucketProductPosition, bucket]);
  const { height: bucketHeight, width: bucketWidth } = getAreaDimensions(
    contentZ,
    contentX,
    bucket.detail.area
  );

  const handleDeleteArea = () => {
    dispatch(removeArea({ selectedBucketId, bucketProductPosition }));
  };

  const hasColor =
    index === selectedBucketId &&
    mode === 'CompartmentEditor' &&
    isAreaMode &&
    (!!bucketProductPosition?.length || selectedBucketIdClone !== undefined);

  const isActiveBucketArea =
    !hasColor &&
    index === selectedBucketIdClone &&
    !bucketProductPosition &&
    isAreaMode &&
    mode === 'CompartmentEditor';

  const getBorder = (value: number) => {
    if (
      (hasColor && !(childrenHasData > 1) && !isDragProduct) ||
      (isHightLightBucket && !isDisabledBuckets && !childrenHasData)
    ) {
      return '2px solid #0A40CA';
    }
    if (isActiveBucketArea) {
      return `${convertMeterToPixel(value)}px solid ${
        theme.palette.dividerBlack.dark
      }`;
    }
    return '';
  };

  const shouldHighlight =
    (hasColor && !(childrenHasData > 1) && !isDragProduct) ||
    (isHightLightBucket && !isDisabledBuckets);
  const bucketBackgroundColor = shouldHighlight
    ? '#E5F0FF'
    : isActiveBucketArea
    ? '#FFFFFF'
    : '';

  const handleDeleteBucket = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(removeBayPart(index));
  };

  const handleSwapMode = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(toggleIsSwappingBayPartMode());
    dispatch(selectBayPartId(bucket.bay_part_id));
    dispatch(updateInitialBucket(bucket));
  };

  useEffect(() => {
    if (!isDragProduct) {
      setHightLightBucket(false);
    }
  }, [isDragProduct, selectedBucketId, dispatch]);

  return {
    bucketsRotateDeg,

    // eslint-disable-next-line no-magic-numbers -- 半透明にするため
    opacity: isEditingBay || (isCompared && mode === 'BayEditor') ? 0.2 : 1,
    isActiveBucketArea,
    padding,
    bucketBackgroundColor,
    isCompared,

    anchorEl,

    selectedBucketId,

    bucketWidth,
    bucketHeight,

    hightLightAreaPosition,
    isEditor,

    isSelectedBucketId,
    isDisplaySplitAreaPopper:
      bucketMode === 'area' && mode === 'CompartmentEditor' && isEditor,
    open:
      !!anchorEl &&
      isSelectedBucketId &&
      !isDragProduct &&
      (selectedBucketIdClone !== undefined || !!bucketProductPosition?.length),

    isDeleteButtonDisabled,
    canSplitHorizontal,
    canSplitVertical,
    isDisplayBayPartMenu:
      selectedBucketId === index &&
      !isSwappingBayPartMode &&
      mode === 'BayEditor' &&
      !isCompared,
    handleDeleteArea,
    handleSplitArea,
    handleSwapMode,
    handleDeleteBucket,
    handleBucketClick,
    getBorder,
    handleClickCompartment,
    resetState,
    onHandleClickArea,
    setHightLightAreaPosition,

    onDragLeave: () => {
      setHightLightBucket(false);
    },
    onDragOver: () => {
      if (isDragProduct && !childrenHasData) {
        setHightLightBucket(true);
      }
    },
  };
};
