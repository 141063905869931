import { createContext, useContext } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { useModelGondolasContext } from '../modelGondolasContext';

export type MemoItem = {
  itemCd: string;
  date: string;
};

export type StoreActionMemo = {
  storeCd: string;
  modelGondolaWeek: string;
  addItems: MemoItem[];
  cutItems: MemoItem[];
};

// 過去何版分のモデゴンに対応したメモを残すか。
const numMemosPerStore = 5;
const removeOldMemos = (
  memos: StoreActionMemo[],
  n: number = numMemosPerStore
) => {
  const storeCds = new Set(memos.map((memo) => memo.storeCd));
  let outMemos: StoreActionMemo[] = [];
  storeCds.forEach((storeCd) => {
    const newStoreMemos = memos
      .filter((memo) => memo.storeCd === storeCd)
      .sort((a, b) => (a.modelGondolaWeek < b.modelGondolaWeek ? 1 : -1))
      .slice(0, n);
    outMemos = outMemos.concat(newStoreMemos);
  });
  return outMemos;
};

const findStoreActionMemo = (
  storeActionMemos: StoreActionMemo[],
  storeCd: string,
  modelGondolaWeek: string
) =>
  storeActionMemos.find(
    (memo) =>
      memo.storeCd === storeCd && memo.modelGondolaWeek === modelGondolaWeek
  ) || { storeCd, modelGondolaWeek, addItems: [], cutItems: [] };
const addMemoItem = (memoItems: MemoItem[], itemCd: string): MemoItem[] =>
  memoItems
    .filter((memoItem) => memoItem.itemCd !== itemCd)
    .concat({ itemCd, date: new Date().toISOString().substring(0, 10) });
const removeMemoItem = (memoItems: MemoItem[], itemCd: string): MemoItem[] =>
  memoItems.filter((memoItem) => memoItem.itemCd !== itemCd);
const updateStoreActionMemo = (
  storeActionMemos: StoreActionMemo[],
  newStoreActionMemo: StoreActionMemo
) =>
  removeOldMemos(
    storeActionMemos
      .filter(
        (memo) =>
          memo.storeCd !== newStoreActionMemo.storeCd ||
          memo.modelGondolaWeek !== newStoreActionMemo.modelGondolaWeek
      )
      .concat(newStoreActionMemo)
  );

type _State = {
  storeActionMemos: StoreActionMemo[];
  addAddItem: (
    modelGondolaWeek: string,
    storeCd: string,
    itemCd: string
  ) => void;
  removeAddItem: (
    modelGondolaWeek: string,
    storeCd: string,
    itemCd: string
  ) => void;
  addCutItem: (
    modelGondolaWeek: string,
    storeCd: string,
    itemCd: string
  ) => void;
  removeCutItem: (
    modelGondolaWeek: string,
    storeCd: string,
    itemCd: string
  ) => void;
};

const useStoreActionMemo = create<_State>(
  persist(
    (set) => ({
      storeActionMemos: [] as StoreActionMemo[],
      addAddItem: (modelGondolaWeek: string, storeCd: string, itemCd: string) =>
        set((state) => {
          const storeActionMemo = findStoreActionMemo(
            state.storeActionMemos,
            storeCd,
            modelGondolaWeek
          );
          storeActionMemo.addItems = addMemoItem(
            storeActionMemo.addItems,
            itemCd
          );
          return {
            storeActionMemos: updateStoreActionMemo(
              state.storeActionMemos,
              storeActionMemo
            ),
          };
        }),
      removeAddItem: (
        modelGondolaWeek: string,
        storeCd: string,
        itemCd: string
      ) =>
        set((state) => {
          const storeActionMemo = findStoreActionMemo(
            state.storeActionMemos,
            storeCd,
            modelGondolaWeek
          );
          storeActionMemo.addItems = removeMemoItem(
            storeActionMemo.addItems,
            itemCd
          );
          return {
            storeActionMemos: updateStoreActionMemo(
              state.storeActionMemos,
              storeActionMemo
            ),
          };
        }),
      addCutItem: (modelGondolaWeek: string, storeCd: string, itemCd: string) =>
        set((state) => {
          const storeActionMemo = findStoreActionMemo(
            state.storeActionMemos,
            storeCd,
            modelGondolaWeek
          );
          storeActionMemo.cutItems = addMemoItem(
            storeActionMemo.cutItems,
            itemCd
          );
          return {
            storeActionMemos: updateStoreActionMemo(
              state.storeActionMemos,
              storeActionMemo
            ),
          };
        }),
      removeCutItem: (
        modelGondolaWeek: string,
        storeCd: string,
        itemCd: string
      ) =>
        set((state) => {
          const storeActionMemo = findStoreActionMemo(
            state.storeActionMemos,
            storeCd,
            modelGondolaWeek
          );
          storeActionMemo.cutItems = removeMemoItem(
            storeActionMemo.cutItems,
            itemCd
          );
          return {
            storeActionMemos: updateStoreActionMemo(
              state.storeActionMemos,
              storeActionMemo
            ),
          };
        }),
    }),
    {
      name: 'storeActionMemo',
      getStorage: () => localStorage,
    }
  )
);

export type ActionMemoContextType = {
  addItems: MemoItem[];
  cutItems: MemoItem[];
  addAddItem: (itemCd: string) => void;
  removeAddItem: (itemCd: string) => void;
  addCutItem: (itemCd: string) => void;
  removeCutItem: (itemCd: string) => void;
  isInAddItems: (itemCd: string) => boolean;
  isInCutItems: (itemCd: string) => boolean;
};
const ActionMemoContext = createContext<ActionMemoContextType | undefined>(
  undefined
);

type ActionMemoProviderProps = {
  children: React.ReactNode;
};

export const ActionMemoProvider = ({ children }: ActionMemoProviderProps) => {
  const storeActionMemo = useStoreActionMemo();
  const modelGondolasContext = useModelGondolasContext();
  const storeCd = modelGondolasContext.value?.storeCd;
  const modelGondolaWeek = modelGondolasContext.value?.modelGondolaWeek;

  const value = {
    addItems:
      storeCd && modelGondolaWeek
        ? findStoreActionMemo(
            storeActionMemo.storeActionMemos,
            storeCd,
            modelGondolaWeek
          ).addItems
        : [],
    cutItems:
      storeCd && modelGondolaWeek
        ? findStoreActionMemo(
            storeActionMemo.storeActionMemos,
            storeCd,
            modelGondolaWeek
          ).cutItems
        : [],
    addAddItem: (itemCd: string) => {
      if (storeCd && modelGondolaWeek) {
        storeActionMemo.addAddItem(modelGondolaWeek, storeCd, itemCd);
      }
    },
    removeAddItem: (itemCd: string) => {
      if (storeCd && modelGondolaWeek) {
        storeActionMemo.removeAddItem(modelGondolaWeek, storeCd, itemCd);
      }
    },
    addCutItem: (itemCd: string) => {
      if (storeCd && modelGondolaWeek) {
        storeActionMemo.addCutItem(modelGondolaWeek, storeCd, itemCd);
      }
    },
    removeCutItem: (itemCd: string) => {
      if (storeCd && modelGondolaWeek) {
        storeActionMemo.removeCutItem(modelGondolaWeek, storeCd, itemCd);
      }
    },
    isInAddItems: (itemCd: string) =>
      storeCd && modelGondolaWeek
        ? findStoreActionMemo(
            storeActionMemo.storeActionMemos,
            storeCd,
            modelGondolaWeek
          )
            .addItems.map((memoItem) => memoItem.itemCd)
            .includes(itemCd)
        : false,
    isInCutItems: (itemCd: string) =>
      storeCd && modelGondolaWeek
        ? findStoreActionMemo(
            storeActionMemo.storeActionMemos,
            storeCd,
            modelGondolaWeek
          )
            .cutItems.map((memoItem) => memoItem.itemCd)
            .includes(itemCd)
        : false,
  };
  return (
    <ActionMemoContext.Provider value={value}>
      {children}
    </ActionMemoContext.Provider>
  );
};

export const useActionMemoContext = () => {
  const ctx = useContext(ActionMemoContext);
  if (!ctx) {
    throw new Error('ActionMemoContext is not provided');
  }
  return ctx;
};
