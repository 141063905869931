import {
  locateModelGondolaProduct,
  splitListIntoEqualParts,
} from '@components/pages/sakura/sakuraUtils';
import {
  ExternalInfo,
  FlatContainer,
  FlatModelGondola,
  FlatModelGondolaHighlight,
  ModelGondolaProduct,
  Range,
  RegularModelGondola,
  RegularModelGondolaHighlight,
  ShelfBoard,
} from '@components/pages/sakura/types';
import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import { useModelGondolaAndAllProductsContext } from '../../utils/modelGondolaAndAllProductsProvider';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { useAllProductsContext } from '@utils/ProductsContext';
import { normalizeProductName } from '@utils/product';
import Image from 'next/image';
import { useState } from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import { RealogramCandidate } from 'types/realogram';
import { Balloon, HighlightColor, withColor } from './balloon';
import { ProductDetailsModal } from './productDetailsModal';

type RealogramCandidateWithItemCd = {
  itemCd: string;
  realogramCandidate: RealogramCandidate;
};

type ModelGondolaProductCellProps = {
  modelGondolaProduct: ModelGondolaProduct;
  externalInfo: ExternalInfo | undefined;
  mode: 'regular' | 'flat';
  numPerBasket?: number;
  realogramCandidates: RealogramCandidateWithItemCd[];
};

const ModelGondolaProductCell = ({
  modelGondolaProduct,
  externalInfo,
  mode,
  numPerBasket,
  realogramCandidates,
}: ModelGondolaProductCellProps) => {
  const [openProductDetails, setOpenProductDetails] = useState(false);

  const modelGondolaContext = useModelGondolaAndAllProductsContext();
  const masterProducts = useAllProductsContext();
  const itemCd = modelGondolaProduct.productCode;
  const masterProduct = masterProducts.products.find(
    (p) => p.detail?.organization_product_id === itemCd
  );
  const name = normalizeProductName(masterProduct?.name || itemCd);

  const productSale = modelGondolaContext?.productSales.find(
    (ps) => ps.itemCd === itemCd
  );

  const realogramCandidate = realogramCandidates.find(
    (rc) => rc.itemCd === itemCd
  )?.realogramCandidate;

  const annots =
    externalInfo?.annotations.filter((annot) => annot.itemCd === itemCd) || [];
  const snsInfoAnnot = annots.find((annot) => annot.type === 'SNS');
  const infoPackageAnnot = annots.find(
    (annot) => annot.type === '情報パッケージ' && !annot.kindImportant
  );
  const infoPackageImportantAnnot = annots.find(
    (annot) => annot.type === '情報パッケージ' && annot.kindImportant
  );
  const newItemAnnot = externalInfo?.newItems.find(
    (newItem) => newItem.itemCd === itemCd && !newItem.kindImportant
  );
  const newItemImportantAnnot = externalInfo?.newItems.find(
    (newItem) => newItem.itemCd === itemCd && newItem.kindImportant
  );
  const icons = [
    snsInfoAnnot && (
      <Image
        key="SNS情報"
        src="/sakura/sakura/sns_info.png"
        alt="SNS情報あり"
        width="12"
        height="12"
        style={{ opacity: 0.7 }}
      />
    ),
    (infoPackageAnnot || newItemAnnot) && (
      <Image
        key="情報パッケージ"
        src="/sakura/sakura/info_icon.png"
        alt="情報パッケージ"
        width="12"
        height="12"
        style={{ opacity: 0.7 }}
      />
    ),
    (infoPackageImportantAnnot || newItemImportantAnnot) && (
      <Image
        key="情報パッケージ（重要）"
        src="/sakura/sakura/info_icon_important.png"
        alt="情報パッケージ（重要）"
        width="12"
        height="12"
        style={{ opacity: 1 }}
      />
    ),
    modelGondolaProduct.comment.length && (
      <Avatar sx={{ bgcolor: 'orange', width: '9pt', height: '9pt' }}>
        <CommentIcon key="コメントあり" sx={{ fontSize: '6pt' }} />
      </Avatar>
    ),
  ].filter((c) => c);
  if (icons.length === 0) {
    icons.push(<Box key="spacer" component="div" sx={{ height: '12px' }} />);
  }

  const modelGondolaProductAssignment =
    modelGondolaContext?.relevantModelGondolas &&
    locateModelGondolaProduct(
      itemCd,
      modelGondolaContext.relevantModelGondolas
    );

  const absenseIcon = !realogramCandidate ? (
    <Chip
      sx={{
        fontSize: '7pt',
        height: '9pt',
        margin: '1pt',
        '& .MuiChip-label': {
          padding: '4px',
        },
        alignSelf: 'start',
      }}
      label="未陳列"
      variant="outlined"
      color="error"
    />
  ) : null;

  const determineLineClamp = () => {
    if (mode === 'regular') {
      return 2;
    }
    if (numPerBasket !== undefined && numPerBasket <= 3) {
      return absenseIcon ? 2 : 3;
    } else if (numPerBasket !== undefined && 3 < numPerBasket) {
      return absenseIcon ? 1 : 2;
    }
    return 3;
  };
  const compactMode = numPerBasket !== undefined && 3 <= numPerBasket;

  return (
    <>
      {masterProduct && externalInfo && productSale && (
        <ProductDetailsModal
          openProductDetails={openProductDetails}
          setOpenProductDetails={setOpenProductDetails}
          masterProduct={masterProduct}
          externalInfo={externalInfo}
          modelGondolaProductAssignment={modelGondolaProductAssignment}
          productSale={productSale}
        />
      )}
      <Box
        component="div"
        flex={1}
        sx={{
          border: '1px solid grey',
          p: '2px',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!openProductDetails) {
            setOpenProductDetails(true);
          }
        }}
      >
        <Stack spacing={0} sx={{ width: '100%' }}>
          <Stack direction="row" spacing={0.2} alignItems="flex-start">
            <Box key="bar" component="div" sx={{ flexGrow: 1 }} />{' '}
            {/* spacer */}
            {icons}
          </Stack>
          {mode === 'regular' ? (
            <Box
              component="div"
              minHeight="35px"
              maxHeight="35px"
              display="flex"
              justifyContent="center"
            >
              <ProductImage
                product={masterProduct}
                width="70"
                height="70"
                objectFit="contain"
                imageSizeType="tiny"
              />
            </Box>
          ) : (
            <Box
              component="div"
              minHeight={compactMode ? '24px' : '45px'}
              maxHeight={compactMode ? '24px' : '45px'}
              display="flex"
              justifyContent="center"
            >
              <ProductImage
                product={masterProduct}
                width="70"
                height="70"
                objectFit="contain"
                imageSizeType="tiny"
              />
            </Box>
          )}
          <Typography fontSize="7pt" color="#888">
            {itemCd}
          </Typography>
          <Typography
            fontSize="7pt"
            color="#444"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: determineLineClamp(),
              overflow: 'hidden',
              lineHeight: '1.25',
            }}
          >
            {name}
          </Typography>
          {absenseIcon}
        </Stack>
      </Box>
    </>
  );
};

/*
 * 段やカゴの配列に対して、元の順序を維持したまま囲む範囲と囲まない範囲にグルーピングしたいロジック。
 * [p0, p1, ..., p9]があり、ranges = [range(5, 8), range(1, 3)]のとき、
 * [
 *   [p0],  // 囲みなし
 *   [p1, p2],  // 囲みあり
 *   [p3, p4],  // 囲みなし
 *   [p5, p6, p7],  // 囲みあり
 *   [p8, p9],  // 囲みなし
 * ]
 * ってする。
 */
type HighlightedItemGroup<T, H extends Range> = {
  items: T[];
  highlight: H | undefined;
};

const groupItemsByRanges = <T, H extends Range>(items: T[], ranges: H[]) => {
  ranges.sort((a, b) => a.start - b.start);

  let idx = 0;
  const result: HighlightedItemGroup<T, H>[] = [];
  for (const r of ranges) {
    const { start, end } = r;
    if (idx < start) {
      result.push({ items: items.slice(idx, start), highlight: undefined });
    }
    result.push({ items: items.slice(start, end), highlight: r });
    idx = end;
  }
  if (idx < items.length) {
    result.push({ items: items.slice(idx), highlight: undefined });
  }
  return result;
};

type ModelGondolaSubRowProps = {
  products: ModelGondolaProduct[];
  externalInfo: ExternalInfo | undefined;
  realogramCandidates: RealogramCandidateWithItemCd[];
  highlights: (RegularModelGondolaHighlight & HighlightColor)[];
  isBottomRow: boolean;
};

const ModelGondolaSubRow = ({
  products,
  externalInfo,
  realogramCandidates,
  highlights,
  isBottomRow,
}: ModelGondolaSubRowProps) => {
  const makeGroupedProducts = () => {
    if (highlights.length === 0) {
      return [{ items: products, highlight: undefined }];
    } else if (!highlights[0].cols) {
      return [{ items: products, highlight: highlights[0] }];
    }
    return groupItemsByRanges(
      products,
      highlights.map((h) => ({
        subject: h.subject,
        start: h.cols?.start || 0,
        end: h.cols?.end || 0,
        color: h.color,
        textColor: h.textColor,
      }))
    );
  };
  return (
    <>
      {makeGroupedProducts().map((group, i) => (
        <Box
          key={i}
          component="div"
          display="flex"
          flex={group.items.length}
          position="relative"
          boxShadow={
            group.highlight
              ? `inset 0 0 0 4px ${group.highlight.color}`
              : undefined
          }
        >
          {group.items.map((p) => (
            <ModelGondolaProductCell
              key={p.productCode}
              modelGondolaProduct={p}
              externalInfo={externalInfo}
              mode="regular"
              realogramCandidates={realogramCandidates}
            />
          ))}
          {group.highlight && (
            <Balloon
              text={group.highlight.subject}
              color={group.highlight.color}
              textColor={group.highlight.textColor}
              position={isBottomRow ? 'top' : 'bottom'} // 下段だったらフキダシは上に表示
            />
          )}
        </Box>
      ))}
    </>
  );
};

type ModelGondolaRowHeaderProps = {
  board: ShelfBoard;
};

const ModelGondolaRowHeader = ({ board }: ModelGondolaRowHeaderProps) => {
  return (
    <Box
      component="div"
      maxWidth="35px"
      minWidth="35px"
      flex="1"
      display="flex"
      alignItems="flex-start"
      sx={{
        paddingY: '0px',
        fontSize: '9px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#666',
        color: '#FFF',
        borderBottom: '2px solid grey',
        justifyItems: 'center',
      }}
    >
      <Stack direction="row" alignItems="baseline">
        <Typography variant="body1">{board.boardIndex}</Typography>
        <Typography variant="caption2">段</Typography>
      </Stack>
    </Box>
  );
};

type RegularModelGondolaBoardProps = {
  board: ShelfBoard;
  externalInfo: ExternalInfo | undefined;
  realogramCandidates: RealogramCandidateWithItemCd[];
  highlights: (RegularModelGondolaHighlight & HighlightColor)[];
  isBottomRow: boolean;
};

const RegularModelGondolaBoard = ({
  board,
  externalInfo,
  realogramCandidates,
  highlights,
  isBottomRow,
}: RegularModelGondolaBoardProps) => {
  const subRows = splitListIntoEqualParts(board.products, 9);

  // TODO: subRowsに分割されたとき、highlightsは未対応で崩れる

  return (
    <Box component="div" display="flex" sx={{ borderBottom: '1px solid grey' }}>
      <ModelGondolaRowHeader board={board} />
      <Stack flex="1">
        {subRows.map((subRowProducts) => (
          <Box
            key={subRowProducts[0].productCode}
            component="div"
            width="100%"
            display="flex"
          >
            <ModelGondolaSubRow
              products={subRowProducts}
              externalInfo={externalInfo}
              realogramCandidates={realogramCandidates}
              highlights={highlights}
              isBottomRow={isBottomRow}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

type FlatModelGondolaBasketColumnProps = {
  modelGondolaProducts: ModelGondolaProduct[];
  externalInfo: ExternalInfo | undefined;
  realogramCandidates: RealogramCandidateWithItemCd[];
};

const FlatModelGondolaBasketColumn = ({
  modelGondolaProducts,
  externalInfo,
  realogramCandidates,
}: FlatModelGondolaBasketColumnProps) => {
  return (
    <Box
      component="div"
      flex={1}
      sx={{
        height: '330px',
      }}
    >
      <Stack height="100%">
        {modelGondolaProducts.map((modelGondolaProduct) => {
          return (
            <ModelGondolaProductCell
              key={modelGondolaProduct.productCode}
              modelGondolaProduct={modelGondolaProduct}
              externalInfo={externalInfo}
              numPerBasket={modelGondolaProducts.length}
              mode="flat"
              realogramCandidates={realogramCandidates}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

type FlatModelGondolaBasketProps = FlatModelGondolaBasketColumnProps & {
  numColsInBasket: 1 | 2;
};

const FlatModelGondolaBasket = ({
  modelGondolaProducts,
  externalInfo,
  numColsInBasket,
  realogramCandidates,
}: FlatModelGondolaBasketProps) => {
  if (numColsInBasket === 1 || modelGondolaProducts.length === 1) {
    return (
      <Box component="div" flex={1}>
        <FlatModelGondolaBasketColumn
          modelGondolaProducts={modelGondolaProducts}
          externalInfo={externalInfo}
          realogramCandidates={realogramCandidates}
        />
      </Box>
    );
  } else {
    const n = Math.ceil(modelGondolaProducts.length / 2);
    const left = modelGondolaProducts.slice(0, n);
    const right = modelGondolaProducts.slice(n);
    return (
      <Box
        component="div"
        flex={1}
        sx={{
          border: '1px solid black',
        }}
      >
        <Stack direction="row">
          <FlatModelGondolaBasketColumn
            modelGondolaProducts={left}
            externalInfo={externalInfo}
            realogramCandidates={realogramCandidates}
          />
          <FlatModelGondolaBasketColumn
            modelGondolaProducts={right}
            externalInfo={externalInfo}
            realogramCandidates={realogramCandidates}
          />
        </Stack>
      </Box>
    );
  }
};

type FlatModelGondolaContainerProps = {
  container: FlatContainer;
  externalInfo: ExternalInfo | undefined;
  realogramCandidates: RealogramCandidateWithItemCd[];
  highlights: (FlatModelGondolaHighlight & HighlightColor)[];
};

const FlatModelGondolaContainer = ({
  container,
  externalInfo,
  realogramCandidates,
  highlights,
}: FlatModelGondolaContainerProps) => {
  // 5カゴの場合は、2列化していい。
  // 10カゴの場合は、1列縦並びがいいんでないか。
  const numColsInBasket = container.baskets.length <= 5 ? 2 : 1;

  const makeGroupedProducts = () => {
    if (highlights.length === 0) {
      return [{ items: container.baskets, highlight: undefined }];
    }
    return groupItemsByRanges(
      container.baskets,
      highlights.map((h) => ({
        subject: h.subject,
        start: h.basketIndices.start,
        end: h.basketIndices.end,
        color: h.color,
        textColor: h.textColor,
      }))
    );
  };
  return (
    <Stack>
      <Stack direction="row">
        {makeGroupedProducts().map((group, i) => (
          <Box
            key={i}
            component="div"
            display="flex"
            flex={group.items.length}
            position="relative"
            boxShadow={
              group.highlight
                ? `inset 0 0 0 4px ${group.highlight.color}`
                : undefined
            }
          >
            {group.items.map((basket) => (
              <FlatModelGondolaBasket
                key={basket.col}
                modelGondolaProducts={basket.products}
                externalInfo={externalInfo}
                numColsInBasket={numColsInBasket}
                realogramCandidates={realogramCandidates}
              />
            ))}
            {group.highlight && (
              <Balloon
                text={group.highlight.subject}
                color={group.highlight.color}
                textColor={group.highlight.textColor}
                position={container.rowIndex === 2 ? 'top' : 'bottom'}
              />
            )}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

type Props = {
  modelGondola: RegularModelGondola | FlatModelGondola;
  realogramCandidatesForTheDay: RealogramCandidateWithItemCd[];
};

export const ModelGondolasSimplifiedViewer = ({
  modelGondola,
  realogramCandidatesForTheDay,
}: Props) => {
  const modelGondolasContext = useModelGondolaAndAllProductsContext();
  const externalInfo = modelGondolasContext?.externalInfo;

  if (!modelGondolasContext) {
    return null;
  }

  const ModelGondolaBox = ({ children }: { children: React.ReactNode }) => (
    <Box
      component="div"
      px="15px"
      paddingBottom="10px"
      sx={{ overflowY: 'auto' }}
    >
      {children}
    </Box>
  );
  if (modelGondola.type === 'regular') {
    const gondola = modelGondola as RegularModelGondola;
    const boards = gondola.boards.map((board, index) => (
      <RegularModelGondolaBoard
        key={index}
        board={board}
        externalInfo={externalInfo}
        realogramCandidates={realogramCandidatesForTheDay}
        highlights={
          withColor(gondola.highlights || []).filter(
            (h) => h.boardIndex === index
          ) || []
        }
        isBottomRow={index === gondola.boards.length - 1}
      />
    ));
    return <ModelGondolaBox>{boards}</ModelGondolaBox>;
  } else {
    const gondola = modelGondola as FlatModelGondola;
    const containers = gondola.containers.map((container, index) => (
      <FlatModelGondolaContainer
        key={container.name}
        container={container}
        externalInfo={externalInfo}
        realogramCandidates={realogramCandidatesForTheDay}
        highlights={
          withColor(gondola.highlights || []).filter(
            (h) => h.containerIndex === index
          ) || []
        }
      />
    ));
    return <ModelGondolaBox>{containers}</ModelGondolaBox>;
  }
};
