import { Product } from 'types/common';
import { useRerenderingDetails } from './rerenderingComponents';
import { PlanogramPlan, Position, ProductPosition } from 'types/planogram';

type Props = {
  products?: Product[];
  isCompared?: boolean;
  productsLayout?: PlanogramPlan['products_layout'];
};

export const useSelectedPlanogramItem = ({ products, isCompared }: Props) => {
  const {
    searchParams,
    operate: { getSelectedItemOfPlanogram },
  } = useRerenderingDetails();
  const { productsSelected } = getSelectedItemOfPlanogram(
    products,
    [],
    isCompared
  );
  const position: Position[] | ProductPosition | undefined = searchParams.get(
    'position'
  )
    ? JSON.parse(searchParams.get('position') as string)
    : undefined;

  const selectedProductCompartment: Product | undefined =
    productsSelected?.at(0);
  const selectedBucketId: number | undefined = searchParams.get(
    'selectedBucketId'
  )
    ? Number(searchParams.get('selectedBucketId'))
    : undefined;

  return {
    selectedProductCompartment,
    position,
    selectedBucketId,
  };
};
