import { setRealogramCandidateStoreBay } from '@reducers/realogramCandidate';
import {
  useListRealogramCandidatesQuery,
  useListStoreBaysQuery,
} from '@reducers/shelfAppsApi';
import { paths } from '@utils/const';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
export type StoreBayChangeButtonState = {
  previous: boolean;
  next: boolean;
};
type Props = {
  storeId: number;
  storeBayId: number;
  storeBay: string;
  createdAt: string;
  searchParams: URLSearchParams;
};
export const useChangeStoreBay = ({
  storeId,
  storeBayId,
  storeBay,
  createdAt,
  searchParams,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectStoreBay, setSelectStoreBay] = useState(storeBayId);
  const [hasData, setHasData] = useState(true);
  const [isChangeButtonActive, setIsChangeButtonActive] =
    useState<StoreBayChangeButtonState>({
      previous: true,
      next: true,
    });
  const { data: storeBayData } = useListStoreBaysQuery(
    { storeId: storeId },
    { skip: !storeId }
  );
  const date = new Date(createdAt);
  const startDate = !createdAt
    ? ''
    : new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0
      ).toISOString();
  const endDate = !createdAt
    ? ''
    : new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        23,
        59,
        59,
        999
      ).toISOString();

  const { data: nextBayRealograms } = useListRealogramCandidatesQuery(
    {
      storeId: [storeId],
      storeBayId: [selectStoreBay],
      limit: 1,
      createdAtStart: startDate,
      createdAtEnd: endDate,
    },
    { skip: !storeId || !createdAt }
  );

  const handleStoreBayChange = (id: number, name: string) => {
    if (id === selectStoreBay) return;
    setSelectStoreBay(id);
    checkButtonState(id);
    dispatch(setRealogramCandidateStoreBay(name));
  };

  const getNextStoreBay = () => {
    const storeBay = storeBayData?.store_bays;
    if (!storeBay) return;
    const index = storeBay.findIndex((item) => item.id === selectStoreBay);
    if (index === storeBay.length - 1) return;
    const newStoreBay = storeBay[index + 1];
    setSelectStoreBay(newStoreBay?.id ?? 0);
    checkButtonState(newStoreBay?.id ?? 0);
    dispatch(setRealogramCandidateStoreBay(newStoreBay?.name ?? ''));
  };

  const getPreviousStoreBay = () => {
    const storeBay = storeBayData?.store_bays;
    if (!storeBay) return;
    const index = storeBay.findIndex((item) => item.id === selectStoreBay);
    const newStoreBay = index > 0 ? storeBay[index - 1] : null;
    if (index === 0) return;
    setSelectStoreBay(newStoreBay?.id ?? 0);
    checkButtonState(newStoreBay?.id ?? 0);
    dispatch(setRealogramCandidateStoreBay(newStoreBay?.name ?? ''));
  };

  const checkButtonState = (newId: number) => {
    const storeBay = storeBayData?.store_bays;
    if (!storeBay) return;
    const index = storeBay.findIndex((item) => item.id === newId);

    if (index === storeBay.length - 1) {
      setIsChangeButtonActive({
        next: false,
        previous: true,
      });
    } else if (index === 0) {
      setIsChangeButtonActive({
        next: true,
        previous: false,
      });
    } else {
      setIsChangeButtonActive({
        next: true,
        previous: true,
      });
    }
  };

  /**
   * check next storebay has realogram data or not, move to the realogram id path
   */
  const checkNextStoreBayData = () => {
    if (!nextBayRealograms) return;
    if (storeBayId === selectStoreBay || selectStoreBay === 0) {
      setHasData(true);
    } else if (
      nextBayRealograms?.realogram_candidates.length === 0 &&
      storeBayId !== selectStoreBay
    ) {
      setHasData(false);
    } else if (nextBayRealograms?.realogram_candidates.length > 0) {
      setHasData(true);
      navigate({
        pathname: paths.actuals.id(
          nextBayRealograms?.realogram_candidates[0].id.toString()
        ),
        search: createSearchParams(searchParams).toString(),
      });
    }
  };

  useEffect(() => {
    checkNextStoreBayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- checkNextStoreBayData does not need
  }, [nextBayRealograms]);

  useEffect(() => {
    dispatch(setRealogramCandidateStoreBay(storeBay));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dispatch does not need
  }, [storeBay]);

  useEffect(() => {
    setSelectStoreBay(storeBayId);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setSelectStoreBay and checkButtonState do not need
  }, [storeBayId]);

  return {
    storeBayData,
    selectStoreBay,
    hasData,
    isChangeButtonActive,
    handleStoreBayChange,
    getPreviousStoreBay,
    getNextStoreBay,
  };
};
