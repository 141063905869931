import { CircularSpinner } from '@components/molecules/circularSpinner/circularSpinner';
import { ComparisonProduct } from '@components/molecules/comparisonProduct/comparisonProduct';
import { ComparisonStatistics } from '@components/organisms/comparisonStatistics/comparisonStatistics';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import {
  CurrentSelectedType,
  setCurrentTab,
  updateCurrentSelectedItemId,
  updateCurrentSelectedType,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { selectModalProps } from '@reducers/modal/selector';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import {
  useGetProductQuery,
  useListProductsBulkQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal } from '@utils/const';
import {
  calcFlatPlanStatistics,
  calcPlanStatistics,
  getBucketsProductIds,
  getProductsLayout,
  isPlanogramBucketPlan,
  isPlanogramShelvesDetail,
} from '@utils/planogram';
import { isInferredAsProduct } from '@utils/product';
import { calculateRealogramStatistics, isSelectedItem } from '@utils/realogram';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'theme';
import {
  ProductTag,
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductSalesReport } from 'types/products';
import {
  ListProductsBulk,
  RealogramCandidate,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { Statistic } from 'types/statistics';
import { useRealogramAnalyticsData } from '../../../../hooks/useRealogramAnalyticsData';
import { useSelectedItem } from '../hooks/useSelectedItem';
import { statisticsWidth } from '../utils';
import { RealogramOrPlanogramImages } from './realogramOrPlanogramImages';

type Props = {
  realogramCandidateId: number;
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  shelfBoards?: RealogramShelfBoard[];
  filteredShelfboards?: RealogramShelfBoard[];
  primaryCandidates?: ListProductsBulk;
  storeBayName: string;
  createdAt: string;
  rate: Rate;
  productTag: ProductTag;
  report?: ProductSalesReport;
  handleChangeProductTag: (productTag: ProductTag) => void;
  handleChangeRate: (rate: Rate) => void;
  comparisonProfitTabValue: ProfitTab;
  setComparisonProfitTabValue: (profitTab: ProfitTab) => void;
  isImageLoading: boolean;
  handleChangeLoading: () => void;
  realogramCandidate?: RealogramCandidate;
  isBucketType: boolean;
  isTenantSalesAnalytics?: boolean;
  isGetRealogramCandidateLoading: boolean;
  initRerenderingSelectedProduct: boolean;
  setInitRerenderingSelectedProduct: (value: boolean) => void;
  compareQueryParams?: {
    id: number;
    type: CurrentSelectedType;
  };
  analyticsDataTerm: string;
  disableChangeToPreviousWeek: boolean;
  disableChangeToNextWeek: boolean;
  handleNextWeekTerm: () => void;
  handlePreviousWeekTerm: () => void;
  realogramStatistics?: Statistic;
  isLoadingAnalyticsData?: boolean;
};

export const Comparison: FC<Props> = ({
  shelfBoards,
  filteredShelfboards,
  primaryCandidates,
  view,
  mode,
  realogramCandidateId,
  createdAt,
  storeBayName,
  productTag,
  rate,
  report,
  handleChangeProductTag,
  handleChangeRate,
  comparisonProfitTabValue,
  setComparisonProfitTabValue,
  isImageLoading,
  handleChangeLoading,
  realogramCandidate,
  isBucketType,
  isTenantSalesAnalytics,
  compareQueryParams,
  analyticsDataTerm,
  disableChangeToPreviousWeek,
  disableChangeToNextWeek,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
  realogramStatistics,
  isLoadingAnalyticsData = false,
}) => {
  const [searchParams] = useSearchParams();
  const { isLarger } = useBreakpoint();
  const { open: isOpenModal } = useSelector(selectModalProps);
  const dispatch = useAppDispatch();

  const {
    isLoading,
    comparedPlanogram,
    comparedRealogram,
    isForbidden,
    fetchGetPlanogram,
  } = useComparisonItemModal();

  const comparedRealogramShelfboards =
    comparedRealogram?.detail?.products_shelves.shelf_boards;

  const isComparingBox = searchParams.get('item') ? false : true;
  const { selectedItem: selectedRealogramItem } = useSelectedItem(
    isComparingBox ? comparedRealogramShelfboards : shelfBoards,
    isComparingBox
  );

  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { data } = useGetProductQuery(
    {
      productId: selectedRealogramItem?.item.primary_candidate?.product_id ?? 0,
    },
    {
      skip:
        !isInferredAsProduct(selectedRealogramItem?.item) ||
        !selectedRealogramItem?.item.primary_candidate?.product_id,
    }
  );

  const {
    removeQueryParameter,
    removeQueryParameters,
    manipulateQueryParameters,
  } = useUrlQueryParams();

  const handleClickRealogramBbox = (
    item: RealogramSelectedItem,
    isComparingBox: boolean
  ) => {
    const key = isComparingBox ? 'compareItem' : 'item';

    if (isSelectedItem(view, item, selectedRealogramItem)) {
      removeQueryParameter(key);

      return;
    }

    manipulateQueryParameters({
      removeQueries: [
        isComparingBox ? 'item' : 'compareItem',
        'position',
        'selectedBucketId',
      ],
      updateQueries: [
        {
          key,
          value: item.item.id,
        },
      ],
    });
  };

  const handleClickOutSideRealogramBbox = () => {
    if (selectedRealogramItem) {
      removeQueryParameters(['item', 'compareItem'], false);
    }
  };

  const reset = () => {
    removeQueryParameters(['item', 'compareItem', 'position'], false);
  };

  const {
    isLoadingAnalyticsData: isLoadingComparedAnalyticsData,
    analyticsDataTerm: comparedAnalyticsDataTerm,
    displayAnalyticsData: displayComparedAnalyticsData,
    disableChangeToPreviousWeek: disableChangeToComparedPreviousWeek,
    disableChangeToNextWeek: disableChangeToComparedNextWeek,
    handleNextWeekTerm: handleComparedNextWeekTerm,
    handlePreviousWeekTerm: handleComparedPreviousWeekTerm,
  } = useRealogramAnalyticsData(
    comparedRealogram?.id ?? 0,
    !comparedRealogram || !isTenantSalesAnalytics || comparedRealogram?.id === 0
  );

  const { filteredShelfBoards: comparedShelfBoards } = useExtractRealogramData(
    view,
    productTag,
    displayComparedAnalyticsData?.products ?? [],
    rate,
    comparedRealogramShelfboards
  );

  // First drawing: Item information is compared
  useEffect(() => {
    const { id: queryId, type: queryType } = compareQueryParams || {};
    const shouldSkip = !queryId || !queryType || isOpenModal;

    if (shouldSkip) return;

    if (queryType === 'plan') {
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
    } else if (queryType === 'actual') {
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
    }
    dispatch(updateSelectedItemId(queryId));
    dispatch(updateCurrentSelectedItemId(queryId));
    dispatch(updateCurrentSelectedType(queryType));
  }, [compareQueryParams, isOpenModal, dispatch]);

  useEffect(() => {
    if (currentSelectedItemId?.toString() && currentSelectedType) {
      void fetchGetPlanogram(currentSelectedItemId, currentSelectedType);
    }
  }, [currentSelectedItemId, currentSelectedType, fetchGetPlanogram]);

  const comparisonProductIds = () => {
    if (comparedPlanogram) {
      if (isPlanogramBucketPlan(comparedPlanogram.plan)) {
        return (comparedPlanogram?.plan.frame.detail.buckets ?? [])
          .flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
          .sort()
          .join(',');
      }
      if (isPlanogramShelvesDetail(comparedPlanogram.plan)) {
        return getProductsLayout(comparedPlanogram.plan)
          .flatMap(({ row }) => row.map(({ product_id }) => product_id))
          .sort()
          .join(',');
      }
    }
    if (comparedShelfBoards) {
      return (comparedShelfBoards ?? [])
        ?.flatMap((shelfboard) =>
          shelfboard.compartments.flatMap((compartment) =>
            compartment.faces
              .filter((face) => isInferredAsProduct(face))
              .map((face) => face.primary_candidate?.product_id)
          )
        )
        .sort()
        .join(',');
    }
    return '';
  };

  const { data: comparisonProductsBulk } = useListProductsBulkQuery(
    { productIds: comparisonProductIds(), shape: true, detail: true },
    { skip: comparisonProductIds() === '' }
  );
  const { selectedProductCompartment } = useSelectedPlanogramItem({
    products: comparisonProductsBulk?.products,
    isCompared: true,
  });

  const { detailView } = useAppSelector(selectPlanogramEditorState);

  const comparedStatistics = useMemo(() => {
    if (comparedPlanogram) {
      if (isPlanogramBucketPlan(comparedPlanogram.plan)) {
        return calcFlatPlanStatistics(
          comparedPlanogram?.plan,
          detailView,
          productTag,
          comparisonProductsBulk?.products
        );
      }
      return calcPlanStatistics(
        comparedPlanogram?.plan,
        detailView,
        productTag,
        comparisonProductsBulk?.products
      );
    }
    if (comparedRealogram) {
      return calculateRealogramStatistics(
        comparedRealogram.detail?.products_shelves.shelf_boards ?? [],
        primaryCandidates?.products
      );
    }
  }, [
    comparedPlanogram,
    comparedRealogram,
    detailView,
    productTag,
    comparisonProductsBulk?.products,
    primaryCandidates?.products,
  ]);

  // TODO: add const with defining which data to return planogram or realogram

  const comparedAnalytics = useMemo(() => {
    if (comparedRealogram) {
      return displayComparedAnalyticsData?.products.find(
        (product) =>
          product.product_id ===
            selectedRealogramItem?.item.primary_candidate?.product_id ||
          product.product_id === selectedProductCompartment?.id
      );
    }
    // TODO: add planogram data after api is ready
    if (comparedPlanogram) {
      return;
    }
  }, [
    displayComparedAnalyticsData,
    selectedRealogramItem,
    selectedProductCompartment,
    comparedPlanogram,
    comparedRealogram,
  ]);

  const originalRealogramAnalytics = useMemo(() => {
    return report?.products.find(
      (product) =>
        product.product_id ===
        selectedRealogramItem?.item.primary_candidate?.product_id
    );
  }, [report, selectedRealogramItem]);
  const { planogramEstimatedData: comparedPlanogramEstimatedData } =
    useEstimatedProfit({
      planogram: comparedPlanogram,
    });
  const comparedReport = useMemo(() => {
    if (comparedPlanogram) {
      return comparedPlanogramEstimatedData?.estimate;
    }
    if (comparedRealogram) {
      return displayComparedAnalyticsData;
    }
  }, [
    comparedPlanogram,
    comparedRealogram,
    displayComparedAnalyticsData,
    comparedPlanogramEstimatedData?.estimate,
  ]);
  const isComparedRealogramBucketType =
    comparedRealogram?.shot_type === 'split_bucket';
  const isBucket = isComparingBox
    ? isComparedRealogramBucketType
    : isBucketType;
  const product = selectedRealogramItem
    ? data?.product
    : selectedProductCompartment;

  return (
    <>
      <Divider orientation="horizontal" flexItem />
      <Box
        component="div"
        display="flex"
        flexDirection={{ xs: 'column', breakpoint: 'row' }}
        flex={1}
        overflow="hidden"
      >
        <Box component="div" flex={1} overflow="hidden" display="flex">
          <PlanogramProvider
            value={{
              comparisonProducts: comparisonProductsBulk?.products,
              productSalesReport: comparedPlanogramEstimatedData?.estimate,
            }}
          >
            <RealogramOrPlanogramImages
              selectedRealogramItem={selectedRealogramItem}
              realogramCandidateId={realogramCandidateId}
              shelfBoards={filteredShelfboards}
              comparedPlanogram={comparedPlanogram}
              comparedRealogram={comparedRealogram}
              comparedShelfboards={comparedShelfBoards}
              isLoading={isLoading}
              handleClickRealogramBbox={handleClickRealogramBbox}
              handleClickOutSideRealogramBbox={handleClickOutSideRealogramBbox}
              view={view}
              mode={mode}
              createdAt={createdAt}
              storeBayName={storeBayName}
              rate={rate}
              profitTab={comparisonProfitTabValue}
              productReports={report?.products}
              isImageLoading={isImageLoading}
              handleChangeLoading={handleChangeLoading}
              realogramCandidate={realogramCandidate}
              currentSelectedType={currentSelectedType}
              report={report}
              isForbidden={isForbidden}
              isBucketType={isBucketType}
              isTenantSalesAnalytics={isTenantSalesAnalytics}
              analyticsDataTerm={analyticsDataTerm}
              disableChangeToPreviousWeek={disableChangeToPreviousWeek}
              disableChangeToNextWeek={disableChangeToNextWeek}
              handleNextWeekTerm={handleNextWeekTerm}
              handlePreviousWeekTerm={handlePreviousWeekTerm}
              comparedAnalyticsData={displayComparedAnalyticsData}
              comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
              isLoadingComparedAnalyticsData={isLoadingComparedAnalyticsData}
              isLoadingAnalyticsData={isLoadingAnalyticsData}
              disableChangeToComparedPreviousWeek={
                disableChangeToComparedPreviousWeek
              }
              disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
              handleComparedNextWeekTerm={handleComparedNextWeekTerm}
              handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
            />
          </PlanogramProvider>
        </Box>
        <Divider orientation={isLarger ? 'vertical' : 'horizontal'} flexItem />
        <Box
          component="div"
          p={2}
          minWidth={{ xs: 'unset', breakpoint: `${statisticsWidth}px` }}
          maxWidth={{ xs: 'unset', breakpoint: `${statisticsWidth}px` }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {(selectedRealogramItem && !isBucket) ||
          selectedProductCompartment ? (
            <Box component="div">
              <Button
                variant="outlined"
                sx={{
                  width: '72px',
                  height: '32px',
                  padding: '0 12px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  '& .MuiButton-startIcon': { margin: '0' },
                }}
                startIcon={
                  <ArrowBackIos
                    sx={{
                      color: theme.palette.primary.main,
                      width: '1rem',
                      height: '1rem',
                    }}
                    fontSize="small"
                  />
                }
                onClick={() => reset()}
              >
                戻る
              </Button>
              <ComparisonProduct
                view={view}
                product={product}
                realograms={shelfBoards}
                comparisonPlanogram={comparedPlanogram}
                comparisonRealogram={comparedRealogramShelfboards}
                selectedRealogramItem={selectedRealogramItem}
                comparedAnalyticsData={comparedAnalytics}
                analyticsData={originalRealogramAnalytics}
              />
            </Box>
          ) : (
            <Box component="div" height="334px">
              {isLoading && (
                <CircularSpinner
                  sx={{ display: 'flex', alignItems: 'center' }}
                />
              )}
              {!isLoading && (
                <ComparisonStatistics
                  view={view}
                  productTag={productTag}
                  handleChangeProductTag={handleChangeProductTag}
                  rate={rate}
                  handleChangeRate={handleChangeRate}
                  statistics={realogramStatistics}
                  comparisonStatistics={comparedStatistics}
                  report={report}
                  comparedReport={comparedReport}
                  comparisonProfitTabValue={comparisonProfitTabValue}
                  setComparisonProfitTabValue={setComparisonProfitTabValue}
                  isFlatPlanogram={isBucketType}
                  isComparedWithPlanogram={!!comparedPlanogram}
                  isPlanPage={false}
                />
              )}
            </Box>
          )}
          {mode === 'comparison' &&
            (view === 'default' || view === 'productFlag') && (
              <Box component="div" display="flex" gap="4px" alignItems="center">
                <Box
                  component="div"
                  borderRadius="2px"
                  width={16}
                  height={16}
                  sx={{
                    backgroundColor: theme.palette.confidence.low,
                  }}
                />
                <Typography
                  color={theme.palette.textBlack.secondary}
                  fontSize={12}
                >
                  片方のみにあるアイテム
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </>
  );
};
