import { FilterCondition } from '../types';
import { usePlanogramDirectories } from '@api/hooks/usePlanogramDirectories';
import { useSearchPlanogramDirectories } from '@api/hooks/useSearchPlanogramDirectories';
import { appConstants, rowsPerPage } from '@utils/const';

export const usePlanogramListData = (
  filterCondition: FilterCondition,
  isSkip: boolean,
  isSkipSearch: boolean,
  directoryId?: string,
  userId?: number
) => {
  // 検索条件がない場合はこちらの値が出る
  const {
    data: directory,
    fetchNextPage,
    isFetching: isDirectoryFetching,
    isFetchingNextPage: isDirectoryFetchingNextPage,
    isLoading: isDirectoryLoading,
    hasNextPage: hasDirectoryNextPage,
  } = usePlanogramDirectories({
    id: directoryId,
    ...{
      limit: rowsPerPage,
      firstOrder: appConstants.allowedFirstOrderInViewedTab.includes(
        filterCondition.firstOrder
      )
        ? 'updated_at_desc'
        : filterCondition.firstOrder,
      name: undefined,
      ownerId: undefined,
      bayPlanCodeId: undefined,
      organizationStatusId: undefined,
      enabled: !isSkip,
    },
  });

  /**
   * 検索条件が一つでもあればこちらを使う
   * APIのオプションが異なるので2つを使い分ける必要がある
   */
  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    fetchNextPage: fetchSearchNextPage,
    isFetchingNextPage: isSearchFetchingNextPage,
    hasNextPage: hasSearchNextPage,
  } = useSearchPlanogramDirectories({
    directory_ancestor_id:
      filterCondition.directoryId === '0'
        ? undefined
        : filterCondition.directoryId,
    limit: rowsPerPage,
    first_order: filterCondition.firstOrder,
    name: filterCondition?.name,
    owner_id: filterCondition.owner_id ? [filterCondition.owner_id] : [],
    bay_plan_code_id: filterCondition.bay_plan_code_id
      ? [filterCondition.bay_plan_code_id]
      : [],
    organization_status_id:
      filterCondition.status === '0' || !!filterCondition.status
        ? [+filterCondition.status]
        : undefined,
    favorite_owner_id: filterCondition.isStar && userId ? [userId] : undefined,
    enabled: !isSkipSearch,
  });

  const planogramDirectories = isSkipSearch
    ? directory?.planogram_directories
    : searchedData?.planogram_directories;

  const hasNextPage = isSkip ? hasSearchNextPage : hasDirectoryNextPage;
  const isFetchingNextPage = isSkip
    ? isSearchFetchingNextPage
    : isDirectoryFetchingNextPage;
  const isLoadingData = isSearchLoading || isDirectoryLoading;
  const isFetchingData = isSearchFetching || isDirectoryFetching;
  const isApiLoading = isLoadingData || isFetchingData;
  const isDisplayLoadingSkeleton =
    isApiLoading && !isFetchingNextPage && !isSearchFetchingNextPage;
  const isShowBreadcrumbList = !!directory && !!directoryId;

  const isRoot = directory?.parent?.type === 'root';
  return {
    directory,
    fetchNextPage,
    isDirectoryFetching,
    isDirectoryFetchingNextPage,
    isDirectoryLoading,
    hasDirectoryNextPage,

    searchedData,
    isSearchLoading,
    isSearchFetching,
    fetchSearchNextPage,
    isSearchFetchingNextPage,
    hasSearchNextPage,

    planogramDirectories,

    hasNextPage,
    isFetchingNextPage,
    isRoot,
    isShowBreadcrumbList,
    isDisplayLoadingSkeleton,
    isFetchingData,
    isLoadingData,
    isApiLoading,
  };
};
