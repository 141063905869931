import { useState } from 'react';
import { useLazyListStoresQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch } from '@store/index';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { useNavigate } from 'react-router-dom';
import { paths } from '@utils/const';

export const useNavigateToScanner = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [fetchStoreData] = useLazyListStoresQuery();
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = useState(false);

  const navigateToScanner = async () => {
    dispatch(updateLoadingIndicatorState(true));
    const stores = await fetchStoreData({ filterByRole: 'editor' });

    if (stores.data?.stores?.length) {
      navigate(paths.actuals.scan, {
        state: { from: paths.home },
      });
    } else {
      setIsPermissionDialogOpen(true);
    }
    dispatch(updateLoadingIndicatorState(false));
  };

  return {
    navigateToScanner,
    isPermissionDialogOpen,
    setIsPermissionDialogOpen,
  };
};
