import {
  EditorProduct,
  ModegonProduct,
  clearShowProductDetailItemCd,
  setAddProductToModegonItemCd,
  selectProducts,
  selectModegonProducts,
  selectShowProductDetail,
  setShowProductDetailItemCd,
  modegonProductLocationText,
  ProductTag,
} from '../features/slices';
import { FC, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { LineChart } from '@mui/x-charts/LineChart';

import Modal from '@mui/material/Modal';
import { numFmt, ProductImage, ProductTagChips } from './CommonComponents';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useProductImage } from '@hooks/useImage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type TimeSeriesChatProps = {
  product: EditorProduct;
};
const TimeSeriesChat: FC<TimeSeriesChatProps> = ({ product }) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const date = product.timeSeries.date.map((p) => new Date(Date.parse(p)));
  const chartConfigDef = [
    {
      title: '売上',
      labelSuffix: '平均売上 (円/日店)',
      description:
        '１日あたりの平均売上金額(円)。DO/ZOの全店舗での平均で未販売店舗を含む',
      series: {
        do: product.timeSeries.sales,
        zo: product.timeSeries.salesZo,
      },
    },
    {
      title: '導入率(POS)',
      labelSuffix: '導入率(%)',
      description: 'POSでその日に販売実績のあった店舗の割合',
      series: {
        do: product.timeSeries.others['adaptRatio'],
        zo: product.timeSeries.others['adaptRatioZo'],
      },
    },
    {
      title: 'ゴンドラ総売上',
      labelSuffix: '総売上平均 (円/日店)',
      description:
        'ゴンドラ(対象PMA)の１日、１店舗あたりの総売上。ゴンドラ全体の動向の指標。',
      series: {
        do: product.timeSeries.others['salesAll'],
        zo: product.timeSeries.others['salesAllZo'],
      },
    },
    {
      title: 'ゴンドラ内構成比',
      labelSuffix: '構成比(%)',
      description:
        '１日あたりのゴンドラ内構成比。ゴンドラ全体の推移に影響されにくい商品の販売推移の指標。',
      series: {
        do: product.timeSeries.others['compositionRatio'],
        zo: product.timeSeries.others['compositionRatioZo'],
      },
    },
    {
      title: '導入店舗販売数',
      labelSuffix: '平均販売数 (個/日店)',
      description:
        '当該日から７日間以内に販売実績のある店舗での1日あたりの販売個数。導入した場合の１日あたりの販売数を推察可能。',
      series: {
        do: product.timeSeries.others['quantityPerStore'],
        zo: product.timeSeries.others['quantityPerStoreZo'],
      },
    },
  ];

  const chartConfig = chartConfigDef[tabIndex];

  return (
    <>
      <Tabs value={tabIndex} onChange={(e, i) => setTabIndex(i)}>
        {chartConfigDef.map((t, i) => {
          return <Tab label={t.title} value={i} key={i} />;
        })}
      </Tabs>
      <Typography variant="caption">{chartConfig.description}</Typography>
      <LineChart
        height={300}
        xAxis={[
          {
            data: date,
            scaleType: 'time',
            valueFormatter: (value) => {
              const d = value as Date;
              return `${d.getMonth() + 1}/${d.getDate()}`;
            },
          },
        ]}
        series={[
          {
            data: chartConfig.series.do,
            label: `DO ${chartConfig.labelSuffix}`,
          },
          {
            data: chartConfig.series.zo,
            label: `ZO ${chartConfig.labelSuffix}`,
          },
        ]}
      />
    </>
  );
};

const ProductDataTable = ({ product }: { product: EditorProduct }) => {
  const cols = [' ', '４週前', '３週前', '前々週', '前週', 'ZO前週平均'];
  const rows = [
    [
      '売上 （円／日店）',
      numFmt(product.sales4w),
      numFmt(product.sales3w),
      numFmt(product.sales2w),
      numFmt(product.sales1w),
      numFmt(product.sales1wZo),
    ],
    [
      '販売数量 （個／日店）',
      numFmt(product.quantity4w),
      numFmt(product.quantity3w),
      numFmt(product.quantity2w),
      numFmt(product.quantity1w),
      numFmt(product.quantity1wZo),
    ],
    [
      '導入率（POS） （%）',
      numFmt(100 * product.adaptRatio4w),
      numFmt(100 * product.adaptRatio3w),
      numFmt(100 * product.adaptRatio2w),
      numFmt(100 * product.adaptRatio1w),
      numFmt(100 * product.adaptRatio1wZo),
    ],
    [
      'ゴンドラ内構成比 （％）',
      numFmt(100 * product.compositionRatio4w),
      numFmt(100 * product.compositionRatio3w),
      numFmt(100 * product.compositionRatio2w),
      numFmt(100 * product.compositionRatio1w),
      numFmt(100 * product.compositionRatio1wZo),
    ],
  ];

  const Row: FC<{
    i: number;
    contents: string[];
  }> = (props) => {
    const { i, contents } = props;
    return (
      <>
        {contents.map((c, j) => {
          return <TableCell key={`${i}-${j}`}>{c}</TableCell>;
        })}
      </>
    );
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {cols.map((c) => {
              return <TableCell key={c}>{c}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((r, i) => {
            return (
              <TableRow key={i}>
                <Row i={i} contents={r} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ProductDetailModal: FC = () => {
  const dispatch = useAppDispatch();

  let preventDismiss = false;

  const detailProps = useAppSelector(selectShowProductDetail);
  const products: EditorProduct[] = useAppSelector(selectProducts);
  const modegonProducts: ModegonProduct[] = useAppSelector(
    selectModegonProducts
  );

  const targetItemCd =
    detailProps.targets.length == 0 ? undefined : detailProps.targets[0];

  const targetProduct = products.find((p) => {
    return p.itemCd == targetItemCd;
  });

  const targetIndex = detailProps.products.indexOf(targetItemCd || '');
  const prevProduct =
    targetIndex > 0 ? detailProps.products[targetIndex - 1] : undefined;
  const nextProduct =
    targetIndex != -1 && targetIndex < detailProps.products.length - 1
      ? detailProps.products[targetIndex + 1]
      : undefined;

  const targetModegon = modegonProducts.find((p) => p.itemCd == targetItemCd);

  const { image } = useProductImage(
    {
      productId: parseInt(targetProduct?.shelfAppsProductId || ''),
      faceFront: targetProduct?.faceDirection || 'front',
      size: 'tiny',
    },
    {
      skip: targetProduct?.shelfAppsProductId == null,
    }
  );

  // dismiss this modal
  const dismissThis = () => {
    if (!preventDismiss) dispatch(clearShowProductDetailItemCd());
  };

  const onAddButtonTapped = () => {
    if (!targetItemCd) return;
    dispatch(setAddProductToModegonItemCd(targetItemCd));
    dismissThis();
  };

  const onMoveButtonTapped = (direction: 'left' | 'right') => {
    const newTarget = direction == 'left' ? prevProduct : nextProduct;

    if (newTarget) {
      dispatch(
        setShowProductDetailItemCd({
          targets: [newTarget],
          products: detailProps.products,
        })
      );
    }
    preventDismiss = true;
  };

  let tagSupText = '';
  if (targetProduct?.tags.includes(ProductTag.Informed)) {
    tagSupText += targetProduct.tagsSupplementalText[ProductTag.Informed];
  }
  if (targetProduct?.tags.includes(ProductTag.EliminationCandidate)) {
    tagSupText += `死筋理由: ${
      targetProduct.tagsSupplementalText[ProductTag.EliminationCandidate]
    }`;
  }

  return targetItemCd == undefined ? (
    <></>
  ) : (
    <div>
      <Modal
        open={targetItemCd != undefined}
        onClose={() => {
          dismissThis();
        }}
      >
        <Box sx={style} component="div" overflow="auto">
          <Stack direction="row">
            <IconButton
              onClick={() => {
                onMoveButtonTapped('left');
              }}
              disabled={prevProduct == undefined}
            >
              <ChevronLeft />
            </IconButton>
            <Stack spacing={1}>
              <Typography variant="h5">商品詳細</Typography>

              {/* グラフ */}
              <Box component="div" height="340px" width="800px">
                <TimeSeriesChat product={targetProduct!} />
              </Box>
              {/* 商品情報 */}
              <Box component="div">
                <Grid container direction="row" spacing={2}>
                  <Grid item width={128} height={128}>
                    {image && (
                      <ProductImage
                        src={image}
                        alt={targetProduct?.name || ''}
                        size={128}
                      />
                    )}
                  </Grid>
                  <Grid item sx={{ flexGrow: 1 }}>
                    <Stack>
                      <Box component="div">
                        <Typography component="span">商品名: </Typography>
                        <Typography component="span" fontWeight="bold">
                          {targetProduct?.name}
                        </Typography>
                      </Box>
                      <Typography fontSize={12}>
                        商品コード: {targetProduct?.itemCd} / JANコード:{' '}
                        {targetProduct?.jan}
                      </Typography>
                      <Typography fontSize={12}>
                        PMA {targetProduct?.pma} / 情報分類コード{' '}
                        {targetProduct?.informationClass}
                      </Typography>
                      <br />
                      <Typography>
                        推奨売価: {targetProduct?.price}円 / 原価:{' '}
                        {targetProduct?.costPrice}円
                      </Typography>
                      <Typography>
                        販売開始: {targetProduct?.startDate}
                        (販売日数: {targetProduct?.daysFromStart} 日)
                        {'  '}
                        販売終了: {targetProduct?.endDate || '-'}
                      </Typography>
                      <Box component="div">
                        {targetModegon != undefined
                          ? 'モデゴン導入済み ' +
                            modegonProductLocationText(targetModegon)
                          : 'モデゴン未導入'}
                        <Box component="span" m={1}>
                          {tagSupText}
                        </Box>
                      </Box>
                      <Box component="div">
                        {targetProduct && (
                          <ProductTagChips product={targetProduct} />
                        )}
                      </Box>
                      <Grid container item direction="row">
                        <Grid item xs={12}>
                          {targetProduct && (
                            <ProductDataTable product={targetProduct} />
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <Button
                variant="contained"
                onClick={() => {
                  onAddButtonTapped();
                }}
                disabled={targetModegon != undefined}
              >
                モデゴンに追加
              </Button>
            </Stack>

            <IconButton
              onClick={() => {
                onMoveButtonTapped('right');
              }}
              disabled={nextProduct == undefined}
            >
              <ChevronRight />
            </IconButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};
