import { EmptyCompartment } from '@components/organisms/realogramCandidatesList/fragments';

import { ListSubheader } from '@components/molecules/listSubheader/listSubheader';
import { findProductInSales } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { isEqual } from 'lodash';
import {
  FC,
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { theme } from 'theme';
import { Product, ProductTag, ProfitTab, ShelfDetailView } from 'types/common';
import { CompartmentBucketPosition, Position } from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { BucketCompartmentsListItem } from './bucketCompartmentsListItem';

type Props = {
  bucketIndex: number;
  view: ShelfDetailView;

  products?: Product[];
  productTag: ProductTag;
  productSales?: ProductReportProduct[];

  profitTab: ProfitTab;

  handleClickListItem: (
    position: Position[],
    bucketIndex: number,
    product?: Product
  ) => void;
  position: CompartmentBucketPosition[];
  bucketProductPosition?: Position[];
  selectedBucketId?: number;
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  showOrganizationProductId: boolean;
};

export const BucketCompartmentsList: FC<Props> = ({
  bucketIndex,
  view,
  productTag,
  profitTab,
  productSales,
  products,
  position,
  bucketProductPosition,
  selectedBucketId,
  handleClickListItem,
  refs,
  showOrganizationProductId,
}) => {
  const handleClickListItemRef = useRef<
    (position: Position[], bucketIndex: number, product?: Product) => void
  >(() => void 0);
  const memoizedHandleClickListItem = useCallback(
    (position: Position[], bucketIndex: number, product?: Product) => {
      handleClickListItemRef.current(position, bucketIndex, product);
    },
    []
  );

  useEffect(() => {
    handleClickListItemRef.current = handleClickListItem;
  });
  return (
    <Fragment key={bucketIndex}>
      {view === 'default' && (
        <ListSubheader
          title={`${bucketIndex + 1}カゴ目`}
          backgroundColor={theme.palette.backgroundBlack.active}
          color={theme.palette.textBlack.primary}
          sx={{ fontWeight: 400, height: 30 }}
          isSKU={false}
          handleChangeSKU={() => void 0}
        />
      )}
      {view === 'default' && !position.length ? (
        <EmptyCompartment
          emptyText="カゴに配置されている商品はありません"
          // eslint-disable-next-line @typescript-eslint/no-empty-function -- TODOリファクタリング
          handleClickDeleteShelfBoard={() => {}}
          isSKU={false}
        />
      ) : (
        position.map(({ compartment, position: productPos }, index) => {
          const product = products?.find(
            (p) => p.id === compartment.product_id
          );
          if (
            view === 'productFlag' &&
            !hasProductTag(productTag, product?.detail?.tags)
          ) {
            return <Fragment key={index} />;
          }
          if (
            view === 'profit' &&
            !findProductInSales(compartment?.product_id ?? 0, productSales)
          ) {
            return <Fragment key={index} />;
          }
          const isSelected =
            isEqual(productPos, bucketProductPosition) &&
            bucketIndex === selectedBucketId;
          return (
            <BucketCompartmentsListItem
              key={index}
              bucketIndex={bucketIndex}
              view={view}
              profitTab={profitTab}
              handleClickListItem={memoizedHandleClickListItem}
              position={productPos}
              refs={refs}
              showOrganizationProductId={showOrganizationProductId}
              compartment={compartment}
              isSelected={isSelected}
              product={product}
            />
          );
        })
      )}
    </Fragment>
  );
};
