import { FC, ReactNode } from 'react';
import { theme } from 'theme';
import { AlertDialog } from '@components/organisms';
import { CancelButton } from '../../../buttons/cancelButton';
import { Button, DialogContentText } from '@mui/material';

type Props = {
  quitDialogOpen: boolean;
  setQuitDialogOpen: (quitDialogOpen: boolean) => void;
  failedDialogOpen: boolean;
  handleCloseFailedDialog: () => void;
  failedDialogOpenForbidden: boolean;
  handleCloseFailedDialogForbidden: () => void;
  uploadButton: ReactNode;
  handleQuit: () => void;
};

export const FormAlertDialogs: FC<Props> = ({
  quitDialogOpen,
  setQuitDialogOpen,
  failedDialogOpen,
  handleCloseFailedDialog,
  uploadButton,
  failedDialogOpenForbidden,
  handleCloseFailedDialogForbidden,
  handleQuit,
}) => {
  return (
    <>
      <AlertDialog
        open={quitDialogOpen}
        title="新規スキャンを中止します。"
        content={
          <DialogContentText sx={{ color: theme.palette.textBlack.primary }}>
            この作業はやり直しできませんがよろしいですか？
          </DialogContentText>
        }
        cancelButton={
          <CancelButton handleClose={() => setQuitDialogOpen(false)} />
        }
        confirmButton={
          <Button
            onClick={handleQuit}
            sx={{
              color: theme.palette.system.errorMain,
              ':hover': { bgcolor: theme.palette.backgroundBlack.primaryHover },
            }}
          >
            中止
          </Button>
        }
      />
      <AlertDialog
        open={failedDialogOpen}
        title="アップロードに失敗しました。"
        content={
          <DialogContentText sx={{ color: theme.palette.textBlack.primary }}>
            通信環境をご確認の上、再度アップロードしてください。
          </DialogContentText>
        }
        cancelButton={<CancelButton handleClose={handleCloseFailedDialog} />}
        confirmButton={uploadButton}
      />
      <AlertDialog
        open={failedDialogOpenForbidden}
        title="アップロードに失敗しました。"
        content={
          <DialogContentText sx={{ color: theme.palette.textBlack.primary }}>
            作成権限のない店舗のため、アップロードできません。
          </DialogContentText>
        }
        cancelButton={
          <CancelButton handleClose={handleCloseFailedDialogForbidden} />
        }
        confirmButton={uploadButton}
      />
    </>
  );
};
