import { BucketBayPart } from '@components/organisms/bucketBayPart/bucketBayPart';
import { DisplayBuckets } from '@components/organisms/displayBuckets/displayBuckets';
import { ErrorOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { withOpacity } from '@utils/colors';
import { FC } from 'react';
import { Bucket } from 'types/planogram';
import { theme } from '../../../../theme';
import { useBucketsData } from '../model/useBucketsData';
import { AddBayPart } from './AddBayPart';

type Props = {
  index: number;
  bucket: Bucket;
  isExceedSize?: boolean;
  hasBottomBuckets?: boolean;
  comparedBottomBucketsNum?: number;
  bottomBucketsNum?: number;
  isEditor: boolean;
  isCompared: boolean;
  zoomScale: number;
  bboxEnabled: boolean;
  translatePosition: number;
  setZoomScale: (zoomScale: number) => void;
  setTranslatePosition: (position: number) => void;
};

const textMargin = 4;

export const Buckets: FC<Props> = ({
  bucket,
  index,
  isExceedSize = false,
  hasBottomBuckets = false,
  comparedBottomBucketsNum = 0,
  bottomBucketsNum = 0,
  isEditor,
  isCompared,
  zoomScale,
  bboxEnabled,
  translatePosition,
  setTranslatePosition,
  setZoomScale,
}) => {
  const {
    refCb,
    height,
    width,
    background,
    mode,
    isSwappingBayPartMode,
    selectedBucketId,
    bottomBucketsLength,
    selectedBayPartId,
    rotateDegree,
    isOver,
    isSameItem,
    isOnRightSide,
    paddingValue,
    isDisplayLeftEdgesAddBayPart,
    isDisabledAddBayPart,

    isSelected,
    isSelectedInEditor,

    initUpperRowIndexThreshold,
    isDisabledBuckets,
    handleAddBayPart,
  } = useBucketsData({
    bucket,
    index,
    comparedBottomBucketsNum,
    bottomBucketsNum,
    isExceedSize,
    isEditor,
    isCompared,
  });

  return (
    <Box
      ref={refCb}
      component="div"
      sx={{
        height,
        width,
        //Need to specify padding bottom when displaying exterior UI
        display: 'flex',
        background,
        position: 'relative',
        '&::before':
          mode === 'BayEditor' &&
          ((!isSwappingBayPartMode && index !== selectedBucketId) ||
            (isSwappingBayPartMode && index === selectedBucketId))
            ? {
                content: `'${bucket.name}'`,
                position: 'absolute',
                top:
                  index >= bottomBucketsLength ? 'unset' : height + textMargin,
                bottom:
                  index >= bottomBucketsLength ? height + textMargin : 'unset',
                left: 0,
                width: '100%',
                fontSize: '10px',
                color: theme.palette.textBlack.secondary,
                transform: `translateY(${
                  selectedBayPartId && !selectedBucketId?.toString()
                    ? index >= bottomBucketsLength
                      ? -40
                      : 40
                    : 0
                }px) rotate(${rotateDegree}deg)`,
              }
            : '',
        '&::after':
          selectedBayPartId && !selectedBucketId?.toString()
            ? {
                content: "''",
                position: 'absolute',
                top:
                  index >= bottomBucketsLength ? 'unset' : height + textMargin,
                bottom:
                  index >= bottomBucketsLength ? height + textMargin : 'unset',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '1px',
                height: '30px',
                background: theme.palette.dividerBlack.dark,
                marginTop: index >= bottomBucketsLength ? 0 : '4px',
                marginBottom: index >= bottomBucketsLength ? '4px' : 0,
              }
            : '',
      }}
    >
      {isOver && !isSameItem && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            right: isOnRightSide ? `-${paddingValue}px` : `100%`,
            width: 2,
            height: '100%',
            background: theme.palette.primary.main,
            borderRadius: '1px',
          }}
        />
      )}
      {isExceedSize && (
        <Box
          component="div"
          sx={{
            width: 11,
            height: 11,
            backgroundColor: withOpacity(theme.palette.system.errorMain, 0.2),
            position: 'absolute',
            top: index >= bottomBucketsLength ? 'calc(100% - 5px)' : '-5px',
            right: index >= bottomBucketsLength ? 'calc(100% - 5px)' : '-7px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '20px',
            zIndex: 2,
          }}
        >
          <ErrorOutlined
            sx={{
              fill: theme.palette.system.errorMain,
              width: 15,
              height: 15,
            }}
          />
        </Box>
      )}
      {mode === 'BayEditor' && !isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: 'translate(-50%,-50%)',
            width: '100%',
            height: '100%',
            background: 'url(/hundle.svg) no-repeat center center',
          }}
        />
      )}

      {!isSwappingBayPartMode && (
        <AddBayPart
          isDisplayLeftEdgesAddBayPart={Boolean(isDisplayLeftEdgesAddBayPart)}
          isDisabledAddBayPart={isDisabledAddBayPart}
          handleAddBayPart={handleAddBayPart}
          hasBottomBuckets={hasBottomBuckets}
          index={index}
          bottomBucketsLength={bottomBucketsLength}
          selectedBayPartId={selectedBayPartId}
          isCompared={isCompared}
          selectedBucketId={selectedBucketId}
          top={
            index >= bottomBucketsLength || !hasBottomBuckets
              ? 'unset'
              : `${height}px`
          }
          bottom={
            index >= bottomBucketsLength || !hasBottomBuckets
              ? `${height}px`
              : 'unset'
          }
        />
      )}

      {!isSwappingBayPartMode && (
        <AddBayPart
          isDisplayLeftEdgesAddBayPart={Boolean(isDisplayLeftEdgesAddBayPart)}
          isDisabledAddBayPart={isDisabledAddBayPart}
          handleAddBayPart={handleAddBayPart}
          hasBottomBuckets={hasBottomBuckets}
          index={index}
          bottomBucketsLength={bottomBucketsLength}
          selectedBayPartId={selectedBayPartId}
          isCompared={isCompared}
          selectedBucketId={selectedBucketId}
          top={
            index >= bottomBucketsLength || !hasBottomBuckets
              ? 'unset'
              : `${height}px`
          }
          bottom={
            index >= bottomBucketsLength || !hasBottomBuckets
              ? `${height}px`
              : 'unset'
          }
        />
      )}

      <Box
        component="div"
        height="100%"
        width="100%"
        sx={{
          display: 'flex',
          alignItems: 'start',
          backgroundColor: isExceedSize
            ? theme.palette.system.errorBg
            : 'transparent',
        }}
      >
        <BucketBayPart
          bucket={bucket}
          isSelected={isSelected}
          isSelectedInEditor={isSelectedInEditor}
          isExceedSize={isExceedSize}
          isCompared={isCompared}
        />
      </Box>
      <DisplayBuckets
        key={index}
        bucket={bucket}
        index={index}
        initUpperRowIndexThreshold={initUpperRowIndexThreshold}
        isDisabledBuckets={isDisabledBuckets}
        isEditor={isEditor}
        isCompared={isCompared}
        translatePosition={translatePosition}
        zoomScale={zoomScale}
        bboxEnabled={bboxEnabled}
        setZoomScale={setZoomScale}
        setTranslatePosition={setTranslatePosition}
      />
    </Box>
  );
};
