import { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SortIcon from '@mui/icons-material/Sort';

import { ExternalInfo, StoreProductSale } from '../../types';
import { Product } from 'types/common';
import { UriageTableEntry, UriageTableItem } from './uriageTableItem';
import { useAllProductsContext } from '@utils/ProductsContext';

const productKeywordMatcher = (product: Product, keyword: string) => {
  if (keyword.split(' ').every((k) => product.name.includes(k.trim()))) {
    return true;
  }
  const itemCd = product.detail?.organization_product_id || '';
  return itemCd.includes(keyword);
};

const defaultFilterCondition = {
  店舗取扱あり: true,
  基本商品: false,
  推奨取消済: false,
  本部配信情報あり: false,
};
const filterConditionKeys = Object.keys(
  defaultFilterCondition
) as (keyof typeof defaultFilterCondition)[];

// TODO: 売金
const sortKeys = ['前週当店売数', '前週DO導入率'] as const;

const sortFilterProductSales = (
  productSales: StoreProductSale[],
  products: Product[],
  sortKey: (typeof sortKeys)[number],
  sortOrder: 'asc' | 'desc',
  searchKeyword: string,
  filterCondition: typeof defaultFilterCondition,
  externalInfo: ExternalInfo
): UriageTableEntry[] => {
  let entries = productSales
    .map((sale) => {
      const product = products.find(
        (p) => p.detail?.organization_product_id === sale.itemCd
      );
      if (!product) {
        return undefined;
      }
      return { product, sale, externalInfo };
    })
    .filter((entry) => entry !== undefined) as UriageTableEntry[];

  // 絞り込み
  if (searchKeyword) {
    entries = entries.filter((entry) =>
      productKeywordMatcher(entry.product, searchKeyword)
    );
  }
  if (filterCondition['店舗取扱あり']) {
    entries = entries.filter((entry) => entry.sale.quantity > 0);
  }
  if (filterCondition['基本商品']) {
    entries = entries.filter((entry) =>
      entry.product.detail?.tags?.includes('基本商品')
    );
  }
  if (filterCondition['推奨取消済']) {
    entries = entries.filter((entry) => {
      const salesEndDate = new Date(entry.product.detail?.sales_end_date || '');
      return salesEndDate < new Date();
    });
  }
  if (filterCondition['本部配信情報あり']) {
    entries = entries.filter((entry) => {
      const annotation = externalInfo.annotations.find(
        (annot) => annot.itemCd === entry.sale.itemCd
      );
      return annotation !== undefined;
    });
  }

  // 並べ替え
  if (sortKey === '前週当店売数') {
    entries.sort((a, b) => a.sale.quantity - b.sale.quantity);
  } else if (sortKey === '前週DO導入率') {
    entries.sort((a, b) => a.sale.doAdoptionRate - b.sale.doAdoptionRate);
  }
  if (sortOrder === 'desc') {
    entries.reverse();
  }

  return entries;
};

type UriageTableViewProps = {
  gondolaFilteredProductSales: StoreProductSale[];
  externalInfo: ExternalInfo;
};

export const UriageTableView = ({
  gondolaFilteredProductSales,
  externalInfo,
}: UriageTableViewProps) => {
  const [currentKeyword, setCurrentKeyword] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');

  const [filterCondition, setFilterCondition] = useState<
    typeof defaultFilterCondition
  >(defaultFilterCondition);

  const [sortKey, setSortKey] =
    useState<(typeof sortKeys)[number]>('前週当店売数');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const itemCds = gondolaFilteredProductSales.map((sale) => sale.itemCd);
  const products = useAllProductsContext(itemCds).products;
  const tableEntries = sortFilterProductSales(
    gondolaFilteredProductSales,
    products,
    sortKey,
    sortOrder,
    keyword,
    filterCondition,
    externalInfo
  );

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      <Stack spacing={1}>
        {/* 検索バー */}
        <Box
          component="div"
          display="flex"
          alignItems="center"
          width="100%"
          px={2}
          paddingTop={2}
        >
          <TextField
            variant="outlined"
            placeholder="絞り込みキーワード（商品名、商品CD）"
            value={currentKeyword}
            onChange={(e) => setCurrentKeyword(e.target.value)}
            size="small"
            fullWidth
            sx={{
              height: 30,
              fontSize: '10pt',
              borderTopLeftRadius: 40,
              borderBottomLeftRadius: 40,
              '& .MuiOutlinedInput-root': {
                height: 30,
                fontSize: '10pt',
                borderRadius: '40px 0 0 40px',
              },
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setKeyword(currentKeyword);
              }
            }}
          />
          <IconButton
            sx={{
              height: 30,
              fontSize: '10pt',
              borderRadius: '0 40px 40px 0',
              marginLeft: 0,
              border: '1px solid lightgrey',
              borderLeft: 'none',
              width: '50px',
              backgroundColor: '#eeeeee',
            }}
            onClick={() => setKeyword(currentKeyword)}
          >
            <SearchIcon />
          </IconButton>
        </Box>

        {/* 絞り込みと並べ替え */}
        <Box component="div" px={2}>
          <Stack spacing={0.1}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <FilterAltOutlinedIcon sx={{ color: '#666', fontSize: '12pt' }} />
              <Typography variant="caption1">絞り込み</Typography>
            </Stack>
            <Box component="div" display="flex" flexWrap="wrap" gap={1}>
              {filterConditionKeys.map((conditionKey) => {
                let label: string = conditionKey;
                if (filterCondition[conditionKey] === false) {
                  // 絞り込んだときの件数を表示する
                  const n = sortFilterProductSales(
                    gondolaFilteredProductSales,
                    products,
                    sortKey,
                    sortOrder,
                    keyword,
                    {
                      ...filterCondition,
                      [conditionKey]: true,
                    },
                    externalInfo
                  ).length;

                  label = `${conditionKey} (${n})`;
                }
                return (
                  <Chip
                    key={conditionKey}
                    size="small"
                    label={label}
                    // アイコンがあるとかえってわかりにくい
                    // icon={filterCondition[conditionKey] ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                    onClick={() => {
                      setFilterCondition({
                        ...filterCondition,
                        [conditionKey]: !filterCondition[conditionKey],
                      });
                    }}
                    variant="filled"
                    sx={{
                      border: '1px solid lightgrey',
                      backgroundColor: filterCondition[conditionKey]
                        ? '#e0e0e0 !important'
                        : 'white !important',
                      fontSize: '8pt',
                    }}
                  />
                );
              })}
            </Box>
            <Box component="div" height={4} />

            <Stack direction="row" spacing={0.5} alignItems="center">
              <ImportExportIcon sx={{ color: '#666', fontSize: '12pt' }} />
              <Typography variant="caption1">並べ替え</Typography>
            </Stack>
            <Box component="div" display="flex" flexWrap="wrap" gap={1}>
              {sortKeys.map((key) => {
                const rightIcon =
                  sortKey === key
                    ? {
                        deleteIcon: (
                          <SortIcon
                            sx={{
                              transform:
                                sortOrder === 'asc' ? 'scaleY(-1)' : null,
                            }}
                          />
                        ),
                        onDelete: () => {
                          //
                        },
                      }
                    : {};
                return (
                  <Chip
                    key={key}
                    size="small"
                    label={key}
                    onClick={() => {
                      if (sortKey === key) {
                        // ソートキーが同じ場合は昇順・降順を切り替える
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                      } else {
                        // ソートキーを変える場合はデフォルト昇順にする
                        setSortKey(key);
                        setSortOrder('asc');
                      }
                    }}
                    {...rightIcon}
                    variant="filled"
                    sx={{
                      border: '1px solid lightgrey',
                      backgroundColor:
                        sortKey === key
                          ? '#e0e0e0 !important'
                          : 'white !important',
                      fontSize: '8pt',
                    }}
                  />
                );
              })}
            </Box>
          </Stack>
        </Box>

        {/* 売上指標リスト */}
        <Box component="div" p={1} sx={{ overflowY: 'auto' }}>
          <Stack>
            <Typography fontSize="8pt" color="grey">
              {tableEntries.length}
              件の商品（このモデルゴンドラと同じPMAに属する商品）
            </Typography>
            <List>
              {tableEntries.map((entry) => (
                <ListItem key={entry.sale.itemCd}>
                  <UriageTableItem entry={entry} externalInfo={externalInfo} />
                </ListItem>
              ))}
            </List>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
