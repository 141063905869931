import { HeaderOptional } from '@components/organisms/compartmentsTable/fragments/headerOptional';
import { ShelfListHeader } from '@components/organisms/shelfListHeader/shelfListHeader';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { Box, List, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { embed } from '@utils/const';
import { hasProductTag } from '@utils/product';
import { FC, MutableRefObject, useEffect, useState } from 'react';
import { Product, ProfitTab } from 'types/common';
import {
  BucketPlanogramPlan,
  CompartmentBucketPosition,
  Position,
} from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { BucketCompartmentsList } from './bucketCompartmentsList';

type Props = {
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  products?: Product[];
  frame: BucketPlanogramPlan['frame'];
  handleClickListItem: (
    position: Position[],
    bucketIndex: number,
    product?: Product
  ) => void;
  profitTab: ProfitTab;
  positions?: CompartmentBucketPosition[][];
  productSales?: ProductReportProduct[];
};

export const BucketCompartmentsTable: FC<Props> = ({
  refs,
  products,
  frame,
  handleClickListItem,
  profitTab,
  positions,
  productSales,
}) => {
  const buckets = frame.detail.buckets;
  const { selectedBucketId, position } = useSelectedPlanogramItem({
    products,
  });
  const bucketProductPosition = position as Position[];
  const { detailView: view, productTag } = useAppSelector(
    selectPlanogramEditorState
  );
  const [showOrganizationProductId, setShowOrganizationProductId] =
    useState(false);

  useEffect(() => {
    const embedParam = new URLSearchParams(window.location.search).get('embed');
    if (embedParam === embed) setShowOrganizationProductId(true);
  }, []);

  const hasNoProductsToShow =
    (!products && view !== 'default' && view !== 'rate') ||
    (view === 'productFlag' &&
      products?.every((product) => {
        return !hasProductTag(productTag, product?.detail?.tags); // 商品属性ビューで該当のタグがない場合
      }));

  return (
    <>
      <ShelfListHeader>
        <HeaderOptional profitTab={profitTab} />
      </ShelfListHeader>
      <List
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        subheader={<Box component="li" />}
      >
        {!!buckets?.length && //[]の時(ロード中)にemptyを表示しない
          hasNoProductsToShow && (
            <Typography sx={{ color: '#999999', mt: 5, textAlign: 'center' }}>
              該当する区画がありません
            </Typography>
          )}
        {positions?.map((position, bucketIndex) => {
          return (
            <BucketCompartmentsList
              key={bucketIndex}
              bucketProductPosition={bucketProductPosition}
              productSales={productSales}
              products={products}
              bucketIndex={bucketIndex}
              view={view}
              productTag={productTag}
              profitTab={profitTab}
              handleClickListItem={handleClickListItem}
              position={position}
              selectedBucketId={selectedBucketId}
              refs={refs}
              showOrganizationProductId={showOrganizationProductId}
            />
          );
        })}
      </List>
    </>
  );
};
