import { ListOptional } from '@components/organisms/compartmentsTable/fragments/listOptional';
import { ShelfListItem } from '@components/organisms/shelfListItem/shelfListItem';
import { FC, memo, MutableRefObject } from 'react';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import {
  BucketArea,
  PlanogramProductCompartment,
  Position,
} from 'types/planogram';

type Props = {
  bucketIndex: number;
  view: ShelfDetailView;

  profitTab: ProfitTab;

  handleClickListItem: (
    position: Position[],
    bucketIndex: number,
    product?: Product
  ) => void;
  position: Position[];
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  showOrganizationProductId: boolean;
  product?: Product;
  compartment: BucketArea;
  isSelected: boolean;
};

export const BucketCompartmentsListItem: FC<Props> = memo(
  ({
    bucketIndex,
    view,
    profitTab,
    position,
    handleClickListItem,
    refs,
    showOrganizationProductId,
    product,
    compartment,
    isSelected,
  }) => {
    return (
      <ShelfListItem
        refs={refs}
        view={view}
        refKey={`${JSON.stringify(position)}-${bucketIndex}}`}
        product={product}
        isSelected={isSelected}
        handleClick={() => {
          handleClickListItem(position, bucketIndex, product);
        }}
        name={product?.name}
        organizationProductId={
          showOrganizationProductId
            ? product?.detail?.organization_product_id
            : ''
        }
        eanCode={product?.detail?.ean_code}
        productCode={product?.detail?.organization_product_id}
      >
        <ListOptional
          compartment={
            {
              ...compartment,
              face_count:
                (compartment.count?.x ?? 0) * (compartment.count?.y ?? 0),
            } as PlanogramProductCompartment
          }
          handleClick={() =>
            handleClickListItem(position, bucketIndex, product)
          }
          profitTab={profitTab}
        />
      </ShelfListItem>
    );
  }
);

BucketCompartmentsListItem.displayName = 'BucketCompartmentsListItem';
