import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  useListStoreBaysQuery,
  useListStoresQuery,
} from '@reducers/shelfAppsApi';
import { CommonColors } from '@utils/colors';
import { FC, useState } from 'react';
import { SearchList } from '../searchList/searchList';
import { t } from 'i18next';

type Props = {
  open: boolean;
  handleClose: () => void;
};

type ShelfType = 'realogram' | 'planogram';

export const SearchModal: FC<Props> = ({
  open,

  handleClose,
}) => {
  const [selectedStore, setSelectedStore] = useState<number>();
  const [selectedBayNumber, setSelectedBayNumber] = useState<number>();
  const [toggleValue, setToggleValue] = useState<ShelfType>('realogram');
  const { data: storeData } = useListStoresQuery({ limit: 10000 });
  const { data: storeBayData } = useListStoreBaysQuery(
    { storeId: selectedStore ?? 0 },
    { skip: !selectedStore }
  );
  const handleClickStore = (value: number) => {
    if (selectedStore === value) {
      setSelectedStore(undefined);
      return;
    }
    setSelectedStore(value);
  };

  const handleClickBayNumber = (value: number) => {
    if (selectedBayNumber === value) {
      setSelectedBayNumber(undefined);
      return;
    }
    setSelectedBayNumber(value);
  };

  const handleOkClick = () => {
    //TODO: api request
    if (toggleValue === 'realogram') {
      //add code
    } else {
      //add code
    }
    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle variant="h6" fontWeight="700">
        条件の変更
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: CommonColors.black,
          }}
        >
          <Close sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <RadioGroup
          value={toggleValue}
          row
          sx={{ px: 3, mb: 1 }}
          onChange={(_, value) => setToggleValue(value as ShelfType)}
        >
          <FormControlLabel
            value="realogram"
            control={<Radio />}
            label="スキャン結果"
          />
          <FormControlLabel
            value="planogram"
            control={<Radio />}
            label="棚割計画"
            disabled
          />
        </RadioGroup>
        <Divider />
        <Box component="div" display="flex" height="416px">
          <Box component="div" width="312px">
            <SearchList
              title="店舗"
              placeHolder="店舗名で検索"
              list={storeData?.stores ?? []}
              selectedItem={selectedStore}
              hasArrowForward
              handleClick={handleClickStore}
            />
          </Box>
          <Divider orientation="vertical" />
          <Box component="div" width="312px">
            <SearchList
              title={t('shelf_type')}
              placeHolder={`${t('shelf_type')}で検索`}
              list={storeBayData?.store_bays ?? []}
              selectedItem={selectedBayNumber}
              handleClick={handleClickBayNumber}
            />
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={handleClose} sx={{ color: CommonColors.black }}>
          キャンセル
        </Button>
        <Button variant="contained" onClick={handleOkClick}>
          決定
        </Button>
      </DialogActions>
    </Dialog>
  );
};
