import { SelectableCard } from '@components/organisms';
import {
  AssortmentProductCards,
  AssortmentTagCount,
} from '@components/organisms/productCards/assortmentProductCards';
import { StyledTabs } from '@components/organisms/productFlagChips/productFlagChips';
import {
  Box,
  Divider,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { theme } from 'theme';
import { AssortmentTag } from 'types/common';
import {
  ModelGondolaWithPatternLabel,
  useModelGondolaAndAllProductsContext,
} from '../../utils/modelGondolaAndAllProductsProvider';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import { gondolaPmas } from '@utils/gondolas.json';
import { t } from 'i18next';

type ModelGondoaLinkProps = {
  relevantModelGondolas: ModelGondolaWithPatternLabel[] | undefined;
  showModelGondola: ModelGondolaWithPatternLabel | undefined;
  setShowModelGondola: (_: ModelGondolaWithPatternLabel | undefined) => void;
};

const ModelGondolaLink = ({
  relevantModelGondolas,
  showModelGondola,
  setShowModelGondola,
}: ModelGondoaLinkProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const store = useModelGondolaAndAllProductsContext()?.currentStore;
  const gondolaCode = relevantModelGondolas?.[0]?.gondolaCode;
  const name = gondolaPmas.find((g) => g.code === gondolaCode)?.name;

  const openModelGondola = (gondola: ModelGondolaWithPatternLabel) => {
    if (store) {
      if (gondola.patternMeta.pattern !== undefined) {
        // パターン分けされたモデゴンの場合
        if (
          showModelGondola?.patternMeta.pattern === gondola.patternMeta.pattern
        ) {
          setShowModelGondola(undefined);
        } else {
          setShowModelGondola(gondola);
        }
      } else {
        // 共通モデゴンの場合
        if (showModelGondola) {
          setShowModelGondola(undefined);
        } else {
          setShowModelGondola(gondola);
        }
      }
      setAnchorEl(null);
    }
  };

  const ModegonOpenIcon = () => {
    if (relevantModelGondolas && relevantModelGondolas.length > 1) {
      if (anchorEl) {
        return <ArrowDropUpIcon sx={{ fontSize: '15pt' }} />;
      } else {
        return <ArrowDropDownIcon sx={{ fontSize: '15pt' }} />;
      }
    }
    return null;
  };

  const shortenGondolaName = (gondolaName: string, lenLimit = 9) =>
    lenLimit < gondolaName.length
      ? gondolaName.slice(0, lenLimit) + '...'
      : gondolaName;

  const hasHighlight = !!relevantModelGondolas?.find(
    (g) => 0 < (g.highlights || []).length
  );

  return (
    <>
      <Box component="div">
        <SelectableCard
          sx={{
            pl: 1,
            verticalAlign: 'middle',
            minWidth: 80,
            height: 54,
            borderColor: showModelGondola ? theme.palette.info.main : undefined,
          }}
          onClick={(e) => {
            if (relevantModelGondolas) {
              if (relevantModelGondolas.length === 1) {
                // 直接開く
                openModelGondola(relevantModelGondolas[0]);
              } else {
                setAnchorEl(e.currentTarget);
              }
            }
          }}
        >
          <Box component="div" position="relative" pr={hasHighlight ? 0.5 : 0}>
            <Stack direction="row" spacing={1} alignItems="center">
              <ModegonOpenIcon />
              <Stack>
                <Typography variant="body2" textAlign="start">
                  {t('assortment.shelf_plan')}を開く
                </Typography>
                <Typography variant="caption2" textAlign="start">
                  {gondolaCode} {shortenGondolaName(name || '')}
                </Typography>
              </Stack>
            </Stack>
            {hasHighlight && (
              <Box component="div" position="absolute" top="-12px" right="-5px">
                <WbIncandescentIcon
                  sx={{
                    color: 'crimson',
                    fontSize: '12px',
                    transform: 'scaleY(-1)',
                  }}
                />
              </Box>
            )}
          </Box>
        </SelectableCard>
      </Box>
      {relevantModelGondolas && relevantModelGondolas.length !== 0 && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {relevantModelGondolas.map((modelGondola, index) => {
            const icon =
              showModelGondola?.patternMeta.pattern ===
              modelGondola.patternMeta.pattern ? (
                <VisibilityIcon />
              ) : (
                <Icon />
              );
            return (
              <MenuItem
                key={index}
                onClick={() => openModelGondola(modelGondola)}
              >
                {showModelGondola && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText>
                  {modelGondola.patternMeta.pattern}パターンのモデゴン
                </ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

type Props = {
  assortmentTag: AssortmentTag;
  tagCounts: AssortmentTagCount[];
  handleChangeAssortmentTag: (tag: AssortmentTag) => void;
  hasSelected: boolean;
  relevantModelGondolas: ModelGondolaWithPatternLabel[] | undefined;
  showModelGondola: ModelGondolaWithPatternLabel | undefined;
  setShowModelGondola: (_: ModelGondolaWithPatternLabel | undefined) => void;
  realogramCandidateId?: number;
};

export const AssortmentDetailTabs = ({
  assortmentTag,
  tagCounts,
  handleChangeAssortmentTag,
  hasSelected,
  relevantModelGondolas,
  showModelGondola,
  setShowModelGondola,
  realogramCandidateId = 0,
}: Props) => {
  return (
    <Stack>
      <Box
        component="div"
        p={2}
        borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
        sx={{ justifyContent: 'space-around' }}
      >
        <StyledTabs
          value={0}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <AssortmentProductCards
            tag={assortmentTag}
            tagValues={tagCounts}
            handleChangeAssortmentTag={(assortmentTag) => {
              handleChangeAssortmentTag(assortmentTag);
              setShowModelGondola(undefined);
            }}
          />
          <Box component="div" sx={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            component="a"
            href={`/actuals/print/${realogramCandidateId}`}
            target="_blank"
          >
            印刷
          </Button>
          <ModelGondolaLink
            relevantModelGondolas={relevantModelGondolas}
            showModelGondola={showModelGondola}
            setShowModelGondola={setShowModelGondola}
          />
        </StyledTabs>
        <Typography variant="caption2">
          ※ このゴンドラと同じ分類の商品のみ
        </Typography>
      </Box>
      {hasSelected && <Divider />}
    </Stack>
  );
};
