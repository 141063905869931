import { useState } from 'react';
import { Fab, Badge } from '@mui/material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { useActionMemoContext } from './storeActionMemoContext';
import { MemoView } from './memoView';
import { AvailableItems, ExternalInfo, StoreProductSale } from '../../types';
import { AllProductsProvider } from '@utils/ProductsContext';

type Props = {
  externalInfo: ExternalInfo;
  storeProductSales: StoreProductSale[];
  availableItems: AvailableItems;
};

export const ActionMemoFAB = ({
  externalInfo,
  storeProductSales,
  availableItems,
}: Props) => {
  const actionMemoContext = useActionMemoContext();
  const memoItemCds = [
    ...actionMemoContext.addItems,
    ...actionMemoContext.cutItems,
  ].map((item) => item.itemCd);
  const numItems = memoItemCds.length;
  const [openList, setOpenList] = useState(false);

  return (
    <AllProductsProvider itemCds={memoItemCds} skip={numItems === 0}>
      <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
        <Badge
          badgeContent={numItems}
          color="warning"
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiBadge-badge': {
              zIndex: 10000,
            },
          }}
        >
          <Fab
            color="primary"
            onClick={() => {
              if (!openList) {
                setOpenList(true);
              } else {
                setOpenList(false);
              }
            }}
          >
            <NoteAltOutlinedIcon />
          </Fab>
        </Badge>
      </div>
      {openList && (
        <MemoView
          open={openList}
          handleClose={() => setOpenList(false)}
          externalInfo={externalInfo}
          storeProductSales={storeProductSales}
          availableItems={availableItems}
        />
      )}
    </AllProductsProvider>
  );
};
