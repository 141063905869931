import { useBayPartDnD } from '@hooks/useDnD';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { Box } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useGetBayPartQuery } from '@reducers/shelfAppsApi/injections/bayPartCategoriesApi';
import { useAppSelector } from '@store/index';
import {
  convertMeterToPixel,
  isPlanogramShelvesV2Compartment,
  shelfElevationToStepElevation,
  stepIndex,
} from '@utils/planogram';
import { FC, Fragment } from 'react';
import { BayPlanBayPart, ShelvesShelfBoardBayPart } from 'types/bayPlan';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import {
  BboxColors,
  PlanogramPlan,
  PlanogramShelvesDetail,
  ProductPosition,
} from 'types/planogram';
import { BayPart } from '../bayPart/bayPart';
import { DisplayShelf } from '../displayShelf/displayShelf';
import { AddIcon } from './fragments/AddIcon';
import { BayPartsPositionIndicator } from './fragments/bayPartsPositionIndicator';
import { BayPartsStepDiff } from './fragments/bayPartsStepDiff';
import { BayPartsStepIndicator } from './fragments/bayPartsStepIndicator';
import { RealogramSelectedItem } from 'types/realogram';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { usePlanogramContext } from '@hooks/usePlanogramProvider';

type Props = {
  plan: PlanogramPlan;
  diffColor?: string;
  bboxColors?: BboxColors;
  bboxEnabled?: boolean;
  detailMode?: ShelfDetailMode;
  detailView?: ShelfDetailView;
  isEditor?: boolean;
  isCompared?: boolean;
  selectedRealogramItem?: RealogramSelectedItem;
};

const defaultBboxColors: BboxColors = {
  borderColor: '#C6DAFC',
  backgroundColor: 'unset',
};

const half = 2;

export const ProductsBay: FC<Props> = ({
  plan,
  diffColor,
  bboxColors = defaultBboxColors,
  bboxEnabled,
  detailMode,
  detailView,
  isEditor = true,
  isCompared = false,
  selectedRealogramItem,
}) => {
  const planogramData = usePlanogramContext();

  const { drop } = useBayPartDnD();
  const { addBayPart, bayPartPosition } = usePlanogramPlan();
  const { mode, selectedBayPartId, isSwappingBayPartMode } = useAppSelector(
    selectPlanogramEditorState
  );
  const { data, isFetching } = useGetBayPartQuery(
    // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: 既存の問題なのであとで修正する
    { bay_part_id: selectedBayPartId ?? 0 },
    { skip: selectedBayPartId === undefined }
  );
  const {
    products_layout: productsLayout,
    shelves_frame: {
      detail: { exterior: baySize },
    },
  } = plan;

  const steps = plan.shelves_frame.detail.shelf_steps
    .map((i) => i.elevation)
    .sort((a, b) => b - a);
  const shelfSteps = plan.shelves.map((item) => {
    const e = shelfElevationToStepElevation(item) ?? -1;
    const baseStepIndex = stepIndex(e, steps);
    return item.type === 'hook_bar'
      ? {
          indexTop: stepIndex(
            steps[baseStepIndex] + item.detail.exterior.height,
            steps
          ),
          indexBottom: baseStepIndex,
        }
      : {
          indexTop: baseStepIndex,
          indexBottom: stepIndex(
            steps[baseStepIndex] - item.detail.exterior.height,
            steps
          ),
        };
  });

  const usedSteps = steps.filter((_, index) => {
    return (
      shelfSteps.findIndex(
        (d) => d.indexTop <= index && d.indexBottom >= index
      ) !== -1
    );
  });

  const canAdd = !!(
    mode === 'BayEditor' &&
    selectedBayPartId &&
    data?.bay_part &&
    !bayPartPosition &&
    !isFetching &&
    !isSwappingBayPartMode &&
    usedSteps.length !== steps.length
  );
  const isCompareModeActive = isEditor && mode === 'BayEditor';
  const defaultElevation = plan.shelves_frame.detail.exterior.height / half;

  const { selectedProductCompartment, position, selectedBucketId } =
    useSelectedPlanogramItem({
      products: isCompared
        ? planogramData?.comparisonProducts
        : planogramData?.products,

      isCompared,
    });
  const productPosition = position as ProductPosition;

  return (
    <>
      {isCompareModeActive && (
        <>
          <BayPartsStepIndicator plan={plan} />
          <BayPartsPositionIndicator
            height={plan.shelves_frame.detail.exterior.height}
            shelfSteps={shelfSteps}
            steps={steps}
          />
        </>
      )}
      <Box
        component="div"
        sx={{
          position: 'relative',
          width: convertMeterToPixel(baySize.width),
          height: convertMeterToPixel(baySize.height),
          boxSizing: 'content-box',
          borderLeft: '4px solid #CCCCCC',
          borderRight: '4px solid #CCCCCC',
          marginX: '-4px',
          borderTop: '1px dashed #CCCCCC',
          borderBottom: '2px solid #CCCCCC',
          marginTop: '-1px',
          marginBottom: '-2px',
        }}
        ref={drop}
      >
        {/* <Box
        component="div"
        sx={{
          position: 'absolute',
          bottom: -2, //TODO: should be 0
          width: convertMeterToPixel(frameWidth),
          height: convertMeterToPixel(frameHeight),
        }}
      >
        <BayFrame width={frameWidth} height={frameHeight} />
      </Box> */}
        <Box
          component="div"
          sx={{
            position: 'absolute',
            bottom: 0,
            height: '100%',
            width: '100%',
          }}
        >
          {isCompareModeActive && !isSwappingBayPartMode && (
            <BayPartsStepDiff plan={plan} />
          )}
          {plan.shelves.map(
            (shelf, index, shelves: PlanogramShelvesDetail['shelves']) => {
              const { height, bottom } = calculate(
                shelf,
                index,
                shelves,
                plan.shelves_frame
              );
              const { newBayPartElevation, firstShelfElevation } =
                getNewBayPartElevation(
                  shelfSteps,
                  index,
                  shelves,
                  steps,
                  data?.bay_part
                );
              const canAddNewBayPart =
                canAdd &&
                !isInsideBayPart(
                  newBayPartElevation,
                  steps,
                  shelfSteps,
                  data?.bay_part
                );
              const canAddFirstBayPart =
                canAdd &&
                firstShelfElevation &&
                !isInsideBayPart(
                  firstShelfElevation,
                  steps,
                  shelfSteps,
                  data?.bay_part
                );
              const marginBottom =
                shelf.type === 'hook_bar'
                  ? (shelf.detail.exterior.padding.top ?? 0) - shelf.height
                  : -(shelf.detail.exterior.padding.bottom ?? 0);

              const layout = productsLayout.at(index);
              const justifyContent = isPlanogramShelvesV2Compartment(layout)
                ? layout.justify_content
                : undefined;

              return (
                <Fragment key={`${shelf.bay_part_id}-${shelf.elevation}`}>
                  <Box
                    component="div"
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: convertMeterToPixel(height),
                      //Need to specify padding bottom when displaying exterior UI
                      bottom: convertMeterToPixel(bottom),
                      paddingTop: `${convertMeterToPixel(
                        shelf.detail.exterior.padding.top ?? 0
                      )}px`,
                      paddingBottom: `${convertMeterToPixel(
                        shelf.detail.exterior.padding.bottom ?? 0
                      )}px`,
                      display: 'flex',
                      flexDirection:
                        shelf.type === 'hook_bar' ? 'column-reverse' : 'column',
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                        // フレームのpadding
                        paddingLeft: `${convertMeterToPixel(
                          plan.shelves_frame.detail.exterior.padding?.left ?? 0
                        )}px`,
                        paddingRight: `${convertMeterToPixel(
                          plan.shelves_frame.detail.exterior.padding?.right ?? 0
                        )}px`,
                        zIndex: 2,
                      }}
                    >
                      <DisplayShelf
                        rowIndex={index}
                        shelf={shelf}
                        row={productsLayout.at(index)?.row}
                        shelfHeight={height}
                        diffColor={diffColor}
                        bboxColors={bboxColors}
                        bboxEnabled={bboxEnabled}
                        detailMode={detailMode}
                        detailView={detailView}
                        justifyContent={justifyContent}
                        isEditor={isEditor}
                        isCompared={isCompared}
                        selectedRealogramItem={selectedRealogramItem}
                        selectedProductCompartment={selectedProductCompartment}
                        productPosition={
                          isCompared ? undefined : productPosition
                        }
                        selectedBucketId={selectedBucketId}
                      />
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        //NOTE: type === board || type === shelf_board
                        marginBottom: `${convertMeterToPixel(marginBottom)}px`,
                        //NOTE: type === hook_bar
                        marginTop: `-${convertMeterToPixel(
                          shelf.detail.exterior.padding.top ?? 0
                        )}px`,
                        display: 'flex',
                        alignItems: 'start',
                        // eslint-disable-next-line no-magic-numbers -- TODO: 削除またはzIndexをまとめて定義する
                        zIndex: mode === 'BayEditor' ? 3 : 1,
                      }}
                    >
                      <BayPart
                        isEditorInCompare={isEditor}
                        {...shelf}
                        rowIndex={index}
                        canAdd={canAdd}
                        isCompared={isCompared}
                        justifyContent={justifyContent}
                      />
                    </Box>
                  </Box>
                  {canAddNewBayPart && (
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        right: -41,
                        bottom: convertMeterToPixel(newBayPartElevation),
                        zIndex: 4,
                        transform: 'translateY(50%)',
                      }}
                    >
                      {isEditor && !isCompared && (
                        <AddIcon
                          handleClick={() =>
                            addBayPart(data.bay_part, {
                              elevation: newBayPartElevation,
                            })
                          }
                        />
                      )}
                    </Box>
                  )}
                  {canAddFirstBayPart && (
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        right: -41,
                        bottom: convertMeterToPixel(firstShelfElevation),
                        zIndex: 4,
                        transform: 'translateY(50%)',
                      }}
                    >
                      {isEditor && (
                        <AddIcon
                          handleClick={() =>
                            addBayPart(data.bay_part, {
                              elevation: firstShelfElevation,
                            })
                          }
                        />
                      )}
                    </Box>
                  )}
                </Fragment>
              );
            }
          )}
        </Box>
        {canAdd && !plan.shelves.length && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              right: -41,
              bottom: convertMeterToPixel(defaultElevation),
              zIndex: 4,
            }}
          >
            {isEditor && (
              <AddIcon
                handleClick={() =>
                  addBayPart(data.bay_part, {
                    elevation: defaultElevation,
                  })
                }
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

const calculate = (
  shelf: PlanogramShelvesDetail['shelves'][0],
  index: number,
  shelves: PlanogramShelvesDetail['shelves'],
  frame: PlanogramShelvesDetail['shelves_frame']
): { bottom: number; height: number } => {
  if (shelf.type !== 'hook_bar') {
    // 棚板なので上部の什器パーツbottomとの位置関係を計算する
    const target = shelves.at(index + 1);
    const targetBottom = target
      ? calcBottom(target)
      : frame.detail.exterior.height;
    return {
      height: targetBottom - calcBottom(shelf),
      bottom: calcBottom(shelf),
    };
  }

  // 相手が棚板の場合は占有エリアを折半する
  const target = index > 0 ? shelves.at(index - 1) : shelves.at(0);
  const targetTop = target && index > 0 ? calcTop(target) : 0;
  // eslint-disable-next-line no-magic-numbers -- 高さの計算
  const divider = target?.type !== 'hook_bar' ? 2 : 1;
  const height = (calcTop(shelf) - targetTop) / divider;

  return {
    height,
    bottom: targetTop + (target && target.type !== 'hook_bar' ? height : 0),
  };
};
const calcBottom = (shelf: PlanogramShelvesDetail['shelves'][0]) =>
  shelf.elevation - shelf.height - (shelf.detail.exterior.padding.bottom ?? 0);
const calcTop = (shelf: PlanogramShelvesDetail['shelves'][0]) =>
  shelf.elevation +
  (shelf.detail.ui_helper?.display_space_2d.elevation ?? 0) +
  (shelf.detail.exterior.padding.top ?? 0);

const addAppBarStep = 4;
// MEMO: 現在ある什器のステップの位置から什器と什器の間のステップを求める
const getNewBayPartElevation = (
  shelf_steps: { indexTop: number; indexBottom: number }[],
  index: number,
  shelves: PlanogramShelvesDetail['shelves'],
  steps: number[],
  data?: BayPlanBayPart
) => {
  if (shelves.length === 1) {
    const middleTopStep =
      Math.floor(shelf_steps[index].indexTop / half) +
      (data?.name === 'アップバー' ? addAppBarStep : 0);
    const middleBottomStep =
      Math.floor((steps.length - 1 - shelf_steps[index].indexBottom) / half) +
      shelf_steps[index].indexBottom +
      (data?.name === 'アップバー' ? addAppBarStep : 0);
    return {
      newBayPartElevation: steps[middleTopStep],
      firstShelfElevation: steps[middleBottomStep],
    };
  }
  if (index === shelves.length - 1) {
    if (shelf_steps[index].indexTop === 0)
      return { newBayPartElevation: 10000 };
    const middleTopStep =
      Math.floor(shelf_steps[index].indexTop / half) +
      (data?.name === 'アップバー' ? addAppBarStep : 0);
    return { newBayPartElevation: steps[middleTopStep] };
  }
  const middleStep =
    Math.floor(
      (shelf_steps[index].indexTop - shelf_steps[index + 1].indexBottom) / half
    ) +
    shelf_steps[index + 1].indexBottom +
    (data?.name === 'アップバー' ? addAppBarStep : 0);
  return {
    newBayPartElevation: steps[middleStep],
  };
};

// 追加する什器のステップが空いているかのチェック
const isInsideBayPart = (
  el: number,
  steps: number[],
  shelfSteps: { indexTop: number; indexBottom: number }[],
  data?: BayPlanBayPart
) => {
  if (!data || !data.detail) return false;
  const baseStepIndex = stepIndex(el, steps);
  const checkStep =
    data.type === 'hook_bar'
      ? {
          indexTop: stepIndex(
            steps[baseStepIndex] + data.detail.exterior?.height,
            steps
          ),
          indexBottom: baseStepIndex,
        }
      : {
          indexTop: baseStepIndex,
          indexBottom: stepIndex(
            steps[baseStepIndex] -
              (data as ShelvesShelfBoardBayPart).detail.exterior?.height,
            steps
          ),
        };
  return shelfSteps.some(
    (step) =>
      (step.indexBottom >= checkStep.indexBottom &&
        step.indexTop <= checkStep.indexBottom) ||
      (step.indexBottom >= checkStep.indexTop &&
        step.indexTop <= checkStep.indexTop) ||
      checkStep.indexBottom >= steps.length
  );
};
