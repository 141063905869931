import { useRealogramDirectories } from '@api/hooks/useRealogramDirectories';
import { useRealogramDirectoriesSearch } from '@api/index';
import {
  setRealogramScrollPos,
  setSelectedDirectoryId,
} from '@reducers/scanner';
import { useAppDispatch } from '@store/index';
import {
  realogramsRowsPerPage,
  removeFirstLastSpaces,
  SidebarValue,
} from '@utils/const';
import { MutableRefObject, useEffect, useState } from 'react';
import {
  Condition,
  RealogramDirectoryType,
  RealogramListOrder,
  RealogramOrder,
} from 'types/realogram';

type Props = {
  condition: Condition;
  userId?: number;
  directoryId?: string;
  isFilteredByLatest: boolean;
  isFilteredByFavorite: boolean;
  isFilteredByViewed: boolean;
  firstOrder: RealogramListOrder | undefined;
  setCondition: (
    newCondition: Condition,
    options?: {
      sideBarValue?: SidebarValue;
      isToRootDirectory?: boolean;
      directoryId?: string;
      replace?: boolean;
    }
  ) => void;
  ref: MutableRefObject<HTMLElement | Window | null>;
  sidebarValue: SidebarValue;
};
export const useRealogramList = ({
  condition,
  userId,
  directoryId,
  isFilteredByLatest,
  isFilteredByFavorite,
  isFilteredByViewed,
  firstOrder,
  ref,
  setCondition,
}: Props) => {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [directoryIdCondition, setDirectoryIdCondition] = useState('0');
  const [isStarCondition, setIsStarCondition] = useState<boolean>(false);
  // フォルダの検索チップ用
  const [ownerIdCondition, setOwnerIdCondition] = useState<
    number | undefined
  >();
  const [directoryTypeCondition, setDirectoryTypeCondition] = useState<
    string | undefined
  >();
  const [folders, setFolders] = useState([
    { id: '0', label: 'すべてのフォルダ' },
  ]);

  const isSearching = condition.isSearching;
  const isSkipListRealogramDirectoriesQuery =
    isFilteredByFavorite ||
    isFilteredByLatest ||
    isFilteredByViewed ||
    isSearching ||
    condition.name;
  // no search condition, default
  const {
    data: directory,
    isLoading: isDirectoryLoading,
    isFetching: isDirectoryFetching,
  } = useRealogramDirectories({
    enabled: !isSkipListRealogramDirectoriesQuery,
    directoryId,
    condition,
  });

  const directoryType =
    condition.directoryType === 'all' ? undefined : condition.directoryType;
  const isSkipSearchRealogramDirectoriesQuery =
    (!isSkipListRealogramDirectoriesQuery && !condition?.ownerId) ||
    (isFilteredByFavorite && !userId);

  // if any condition exist
  const {
    data: searchedData,
    fetchNextPage,
    isFetching: isSearchFetching,
    isFetchingNextPage,
    isLoading: isSearchLoading,
    hasNextPage,
  } = useRealogramDirectoriesSearch({
    enabled: !isSkipSearchRealogramDirectoriesQuery,
    name: condition?.name,
    first_order: condition.firstOrder as RealogramOrder,
    limit: realogramsRowsPerPage,
    owner_id: condition?.ownerId ? condition.ownerId : undefined,
    status: condition?.status ? condition.status : undefined,
    favorite_owner_id: condition.isStar && userId ? [userId] : undefined,
    directory_type: isFilteredByLatest
      ? 'file'
      : (directoryType as RealogramDirectoryType),
    directory_ancestor_id:
      condition.directoryAncestorId === '0'
        ? undefined
        : condition.directoryAncestorId,
    // only show viewer role realograms when latest tab is selected
    filter_by_role: isFilteredByLatest ? 'viewer' : undefined,
  });

  const realogramDirectories = isSkipSearchRealogramDirectoriesQuery
    ? directory?.realogram_directories
    : searchedData;

  const isLoadingData = isSearchLoading || isDirectoryLoading;
  const isFetchingData = isSearchFetching || isDirectoryFetching;
  const isRoot = directory?.parent.type === 'root';
  const isShowBreadcrumbList = !!directory && !!directoryId;
  const isApiLoading =
    isDirectoryLoading ||
    isDirectoryFetching ||
    isSearchLoading ||
    isSearchFetching;
  const isDisplayLoadingSkeleton = isApiLoading && !isFetchingNextPage;
  const isShowFolderTip = condition.directoryAncestorId !== '0';

  // these 2 fields only used to determine hightlight filter button or not
  const planogramConiditionFilters = {
    statusId: condition?.status,
    ownerId: condition?.ownerId,
  };

  const hasCondition = Object.values(planogramConiditionFilters).some((el) => {
    if (Array.isArray(el)) {
      return !!el.length;
    }
    return !!el;
  });

  // keyword search (not using search modal)
  const onSubmit = (text: string) => {
    const name = removeFirstLastSpaces(text);
    if (name === '') return;
    // always search in the tab all
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        firstOrder,
        name,
      },
      { isToRootDirectory: !isSearching }
    );
  };

  const handleSubmitCondition = () => {
    setIsOpen(false);
    setCondition(
      {
        name: searchText,
        isStar: isStarCondition,
        ownerId: ownerIdCondition ? [+ownerIdCondition] : undefined,
        firstOrder: condition.firstOrder ?? 'created_at_desc',
        directoryAncestorId: directoryIdCondition,
        directoryType: directoryTypeCondition,
        isSearching: true,
        directoryName: directory?.parent?.name,
      },
      {
        isToRootDirectory: directoryIdCondition === '0',
        directoryId: directoryIdCondition !== '0' ? directoryIdCondition : '',
        sideBarValue: 'all',
      }
    );
  };

  const clearTextSearch = () => {
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        name: undefined,
        firstOrder: undefined,
      },
      {
        isToRootDirectory: !isSearching,
      }
    );
  };

  const handleResetConditions = () => {
    setDirectoryIdCondition('0');
    setIsStarCondition(false);
    setOwnerIdCondition(undefined);
    setDirectoryTypeCondition('all');
  };

  const handleDeleteTip = (key: keyof typeof condition) => {
    switch (key) {
      case 'directoryAncestorId':
        setFolders([{ id: '0', label: 'すべてのフォルダ' }]);
        setCondition(
          {
            ...condition,
            directoryAncestorId: '0',
          },
          {
            isToRootDirectory: true,
          }
        );
        break;
      case 'isStar':
        setIsStarCondition(false);
        setCondition(
          {
            ...condition,
            isStar: false,
          },
          {
            isToRootDirectory: directoryId === '0',
            directoryId: directoryId === '0' ? '' : directoryId,
          }
        );
        break;
      case 'ownerId':
        setOwnerIdCondition(undefined);
        setCondition({
          ...condition,
          ownerId: undefined,
        });
        break;
      case 'directoryType':
        setDirectoryTypeCondition(undefined);
        setCondition({
          ...condition,
          directoryType: undefined,
        });
        break;
    }
  };

  const handleConditionModalOpen = () => {
    setIsOpen(true);
    setIsStarCondition(false);
    setDirectoryTypeCondition(undefined);
    if (condition.directoryType) {
      setDirectoryTypeCondition(condition.directoryType);
    }
    if (isFilteredByFavorite || condition.isStar) {
      setIsStarCondition(true);
    }
    if (isFilteredByLatest || isFilteredByViewed) {
      setDirectoryTypeCondition('file');
    }
    if (directory?.parent?.type === 'root') {
      setFolders([{ id: '0', label: 'すべてのフォルダ' }]);
      setDirectoryIdCondition('0');
    } else if (directory?.parent) {
      setFolders([
        { id: '0', label: 'すべてのフォルダ' },
        { id: directory?.parent?.id, label: directory?.parent?.name },
      ]);
      setDirectoryIdCondition(directory?.parent?.id);
    }
  };

  useEffect(() => {
    if (
      !isShowFolderTip &&
      !condition.isStar &&
      !condition.name &&
      !condition.ownerId &&
      !condition.directoryType &&
      isSearching
    ) {
      setCondition(
        { isSearching: undefined, firstOrder: undefined },
        { isToRootDirectory: true }
      );
      dispatch(setRealogramScrollPos(0));
      ref.current?.scrollTo({ top: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setCondition will cause infinite loop
  }, [condition, isShowFolderTip]);

  return {
    // not search
    directory,
    isDirectoryLoading,
    isDirectoryFetching,
    // search
    searchedData,
    fetchNextPage,
    isFetching: isSearchFetching,
    isFetchingNextPage,
    isLoading: isSearchLoading,
    hasNextPage,

    // flags
    isSearching,
    realogramDirectories,
    isLoadingData,
    isFetchingData,
    isRoot,
    isShowBreadcrumbList,
    isApiLoading,
    isDisplayLoadingSkeleton,
    hasCondition,
    isOpen,
    isShowFolderTip,

    // condition
    folders,
    ownerIdCondition,
    setDirectoryIdCondition,
    setOwnerIdCondition,
    setIsStarCondition,
    setDirectoryTypeCondition,
    directoryTypeCondition,
    directoryIdCondition,
    isStarCondition,

    // handlers
    onSubmit,
    clearTextSearch,
    handleResetConditions,
    handleConditionModalOpen,
    setSearchText,
    handleDeleteTip,
    handleSubmitCondition,
    setIsOpen,
  };
};
