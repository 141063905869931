import { Create, Delete, Star, StarBorderOutlined } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { t } from 'i18next';

type ScabResultMenuProps = {
  isStarred: boolean;
  handleOpenDeleteDialog: () => void;
  isCanDelete: boolean;
  handleFavoriteClick: () => void;
};
export const ScanResultMenu: FC<ScabResultMenuProps> = ({
  isStarred,
  handleOpenDeleteDialog,
  isCanDelete,
  handleFavoriteClick,
}) => (
  <>
    <MenuItem onClick={handleOpenDeleteDialog} disabled={isCanDelete}>
      <ListItemIcon>
        <Delete />
      </ListItemIcon>
      <ListItemText>削除</ListItemText>
    </MenuItem>
    <MenuItem onClick={handleFavoriteClick}>
      <ListItemIcon>
        {isStarred ? <Star /> : <StarBorderOutlined />}
      </ListItemIcon>
      <ListItemText>
        {isStarred ? 'スターを外す' : 'スターをつける'}
      </ListItemText>
    </MenuItem>
    <MenuItem
      onClick={() => {
        console.log('名前を変更');
      }}
      // TODO:機能追加時にenabledにする
      disabled
    >
      <ListItemIcon>
        <Create />
      </ListItemIcon>
      <ListItemText>名前・{t('shelf_type')}を変更</ListItemText>
    </MenuItem>
  </>
);
