import {
  GetSakuraStoresResponse,
  SakuraUser,
} from '@reducers/shelfAppsApi/injections/sakuraApi';
import { ACLEntry, DO, Store, ZO } from 'components/pages/sakura/types';

export const filterAvailableDoZoStores = (
  sakuraStoresRes: GetSakuraStoresResponse | undefined,
  sakuraUser: SakuraUser | undefined
): GetSakuraStoresResponse | undefined => {
  if (!sakuraStoresRes || !sakuraUser) {
    // 情報が揃うまでなにも見えない
    return undefined;
  }
  if (sakuraUser === undefined) {
    // 記述がないユーザはなにも見えない。403
    return undefined;
  }

  if (sakuraUser.role === 'admin' || sakuraUser.role === 'editor') {
    // admin/editorはすべて見れる
    return sakuraStoresRes;
  }

  const aclEntryMatcher = (aclEntry: ACLEntry, store: Store) => {
    if (aclEntry.region === 'store') {
      return aclEntry.code === store.storeCd;
    } else if (aclEntry.region === 'do') {
      return aclEntry.code === store.doCd;
    } else if (aclEntry.region === 'zo') {
      return aclEntry.code === store.zoCd;
    }
    return false;
  };

  const acl = sakuraUser.acl || [];
  const filteredDos: DO[] = [];
  const filteredZos: ZO[] = [];
  const filteredStores = sakuraStoresRes.stores.filter((store) => {
    const matches = acl.some((aclEntry) => aclEntryMatcher(aclEntry, store));
    if (matches && !filteredDos.find((_do) => _do.doCd === store.doCd)) {
      const _do = sakuraStoresRes.dos.find((_do) => _do.doCd === store.doCd);
      if (_do) {
        filteredDos.push(_do);
      }
    }
    if (matches && !filteredZos.find((zo) => zo.zoCd === store.zoCd)) {
      const zo = sakuraStoresRes.zos.find((zo) => zo.zoCd === store.zoCd);
      if (zo) {
        filteredZos.push(zo);
      }
    }
    return matches;
  });

  return {
    date: sakuraStoresRes.date,
    dos: filteredDos,
    zos: filteredZos,
    stores: filteredStores,
  };
};
