import { BottleAndCanIcon } from '@components/molecules/bottleAndCanIcon/bottleAndCan';
import { ProductIcon } from '@components/molecules/productIcon/productIcon';
import { ShelfEditorIcon } from '@components/molecules/shelfEditorIcon/shelfEditorIcon';
import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';
import { ShelfIconPlus } from '@components/molecules/shelfIconPlus/shelfIconPlus';
import { AppBar } from '@components/organisms';
import { ScanPermissionDialog } from '@components/organisms/scanPermissionDialog/scanPermissionDialog';
import { useCreatePlanogram } from '@hooks/useCreatePlanogram';
import { usePageTitle } from '@hooks/usePageTitle';
import { CameraAltOutlined, PeopleOutlined } from '@mui/icons-material';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useAppSelector } from '@store/index';
import { paths } from '@utils/const';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { PrimaryNavigationButton } from './fragments/primaryNavigationButton';
import { SecondaryNavigationButton } from './fragments/secondaryNavigationButton';
import { useNavigateToScanner } from './hooks/useNavigateToScanner';
import { selectUser } from '@reducers/auth/selectors';

const displayStoreBays = true;

export const Home: FC = () => {
  usePageTitle();
  const {
    navigateToScanner,
    isPermissionDialogOpen,
    setIsPermissionDialogOpen,
  } = useNavigateToScanner();
  const handleCreatePlanogram = useCreatePlanogram();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const isAdmin = user?.role === 'admin';
  return (
    <>
      <AppBar title="棚アプリ" />
      <Box
        component="div"
        maxWidth={576}
        mt={4}
        mx="auto"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box component="div" flex={1} display="flex" gap={2}>
          <PrimaryNavigationButton
            onClickAction={() => navigate(paths.actuals.foldersRecentlyScanned)}
            icon={<ProductIcon sx={{ fontSize: 40, my: 3 }} />}
          >
            棚割実績一覧
            <br />
            （棚スキャナ）
          </PrimaryNavigationButton>
          <PrimaryNavigationButton
            onClickAction={() => navigate(paths.plans.root)}
            icon={<ShelfEditorIcon sx={{ fontSize: 40, my: 3 }} />}
          >
            棚割計画一覧
            <br />
            （棚エディタ）
          </PrimaryNavigationButton>
        </Box>
        <Box component="div" flex={1} display="flex" gap={2}>
          <SecondaryNavigationButton
            onClickAction={navigateToScanner}
            title="新規スキャン"
            icon={
              <CameraAltOutlined
                color="primary"
                sx={{ fontSize: 24, mr: 2, my: 2 }}
              />
            }
            sx={{
              xs: 'flex',
              width: '100%',
            }}
          />
          <SecondaryNavigationButton
            onClickAction={handleCreatePlanogram}
            title="新規棚割"
            icon={
              <ShelfIconPlus
                color="primary"
                sx={{ fontSize: 24, mr: 2, my: 2 }}
              />
            }
            sx={{
              display: { xs: 'none', sm: 'flex' },
              width: '100%',
            }}
          />
        </Box>
        {!user ? (
          <Skeleton sx={{ mt: 4 }} height={56} variant="rounded" />
        ) : (
          <Box
            component="div"
            flex={1}
            display="flex"
            gap={2}
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
            justifyContent="center"
            mt={4}
          >
            <Button
              variant="outlined"
              sx={{
                width:
                  !isAdmin && !displayStoreBays ? 'calc(50% - 8px)' : '100%',
                padding: 0,
                borderColor: theme.palette.dividerBlack.dark,
              }}
              component={Link}
              to={paths.products.root}
            >
              <BottleAndCanIcon
                color="primary"
                sx={{ fontSize: 24, mr: 2, my: 2 }}
              />
              <Typography
                variant="subtitle1"
                color={theme.palette.textBlack.primary}
                component="div"
                lineHeight={1}
              >
                商品一覧
              </Typography>
            </Button>
            {displayStoreBays && (
              <Button
                variant="outlined"
                sx={{
                  width: '100%',
                  padding: 0,
                  borderColor: theme.palette.dividerBlack.dark,
                }}
                component={Link}
                to={paths.storeBays.home}
              >
                <ShelfIcon
                  color="primary"
                  fillOpacity={0.65}
                  sx={{
                    fontSize: 24,
                    mr: 2,
                    my: 2,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.textBlack.primary}
                  component="div"
                  lineHeight={1}
                >
                  什器管理
                </Typography>
              </Button>
            )}
            {isAdmin && (
              <Button
                variant="outlined"
                sx={{
                  width: '100%',
                  padding: 0,
                  borderColor: theme.palette.dividerBlack.dark,
                }}
                component={Link}
                to={paths.users.root}
              >
                <PeopleOutlined
                  sx={{
                    fontSize: 24,
                    mr: 2,
                    my: 2,
                    color: theme.palette.icons.primary,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.textBlack.primary}
                  component="div"
                  lineHeight={1}
                >
                  ユーザー管理
                </Typography>
              </Button>
            )}
          </Box>
        )}
      </Box>
      <ScanPermissionDialog
        open={isPermissionDialogOpen}
        handleCancel={() => setIsPermissionDialogOpen(false)}
      />
    </>
  );
};
