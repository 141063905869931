import { Box, Stack, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { emptyText } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import { AssortmentTag } from 'types/common';
import { SelectableCard } from '../selectableCard/selectableCard';

export type AssortmentTagCount = {
  tag: AssortmentTag;
  numItemsTotal: number | undefined;
  numItemsAbsent: number | undefined;
};

type Props = {
  tag: AssortmentTag;
  tagValues: AssortmentTagCount[];
  handleChangeAssortmentTag: (tag: AssortmentTag) => void;
};

const isHasProductValueMarginBottom = 0.4;
const isNotHasProductValueMarginBottom = 0.6;
export const AssortmentProductCards: FC<Props> = ({
  tag,
  tagValues,
  handleChangeAssortmentTag,
}) => {
  return (
    <>
      {tagValues.map((tagValue, index) => {
        const isSelected = tagValue.tag === tag;
        const isHasProductValue =
          tagValue.numItemsTotal !== undefined &&
          tagValue.numItemsAbsent !== undefined;
        return (
          //NOTE: Boxを囲わない場合、Selectable Cardのスタイルが適用されない
          <Box component="div" key={index}>
            <SelectableCard
              selected={isSelected}
              onClick={() => handleChangeAssortmentTag(tagValue.tag)}
              sx={{
                px: 0.8,
                alignItems: 'unset',
                flexDirection: 'column',
                height: 54,
              }}
            >
              <Typography
                variant="caption"
                color={CommonColors.grayLight}
                textAlign="start"
                lineHeight={1}
                marginBottom={
                  isHasProductValue
                    ? isHasProductValueMarginBottom
                    : isNotHasProductValueMarginBottom
                }
              >
                {tagValue.tag}
              </Typography>
              {isHasProductValue ? (
                <Stack
                  direction="row"
                  alignItems="baseline"
                  justifyContent="flex-end"
                >
                  <Typography
                    fontSize={20}
                    fontWeight={1000}
                    color={
                      tagValue.numItemsAbsent === 0
                        ? theme.palette.textBlack.primary
                        : theme.palette.error.main
                    }
                  >
                    {tagValue.numItemsAbsent}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color={theme.palette.textBlack.primary}
                    paddingLeft={0.3}
                  >
                    / {tagValue.numItemsTotal}
                  </Typography>
                  <Typography
                    fontSize={9}
                    fontWeight={500}
                    color="grey"
                    paddingLeft={0.2}
                  >
                    未取扱
                  </Typography>
                </Stack>
              ) : (
                <Typography
                  textAlign="end"
                  variant="body1"
                  color={theme.palette.textBlack.disabled}
                  lineHeight={1.5}
                >
                  {emptyText}
                </Typography>
              )}
            </SelectableCard>
          </Box>
        );
      })}
    </>
  );
};
