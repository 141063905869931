import { Stack, Typography } from '@mui/material';
import { ModelGondolaProductAssignment } from '@components/pages/sakura/sakuraUtils';
import { ExternalInfo, StoreProductSale } from '@components/pages/sakura/types';
import {
  SakuraModal,
  SectionDivider,
} from '@components/pages/sakura/fragments/modal';
import { ProductImageAndName } from '@components/pages/sakura/fragments/productDetailsModal/productBaseInfoSection';
import {
  ExternalInfoList,
  ModelGondolaProductComment,
} from '@components/pages/sakura/fragments/productDetailsModal/modelGondolasSection';
import { Product } from 'types/common';
import { theme } from 'theme';
import { t } from 'i18next';

type ProductDetailsModalProps = {
  openProductDetails: boolean;
  setOpenProductDetails: (_: boolean) => void;
  masterProduct: Product;
  externalInfo: ExternalInfo;
  modelGondolaProductAssignment: ModelGondolaProductAssignment | undefined;
  productSale: StoreProductSale;
};

export const ProductDetailsModal = ({
  openProductDetails,
  setOpenProductDetails,
  masterProduct,
  externalInfo,
  modelGondolaProductAssignment,
  productSale,
}: ProductDetailsModalProps) => {
  const determineColor = (rate: number) => {
    if (0.8 < rate) {
      return theme.palette.error.dark;
    } else if (0.5 < rate) {
      return theme.palette.warning.dark;
    } else {
      return theme.palette.textBlack.primary;
    }
  };

  return (
    <SakuraModal
      title="商品の詳細"
      open={openProductDetails}
      handleClose={() => setOpenProductDetails(false)}
      closeButton
    >
      <Stack spacing={2}>
        <ProductImageAndName
          product={masterProduct}
          externalInfo={externalInfo}
        />
        <SectionDivider />

        <ExternalInfoList product={masterProduct} externalInfo={externalInfo} />
        <ModelGondolaProductComment
          modelGondolaProductAssignment={modelGondolaProductAssignment}
        />

        <Typography variant="body3" fontWeight="bold">
          地域の動向
        </Typography>

        <Stack spacing={0.5}>
          {/* DO導入率 */}
          <Stack direction="row" spacing={0.5} alignContent="baseline">
            <Typography
              fontSize="9pt"
              color={determineColor(productSale.doAdoptionRate)}
            >
              {t('region')}導入率：
              {Math.round(productSale.doAdoptionRate * 100)}%
            </Typography>
          </Stack>

          {/* ZO導入率 */}
          <Stack direction="row" spacing={0.5} alignContent="baseline">
            <Typography
              fontSize="9pt"
              color={determineColor(productSale.zoAdoptionRate)}
            >
              ZO導入率：{Math.round(productSale.zoAdoptionRate * 100)}%
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </SakuraModal>
  );
};
