import { regenerateIdToken } from '@reducers/auth';
import { selectToken } from '@reducers/auth/selectors';
import { KeysToCamelcase } from '@reducers/shelfAppsApi/baseApi';
import { baseUrl } from '@utils/const';
import httpStatus from 'http-status';
import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { paths } from 'types/api';
import { Product } from 'types/common';

type GetProductImageParams = KeysToCamelcase<
  paths['/api/v1/media/products/{product_id}_{face_front}_{size}.png']['get']['parameters']['path']
>;

type GetRealogramCandidateImageParams = KeysToCamelcase<
  paths['/api/v1/media/realogram_candidates/{realogram_candidate_id}_{size}.jpg']['get']['parameters']['path']
>;

type GetBayPartImageParams = KeysToCamelcase<
  paths['/api/v1/media/bay_parts/{bay_part_id}_front_{layer}.svg']['get']['parameters']['path']
>;

type GetPlanogramImageParams = KeysToCamelcase<
  paths['/api/v1/media/planogram/{planogram_id}_{camera}_{size}.png']['get']['parameters']['path']
>;

type ImageResponse = {
  image?: string;
  isLoading: boolean;
  error?: string;
  getImage?: () => Promise<Blob | undefined>;
  setError?: (value?: string) => void;
  setImage?: (value?: string) => void;
};

type UsePfnMediaOptions = {
  skip: boolean;
  assetVersion?: Product['asset_version'];
  isNoCache?: boolean;
};

const fetcher = (url: string, token: string, isNoCache: boolean) =>
  fetch(`${baseUrl}/media${url}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
    cache: isNoCache ? 'no-cache' : 'default',
  });

export const usePfnMedia = (
  url: string,
  options?: UsePfnMediaOptions
): ImageResponse => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken) ?? '';

  const [image, setImage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  // tokenが変化しても画像を再取得する必要がないため個別で状態管理する
  const [lodingStatus, setLoadingStatus] = useState<
    'pending' | 'loading' | 'rejected' | 'fulfilled'
  >('pending');

  // urlが変化した場合は画像を再取得する
  useEffect(() => {
    // reset
    setImage(undefined);
    setLoadingStatus('pending');
    setError(undefined);
  }, [url]);

  const getImage = useCallback(async () => {
    try {
      let response = await fetcher(url, token, Boolean(options?.isNoCache));
      if (
        response.status === httpStatus.UNAUTHORIZED ||
        response.status === httpStatus.FORBIDDEN
      ) {
        const { idToken: newToken } = await dispatch(
          regenerateIdToken()
        ).unwrap();
        response = await fetcher(url, newToken, Boolean(options?.isNoCache));
      }
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return await response.blob();
    } catch (error) {
      setLoadingStatus('rejected');
      setError(
        error instanceof Error ? `error: ${error.message}` : 'unknown error'
      );
      console.error(error);
    }
  }, [dispatch, token, url, options]);

  useEffect(() => {
    // 画像取得済み、もしくはロード中の場合は処理しない
    if (lodingStatus !== 'pending' || options?.skip) return;
    setLoadingStatus('loading');
    void getImage().then((blob) => {
      if (blob) {
        setImage(URL.createObjectURL(blob));
        setLoadingStatus('fulfilled');
      }
    });
  }, [dispatch, lodingStatus, token, url, options, getImage]);

  useEffect(() => {
    setIsLoading(lodingStatus === 'loading');
  }, [lodingStatus]);

  return {
    image,
    isLoading,
    error,
    getImage,
    setError,
    setImage,
  };
};

export const useProductImage = (
  { productId, faceFront, size }: GetProductImageParams,
  options?: UsePfnMediaOptions
): ImageResponse => {
  return usePfnMedia(
    `/products/${productId}_${faceFront}_${size}.png${
      options?.assetVersion ? `?asset_version=${options?.assetVersion}` : ''
    }`,
    options
  );
};

export const useRealogramCandidateImage = (
  {
    realogramCandidateId,
    size,
    shotIndex,
  }: GetRealogramCandidateImageParams & { shotIndex?: number },
  options?: UsePfnMediaOptions
): ImageResponse => {
  return usePfnMedia(
    `/realogram_candidates/${realogramCandidateId}_${
      shotIndex ?? 1
    }_${size}.jpg`,
    options
  );
};

export const useBayPartImage = (
  { bayPartId, layer }: GetBayPartImageParams,
  options?: UsePfnMediaOptions
) => {
  return usePfnMedia(`/bay_parts/${bayPartId}_front_${layer}.svg`, options);
};

export const usePlanogramImage = (
  { planogramId, camera, size }: GetPlanogramImageParams,
  options?: UsePfnMediaOptions
) => {
  return usePfnMedia(
    `/planogram/${planogramId}_${camera}_${size}.png`,
    options
  );
};
