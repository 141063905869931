import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  useGetRealogramCandidateQuery,
  useUpdateFaceMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { toastMessages } from '@utils/const';
import {
  findMostHighScore,
  getUnknownProductsFromShelfBoards,
} from '@utils/product';
import { useEffect, useState } from 'react';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { RealogramShelfBoard, UnknownProduct } from 'types/realogram';

type Props = {
  realogramDetailView: ShelfDetailView;
  shelfBoardsToDisplay?: RealogramShelfBoard[];
  realogramCandidateId: number;
  realogramDetailMode: ShelfDetailMode;
};

export const useUnknownProductsRevise = ({
  realogramDetailView,
  shelfBoardsToDisplay,
  realogramCandidateId,
  realogramDetailMode,
}: Props) => {
  const dispatch = useAppDispatch();
  const [updateFace] = useUpdateFaceMutation();
  const { refetch } = useGetRealogramCandidateQuery({
    realogramCandidateId,
  });
  const [isUnknownProductsReviseMode, setIsUnknownProductsReviseMode] =
    useState(false); // 一括訂正モーダル開閉フラグ
  const [unknownProductsInfo, setUnknownProductsInfo] = useState<
    UnknownProduct[]
  >([]);
  const [hasRecommendProducts, setHasRecommendProducts] = useState(false); // 訂正推奨商品があるかのフラグ
  const [isCheckedRecommendedRevise, setIsCheckedRecommendedRevise] =
    useState(false); // 「訂正推奨のみ選択」フラグ
  const [isEnableReviseButton, setIsEnableReviseButton] = useState(false);

  // default(棚割)の時のみ一括訂正の表示が可能、タブが切り替わったら閉じる
  useEffect(() => {
    setIsUnknownProductsReviseMode(false);
  }, [realogramDetailView, realogramDetailMode]);

  useEffect(() => {
    if (!shelfBoardsToDisplay) return;
    const unknownProductsInfo = getUnknownProductsFromShelfBoards(
      shelfBoardsToDisplay
    ).map((product) => {
      const mostHighScore = findMostHighScore(product);

      return {
        ...product,
        mostHighScore: mostHighScore,
        isChecked: false,
        isRecommend:
          mostHighScore?.confidence_level === 'medium' ||
          mostHighScore?.confidence_level === 'high',
      };
    });
    setUnknownProductsInfo((prevInfo) => {
      // ここで新旧の unknownProductsInfo を比較して、異なる場合にのみ更新
      if (JSON.stringify(prevInfo) !== JSON.stringify(unknownProductsInfo)) {
        return unknownProductsInfo;
      }
      return prevInfo;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not need
  }, [realogramDetailMode, shelfBoardsToDisplay]);

  useEffect(() => {
    setHasRecommendProducts(
      !unknownProductsInfo.every((product) => !product.isRecommend)
    );
    if (!hasRecommendProducts) {
      setIsCheckedRecommendedRevise(false);
    } else {
      // 訂正推奨が一つでもfalseになってたらisCheckedRecommendedReviseもfalseに変更
      const isNeedCheck = unknownProductsInfo.every((product) => {
        if (product.isRecommend && !product.isChecked) {
          return false;
        }
        return true;
      });
      setIsCheckedRecommendedRevise(isNeedCheck);
    }

    setIsEnableReviseButton(
      unknownProductsInfo.some((product) => product.isChecked)
    );
  }, [unknownProductsInfo, hasRecommendProducts]);

  const handleCheckboxChange = (id: string) => {
    setUnknownProductsInfo((prev) => {
      return prev.map((unknownProduct) => {
        if (unknownProduct.id === id) {
          return {
            ...unknownProduct,
            isChecked: !unknownProduct.isChecked,
          };
        } else return unknownProduct;
      });
    });
  };

  const handleToggleRecommendedRevise = () => {
    setUnknownProductsInfo((prev) => {
      return prev.map((unknownProduct) => {
        if (unknownProduct.isRecommend) {
          return {
            ...unknownProduct,
            isChecked: isCheckedRecommendedRevise ? false : true,
          };
        } else return unknownProduct;
      });
    });
    setIsCheckedRecommendedRevise((prev) => !prev);
  };

  const handleOnBulkRevise = async () => {
    if (!isEnableReviseButton) return;
    const updatePromises = unknownProductsInfo.map(async (unknownProduct) => {
      if (!unknownProduct.isChecked) return;
      const args = {
        realogramCandidateId,
        faceId: unknownProduct.id,
        primaryCandidateProductId: unknownProduct.mostHighScore?.product_id,
        outOfStock: !unknownProduct.in_stock,
      };

      try {
        return await updateFace(args);
      } catch (e) {
        console.log(`Error ${unknownProduct.id}`, e);
        return null;
      }
    });

    try {
      dispatch(updateLoadingIndicatorState(true));
      await Promise.all(updatePromises);
      await refetch();

      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.corrected,
        })
      );
    } catch (e) {
      console.log('Error promise all', e);
    } finally {
      setIsUnknownProductsReviseMode(false);
      setIsEnableReviseButton(false);
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const handleResetReviseMode = () => {
    setIsUnknownProductsReviseMode(false);
    setUnknownProductsInfo((prev) => {
      return prev.map((unknownProduct) => {
        return {
          ...unknownProduct,
          isChecked: false,
        };
      });
    });
    setHasRecommendProducts(false);
  };

  return {
    isEnableReviseButton,
    isUnknownProductsReviseMode,
    unknownProductsInfo,
    isCheckedRecommendedRevise,
    hasRecommendProducts,
    handleCheckboxChange,
    handleToggleRecommendedRevise,
    setIsUnknownProductsReviseMode,
    handleOnBulkRevise,
    handleResetReviseMode,
  };
};
