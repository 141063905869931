import {
  addProductToBulk as addProduct,
  updateProductsBulk,
} from '@reducers/planogramEditor/reducer';
import { selectProductsBulk } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Product } from 'types/common';
import { Estimate } from 'types/planogram';

type PlanogramContextType =
  | {
      products?: Product[];
      comparisonProducts?: Product[];
      productsBulk?: Map<number, Product[]>;
      comparisonProductsBulk?: Map<number, Product[]>;
      productSalesReport?: Estimate;
      comparisonProductSalesReport?: Estimate;
      addProductToBulk?: (product: Product) => void;
      removeProductFromBulk?: (product: Product) => void;
    }
  | undefined;

const planogramContext = createContext<PlanogramContextType>(undefined);

export const PlanogramProvider: FC<
  PropsWithChildren<{ value: PlanogramContextType }>
> = ({ children, value }) => {
  const productsBulk = useAppSelector(selectProductsBulk);
  const dispatch = useAppDispatch();
  const [comparisonProductsBulk, setComparisonProductsBulk] = useState<
    Map<number, Product[]> | undefined
  >();

  const removeProductFromBulk = (product: Product) => {
    const newProductsBulk = new Map(productsBulk);
    const productArr = newProductsBulk?.get(product.id);
    if (productArr?.length && productArr?.length <= 1) {
      newProductsBulk?.delete(product.id);
      dispatch(updateProductsBulk(newProductsBulk));
    } else {
      const value = productArr?.at(0) ?? ({} as Product);
      newProductsBulk?.set(product.id, [value]);
      dispatch(updateProductsBulk(newProductsBulk));
    }
  };

  useEffect(() => {
    if (value?.products && productsBulk?.size === 0) {
      dispatch(
        updateProductsBulk(
          new Map(value?.products?.map((product) => [product.id, [product]]))
        )
      );
    }
  }, [value?.products, productsBulk, dispatch]);
  useEffect(() => {
    if (value?.comparisonProducts) {
      setComparisonProductsBulk(
        new Map(
          value?.comparisonProducts?.map((product) => [product.id, [product]])
        )
      );
    }
  }, [value?.comparisonProducts]);
  const productSales = useMemo(
    () => value?.productSalesReport,
    [value?.productSalesReport]
  );
  const comparisonProductSales = useMemo(
    () => value?.comparisonProductSalesReport,
    [value?.comparisonProductSalesReport]
  );

  return (
    <planogramContext.Provider
      value={{
        productsBulk,
        comparisonProductsBulk,
        productSalesReport: productSales,
        comparisonProductSalesReport: comparisonProductSales,
        products: value?.products,
        comparisonProducts: value?.comparisonProducts,
        addProductToBulk: (product: Product) => dispatch(addProduct(product)),
        removeProductFromBulk,
      }}
    >
      {children}
    </planogramContext.Provider>
  );
};

export const usePlanogramContext = (): PlanogramContextType => {
  const state = useContext(planogramContext);
  return state;
};
