import { Draggable } from '@components/organisms/draggable/draggable';
import { Box } from '@mui/material';
import {
  setOpenOrientationMenu,
  updateFlatOverflowState,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { convertMeterToPixel } from '@utils/planogram';
import { getProductDisplaySize } from '@utils/product';
import { getModeQueryParams, getViewQueryParams } from '@utils/urlQueryParams';
import { isEqual } from 'lodash';
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDragLayer } from 'react-dnd';
import { useSearchParams } from 'react-router-dom';
import { FaceFrontId, Product, ProfitTab } from 'types/common';
import { BucketArea, Position } from 'types/planogram';
import { ItemTypes } from '../../../types/rack';
import { ProductsGroup } from '../productsGroup/productsGroup';
import { CompartmentMenu } from './fragments/compartmentMenu';
import { CompartmentOrientationMenu } from './fragments/compartmentOrientationMenu';

import { useBucketsContext } from '@components/pages/planogramEditor/flatPlanogram/fragments/useBucketsContext';
import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { selectProductState } from '@reducers/product/selector';
import { getProductReportValue } from '../realogramImageAndBbox/fragments/bboxes';
import { getMaxValueInReport } from '../realogramImageAndBbox/utils';

type Props = {
  product?: Product;
  compartmentArea: BucketArea;
  index: number;
  handleClickCompartment: (e: MouseEvent<HTMLElement>) => void;
  areaPath?: Position[];
  anchorEl: HTMLElement | null;
  resetState: () => void;
  isDisabledBucket: boolean;
  areaWidth?: number;
  areaHeight?: number;
  bboxEnabled: boolean;
  isCompared?: boolean;
  isEditor?: boolean;
  products?: Product[];
};

export const BucketProductCompartments: FC<Props> = ({
  product,
  compartmentArea,
  index,
  handleClickCompartment,
  areaPath = [],
  anchorEl,
  resetState,
  isDisabledBucket,
  areaWidth,
  areaHeight,
  bboxEnabled,
  isCompared = false,
  isEditor = false,
  products,
}) => {
  const [searchParams] = useSearchParams();
  const detailMode = getModeQueryParams(searchParams);
  const detailView = getViewQueryParams(searchParams);
  const { width: w, height: h } = product
    ? getProductDisplaySize(
        product,
        compartmentArea.face_front as FaceFrontId,
        compartmentArea.orientation ?? 0
      )
    : { width: 0, height: 0 };
  const width = w * (compartmentArea.count?.x ?? 0);
  const height = h * (compartmentArea.count?.y ?? 0);
  const faceCount =
    (compartmentArea.count?.x ?? 0) * (compartmentArea.count?.y ?? 0);
  const compartment = {
    product_id: compartmentArea.product_id ?? 0,
    face_count: faceCount,
    count: {
      x: compartmentArea.count?.x ?? 0,
      y: compartmentArea.count?.y ?? 0,
    },
    face_front: compartmentArea.face_front as FaceFrontId,
    orientation: compartmentArea.orientation ?? 0,
  };
  const { selectedBucketId, selectedProductCompartment, position } =
    useSelectedPlanogramItem({ products, isCompared });
  const bucketProductPosition = position as Position[];
  const {
    openOrientationMenu,
    bucketMode,
    mode,
    isDragProduct,
    selectedBucketIdClone,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);
  const comparisonSameIds = useAppSelector(selectProductState);
  const { selectedRealogramItem } = useBucketsContext();

  const dispatch = useAppDispatch();

  const isSelectedCompartment =
    isEqual(areaPath, bucketProductPosition) &&
    index === selectedBucketId &&
    bucketMode === 'compartment';

  const isRealogramSelected =
    (selectedRealogramItem &&
      selectedRealogramItem?.item?.primary_candidate?.product_id ===
        compartment.product_id) ??
    false;

  const isPlanogramSelected =
    selectedProductCompartment &&
    selectedProductCompartment.id === compartment.product_id &&
    selectedBucketId === index;

  const isProductSelected =
    (detailMode === 'comparison' &&
      (isRealogramSelected || isPlanogramSelected)) ??
    false;

  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));

  const [isOverflown, setIsOverflown] = useState<boolean>(false);
  useEffect(() => {
    if (
      (areaWidth && width > areaWidth) ||
      (areaHeight && height > areaHeight)
    ) {
      setIsOverflown(true);
    } else {
      setIsOverflown(false);
    }
  }, [width, height, areaWidth, areaHeight]);
  useEffect(() => {
    if (isCompared) return;
    const isOverflown =
      !!(areaWidth && width > areaWidth) ||
      !!(areaHeight && height > areaHeight);
    dispatch(
      updateFlatOverflowState({
        bucketIndex: index,
        overflow: isOverflown,
        areaPath,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding areaPath to deps causes an infinite loop
  }, [areaWidth, width, areaHeight, height, index, mode, dispatch, isCompared]);

  const profitTab = searchParams.get('index') as ProfitTab;
  const data = usePlanogramContext();
  const productSalesReport = isCompared
    ? data?.comparisonProductSalesReport
    : data?.productSalesReport;
  const originalMaxThreshold = getMaxValueInReport(
    profitTab,
    productSalesReport?.products
  );
  const comparedMaxThreshold = getMaxValueInReport(profitTab, undefined);

  const maxThreshold =
    originalMaxThreshold > comparedMaxThreshold
      ? originalMaxThreshold
      : comparedMaxThreshold;
  const productReport = productSalesReport?.products.find(
    (report) => product?.id === report.product_id
  );
  const productValue = getProductReportValue(profitTab, productReport);
  const handleClickCompartmentRef = useRef<
    (e: MouseEvent<HTMLElement>) => void
  >(() => void 0);
  const memoizedHandleClickCompartment = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      handleClickCompartmentRef.current(e);
    },
    []
  );

  useEffect(() => {
    handleClickCompartmentRef.current = handleClickCompartment;
  });

  const bboxColors = useRef({
    borderColor: 'red',
    backgroundColor: 'red',
  });
  const isComparisonCommonId =
    product && comparisonSameIds.includes(product.id);
  const isDecreaseOpacity =
    (bucketProductPosition ||
      selectedBucketId?.toString ||
      selectedBucketIdClone?.toString) &&
    bucketMode === 'area';
  return (
    <Box
      component="div"
      sx={{ position: 'absolute', bottom: '0', left: 0 }}
      height={`${convertMeterToPixel(height)}px`}
      width={`${convertMeterToPixel(width)}px`}
    >
      {product && (
        <Draggable
          itemType={ItemTypes.ITEM_GROUP}
          data={{ compartment, product }}
          index={index}
          areaPath={areaPath}
          canDrag={
            !isDisabledBucket &&
            mode !== 'BayEditor' &&
            bucketMode !== 'area' &&
            !isCompared
          }
        >
          <Box component="div" width="100%" height="100%" position="relative">
            <ProductsGroup
              editorMode={mode}
              isDragProduct={isDragProduct ?? false}
              isComparisonCommonId={!!isComparisonCommonId}
              isDecreaseOpacity={!!isDecreaseOpacity}
              bucketMode={bucketMode}
              productValue={productValue}
              maxThreshold={maxThreshold}
              bboxColors={bboxColors.current}
              justifyMargin={0}
              isSelected={isSelectedCompartment || isProductSelected}
              product={product}
              isOverflown={!isEditor ? false : isOverflown}
              handleClickGroup={memoizedHandleClickCompartment}
              bboxEnabled={bboxEnabled}
              view={detailView}
              mode={detailMode}
              countX={compartment.count.x ?? 0}
              countY={compartment.count.y ?? 0}
              margin={0}
              isCompared={isCompared}
              rowIndex={index}
              faceFront={compartment.face_front}
              orientation={compartment.orientation}
              productTag={productTag}
            />
          </Box>
        </Draggable>
      )}
      {isSelectedCompartment &&
        !isDragging &&
        product &&
        mode === 'CompartmentEditor' &&
        !isCompared && (
          <>
            <CompartmentMenu
              product={product}
              anchorEl={anchorEl}
              handleClose={resetState}
              targetCompartment={compartmentArea}
              open={!openOrientationMenu}
              handleClickRotate={() => dispatch(setOpenOrientationMenu(true))}
              bucketIndex={index}
              areaPath={areaPath}
            />
            <CompartmentOrientationMenu
              bucketIndex={index}
              areaPath={areaPath}
              compartment={compartment}
              anchorEl={anchorEl}
              open={openOrientationMenu}
              handleClose={() => dispatch(setOpenOrientationMenu(false))}
            />
          </>
        )}
    </Box>
  );
};
