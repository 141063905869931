import { useAbility } from '@casl/react';
import { DemoIcon } from '@components/molecules';
import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { Box, ToggleButton, Typography } from '@mui/material';
import { AbilityContext } from '@utils/abac';
import { FC } from 'react';
import { theme } from 'theme';
import { ShelfDetailView } from 'types/common';

type Props = {
  value: ShelfDetailView;
  onChange: (value: ShelfDetailView) => void;
  isActual: boolean;
  disabled?: boolean;
  isShowAnalyticsButton?: boolean;
  isBucketType?: boolean;
};

const viewToggleFontSize = '14px';

export const ViewToggle: FC<Props> = ({
  value,
  onChange,
  isActual,
  disabled,
  isShowAnalyticsButton,
  isBucketType,
}) => {
  const ability = useAbility(AbilityContext);
  const canReadDemo = ability.can('read', 'Demo');
  return (
    <StyledToggleButtonGroup
      orientation="horizontal"
      value={value}
      exclusive
      onChange={(_, value: ShelfDetailView) => onChange(value)}
      disabled={disabled}
      sx={{
        '& .Mui-disabled.Mui-selected ': {
          color: theme.palette.textBlack.disabled,
        },
      }}
    >
      <ToggleButton value="default">棚割</ToggleButton>
      <ToggleButton value="productFlag">
        <Box component="div" display="flex" alignItems="center">
          <Typography
            whiteSpace="nowrap"
            fontSize={viewToggleFontSize}
            lineHeight={1}
            mx="2px"
            fontWeight="inherit"
          >
            商品属性
          </Typography>
        </Box>
      </ToggleButton>
      <ToggleButton value="assortment">
        <Box component="div" display="flex" alignItems="center">
          <Typography
            whiteSpace="nowrap"
            fontSize={viewToggleFontSize}
            lineHeight={1}
            mx="2px"
            fontWeight="inherit"
          >
            品揃え
          </Typography>
        </Box>
      </ToggleButton>
      {isShowAnalyticsButton && (
        <ToggleButton value="profit">
          {isActual ? (
            <>分析</>
          ) : (
            <Box component="div" display="flex" alignItems="center">
              <Typography
                whiteSpace="nowrap"
                fontSize={viewToggleFontSize}
                lineHeight={1}
                mx="2px"
                fontWeight="inherit"
              >
                分析
              </Typography>
            </Box>
          )}
        </ToggleButton>
      )}

      {canReadDemo && (
        <ToggleButton value="rate" disabled={isBucketType}>
          <Box component="div" display="flex" alignItems="center">
            <Typography
              fontSize={viewToggleFontSize}
              whiteSpace="nowrap"
              lineHeight={1}
              mx={0.5}
              fontWeight="inherit"
            >
              評価
            </Typography>
            <DemoIcon />
          </Box>
        </ToggleButton>
      )}
    </StyledToggleButtonGroup>
  );
};
