import {
  ComparisonGridContainer,
  ComparisonGridItem,
} from '@components/organisms';
import { ComparisonDivider } from '@components/organisms/comparisonDivider/comparisonDivider';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { StatisticBox } from '@components/organisms/statisticBox/statisticBox';
import { Box, Stack } from '@mui/material';
import { getComparisonStatisticType } from '@utils/comparison';
import { emptyText, formatNumberToYen } from '@utils/const';
import { FC } from 'react';
import { Product, ShelfDetailView } from 'types/common';
import { ProductSales } from 'types/products';

type Props = {
  product?: Product;
  faceCount: number | '';
  comparisonFaceCount: number | '';
  view: ShelfDetailView;
  comparedAnalyticsData?: ProductSales;
  analyticsData?: ProductSales;
  isFaceCountHidden?: boolean;
};

const placeholder = '';

const getValues = (currentValue?: number, prevValue?: number) => {
  let diff: number | '' = placeholder;
  if (currentValue && prevValue) {
    diff = currentValue - prevValue;
  }
  return {
    currentValue: currentValue?.toString() ?? placeholder,
    prevValue: prevValue?.toString() ?? placeholder,
    diff: diff?.toString() ?? placeholder,
    type: getComparisonStatisticType(diff),
  };
};

export const ComparisonProductOptional: FC<Props> = ({
  product,
  view,
  faceCount,
  comparisonFaceCount,
  comparedAnalyticsData,
  analyticsData,
  isFaceCountHidden = false,
}) => {
  const diff =
    typeof comparisonFaceCount === 'number' && typeof faceCount === 'number'
      ? faceCount - comparisonFaceCount
      : '';
  const statisticType = getComparisonStatisticType(diff);
  const {
    currentValue: originalGrossSalesPrice,
    prevValue: comparisonGrossSalesPrice,
    diff: grossSalesPriceDiff,
    type: grossSalesPriceType,
  } = getValues(
    analyticsData?.gross_sales_price,
    comparedAnalyticsData?.gross_sales_price
  );
  const {
    currentValue: originalSalesCount,
    prevValue: comparisonSalesCount,
    diff: salesCountDiff,
    type: salesCountType,
  } = getValues(analyticsData?.sales_count, comparedAnalyticsData?.sales_count);
  switch (view) {
    case 'default':
      return (
        <ComparisonGridContainer>
          {!isFaceCountHidden && (
            <ComparisonGridItem>
              <StatisticBox
                title="フェイス数"
                columnType="neutral"
                statisticType={statisticType}
                prevValue={comparisonFaceCount.toString() || emptyText}
                value={faceCount.toString() || emptyText}
                diffValue={diff.toString()}
                isPrevValueEmpty={!comparisonFaceCount.toString()}
                isValueEmpty={!faceCount.toString()}
              />
            </ComparisonGridItem>
          )}
        </ComparisonGridContainer>
      );
    case 'productFlag':
      return (
        <>
          <Stack direction="row" spacing={0.5}>
            {product?.detail?.tags?.map((tag) => (
              <ProductFlagLabel key={tag} name={tag} />
            ))}
          </Stack>
          <ComparisonGridContainer>
            {!isFaceCountHidden && (
              <ComparisonGridItem>
                <StatisticBox
                  title="フェイス数"
                  columnType="neutral"
                  statisticType={statisticType}
                  prevValue={comparisonFaceCount.toString() || emptyText}
                  value={faceCount.toString() || emptyText}
                  diffValue={diff.toString()}
                  isPrevValueEmpty={!comparisonFaceCount.toString()}
                  isValueEmpty={!faceCount.toString()}
                />
              </ComparisonGridItem>
            )}
          </ComparisonGridContainer>
        </>
      );
    case 'profit':
      return (
        <ComparisonGridContainer>
          <ComparisonGridItem>
            <Box component="div" display={{ xs: 'flex', breakpoint: 'block' }}>
              <StatisticBox
                title="売上金額見込み"
                columnType="positive"
                statisticType={grossSalesPriceType}
                percent=""
                prevValue={
                  comparisonGrossSalesPrice
                    ? formatNumberToYen(parseInt(comparisonGrossSalesPrice))
                    : emptyText
                }
                value={
                  originalGrossSalesPrice
                    ? formatNumberToYen(parseInt(originalGrossSalesPrice))
                    : emptyText
                }
                diffValue={
                  grossSalesPriceDiff
                    ? formatNumberToYen(parseInt(grossSalesPriceDiff))
                    : placeholder
                }
                isPrevValueEmpty={!comparisonGrossSalesPrice}
                isValueEmpty={!originalGrossSalesPrice}
              />
              <ComparisonDivider />
            </Box>
          </ComparisonGridItem>

          <ComparisonGridItem>
            <StatisticBox
              title="販売数見込み"
              columnType="positive"
              statisticType={salesCountType}
              percent=""
              prevValue={comparisonSalesCount || emptyText}
              value={originalSalesCount || emptyText}
              diffValue={salesCountDiff}
              isPrevValueEmpty={!comparisonSalesCount}
              isValueEmpty={!originalSalesCount}
            />
          </ComparisonGridItem>
        </ComparisonGridContainer>
      );
    case 'rate':
      return (
        <Box component="div" maxWidth="250px">
          {!isFaceCountHidden && (
            <StatisticBox
              title="フェイス数"
              columnType="neutral"
              statisticType={statisticType}
              prevValue={comparisonFaceCount.toString() || emptyText}
              value={faceCount.toString() || emptyText}
              diffValue={diff.toString()}
              isPrevValueEmpty={!comparisonFaceCount.toString()}
              isValueEmpty={!faceCount.toString()}
            />
          )}
        </Box>
      );
    default:
      throw new Error('this should not happen');
  }
};
