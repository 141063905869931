import { scale } from 'chroma-js';
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import {
  RegularModelGondola,
  FlatModelGondola,
  ShelfBoard,
  ModelGondolaProduct,
  StoreProductSale,
  ExternalInfo,
  FlatContainer,
} from '../types';
import Image from 'next/image';
import { useState } from 'react';
import { ProductAnnotation } from '@reducers/shelfAppsApi/injections/sakuraApi';
import CommentIcon from '@mui/icons-material/Comment';
import { normalizeProductName } from '@utils/product';
import { Product } from 'types/common';
import {
  listModelGondolaProducts,
  locateModelGondolaProduct,
  splitListIntoEqualParts,
} from '../sakuraUtils';
import { ProductDetailsModal } from './productDetailsModal/productDetailsModal';
import { NewItemChipIfNeeded } from './chips/newItem';
import { KihonShohinChipIfNeeded } from './chips/kihonShohin';
import { useModelGondolasContext } from './modelGondolasContext';
import { useAllProductsContext } from '@utils/ProductsContext';

type _TopKSale = {
  rank: number;
  sale: StoreProductSale;
};

const _defaultItemHeight = 16 as const;
const _itemToHeight = {
  商品名: 80,
  商品コード: _defaultItemHeight,
  価格: _defaultItemHeight,
  フェイス数: _defaultItemHeight,
  ゴンドラ内構成比: _defaultItemHeight,
  前週導入率: _defaultItemHeight,
};

type ModelGondolaRowHeaderProps = {
  board: ShelfBoard;
};

const ModelGondolaRowHeader = ({ board }: ModelGondolaRowHeaderProps) => {
  return (
    <Box
      component="div"
      maxWidth="50px"
      minWidth="50px"
      flex="1"
      display="flex"
      alignItems="flex-start"
      sx={{
        paddingY: '0px',
        fontSize: '9px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000',
        color: '#FFF',
        borderBottom: '2px solid grey',
        justifyItems: 'center',
      }}
    >
      <Typography variant="body3">{board.boardIndex}段目</Typography>
    </Box>
  );
};

const round = (n: number, digit?: number) => {
  if (digit === undefined) {
    const d = Math.pow(10, digit || 0);
    return Math.round(n * d) / d;
  }

  const integerPart = Math.floor(Math.abs(n));
  const integerDigits = integerPart.toString().length;
  const decimalPlaces = Math.max(digit - integerDigits, 0);
  return n.toFixed(decimalPlaces);
};

const _colorScale = scale(['green', 'yellow', 'red']).domain([0, 0.05]);

type ModelGondolaSubRowProps = {
  masterProducts: Product[];
  products: ModelGondolaProduct[];
  productSales: StoreProductSale[];
  markAbsense: boolean;
  externalInfo: ExternalInfo | undefined;
  highlightedAnnotation?: ProductAnnotation;
  setHighlightedAnnotation: (_?: ProductAnnotation) => void;
  topKsales: _TopKSale[];
};

const ModelGondolaSubRow = ({
  masterProducts,
  products,
  productSales,
  markAbsense,
  externalInfo,
  highlightedAnnotation,
  setHighlightedAnnotation,
  topKsales,
}: ModelGondolaSubRowProps) => {
  return (
    <>
      {products.map((p) => {
        const productSale = productSales.find(
          (s) => s.itemCd === p.productCode
        );
        const masterProduct = masterProducts.find(
          (mp) => mp.detail?.organization_product_id === p.productCode
        );
        return (
          <ModelGondolaProductCell
            key={p.productCode}
            masterProduct={masterProduct}
            modelGondolaProduct={p}
            productSale={productSale}
            markAbsense={markAbsense}
            externalInfo={externalInfo}
            highlightedAnnotation={highlightedAnnotation}
            setHighlightedAnnotation={setHighlightedAnnotation}
            topKsales={topKsales}
            compactMode={false}
            mode="regular"
          />
        );
      })}
    </>
  );
};

const splitProductsToSubRows = (
  products: ModelGondolaProduct[]
): ModelGondolaProduct[][] => splitListIntoEqualParts(products, 9);

type RegularModelGondolaBoardProps = {
  board: ShelfBoard;
  productSales: StoreProductSale[];
  masterProducts: Product[];
  markAbsense: boolean;
  externalInfo: ExternalInfo | undefined;
  highlightedAnnotation?: ProductAnnotation;
  setHighlightedAnnotation: (_?: ProductAnnotation) => void;
  topKsales: _TopKSale[];
};

const RegularModelGondolaBoard = ({
  board,
  productSales,
  masterProducts,
  markAbsense,
  externalInfo,
  highlightedAnnotation,
  setHighlightedAnnotation,
  topKsales,
}: RegularModelGondolaBoardProps) => {
  const totalHeight = Object.values(_itemToHeight).reduce((a, b) => a + b, 0);

  const subRows = splitProductsToSubRows(board.products);

  return (
    <Box component="div" display="flex" sx={{ borderBottom: '1px solid grey' }}>
      <ModelGondolaRowHeader board={board} />
      <Stack flex="1">
        {subRows.map((subRowProducts) => (
          <Box
            key={subRowProducts[0].productCode}
            component="div"
            width="100%"
            height={`${totalHeight}px`}
            display="flex"
          >
            <ModelGondolaSubRow
              masterProducts={masterProducts}
              products={subRowProducts}
              productSales={productSales}
              markAbsense={markAbsense}
              externalInfo={externalInfo}
              highlightedAnnotation={highlightedAnnotation}
              setHighlightedAnnotation={setHighlightedAnnotation}
              topKsales={topKsales}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

type ModelGondolaProductCellProps = {
  masterProduct: Product | undefined;
  modelGondolaProduct: ModelGondolaProduct;
  productSale: StoreProductSale | undefined;
  markAbsense: boolean;
  externalInfo: ExternalInfo | undefined;
  setHighlightedAnnotation: (_?: ProductAnnotation) => void;
  highlightedAnnotation?: ProductAnnotation;
  topKsales: _TopKSale[];
  compactMode: boolean;
  mode: 'regular' | 'flat';
};

const ModelGondolaProductCell = ({
  modelGondolaProduct,
  masterProduct,
  productSale,
  compactMode,
  topKsales,
  externalInfo,
  markAbsense,
  mode,
}: ModelGondolaProductCellProps) => {
  const name =
    (masterProduct && normalizeProductName(masterProduct.name)) ||
    modelGondolaProduct.productCode;

  const annots =
    externalInfo?.annotations.filter(
      (annot) => annot.itemCd === modelGondolaProduct.productCode
    ) || [];
  const snsInfoAnnot = annots.find((annot) => annot.type === 'SNS');
  const infoPackageAnnot = annots.find(
    (annot) => annot.type === '情報パッケージ'
  );

  const topKsale = topKsales.find(
    (topKsale) => topKsale.sale.itemCd === modelGondolaProduct.productCode
  );
  const background = topKsale
    ? {
        backgroundImage: `url("/sakura/sakura/recommendation/top${topKsale.rank}.png")`,
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '40px 20px',
      }
    : {};

  const chips = [
    <NewItemChipIfNeeded
      key="新規"
      externalInfo={externalInfo}
      product={masterProduct}
    />,
    <KihonShohinChipIfNeeded key="基本商品" product={masterProduct} />,
    <Box key="bar" component="div" sx={{ flexGrow: 1 }} />,
    snsInfoAnnot && (
      <Image
        key="SNS情報"
        src="/sakura/sakura/sns_info.png"
        alt="SNS情報あり"
        width="16"
        height="16"
      />
    ),
    infoPackageAnnot && (
      <Image
        key="情報パッケージ"
        src="/sakura/sakura/info_icon.png"
        alt="情報パッケージ"
        width="16"
        height="16"
      />
    ),
    modelGondolaProduct.comment.length && (
      <CommentIcon key="コメントあり" sx={{ fontSize: '12pt' }} />
    ),
  ].filter((c) => c);

  const decideBackground = () => {
    if (markAbsense && productSale && productSale.quantity === 0) {
      return '#fdd';
    }
    return null;
  };

  const [openProductDetails, setOpenProductDetails] = useState(false);
  const modelGondolas = useModelGondolasContext().value?.modelGondolas;
  const modelGondolaProductAssignment =
    modelGondolas &&
    locateModelGondolaProduct(modelGondolaProduct.productCode, modelGondolas);
  return (
    <Box
      component="div"
      flex={1}
      sx={{
        border: '1px solid grey',
        padding: '2px',
        background: decideBackground(),
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (!openProductDetails) {
          setOpenProductDetails(true);
        }
      }}
    >
      {masterProduct && externalInfo && productSale && (
        <ProductDetailsModal
          product={masterProduct}
          externalInfo={externalInfo}
          storeProductSale={productSale}
          modelGondolaProductAssignment={modelGondolaProductAssignment}
          open={openProductDetails}
          handleClose={() => {
            setOpenProductDetails(false);
          }}
        />
      )}
      <Box
        component="div"
        sx={{
          maxWidth: '200px',
          minWidth: mode === 'flat' ? '80px' : '100px',
          marginX: mode === 'flat' ? null : '4px',
        }}
      >
        <Stack>
          <Box
            component="div"
            sx={{
              height: compactMode ? '40px' : '65px',
              ...background,
            }}
            overflow="hidden"
          >
            <Stack>
              <Typography fontSize="6pt" color="#666">
                {modelGondolaProduct.productCode}
              </Typography>
              <Typography
                fontSize={compactMode ? '7pt' : '8pt'}
                fontWeight="bold"
              >
                {name}
              </Typography>
              <Stack direction="row">{chips}</Stack>
            </Stack>
          </Box>
          <Divider variant="middle" component="div" />
          <Box component="div" sx={{ height: '2px' }} /> {/* spacer */}
          {/* フェイス数 */}
          {mode === 'regular' && (
            <>
              <Stack direction="row" sx={{ alignItems: 'baseline' }}>
                <Typography fontSize="6pt" color="#666">
                  陳列
                </Typography>
                <Box component="div" sx={{ flexGrow: 1 }} />
                <Typography
                  fontSize={compactMode ? '7pt' : '8pt'}
                  paddingLeft="2pt"
                >
                  {modelGondolaProduct.face}フェース
                </Typography>
              </Stack>
              <Box component="div" sx={{ height: '2px' }} /> {/* spacer */}
            </>
          )}
          <>
            <Stack direction="row" sx={{ alignItems: 'baseline' }}>
              <Typography fontSize="6pt" color="#666">
                価格
              </Typography>
              <Box component="div" sx={{ flexGrow: 1 }} />
              <Typography
                fontSize={compactMode ? '7pt' : '8pt'}
                paddingLeft="2pt"
              >
                {modelGondolaProduct.price}円
              </Typography>
            </Stack>
            {!compactMode && <Box component="div" sx={{ height: '2px' }} />}{' '}
            {/* spacer */}
          </>
          {/* 構成比 */}
          <>
            <Stack direction="row">
              <Box component="div" sx={{ minWidth: '20px' }}>
                <Typography
                  fontSize="6pt"
                  color="#666"
                  sx={{ lineHeight: 1.1 }}
                >
                  {compactMode ? (
                    '構成比'
                  ) : (
                    <>
                      ゴンドラ
                      <br />
                      内構成比
                    </>
                  )}
                </Typography>
              </Box>
              <Box component="div" sx={{ flexGrow: 1 }} />
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Box
                  component="div"
                  sx={{
                    backgroundColor: productSale
                      ? _colorScale(productSale.doGondolaShare)
                          .brighten(2)
                          .hex()
                      : null,
                  }}
                  paddingX="1pt"
                >
                  <Typography
                    fontSize={compactMode ? '7pt' : '10pt'}
                    paddingLeft="2pt"
                    color="#333"
                    textAlign="right"
                  >
                    {productSale
                      ? `${round(100 * productSale.doGondolaShare, 2)}%`
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Stack>
            {!compactMode && <Box component="div" sx={{ height: '2px' }} />}{' '}
            {/* spacer */}
          </>
          {/* 導入率 */}
          <>
            <Stack direction="row" sx={{ alignItems: 'baseline' }}>
              <Typography fontSize="6pt" color="#666">
                ZO導入率
              </Typography>
              <Box component="div" sx={{ flexGrow: 1 }} />
              <Typography
                fontSize={compactMode ? '7pt' : '8pt'}
                paddingLeft="2pt"
              >
                {productSale
                  ? `${round(100 * productSale.zoAdoptionRate)}%`
                  : '-'}
              </Typography>
            </Stack>

            <Stack direction="row" sx={{ alignItems: 'baseline' }}>
              <Typography fontSize="6pt" color="#666">
                DO導入率
              </Typography>
              <Box component="div" sx={{ flexGrow: 1 }} />
              <Typography
                fontSize={compactMode ? '7pt' : '8pt'}
                paddingLeft="2pt"
              >
                {productSale
                  ? `${round(100 * productSale.doAdoptionRate)}%`
                  : '-'}
              </Typography>
            </Stack>
          </>
        </Stack>
      </Box>
    </Box>
  );
};

/////////////////////////
// カゴ内を2列に分けて表示するときにそれぞれの列を表すコンテナ
// カゴ内1列の場合はこのColumnが1つだけ現れる

type FlatModelGondolaBasketColumnProps = {
  modelGondolaProducts: ModelGondolaProduct[];
  masterProducts: Product[];
  productSales: StoreProductSale[];
  markAbsense: boolean;
  externalInfo: ExternalInfo | undefined;
  setHighlightedAnnotation: (_?: ProductAnnotation) => void;
  highlightedAnnotation?: ProductAnnotation;
  topKsales: _TopKSale[];
};

const FlatModelGondolaBasketColumn = ({
  modelGondolaProducts,
  masterProducts,
  productSales,
  markAbsense,
  externalInfo,
  setHighlightedAnnotation,
  highlightedAnnotation,
  topKsales,
}: FlatModelGondolaBasketColumnProps) => {
  return (
    <Box
      component="div"
      flex={1}
      sx={{
        height: '450px',
      }}
    >
      <Stack height="100%">
        {modelGondolaProducts.map((modelGondolaProduct, index) => {
          const productSale = productSales.find(
            (s) => s.itemCd === modelGondolaProduct.productCode
          );
          const masterProduct = masterProducts.find(
            (mp) =>
              mp.detail?.organization_product_id ===
              modelGondolaProduct.productCode
          );
          return (
            <ModelGondolaProductCell
              key={masterProduct?.id || index}
              masterProduct={masterProduct}
              modelGondolaProduct={modelGondolaProduct}
              productSale={productSale}
              markAbsense={markAbsense}
              externalInfo={externalInfo}
              setHighlightedAnnotation={setHighlightedAnnotation}
              highlightedAnnotation={highlightedAnnotation}
              topKsales={topKsales}
              compactMode={2 < modelGondolaProducts.length}
              mode="flat"
            />
          );
        })}
      </Stack>
    </Box>
  );
};

type FlatModelGondolaBasketProps = FlatModelGondolaBasketColumnProps & {
  numColsInBasket: 1 | 2;
};

const FlatModelGondolaBasket = ({
  modelGondolaProducts,
  masterProducts,
  productSales,
  markAbsense,
  externalInfo,
  setHighlightedAnnotation,
  highlightedAnnotation,
  topKsales,
  numColsInBasket,
}: FlatModelGondolaBasketProps) => {
  const props = {
    masterProducts: masterProducts,
    productSales: productSales,
    markAbsense: markAbsense,
    externalInfo: externalInfo,
    setHighlightedAnnotation: setHighlightedAnnotation,
    highlightedAnnotation: highlightedAnnotation,
    topKsales: topKsales,
  };
  if (numColsInBasket === 1 || modelGondolaProducts.length === 1) {
    return (
      <Box component="div" flex={1}>
        <FlatModelGondolaBasketColumn
          modelGondolaProducts={modelGondolaProducts}
          {...props}
        />
      </Box>
    );
  } else {
    const n = Math.ceil(modelGondolaProducts.length / 2);
    const left = modelGondolaProducts.slice(0, n);
    const right = modelGondolaProducts.slice(n);
    return (
      <Box
        component="div"
        flex={1}
        sx={{
          border: '1px solid black',
        }}
      >
        <Stack direction="row">
          <FlatModelGondolaBasketColumn
            modelGondolaProducts={left}
            {...props}
          />
          <FlatModelGondolaBasketColumn
            modelGondolaProducts={right}
            {...props}
          />
        </Stack>
      </Box>
    );
  }
};

type FlatModelGondolaContainerProps = {
  container: FlatContainer;
  masterProducts: Product[];
  productSales: StoreProductSale[];
  highlightedAnnotation?: ProductAnnotation;
  setHighlightedAnnotation: (_?: ProductAnnotation) => void;
  markAbsense: boolean;
  externalInfo: ExternalInfo | undefined;
  topKsales: _TopKSale[];
  captionBottom: boolean;
};

const FlatModelGondolaContainer = ({
  container,
  masterProducts,
  productSales,
  highlightedAnnotation,
  setHighlightedAnnotation,
  markAbsense,
  externalInfo,
  topKsales,
  captionBottom,
}: FlatModelGondolaContainerProps) => {
  // 5カゴの場合は、2列化していい。
  // 10カゴの場合は、1列縦並びがいいんでないか。
  const numColsInBasket = container.baskets.length <= 5 ? 2 : 1;
  const captionEl = (
    <Box
      component="div"
      sx={{
        border: '1px solid lightgrey',
        backgroundColor: '#eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20px',
        marginBottom: !captionBottom ? '5px' : null,
        marginTop: captionBottom ? '5px' : null,
      }}
    >
      <Typography fontSize="8pt" color="#000">
        {container.name}
      </Typography>
    </Box>
  );
  return (
    <Stack>
      {!captionBottom && captionEl}
      <Stack direction="row">
        {container.baskets.map((basket, index) => (
          <FlatModelGondolaBasket
            key={index}
            modelGondolaProducts={basket.products}
            masterProducts={masterProducts}
            productSales={productSales}
            highlightedAnnotation={highlightedAnnotation}
            setHighlightedAnnotation={setHighlightedAnnotation}
            markAbsense={markAbsense}
            externalInfo={externalInfo}
            topKsales={topKsales}
            numColsInBasket={numColsInBasket}
          />
        ))}
      </Stack>
      {captionBottom && captionEl}
    </Stack>
  );
};

type ModelGondolaContentProps = {
  modelGondola: RegularModelGondola | FlatModelGondola;
  productSales: StoreProductSale[];
  externalInfo: ExternalInfo | undefined;
  highlightedAnnotation?: ProductAnnotation;
  setHighlightedAnnotation: (_?: ProductAnnotation) => void;
  // TODO: support ice
};

export const ModelGondolaContent = ({
  modelGondola,
  productSales,
  externalInfo,
  highlightedAnnotation,
  setHighlightedAnnotation,
}: ModelGondolaContentProps) => {
  const [markAbsense, setMarkAbsense] = useState<boolean>(false);

  const modelGondolaProducts = listModelGondolaProducts(modelGondola);
  const sales = modelGondolaProducts
    .map((p) => productSales.find((s) => s.itemCd === p.productCode))
    .filter((s) => s !== undefined) as StoreProductSale[];
  const top5sales: _TopKSale[] = sales
    .sort((a, b) => b.zoGondolaShare - a.zoGondolaShare)
    .slice(0, 5)
    .map((sale, index) => ({ rank: index + 1, sale: sale }));

  const itemCds = modelGondolaProducts.map((p) => p.productCode);
  const masterProducts = useAllProductsContext(itemCds).products;
  return (
    <Stack spacing={2}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={markAbsense}
              onChange={() => {
                setMarkAbsense(!markAbsense);
              }}
            />
          }
          label="店舗未導入の商品を強調表示"
          disabled={productSales.length === 0}
        />
      </FormGroup>

      <Box component="div" minWidth="500px" width="100%" maxWidth="1000px">
        {modelGondola.type === 'regular' ? (
          <Stack>
            {(modelGondola as RegularModelGondola).boards.map(
              (board, index) => (
                <RegularModelGondolaBoard
                  key={index}
                  board={board}
                  masterProducts={masterProducts}
                  productSales={productSales}
                  markAbsense={markAbsense}
                  externalInfo={externalInfo}
                  highlightedAnnotation={highlightedAnnotation}
                  setHighlightedAnnotation={setHighlightedAnnotation}
                  topKsales={top5sales}
                />
              )
            )}
          </Stack>
        ) : (
          <Stack direction="row">
            <Box
              component="div"
              minWidth="20px"
              maxWidth="20px"
              sx={{
                border: '1px solid lightgrey',
                backgroundColor: '#eee',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '5px',
                marginRight: '15px',
              }}
            >
              <Typography
                fontSize="8pt"
                color="#000"
                textAlign="center"
                sx={{
                  transform: 'rotate(90deg)',
                  transformOrigin: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                レジカウンター側
              </Typography>
            </Box>
            <Stack spacing={1}>
              {(modelGondola as FlatModelGondola).containers.map(
                (container, index) => (
                  <FlatModelGondolaContainer
                    key={container.name}
                    container={container}
                    masterProducts={masterProducts}
                    productSales={productSales}
                    highlightedAnnotation={highlightedAnnotation}
                    setHighlightedAnnotation={setHighlightedAnnotation}
                    markAbsense={markAbsense}
                    externalInfo={externalInfo}
                    topKsales={top5sales}
                    captionBottom={index !== 0}
                  />
                )
              )}
            </Stack>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};
