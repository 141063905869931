import { CircularSpinner } from '@components/molecules';
import { headerHeight } from '@components/organisms/header/header';
import { SearchModal } from '@components/organisms/searchModal/searchModal';
import { PreviewDragLayer } from '@components/pages/planogramEditor/fragments/previewDragLayer';
import { RightSideArea } from '@components/pages/planogramEditor/fragments/rightSideArea';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import {
  PlanogramProvider,
  usePlanogramContext,
} from '@hooks/usePlanogramProvider';
import { useRealogramAnalyticsData } from '@hooks/useRealogramAnalyticsData';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { useZoomController } from '@hooks/useZoomController';
import { Box, Divider } from '@mui/material';
import {
  CurrentSelectedType,
  setCurrentTab,
  updateCurrentSelectedItemId,
  updateCurrentSelectedType,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { selectModalProps } from '@reducers/modal/selector';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import {
  EstimateForPlanogramResponse,
  useListProductsBulkQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  ComparisonTabModal,
  fullHeight,
  planogramRightSideHeight,
} from '@utils/const';
import {
  getBucketsProductIds,
  getProductsLayout,
  isPlanogramBucketPlan,
  isPlanogramShelvesDetail,
} from '@utils/planogram';
import { isInferredAsProduct } from '@utils/product';
import { FC, useEffect, useState } from 'react';
import {
  BucketMode,
  BucketPlanogramPlan,
  CompartmentBucketPosition,
  Planogram,
  ProductPosition,
} from 'types/planogram';
import { StoreArea } from 'types/products';
import { FlatPlanogramImages } from './flatPlanogramImages';

type Props = {
  planogram?: Planogram;
  plan: BucketPlanogramPlan;
  positions?: CompartmentBucketPosition[][];
  handleChangeBucketMode: (value: BucketMode) => void;
  handleClickAwayPlanogram: () => void;
  handleRecalculate: () => void;
  handleChangeProfit: (value: string) => void;
  planogramEstimatedData?: EstimateForPlanogramResponse;
  storeAreaType: StoreArea;
  profit: string;
  handleChangeFront?: () => void;
  isTenantSalesAnalytics?: boolean;

  compareQueryParams?: {
    id: number;
    type: CurrentSelectedType;
  };
};

export const FlatComparison: FC<Props> = ({
  planogram,
  plan,
  handleChangeBucketMode,
  handleClickAwayPlanogram,
  handleRecalculate,
  handleChangeProfit,
  planogramEstimatedData,
  storeAreaType,
  profit,
  handleChangeFront,
  isTenantSalesAnalytics,
  compareQueryParams,
}) => {
  const data = usePlanogramContext();
  const { searchParams } = useUrlQueryParams();
  const dispatch = useAppDispatch();
  const { isLarger } = useBreakpoint();
  const { open: isOpenModal } = useAppSelector(selectModalProps);
  const { detailMode, detailView, rateValue, productTag, profitTab } =
    useAppSelector(selectPlanogramEditorState);
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { updateModeQueryParams } = useUpdateUrlQueryParamsOfDetailPages();
  const { zoomScale } = useZoomController();
  const [modalOpen, setModalOpen] = useState(false);
  const originRealogramCandidateId =
    planogram?.link?.origin_realogram_candidate_id;
  const originPlanogramId = planogram?.link?.origin_planogram_id;
  const {
    isLoading,
    comparedPlanogram,
    comparedRealogram,
    isForbidden,
    fetchGetPlanogram,
  } = useComparisonItemModal(
    currentSelectedType === 'actual' ||
      (originRealogramCandidateId && !currentSelectedItemId?.toString())
      ? 'actual'
      : ''
  );

  const {
    analyticsDataTerm: comparedAnalyticsDataTerm,
    displayAnalyticsData: displayComparedAnalyticsData,

    disableChangeToPreviousWeek: disableChangeToComparedPreviousWeek,
    disableChangeToNextWeek: disableChangeToComparedNextWeek,
    handleNextWeekTerm: handleComparedNextWeekTerm,
    handlePreviousWeekTerm: handleComparedPreviousWeekTerm,
  } = useRealogramAnalyticsData(
    comparedRealogram?.id ?? 0,
    !comparedRealogram || !isTenantSalesAnalytics || comparedRealogram?.id === 0
  );

  const comparedRealogramShelfboards =
    comparedRealogram?.detail?.products_shelves.shelf_boards;

  const comparisonProductIds = () => {
    if (comparedPlanogram) {
      if (isPlanogramBucketPlan(comparedPlanogram.plan)) {
        return (comparedPlanogram.plan.frame.detail.buckets ?? [])
          .flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
          .sort()
          .join(',');
      }
      if (isPlanogramShelvesDetail(comparedPlanogram.plan)) {
        return getProductsLayout(comparedPlanogram.plan)
          .flatMap(({ row }) => row.map(({ product_id }) => product_id))
          .sort()
          .join(',');
      }
    }
    if (comparedRealogramShelfboards) {
      return comparedRealogramShelfboards
        .flatMap((shelfboard) =>
          shelfboard.compartments.flatMap((compartment) =>
            compartment.faces
              .filter((face) => isInferredAsProduct(face))
              .map((face) => face.primary_candidate?.product_id)
          )
        )
        .sort()
        .join(',');
    }
    return '';
  };
  const { data: comparisonProductsBulk } = useListProductsBulkQuery(
    { productIds: comparisonProductIds(), shape: true, detail: true },
    { skip: !comparisonProductIds() }
  );

  const { filteredShelfBoards: comparedShelfboards } = useExtractRealogramData(
    detailView,
    productTag,
    displayComparedAnalyticsData?.products ?? [],
    rateValue,
    comparedRealogramShelfboards
  );

  useEffect(() => {
    if (originRealogramCandidateId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedType('actual'));
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        detailView,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `actual-${originRealogramCandidateId}`,
        }
      );
      void fetchGetPlanogram(originRealogramCandidateId, 'actual');
    } else if (originPlanogramId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedType('plan'));
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        detailView,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `plan-${originPlanogramId}`,
        }
      );
      void fetchGetPlanogram(originPlanogramId, 'plan');
    } else if (currentSelectedItemId?.toString() && currentSelectedType) {
      void fetchGetPlanogram(currentSelectedItemId, currentSelectedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- updateModeQueryParams only updates queryParams the first time the page loads, Avoid falling into an infinite loop
  }, [
    currentSelectedItemId,
    currentSelectedType,
    detailMode,
    detailView,
    dispatch,
    fetchGetPlanogram,
    originPlanogramId,
    originRealogramCandidateId,
    productTag,
    profitTab,
    rateValue,
  ]);

  useEffect(() => {
    const { id: queryId, type: queryType } = compareQueryParams || {};
    const shouldSkip = !queryId || !queryType || isOpenModal;
    if (shouldSkip) return;
    if (queryType === 'plan') {
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
    } else if (queryType === 'actual') {
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
    }
    dispatch(updateSelectedItemId(queryId));
    dispatch(updateCurrentSelectedItemId(compareQueryParams?.id));
    dispatch(updateCurrentSelectedType(compareQueryParams?.type));
  }, [compareQueryParams, dispatch, isOpenModal]);

  const isComparedItem = searchParams.get('item') ? false : true;

  const { selectedProductCompartment, position, selectedBucketId } =
    useSelectedPlanogramItem({
      products: isComparedItem ? data?.comparisonProducts : data?.products,
      isCompared: isComparedItem,
    });

  return (
    <>
      <Divider orientation="horizontal" flexItem />
      <Box
        component="div"
        display="flex"
        flexDirection={{ xs: 'column', breakpoint: 'row' }}
        height={`calc(${fullHeight} - ${headerHeight}px)`}
      >
        <Box
          component="div"
          flex={{ xs: 'unset', breakpoint: 1 }}
          height={{
            xs: `calc(${fullHeight} - ${headerHeight}px - 170px)`,
            breakpoint: `calc(${fullHeight} - ${headerHeight}px)`,
          }}
          overflow="hidden"
        >
          <PlanogramProvider
            value={{
              productsBulk: data?.productsBulk,
              comparisonProducts: comparisonProductsBulk?.products,
            }}
          >
            <FlatPlanogramImages
              isLoading={isLoading}
              isForbidden={isForbidden}
              handleChangeBucketMode={handleChangeBucketMode}
              planogram={planogram}
              plan={plan}
              comparedPlanogram={comparedPlanogram}
              comparedRealogram={comparedRealogram}
              handleClickAwayPlanogram={handleClickAwayPlanogram}
              planogramEstimatedData={planogramEstimatedData}
              storeAreaType={storeAreaType}
              profit={profit}
              handleRecalculate={handleRecalculate}
              handleChangeProfit={handleChangeProfit}
              currentSelectedType={currentSelectedType}
              comparedShelfboards={comparedShelfboards}
              comparisonProductIds={comparisonProductIds()}
              isBucketType={false} // To make header style direction is always 'column'
              handleChangeFront={handleChangeFront}
              isTenantSalesAnalytics={isTenantSalesAnalytics}
              comparedAnalyticsData={displayComparedAnalyticsData}
              comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
              disableChangeToComparedPreviousWeek={
                disableChangeToComparedPreviousWeek
              }
              disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
              handleComparedNextWeekTerm={handleComparedNextWeekTerm}
              handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
            />
          </PlanogramProvider>
        </Box>
        <Divider orientation={isLarger ? 'vertical' : 'horizontal'} flexItem />
        <Box
          component="div"
          minWidth={{ xs: 'unset', breakpoint: '254px' }}
          maxWidth={{ xs: 'unset', breakpoint: '254px' }}
          overflow="hidden"
          position="relative"
          maxHeight={{
            xs: planogramRightSideHeight,
            breakpoint: 'calc(100% + 15px)',
          }}
          height="100%"
        >
          <Box component="div" height="100%">
            {isLoading ? (
              <CircularSpinner sx={{ display: 'flex', alignItems: 'center' }} />
            ) : (
              <RightSideArea
                selectedProductCompartment={selectedProductCompartment}
                productPosition={position as ProductPosition}
                selectedBucketId={selectedBucketId}
                bayPlanId={planogram?.bay_plan_id ?? 0}
              />
            )}
          </Box>
        </Box>
        <PreviewDragLayer detailMode={detailMode} scale={zoomScale} />
      </Box>
      <SearchModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};
