import { AddCircleOutlined, AnalyticsOutlined } from '@mui/icons-material';

import { useProductImage } from '@hooks/useImage';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useAppDispatch } from '@store/index';
import { FC, memo } from 'react';
import {
  EditorProduct,
  ItemCd,
  ModegonProduct,
  modegonProductLocationText,
  setAddProductToModegonItemCd,
  setShowProductDetailItemCd,
} from '../features/slices';
import {
  numFmt,
  ProductImage,
  ProductTagChips,
  compositionRatioColor,
} from './CommonComponents';

export const ProductListItemHeight = 160;

type ProductListItemProps = {
  product: EditorProduct;
  allProductItemCds: ItemCd[];
  modegon: ModegonProduct | undefined;
};

const RowGrid = (props: { text: string }) => {
  const { text } = props;
  return (
    <Grid>
      <Typography fontSize={14}>{text}</Typography>
    </Grid>
  );
};

export const ProductListItemImpl: FC<ProductListItemProps> = ({
  product,
  allProductItemCds,
  modegon,
}) => {
  const dispatch = useAppDispatch();

  const { image } = useProductImage(
    {
      productId: parseInt(product.shelfAppsProductId || ''),
      faceFront: product.faceDirection || 'front',
      size: 'tiny',
    },
    {
      skip: product.shelfAppsProductId == null,
    }
  );

  const SimpleTable = styled('table')({
    fontSize: 12,
  });

  return (
    <Stack
      direction="column"
      sx={{
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        sx={{ flexWrap: 'nowrap', p: 1 }}
      >
        <Grid item container direction="column" width="auto">
          <Grid item style={{ width: 80, height: 80 }}>
            {image && <ProductImage src={image} alt={product.name} size={80} />}
          </Grid>
          <Grid item container direction="row">
            <Grid>
              <IconButton
                onClick={() => {
                  dispatch(setAddProductToModegonItemCd(product.itemCd));
                }}
                disabled={modegon != undefined}
              >
                <AddCircleOutlined />
              </IconButton>
            </Grid>
            <Grid>
              <IconButton
                onClick={() => {
                  dispatch(
                    setShowProductDetailItemCd({
                      targets: [product.itemCd],
                      products: allProductItemCds,
                    })
                  );
                }}
              >
                <AnalyticsOutlined />
              </IconButton>
            </Grid>
          </Grid>
          <Typography fontSize={10} textAlign="center">
            {modegon ? modegonProductLocationText(modegon) : 'モデゴン未導入'}
          </Typography>
        </Grid>

        <Grid item container direction="column" sx={{ width: '100%' }}>
          <Grid item alignItems="space-between" margin={1}>
            <Box
              component="span"
              title={`PMA  ${product.pma}-${product.informationClass}-${product.minorClass}`}
            >
              <Typography
                sx={{ fontSize: 14, fontWeight: 'bold' }}
                component="div"
              >
                {product.name}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 10 }} component="div">
              商品コード:{product.itemCd} / 販売開始日:{product.startDate}
            </Typography>
          </Grid>
          <Grid item container>
            <Grid item>
              <ProductTagChips product={product} />
            </Grid>
          </Grid>

          <Grid container item>
            <Grid container item xs={7}>
              <SimpleTable>
                <tbody>
                  <tr>
                    <td>前週売上</td>
                    <td>{numFmt(product.sales1w)}円</td>
                    <td>
                      ({numFmt(product.sales1w - product.sales2w, true)})円
                    </td>
                  </tr>
                  <tr>
                    <td>前週売数</td>
                    <td>{numFmt(product.quantity1w)}個</td>
                    <td>
                      ({numFmt(product.quantity1w - product.quantity2w, true)}
                      )個
                    </td>
                  </tr>
                  <tr>
                    <td>導入率(POS)</td>
                    <td>{numFmt(product.adaptRatio1w * 100)}%</td>
                    <td>
                      (
                      {numFmt(
                        100 * (product.adaptRatio1w - product.adaptRatio2w),
                        true
                      )}
                      )%
                    </td>
                  </tr>
                </tbody>
              </SimpleTable>
            </Grid>

            {/* misc */}
            <Grid container item xs={5} direction="row">
              <RowGrid text={`推奨売価  ${product.price}円`} />
              <Grid>
                <Box
                  sx={{
                    bgcolor: compositionRatioColor(product.compositionRatio1w),
                  }}
                  component="div"
                  padding={0.4}
                >
                  <Typography component="span" fontSize={12}>
                    ゴンドラ内構成比{' '}
                  </Typography>
                  <Typography component="span" fontSize={14}>
                    {numFmt(100 * product.compositionRatio1w)}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Stack>
  );
};
export const ProductListItem = memo(ProductListItemImpl);
