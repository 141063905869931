import { ComponentProps, forwardRef } from 'react';

export const ListWrapper = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  ({ style, children, ...props }, ref) => {
    return (
      <div ref={ref} style={{ ...style, flex: 1 }} {...props}>
        {children}
      </div>
    );
  }
);

ListWrapper.displayName = 'ListWrapper';
