import { Box } from '@mui/material';
import Image from 'next/legacy/image';
import { FC, useMemo } from 'react';
import {
  Product,
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductReport } from 'types/products';
import {
  BboxColors,
  RealogramCandidateCompartment,
  RealogramSelectedItem,
  RealogramShelfBoard,
  UnknownProduct,
} from 'types/realogram';
import { Bboxes } from './bboxes';
import { FlatBboxes } from './flatBbox';

type Props = {
  markerEnabled: boolean;
  defaultBboxColor?: BboxColors;
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  handleClickBbox: (
    selectedItem: RealogramSelectedItem,
    isComparingBox: boolean
  ) => void;
  handleChangeLoading: () => void;
  rate?: Rate;
  profitTab?: ProfitTab;
  productReports?: ProductReport[];
  comparedProductReports?: ProductReport[];
  comparisonSameIdsSet?: Set<number>;
  isUnknownProductsReviseMode: boolean;
  unknownProductsInfo?: UnknownProduct[];
  height: number;
  width: number;
  realogramImage?: string;
  dataImage: string;
  setWidth: (width: number) => void;
  setHeight: (height: number) => void;
  imageElement: HTMLImageElement | null;
  ratio: number;
  imageShotBboxes?: RealogramShelfBoard[];
  bboxEnabled: boolean;
  maxThreshold: number;
  isBucket?: boolean;
  isComparingBox: boolean;
  selectedProductCompartment?: Product;
};
const generateFullBBox = (compartments: RealogramCandidateCompartment[]) => {
  if (!compartments.length || !compartments[0].faces.length) {
    return { height: 0, width: 0, minX: 0, minY: 0 };
  }

  let {
    x: minX,
    y: minY,
    width: maxWidth,
    height: maxHeight,
  } = compartments[0].faces[0].bbox;
  let maxX = minX + maxWidth;
  let maxY = minY + maxHeight;

  compartments.forEach((compartment) => {
    compartment.faces.forEach((face) => {
      const { x, y, width, height } = face.bbox;

      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x + width);
      maxY = Math.max(maxY, y + height);

      maxHeight = Math.max(maxHeight, height);
      maxWidth = Math.max(maxWidth, width);
    });
  });

  const width = maxX - minX;
  const height = maxY - minY;

  return { height, width, x: minX, y: minY };
};
export const ImageAndBbox: FC<Props> = ({
  markerEnabled,
  defaultBboxColor,
  view,
  mode,
  handleClickBbox,
  handleChangeLoading,
  rate,
  profitTab,
  productReports,
  comparisonSameIdsSet,
  isUnknownProductsReviseMode,
  unknownProductsInfo,
  height,
  width,
  realogramImage,
  dataImage,
  setWidth,
  setHeight,
  imageElement,
  ratio,
  imageShotBboxes,
  bboxEnabled,
  maxThreshold,
  isBucket = false,
  isComparingBox,
  selectedProductCompartment,
}) => {
  const fullBboxShelfBoards = useMemo(
    () =>
      imageShotBboxes?.map((shelfBoard) => {
        const bbox = generateFullBBox(shelfBoard.compartments);

        const compartments = shelfBoard.compartments.map((compartment) => {
          return {
            ...compartment,
            bbox,
            faces: compartment.faces.map((face) => ({
              ...face,
              bbox,
            })),
          };
        });

        const uniqueCompartments = compartments.filter(
          (compartment, index, self) =>
            index ===
            self.findIndex(
              (c) =>
                c.bbox.x === compartment.bbox.x &&
                c.bbox.y === compartment.bbox.y &&
                c.bbox.width === compartment.bbox.width &&
                c.bbox.height === compartment.bbox.height &&
                c.faces[0].primary_candidate?.product_id ===
                  compartment.faces[0].primary_candidate?.product_id
            )
        );

        const mapUniqueCompartments = uniqueCompartments.map((compartment) => {
          return {
            ...compartment,
            bbox,
            faces: compartment.faces.filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.primary_candidate?.product_id ===
                    item.primary_candidate?.product_id
                )
            ),
          };
        });
        return {
          ...shelfBoard,
          compartments: mapUniqueCompartments,
        };
      }),
    [imageShotBboxes]
  );

  return (
    <Box
      flex={1}
      component="div"
      height={height}
      width={width}
      position="relative"
    >
      <Image
        data-image={dataImage}
        src={realogramImage ?? ''}
        alt=""
        loading="lazy"
        layout="fill"
        onLoadingComplete={handleChangeLoading}
        objectFit="contain"
        onLoad={() => {
          if (width == 0 || height === 0) {
            setWidth((imageElement?.naturalWidth ?? 0) * ratio);
            setHeight((imageElement?.naturalHeight ?? 0) * ratio);
          }
        }}
      />
      {imageShotBboxes && isBucket && (
        <FlatBboxes
          markerEnabled={markerEnabled}
          ratio={ratio}
          bboxEnabled={bboxEnabled}
          shelfBoards={fullBboxShelfBoards as RealogramShelfBoard[]}
          defaultBboxColor={defaultBboxColor}
          view={view}
          mode={mode}
          handleClickBbox={view === 'default' ? handleClickBbox : () => void 0}
          rate={rate}
          maxThreshold={maxThreshold}
          productReports={productReports}
          profitTab={profitTab}
          comparisonSameIdsSet={comparisonSameIdsSet}
          unknownProductsInfo={unknownProductsInfo}
          isUnknownProductsReviseMode={isUnknownProductsReviseMode}
          isComparingBox={isComparingBox}
          allShelfBoards={imageShotBboxes}
        />
      )}
      {imageShotBboxes && !isBucket && (
        <Bboxes
          markerEnabled={markerEnabled}
          ratio={ratio}
          bboxEnabled={bboxEnabled}
          shelfBoards={imageShotBboxes}
          defaultBboxColor={defaultBboxColor}
          view={view}
          mode={mode}
          handleClickBbox={handleClickBbox}
          rate={rate}
          maxThreshold={maxThreshold}
          productReports={productReports}
          profitTab={profitTab}
          comparisonSameIdsSet={comparisonSameIdsSet}
          unknownProductsInfo={unknownProductsInfo}
          isUnknownProductsReviseMode={isUnknownProductsReviseMode}
          isComparingBox={isComparingBox}
          selectedProductCompartment={selectedProductCompartment}
        />
      )}
    </Box>
  );
};
