import { Chip } from '@mui/material';
import { ExternalInfo } from '../../types';
import { Product } from 'types/common';
import { t } from 'i18next';

type Props = {
  externalInfo: ExternalInfo | undefined;
  product: Product | undefined;
};

export const NewItemChipIfNeeded = ({ externalInfo, product }: Props) => {
  if (!externalInfo || !product) {
    return <></>;
  }
  const isNewItem =
    (externalInfo?.newItems || []).find(
      (item) => item.itemCd === product.detail!.organization_product_id!
    ) !== undefined;

  if (!isNewItem) {
    return <></>;
  }

  return (
    <Chip
      key={t('assortment.new_sales')}
      sx={{
        fontSize: '7pt',
        height: '10pt',
        margin: '1pt',
        '& .MuiChip-label': {
          padding: '4px',
        },
      }}
      label={t('assortment.new_sales')}
      color="secondary"
      variant="outlined"
    />
  );
};
