import { Box, Grid, Paper } from '@mui/material';
import { FC, useEffect } from 'react';

import { Provider } from 'react-redux';
import { store } from '../../../store';
import { ProductList } from './fragments/ProductList';
import { AddProductToModegonModal } from './fragments/AddProductToModegon';
import { ProductDetailModal } from './fragments/ProductDetailModal';
import { GondolaLayout } from './fragments/GondolaLayout';
import { TopAppBar } from './fragments/TopAppBar';

export const SakuraEditor: FC = () => {
  const useBeforeUnloadAlert = (message: string) => {
    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        const e = event || window.event;
        if (e) {
          e.preventDefault();
          e.returnValue = message;
        }

        return message;
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [message]);
  };

  useBeforeUnloadAlert('ページを離れますがよろしいですか？');

  return (
    <Provider store={store}>
      <Box
        width="100%"
        height="100vh"
        flexWrap="nowrap"
        component="div"
        display="flex"
        flexDirection="column"
      >
        <TopAppBar />
        <Grid
          id="main-container-grid"
          container
          flex={1}
          columns={2}
          flexWrap="nowrap"
          display="flex"
          width="100%"
          height="Calc(100vh - 64px)"
        >
          <Grid
            id="left-container"
            // element itself
            minWidth={600}
            item
            flex={1}
            // child items
            container
            display="flex"
            flexDirection="column"
            padding={1}
          >
            <Box
              component="div"
              flex={1}
              width="100%"
              maxHeight="100%"
              sx={{ overflow: 'scroll' }}
            >
              <GondolaLayout />
            </Box>
          </Grid>

          <Grid id="right-container" display="block" width="450px">
            <Paper
              elevation={1}
              square
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ProductList />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <AddProductToModegonModal />
      <ProductDetailModal />
    </Provider>
  );
};
