import { AvailableItems } from '../../types';
import GondolaPmas from '@utils/gondolas.json';

type GondolaPma = (typeof GondolaPmas.gondolaPmas)[0];

export const findPossibleGondolasForItem = (
  itemCd: string,
  availableItems: AvailableItems
): GondolaPma[] => {
  const possibleGondolaCodes =
    availableItems.items.find((item) => item.itemCd === itemCd)?.gondolaCodes ||
    [];
  const possibleGondolas = possibleGondolaCodes
    .map((code) =>
      GondolaPmas.gondolaPmas.find((gondolaPma) => gondolaPma.code === code)
    )
    .filter((gondolaPma) => gondolaPma) as GondolaPma[];
  return possibleGondolas;
};

export const abbrevGondolaName = (name: string, n = 8 as number) => {
  if (name.length <= n) {
    return name;
  }
  return name.slice(0, n) + '...';
};
