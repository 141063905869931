import { ChangeEvent, FC } from 'react';
import { RealogramUploadStep, StepFiveInfo } from 'types/realogram';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Header } from '@components/organisms/header/header';
import { theme } from 'theme';
import { RealogramDetail } from './realogramDetail';
import { paths } from '@utils/const';

type Props = {
  changeStep: (step: RealogramUploadStep, isNext: boolean) => void;
  isFlat: boolean;
  onFirstFileSelect: (file: File) => void;
  onSecondFileSelect: (file?: File) => void;
  stepFiveInfo?: StepFiveInfo;
  handleNavigate: (nextPath: string) => void;
  isXs?: boolean;
};

export const StepFive: FC<Props> = ({
  changeStep,
  isFlat,
  onFirstFileSelect,
  onSecondFileSelect,
  stepFiveInfo,
  handleNavigate,
  isXs = false,
}) => {
  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // 平置きのsecondFileのファイルのみ初期化。firstFileは上書きされるので問題ない
      onSecondFileSelect(undefined);
      onFirstFileSelect(e.target.files[0]);
      changeStep('three', true);
    }
  };
  return (
    <Box
      component="div"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',

        backgroundColor: theme.palette.white.primary,
      }}
    >
      <Header>
        <Typography variant="headingS">
          新規スキャン アップロード完了
        </Typography>
      </Header>
      <Box component="div" flex={1} py={7} px={2}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Box component="div">
            <Typography variant="headingM">
              アップロードが完了しました
            </Typography>
          </Box>
          <RealogramDetail
            isFlat={isFlat}
            stepFiveInfo={stepFiveInfo}
            handleNavigate={handleNavigate}
            isXs={isXs}
            handleChangeFile={handleChangeFile}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        component="div"
        sx={{
          padding: '24px 24px 80px 24px',
          height: '152px',
        }}
      >
        <Button
          variant="contained"
          aria-describedby="menuButton"
          component="label"
          sx={{
            height: '48px',
            p: 2,
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '16px',
            backgroundColor: theme.palette.white.primary,
            color: theme.palette.textBlack.primary,
            '&:hover': {
              backgroundColor: theme.palette.backgroundBlack.light,
            },
          }}
          onClick={() => handleNavigate(paths.actuals.foldersRecentlyScanned)}
        >
          スキャン結果一覧に戻る
        </Button>
      </Box>
    </Box>
  );
};
