import { createContext, useContext } from 'react';
import { FlatModelGondola, RegularModelGondola } from '../types';

type Gondolas = (RegularModelGondola | FlatModelGondola)[];

export type ModelGondolasContextType = {
  value:
    | {
        storeCd: string;
        modelGondolaWeek: string;
        modelGondolas: Gondolas;
      }
    | undefined;
};

export const ModelGondolasContext = createContext<
  ModelGondolasContextType | undefined
>({ value: undefined });

export const useModelGondolasContext = () => {
  const ctx = useContext(ModelGondolasContext);
  if (!ctx) {
    throw new Error('ModelGondolasContext is not provided');
  }
  return ctx;
};
