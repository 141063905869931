import { RealogramFaceImage } from '@components/molecules/realogramFaceImage/realogramFaceImage';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Stack, Typography } from '@mui/material';
import { imageBoxSize } from '@utils/const';
import { theme } from 'theme';
import { DynamicImage } from '@components/organisms/dynamicImage/dynamicImage';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import {
  ProductCandidate,
  RealogramSelectedItem,
  ProductCandidateWithName,
} from 'types/realogram';
import { ProfitInfo } from './profitInfo';
import { RealogramProductCandidateOptionalItem } from './realogramProductCandidateOptionalItem';
import { ProductSalesReport } from 'types/products';
import { ReactNode } from 'react';
import { t } from 'i18next';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { Help } from '@mui/icons-material';
import { CorrectedChip } from '@components/molecules';

type Props = {
  isBucketType: boolean;
  isProductUnknown?: boolean;
  realogramDetailView: ShelfDetailView;
  referenceImage: string;
  handleOpenReferenceImagePreview: VoidFunction;
  setSelectedProductZoomIn: (product?: Product) => void;
  product?: Product;
  selectedItem: RealogramSelectedItem;
  name: string;
  isShowJANAndCD: boolean;
  eanCodeValue: string;
  productCodeValue: string;
  isCanNotEditProductAndCompartment?: boolean;
  handleModalOpen: VoidFunction;
  productSalesReport?: ProductSalesReport[];
  profitTab?: ProfitTab;
  realogramCandidateId: number;
  isTenantSalesAnalytics?: boolean;
  mostHighScoreProduct?: Product;
  mostHighScore?: ProductCandidate;
  handleChangeCandidate?: (
    mostHighScoreProduct: ProductCandidateWithName
  ) => void;
  isUnrevisedUnknownProduct?: boolean;
  slideButtons: ReactNode;
  displayAnalyticsDataIndex: number;
  productCode: string;
};

export const HorizontalCandidate = ({
  isBucketType,
  isProductUnknown,
  realogramDetailView,
  referenceImage,
  product,
  selectedItem,
  name,
  isShowJANAndCD,
  eanCodeValue,
  productCodeValue,
  isCanNotEditProductAndCompartment,
  productSalesReport,
  profitTab,
  realogramCandidateId,
  isTenantSalesAnalytics,
  mostHighScoreProduct,
  mostHighScore,
  isUnrevisedUnknownProduct,
  slideButtons,
  displayAnalyticsDataIndex,
  productCode,
  handleOpenReferenceImagePreview,
  setSelectedProductZoomIn,
  handleModalOpen,
  handleChangeCandidate,
}: Props) => {
  return (
    <Box
      component="div"
      display="flex"
      flexGrow="1"
      flexDirection="row"
      position="relative"
      height="100%"
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        mr="16px"
        gap={1}
      >
        <Box component="div" display="flex" justifyContent="flex-start" gap={1}>
          {!!referenceImage &&
            (realogramDetailView === 'default' ||
              realogramDetailView === 'rate') && (
              <Box
                component="div"
                mr={!isProductUnknown ? 0 : 1}
                sx={{
                  cursor: 'zoom-in',
                  borderRight: !isProductUnknown ? 'none' : '',
                  position: 'relative',
                  width: 120,
                  height: 120,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (isProductUnknown) handleOpenReferenceImagePreview();
                  else {
                    if (isProductUnknown || !setSelectedProductZoomIn) return;
                    handleOpenReferenceImagePreview();
                    setSelectedProductZoomIn(product);
                  }
                }}
              >
                <DynamicImage
                  src={referenceImage}
                  alt=""
                  layout="fill"
                  objectFit="contain"
                />
                {isProductUnknown && (
                  <ZoomInIcon
                    sx={{
                      zIndex: 999,
                      fontSize: 24,
                      position: 'absolute',
                      right: '4px',
                      bottom: '4px',
                      color: '#838583',
                    }}
                  />
                )}
              </Box>
            )}
          {!isProductUnknown && (
            <Box
              component="div"
              sx={{
                cursor: !isProductUnknown ? 'zoom-in' : '',
                position: 'relative',
                width: 120,
                height: 120,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                if (!setSelectedProductZoomIn) return;
                handleOpenReferenceImagePreview();
                setSelectedProductZoomIn(product);
              }}
            >
              <Box
                component="div"
                width={imageBoxSize}
                height={imageBoxSize}
                position="relative"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RealogramFaceImage
                  product={product}
                  size={imageBoxSize}
                  face={selectedItem.item}
                  baseSize={imageBoxSize}
                  fontSize={24}
                />
                {!isProductUnknown && (
                  <ZoomInIcon
                    sx={{
                      zIndex: 999,
                      fontSize: 24,
                      position: 'absolute',
                      right: selectedItem.item.is_unknown ? '-25px' : '-10px',
                      color: '#838583',
                      bottom: '-4px',
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
        {/* 分析タブの時の画像下の情報 */}
        {realogramDetailView === 'profit' && (
          <Box component="div">
            <ProfitInfo eanCode={eanCodeValue} productCode={productCodeValue} />
          </Box>
        )}
      </Box>
      <Stack
        component="div"
        my={isBucketType ? 0 : { xs: 1, breakpoint: 0 }}
        mx={isBucketType ? 0 : { xs: 1, breakpoint: 0 }}
        width="100%"
      >
        {realogramDetailView === 'default' &&
          productCode &&
          product?.detail?.tags?.includes(t('attributes.sales_ended')) && (
            <Box component="div" sx={{ display: 'inline-flex', pb: 0.5 }}>
              <ProductFlagLabel name={t('attributes.sales_ended')} />
            </Box>
          )}
        <Box
          component="div"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '4px',
          }}
        >
          {isUnrevisedUnknownProduct ? (
            <Box
              component="div"
              gap="8px"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Help sx={{ width: 24, height: 24, color: '#b5b5b5' }} />
              <Typography fontWeight={500} lineHeight="24px">
                {name}
              </Typography>
              {selectedItem.item.revised && <CorrectedChip />}
            </Box>
          ) : (
            <Typography fontWeight={500} lineHeight="24px">
              {name}
            </Typography>
          )}
        </Box>
        {isShowJANAndCD && (
          <Box
            component="div"
            sx={{
              display: 'flex',
              columnGap: 5,
            }}
            mb={1}
          >
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.textBlack.secondary,
              }}
            >
              JAN：{eanCodeValue}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.textBlack.secondary,
              }}
            >
              {t('product_code')}：{productCodeValue}
            </Typography>
          </Box>
        )}
        <RealogramProductCandidateOptionalItem
          isCanNotEditProductAndCompartment={isCanNotEditProductAndCompartment}
          detailView={realogramDetailView}
          product={product}
          selectedItem={selectedItem}
          handleModalOpen={handleModalOpen}
          productSalesReport={productSalesReport}
          profitTab={profitTab}
          realogramCandidateId={realogramCandidateId}
          isTenantSalesAnalytics={isTenantSalesAnalytics}
          mostHighScoreProduct={mostHighScoreProduct}
          mostHighScore={mostHighScore}
          handleChangeCandidate={handleChangeCandidate}
          isUnrevisedUnknownProduct={isUnrevisedUnknownProduct}
          slideButtons={slideButtons}
          displayAnalyticsDataIndex={displayAnalyticsDataIndex}
        />
      </Stack>
      {realogramDetailView !== 'profit' && !isUnrevisedUnknownProduct && (
        <>{slideButtons}</>
      )}
    </Box>
  );
};
