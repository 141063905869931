import {
  EditorProduct,
  ModegonProduct,
  ProductTag,
  selectProducts,
  selectModegonProducts,
  selectPreviousModegon,
  ItemCd,
} from '../features/slices';
import {
  Dispatch,
  FC,
  SetStateAction,
  useState,
  MouseEvent,
  useRef,
  useEffect,
  ChangeEvent,
  useCallback,
  memo,
  CompositionEvent,
} from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useAppSelector } from '@store/index';
import {
  FilterListOutlined,
  SwapVertOutlined,
  ClearOutlined,
} from '@mui/icons-material';

import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { ProductListItem, ProductListItemHeight } from './ProductListItem';
import { ProductTagChip } from './CommonComponents';
import { uniq } from 'lodash';

type OrderKey =
  | 'itemCd'
  | 'jan'
  | 'sales1w'
  | 'quantity1w'
  | 'daysFromStart'
  | 'name'
  | 'compositionRatio1w';

type FilterKey =
  | 'Basic'
  | 'New'
  | 'Informed'
  | 'RecommendedToCancel'
  | 'EliminationCandidate'
  | 'NotInModegon'
  | 'hasSales';

type ProductValiants = 'candidate' | 'all' | 'trash'; // 全商品か掲載候補か

type ProductFilterCondition = {
  productSetVariant: ProductValiants;
  searchText: string;
  filterBy: FilterKey[];
  order: OrderKey;
};

// #region Search Field
type SearchFieldProps = {
  filterCondition: ProductFilterCondition;
  setFilterCondition: Dispatch<SetStateAction<ProductFilterCondition>>;
  isComposing: boolean;
  setIsComposing: Dispatch<SetStateAction<boolean>>;
  searchFieldFocused: boolean;
  setSearchFieldFocused: Dispatch<SetStateAction<boolean>>;
};

const SearchFieldImpl: FC<SearchFieldProps> = (props) => {
  const {
    filterCondition,
    setFilterCondition,
    isComposing,
    setIsComposing,
    searchFieldFocused,
    setSearchFieldFocused,
  } = props;

  const [inputText, setInputText] = useState('');
  const inputRef = useRef(null);

  // テキストフィールドの挙動
  // - 検索文字列がある時は、その文字列での絞り込みのみでソートや絞り込みボタンは出さない
  // - クリアボタンを押す or 検索文字列が空のときは ソートや絞り込みも表示する
  // - フォーカスが外れる -> テキストフィールドが伸びているかどうかはあくまで検索クエリがあるかどうか

  const onCompositionStart = useCallback(() => {
    setIsComposing(true);
  }, [setIsComposing]);

  const onCompositionEnd = useCallback(
    (e: CompositionEvent) => {
      const text = e.target instanceof HTMLInputElement ? e.target.value : '';
      setIsComposing(false);
      setInputText(text);
      setFilterCondition({
        ...filterCondition,
        searchText: text,
      });
    },
    [filterCondition, setFilterCondition, setIsComposing]
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;
      setInputText(text);
      if (!isComposing)
        setFilterCondition({
          ...filterCondition,
          searchText: text,
        });
    },
    [filterCondition, setFilterCondition, isComposing]
  );

  const onClearButtonClick = () => {
    console.log('clear button tapped');
    setFilterCondition({ ...filterCondition, searchText: '' });
    setInputText('');
    setSearchFieldFocused(false);
  };

  const showClearButton = inputText.length > 0;

  return (
    <TextField
      sx={{ flex: 1 }}
      inputRef={inputRef}
      value={inputText}
      onChange={onChange}
      focused={searchFieldFocused}
      onFocus={() => setSearchFieldFocused(true)}
      onBlur={() => setSearchFieldFocused(false)}
      InputProps={{
        endAdornment: showClearButton ? (
          <IconButton onClick={onClearButtonClick}>
            <ClearOutlined />
          </IconButton>
        ) : (
          <></>
        ),
      }}
      onCompositionStart={onCompositionStart}
      onCompositionEnd={onCompositionEnd}
      size="small"
      variant="standard"
      label="検索"
      placeholder="商品名、商品コード、JAN"
    />
  );
};
const SearchField = memo(SearchFieldImpl);

// #region ProductListHeader
type ProductListHeaderProps = {
  filterCondition: ProductFilterCondition;
  setFilterCondition: Dispatch<SetStateAction<ProductFilterCondition>>;
  isComposing: boolean;
  setIsComposing: Dispatch<SetStateAction<boolean>>;
};
const ProductListHeaderImpl: FC<ProductListHeaderProps> = (props) => {
  const { filterCondition, setFilterCondition, isComposing, setIsComposing } =
    props;

  const [tabIndex, setTabIndex] = useState<number>(
    filterCondition.productSetVariant == 'candidate' ? 0 : 1
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(
    undefined
  );
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);

  const onSortSelectChange = (e: SelectChangeEvent<number>) => {
    const foundOption = sortOptions.find((p) => p.value == e.target.value);
    if (foundOption) {
      const newOrder = foundOption.key as OrderKey;
      setFilterCondition({
        ...filterCondition,
        order: newOrder,
      });
    }
  };

  const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? undefined : event.currentTarget);
  };

  const onCheckboxSelected = (
    target: HTMLInputElement,
    filterKey: FilterKey
  ) => {
    let newFilterBy: FilterKey[] = [];
    if (target.checked) newFilterBy = [...filterCondition.filterBy, filterKey];
    else newFilterBy = filterCondition.filterBy.filter((f) => f != filterKey);
    setFilterCondition({ ...filterCondition, filterBy: newFilterBy.sort() });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter_popover' : undefined;

  const tabs: { index: number; variant: ProductValiants; label: string }[] = [
    { index: 0, variant: 'candidate', label: 'ゴンドラ内PMA' },
    { index: 1, variant: 'all', label: 'PMA全商品' },
    { index: 2, variant: 'trash', label: 'ゴミ箱' },
  ];

  const sortOptions = [
    { value: 0, key: 'itemCd', display: '商品コード' },
    { value: 1, key: 'jan', display: 'JANコード' },
    { value: 2, key: 'sales1w', display: '売上' },
    { value: 3, key: 'quantity1w', display: '販売数量' },
    { value: 4, key: 'daysFromStart', display: '発売開始日' },
    { value: 5, key: 'name', display: '商品名' },
    { value: 6, key: 'compositionRatio1w', display: 'ゴンドラ構成比' },
  ];

  const filterOptions: { key: FilterKey; display: JSX.Element }[] = [
    {
      key: 'Basic',
      display: <ProductTagChip tag={ProductTag.Kihon} />,
    },
    {
      key: 'New',
      display: <ProductTagChip tag={ProductTag.New} />,
    },
    {
      key: 'Informed',
      display: <ProductTagChip tag={ProductTag.Informed} />,
    },
    {
      key: 'RecommendedToCancel',
      display: <ProductTagChip tag={ProductTag.RecommendedToCancel} />,
    },
    {
      key: 'EliminationCandidate',
      display: <ProductTagChip tag={ProductTag.EliminationCandidate} />,
    },
    {
      key: 'NotInModegon',
      display: (
        <Typography fontSize="14px" fontWeight="540">
          モデゴン未導入
        </Typography>
      ),
    },
    {
      key: 'hasSales',
      display: (
        <Typography fontSize="14px" fontWeight="540">
          販売実績あり
        </Typography>
      ),
    },
  ];

  const initValue =
    sortOptions.find((p) => p.key == filterCondition.order)?.value ?? 0;
  const displaySortFilter = true;
  const isFiltered = filterCondition.filterBy.length > 0;

  return (
    <>
      <Box component="div" display="flex" alignItems="center">
        <Typography variant="headingS" marginRight={1}>
          商品一覧
        </Typography>

        <Tabs
          value={tabIndex}
          onChange={(e, i) => {
            setTabIndex(i);
            setFilterCondition({
              ...filterCondition,
              productSetVariant: tabs[i].variant,
            });
          }}
        >
          {tabs.map((t) => {
            return <Tab label={t.label} value={t.index} key={t.index} />;
          })}
        </Tabs>
      </Box>
      {tabIndex == 0 || tabIndex == 1 ? (
        <Box component="div" display="flex" alignItems="center">
          <SearchField
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
            isComposing={isComposing}
            setIsComposing={setIsComposing}
            searchFieldFocused={searchFieldFocused}
            setSearchFieldFocused={setSearchFieldFocused}
          />
          {displaySortFilter && (
            <Box component="span" alignItems="bottom">
              <SwapVertOutlined />
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                size="small"
                variant="standard"
              >
                <Select value={initValue} onChange={onSortSelectChange}>
                  {sortOptions.map((sp) => {
                    return (
                      <MenuItem key={sp.value} value={sp.value}>
                        {sp.display}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                aria-describedby={id}
                variant={isFiltered ? 'contained' : 'outlined'}
                size="small"
                onClick={onButtonClick}
              >
                <FilterListOutlined />
                絞り込み
                {isFiltered ? `(${filterCondition.filterBy.length})` : ''}
              </Button>
              <Popper id={id} anchorEl={anchorEl} open={open}>
                <Paper elevation={2} sx={{ padding: 1 }}>
                  <FormGroup>
                    {filterOptions.map((opt) => {
                      return (
                        <FormControlLabel
                          key={opt.key}
                          checked={filterCondition.filterBy.includes(opt.key)}
                          control={
                            <Checkbox
                              onClick={(e) =>
                                onCheckboxSelected(
                                  e.target as HTMLInputElement,
                                  opt.key
                                )
                              }
                            />
                          }
                          label={opt.display}
                          id={`check-box-list-${opt.key}`}
                        />
                      );
                    })}
                  </FormGroup>
                </Paper>
              </Popper>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};

const ProductListHeader = memo(ProductListHeaderImpl);

// #region Filter and Sort
function filterAndSortProductsToShow(
  products: EditorProduct[],
  modegonItemCds: ItemCd[],
  previousModegon: ItemCd[],
  condition: ProductFilterCondition
): EditorProduct[] {
  let result: EditorProduct[] = [];

  // 母集団の設定
  if (condition.productSetVariant == 'all') {
    // all の場合は全商品から
    result = [...products];
  } else if (condition.productSetVariant == 'candidate') {
    const productAndPma = products.map((p) => {
      return {
        product: p,
        pma: `${p.pma}-${p.informationClass}-${p.minorClass}`,
      };
    });

    const modegonPmas = uniq(
      modegonItemCds.map(
        (itemCd) => productAndPma.find((p) => p.product.itemCd == itemCd)?.pma
      )
    );

    result = productAndPma
      .filter((p) => modegonPmas.includes(p.pma))
      .map((p) => p.product);
  } else {
    // productSetVariant = 'trash'
    result = [...previousModegon]
      .reverse()
      .map((pm) => products.find((p) => p.itemCd == pm))
      .filter((p): p is EditorProduct => p !== undefined);
    return result; // trashは追加した順に表示するので絞り込みやソートは行わない
  }

  // 絞り込み
  if (condition.filterBy.length > 0) {
    result = result.filter((p) => {
      if (condition.filterBy.includes('Basic')) {
        if (!p.tags.includes(ProductTag.Kihon)) return false;
      }

      if (condition.filterBy.includes('New')) {
        if (!p.tags.includes(ProductTag.New)) return false;
      }

      if (condition.filterBy.includes('Informed')) {
        if (!p.tags.includes(ProductTag.Informed)) return false;
      }

      if (condition.filterBy.includes('RecommendedToCancel')) {
        if (!p.tags.includes(ProductTag.RecommendedToCancel)) return false;
      }

      if (condition.filterBy.includes('EliminationCandidate')) {
        if (!p.tags.includes(ProductTag.EliminationCandidate)) return false;
      }

      if (condition.filterBy.includes('NotInModegon')) {
        if (modegonItemCds.includes(p.itemCd)) return false;
      }

      if (condition.filterBy.includes('hasSales')) {
        if (p.timeSeries.salesZo.every((p) => p == null)) return false;
      }

      return true;
    });
  }

  // 文字列検索
  if (condition.searchText.length > 0) {
    result = result.filter((p) => {
      return (
        p.name.includes(condition.searchText) ||
        p.itemCd.includes(condition.searchText) ||
        p.jan.includes(condition.searchText)
      );
    });
  }

  // ソート
  result = result.sort((a, b) => {
    const va = a[condition.order];
    const vb = b[condition.order];
    if (typeof va === 'string' && typeof vb === 'string')
      return va.localeCompare(vb);
    else if (
      condition.order == 'daysFromStart' &&
      typeof va === 'number' &&
      typeof vb === 'number'
    )
      return va - vb;
    else if (typeof va === 'number' && typeof vb === 'number') return vb - va;
    return 0;
  });

  return result;
}

export const ProductList: FC = () => {
  const products: EditorProduct[] = useAppSelector(selectProducts);
  const modegonProducts: ModegonProduct[] = useAppSelector(
    selectModegonProducts
  );
  const previousModegon = useAppSelector(selectPreviousModegon);

  const [isComposing, setIsComposing] = useState<boolean>(false);

  const [filterCondition, setFilterCondition] =
    useState<ProductFilterCondition>({
      productSetVariant: 'all',
      searchText: '',
      filterBy: [],
      order: 'sales1w',
    });

  const [productsToShow, setProductsToShow] = useState<EditorProduct[]>([]);

  useEffect(() => {
    if (!isComposing) {
      const newValue = filterAndSortProductsToShow(
        products,
        modegonProducts.map((p) => p.itemCd),
        previousModegon,
        filterCondition
      );
      setProductsToShow(newValue);
    }
  }, [
    isComposing,
    products,
    modegonProducts,
    filterCondition,
    previousModegon,
  ]);

  const Row: FC<ListChildComponentProps> = ({ index, style }) => {
    const product = productsToShow[index];

    return (
      <span style={style}>
        <ProductListItem
          key={index}
          product={product}
          modegon={modegonProducts.find((mp) => mp.itemCd == product.itemCd)}
          allProductItemCds={productsToShow.map((p) => p.itemCd)}
        />
      </span>
    );
  };

  return (
    <>
      <Box component="div" margin={1}>
        <ProductListHeader
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          isComposing={isComposing}
          setIsComposing={setIsComposing}
        />
      </Box>
      <Box component="div" height="100%" sx={{ overflow: 'auto' }}>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            return (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={productsToShow.length}
                itemSize={ProductListItemHeight}
              >
                {Row}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      </Box>
    </>
  );
};
