import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { SakuraModal } from './modal';

export const TakeGondolaPhotoButton = () => {
  const [open, setOpen] = useState(false);

  const handleNavigate = () => {
    const newUrl = '/actuals/scan';
    const baseUrl = window.location.origin;
    window.open(`${baseUrl}${newUrl}`, '_blank');
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ ml: 2, width: '170px' }}
        startIcon={<CameraAltIcon />}
        onClick={() => setOpen(true)}
      >
        ゴンドラ撮影
      </Button>
      <SakuraModal
        title="ゴンドラ撮影"
        open={open}
        handleClose={() => setOpen(false)}
      >
        <Stack spacing={4}>
          <Typography variant="body3">
            什器の写真の撮影にご協力をお願いいたします。
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                handleNavigate();
              }}
            >
              撮影アプリに移動
            </Button>
          </Stack>
        </Stack>
      </SakuraModal>
    </>
  );
};
