import { FC } from 'react';
import {
  EditorProduct,
  ProductTag,
  ModegonProductAnnotation,
} from '../features/slices';

import NextImage from 'next/legacy/image';
import { Box } from '@mui/system';
import { scale } from 'chroma-js';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';

// TODO: remove
export function formatNum(x: number, precision = 2): string {
  return (
    Math.round(x * Math.pow(10, precision)) / Math.pow(10, precision)
  ).toString();
}

export const numFmt = (x: number, sign?: boolean) => {
  return Intl.NumberFormat('ja-JP', {
    maximumFractionDigits: 1,
    signDisplay: sign ? 'always' : 'auto',
  }).format(x);
};

export function compositionRatioColor(r: number): string {
  const compositionRatioColorScale = scale(['green', 'yellow', 'red']).domain([
    0, 0.02,
  ]);

  return compositionRatioColorScale(r).brighten(1.2).hex();
}

export type ProductTagChipProps = {
  tag: ProductTag;
  title?: string;
};
export const ProductTagChip: FC<ProductTagChipProps> = (props) => {
  const { tag, title } = props;

  const labels: Record<ProductTag, string> = {
    [ProductTag.Kihon]: '基本商品',
    [ProductTag.New]: '新商品',
    [ProductTag.Informed]: '情報有',
    [ProductTag.RecommendedToCancel]: '推奨取消',
    [ProductTag.EliminationCandidate]: '死筋候補',
  } as const;

  const colors = {
    [ProductTag.Kihon]: '#3f51b5',
    [ProductTag.New]: '#4caf50',
    [ProductTag.Informed]: '#2196f3',
    [ProductTag.RecommendedToCancel]: '#ef5350',
    [ProductTag.EliminationCandidate]: '#ff9800',
  };

  const ChipStyledBox = styled(Box)(() => ({
    color: colors[tag],
    fontSize: '14px',
    fontWeight: '540',
    borderStyle: 'solid',
    borderWidth: '3',
    borderColor: colors[tag],
    borderRadius: '4px',
    padding: '4px',
    margin: '2px',
  }));

  return (
    <ChipStyledBox component="span" title={title}>
      {labels[tag]}
    </ChipStyledBox>
  );
};

export type ProductTagChipsProps = {
  product: EditorProduct;
};

export const ProductTagChips: FC<ProductTagChipsProps> = (props) => {
  const { product } = props;

  return (
    <>
      {Object.values(ProductTag)
        .filter((e) => product.tags.includes(e))
        .map((e) => {
          return (
            <ProductTagChip
              key={e}
              tag={e}
              title={product.tagsSupplementalText[e]}
            />
          );
        })}
    </>
  );
};

export type AnnotationImageTagProps = {
  annotation: keyof ModegonProductAnnotation;
  wh: number;
  style?: object;
};

export const AnnotationImageTag: FC<AnnotationImageTagProps> = ({
  annotation,
  wh,
  style = {},
}) => {
  const imageFile = {
    top1: 'top1.png',
    top2: 'top2.png',
    top3: 'top3.png',
    top4: 'top4.png',
    top5: 'top5.png',
  };

  return (
    <NextImage
      src={`/sakura/recommendation/${imageFile[annotation]}`}
      alt={`アノテーション: ${annotation}`}
      width={wh}
      height={wh}
      style={{
        ...{
          maxWidth: wh,
          maxHeight: wh,
          margin: 'auto',
          display: 'block',
        },
        ...style,
      }}
    />
  );
};

export type ProductImageProps = {
  src: string;
  alt: string;
  size: number | string;
};

export const ProductImage: FC<ProductImageProps> = ({ src, alt, size }) => {
  return (
    <Box
      component="div"
      mx={0}
      my={0}
      position="relative"
      minHeight={size}
      maxWidth={size}
      justifyContent="center"
      alignItems="center"
    >
      <NextImage
        src={src}
        alt={alt}
        sizes={`${size}`}
        layout="fill"
        objectFit="contain"
        style={{ aspectRatio: 'auto' }}
      />
    </Box>
  );
};

export type SimpleYesNoDialogProps = {
  showDialog: boolean;
  title: string;
  message: string;
  callback: (yn: boolean) => void;
};

export const SimpleYesNoDialog: FC<SimpleYesNoDialogProps> = (
  props: SimpleYesNoDialogProps
) => {
  const { showDialog, title, message, callback } = props;

  return (
    <Dialog open={showDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(true)}>はい</Button>
        <Button onClick={() => callback(false)}>いいえ</Button>
      </DialogActions>
    </Dialog>
  );
};
