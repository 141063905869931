import { Box, Stack, Typography } from '@mui/material';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';

export type HighlightColor = {
  color: string;
  textColor: string;
};

const highlightPalette: HighlightColor[] = [
  { color: 'coral', textColor: '#fff' },
  { color: 'violet', textColor: '#000' },
  { color: 'darkturquoise ', textColor: '#000' },
];

export const withColor = <T,>(items: T[]) =>
  items.map((item, i) => ({
    ...item,
    color: highlightPalette[i % highlightPalette.length].color,
    textColor: highlightPalette[i % highlightPalette.length].textColor,
  }));

type BalloonProps = {
  text: string;
  color: string;
  textColor: string;
  position?: 'top' | 'bottom';
};

// ※ Balloonを囲む要素はposition: relative; にしておくこと
export const Balloon = ({ text, color, textColor, position }: BalloonProps) => {
  const isBottom = !position || position === 'bottom';
  const triangle = isBottom
    ? {
        borderColor: `transparent transparent ${color} transparent`,
        bottom: '100%',
      }
    : {
        borderColor: `${color} transparent transparent transparent`,
        top: '100%',
      };
  const triangleSize = '7px';

  return (
    <Box
      component="div"
      position="absolute"
      top={isBottom ? '100%' : undefined}
      bottom={isBottom ? undefined : '100%'}
      left="50%"
      sx={{
        transform: 'translateX(-50%)',
      }}
      zIndex={1}
    >
      <Box
        component="div"
        mt={isBottom ? triangleSize : undefined}
        mb={isBottom ? undefined : triangleSize}
        sx={{
          position: 'relative',
          backgroundColor: color,
          display: 'inline-block',
          opacity: 0.7,
          borderRadius: '15px',
          pt: '3px',
          pb: '1px',
          px: '12px',
          '&::before': {
            content: '""',
            position: 'absolute',
            borderWidth: triangleSize,
            borderStyle: 'solid',
            left: '50%',
            transform: 'translateX(-50%)',
            ...triangle,
          },
        }}
      >
        <Stack direction="row" alignItems="baseline" spacing={0.4}>
          <WbIncandescentIcon
            sx={{
              color: textColor,
              fontSize: '12px',
              transform: 'scaleY(-1)',
            }}
          />
          <Typography
            fontSize="9pt"
            fontWeight="700"
            color={textColor}
            whiteSpace="nowrap"
          >
            {text}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
