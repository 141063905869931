import { useProductImage } from '@hooks/useImage';
import {
  Badge,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useAppDispatch } from '@store/index';
import { FC, useEffect, useState } from 'react';
import {
  EditorProduct,
  ModegonProduct,
  isBevModegonProduct,
  isStandardModegonProduct,
  removeModegonProduct,
  setAddProductToModegonItemCd,
  setShowProductDetailItemCd,
  updateModegonProduct,
  ItemCd,
} from '../features/slices';

import {
  AddCommentRounded,
  Delete,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
  AnalyticsOutlined,
} from '@mui/icons-material';

import {
  compositionRatioColor,
  formatNum,
  ProductImage,
  ProductTagChips,
} from './CommonComponents';

export const ProductColumnHeight = 257; // 'px'
export const ProductColumnWidth = 180; // 'px'

const TextGrid = ({
  text,
  fontSize = 12,
  sx = undefined,
}: {
  text: string;
  fontSize?: number;
  sx?: SxProps;
}) => {
  return (
    <Grid sx={sx}>
      <Typography fontSize={fontSize}>{text}</Typography>
    </Grid>
  );
};

type GondolaProductCardProps = {
  product: EditorProduct;
  modegon: ModegonProduct;
  sameShelfItemCds: ItemCd[];
};

export const GondolaProductCard: FC<GondolaProductCardProps> = ({
  product,
  modegon,
  sameShelfItemCds,
}) => {
  const dispatch = useAppDispatch();

  const { image } = useProductImage(
    {
      productId: parseInt(product.shelfAppsProductId || ''),
      faceFront: product.faceDirection || 'front',
      size: 'tiny',
    },
    {
      skip: product.shelfAppsProductId == null,
    }
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [commentPopoverAnchor, setCommentPopoverAnchor] = useState<
    HTMLButtonElement | undefined
  >(undefined);
  const [commentText, setCommentText] = useState<string>(modegon.comment);

  useEffect(() => {
    if (modegon.comment !== '') {
      setCommentText(modegon.comment);
    }
  }, [modegon.comment]);

  // MARK: button callbacks
  const onEditClick = () => {
    dispatch(setAddProductToModegonItemCd(product.itemCd));
  };

  const onDetailClick = () => {
    dispatch(
      setShowProductDetailItemCd({
        targets: [product.itemCd],
        products: sameShelfItemCds,
      })
    );
  };

  const onMoveClick = (direction: 'left' | 'right' | 'up' | 'down') => {
    let newPos = {};
    if (isStandardModegonProduct(modegon)) {
      if (direction == 'left') newPos = { col: Math.max(modegon.col - 2, 0) };
      else if (direction == 'right') newPos = { col: modegon.col + 2 };
      else if (direction == 'up')
        newPos = { row: Math.max(modegon.row - 1, 1) };
      else if (direction == 'down') newPos = { row: modegon.row + 1 };
    } else if (isBevModegonProduct(modegon)) {
      if (direction == 'left')
        newPos = { basket: Math.max(modegon.basket - 1, 1) };
      else if (direction == 'right') newPos = { basket: modegon.basket + 1 };
      else if (direction == 'up')
        newPos = { index: Math.max(modegon.index - 2, 0) };
      else if (direction == 'down') newPos = { index: modegon.index + 2 };
    }

    if (Object.keys(newPos).length > 0) {
      dispatch(updateModegonProduct({ ...modegon, ...newPos }));
    }
  };

  const onDeleteClick = () => {
    setOpenDialog(true);
  };

  // MARK: comment popover
  const onCommentClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setCommentPopoverAnchor(e.currentTarget);
  };
  const onCommentPopoverClose = () => {
    setCommentPopoverAnchor(undefined);
  };
  const onCommentSubmitClick = () => {
    dispatch(
      updateModegonProduct({
        ...modegon,
        ...{ comment: commentText },
      })
    );
    setCommentPopoverAnchor(undefined);
  };

  const IsPopoverOpen = Boolean(commentPopoverAnchor);
  const popOverId = IsPopoverOpen
    ? `comment-popover-${product.itemCd}`
    : undefined;
  const commentBadgeVisible = modegon.comment.length > 0;

  // MARK: Delete dialog
  const handleRemoveDialogClose = (confirmed: boolean) => {
    if (confirmed) {
      dispatch(removeModegonProduct(product.itemCd));
    }
    setOpenDialog(false);
  };

  // MARK: Product Card elements
  return (
    <Card
      sx={{
        display: 'grid',
        width: ProductColumnWidth + 'px',
        minHeight: '20dvh',
        padding: 1,
        marginRight: 1,
      }}
    >
      <Grid container direction="row">
        <Grid width="100%" height="36px">
          <Box
            component="span"
            title={`PMA: ${product.pma}-${product.informationClass}-${product.minorClass}`}
          >
            <Typography fontSize={12} fontWeight="bold">
              {product.name}
            </Typography>
          </Box>
        </Grid>
        <Grid container width="100%">
          <Grid item style={{ width: 64, height: 64 }}>
            {image && <ProductImage src={image} alt={product.name} size={64} />}
          </Grid>

          <Grid>
            <TextGrid text={`売価: ${product.price}円`} />
            <TextGrid text={`売上: ${formatNum(product.sales1w, 1)}円`} />
            <TextGrid
              text={`販売率: ${formatNum(100 * product.adaptRatio1w)}%`}
            />
          </Grid>
        </Grid>

        <Grid minHeight="27px" width="100%">
          <ProductTagChips product={product} />
        </Grid>
        <TextGrid
          text={`ゴンドラ内構成比: ${formatNum(
            100 * product.compositionRatio1w
          )} %`}
          sx={{ bgcolor: compositionRatioColor(product.compositionRatio1w) }}
        />
        <TextGrid text={`商品コード: ${product.itemCd}`} />
        <TextGrid
          text={
            isStandardModegonProduct(modegon)
              ? `フェイス数: ${modegon.faces}, 幅: ${product.width / 10} cm`
              : isBevModegonProduct(modegon)
              ? `幅 ${product.width / 10} cm, 奥行き: ${product.depth / 10} cm`
              : ''
          }
        />

        <Grid
          width="100%"
          alignItems="space-between"
          display="flex"
          justifyContent="space-evenly"
        >
          <IconButton sx={{ padding: '4px' }} onClick={() => onDetailClick()}>
            <AnalyticsOutlined />
          </IconButton>

          <IconButton
            sx={{ padding: '4px' }}
            aria-describedby={popOverId}
            onClick={onCommentClick}
          >
            <Badge color="error" variant="dot" invisible={!commentBadgeVisible}>
              <AddCommentRounded />
            </Badge>
          </IconButton>

          <IconButton
            sx={{ padding: '4px' }}
            onClick={() => {
              onEditClick();
            }}
          >
            <Edit fontSize="small" />
          </IconButton>

          <IconButton
            sx={{ padding: '4px' }}
            onClick={() => {
              onDeleteClick();
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Grid>
        <Grid width="100%" display="flex" justifyContent="space-evenly">
          <IconButton
            sx={{ padding: '4px' }}
            onClick={() => {
              onMoveClick('left');
            }}
          >
            <KeyboardArrowLeft fontSize="small" />
          </IconButton>

          <IconButton
            sx={{ padding: '4px' }}
            onClick={() => {
              onMoveClick('up');
            }}
          >
            <KeyboardArrowUp fontSize="small" />
          </IconButton>

          <IconButton
            sx={{ padding: '4px' }}
            onClick={() => {
              onMoveClick('down');
            }}
          >
            <KeyboardArrowDown fontSize="small" />
          </IconButton>

          <IconButton
            sx={{ padding: '4px' }}
            onClick={() => {
              onMoveClick('right');
            }}
          >
            <KeyboardArrowRight fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>

      <Popover
        id={popOverId}
        open={IsPopoverOpen}
        anchorEl={commentPopoverAnchor}
        onClose={onCommentPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {/* <Typography p={1} fontSize={14}>
            コメントを追加
          </Typography> */}
        <Box
          component="form"
          noValidate
          autoComplete="off"
          width="300px"
          display="flex"
          flexDirection="column"
          sx={{ marginLeft: 1, marginRight: 1 }}
        >
          <TextField
            sx={{ flexGrow: '1' }}
            size="small"
            multiline
            maxRows={4}
            margin="normal"
            placeholder="コメントを追加"
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
          />

          <Button
            variant="contained"
            size="small"
            onClick={onCommentSubmitClick}
          >
            コメント
          </Button>
          <br />
        </Box>
      </Popover>

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`「${product.name}」をモデゴンから削除しますか?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleRemoveDialogClose(true);
            }}
          >
            はい
          </Button>

          <Button
            onClick={() => {
              handleRemoveDialogClose(false);
            }}
            autoFocus
          >
            いいえ
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
