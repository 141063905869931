/* eslint filename-consistency/match: off -- ファイル名変更に伴い、呼び出し元もLintによる修正をする必要があるため */

// Imports
// Custom made components import
import { realogramDirectorySearchQueryKey } from '@api/index';
import { queryClient } from '@api/query-client';
import { realogramsApi } from '@api/services/realogramDirectories';
import { ShelvesViewToggle } from '@components/molecules';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import { AppBar, DeleteRealogramDialog } from '@components/organisms';
import {
  GridModeSort,
  ListOrder,
} from '@components/organisms/gridModeSort/gridModeSort';
import { RealogramsSidebar } from '@components/organisms/realogramsSidebar/realogramsSidebar';
import { ScanPermissionDialog } from '@components/organisms/scanPermissionDialog/scanPermissionDialog';
import { useRerenderingScanner } from '@hooks/rerenderingComponents';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { Box, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  setLastVisitedRealogramListURL,
  setRealogramScrollPos,
  setRealogramsPageNumber,
  setSelectedDirectoryId,
} from '@reducers/scanner';
import { selectRealogramsScrollPos } from '@reducers/scanner/selectors';
import { setDirectoryId, setDirectoryType } from '@reducers/sharePermission';
import { useLazyListStoresQuery } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import {
  SidebarValue,
  getListPageTitle,
  getSortOptions,
  pageTitle,
  paths,
  toastMessages,
} from '@utils/const';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DirectoryBreadcrumbs,
  DirectoryType,
  ParentDirectory,
} from 'types/common';
import { RealogramDirectory, RealogramListOrder } from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { SearchText } from '../planograms/fragments/searchText';
import { realogramDirectoriesQueryKey } from '../storeBays';
import { RealogramsDirectoryGrid } from './fragments/ralogramsDirectoryGrid/realogramsDirectoryGrid';
import { RealogramsDirectoryTable } from './fragments/realogramsDirectoryTable/realogramsDirectoryTable';
import { RealogramSearchDialog } from './fragments/realogramSearchDialog/realogramSearchDialog';
import { SearchTipsArea } from './fragments/realogramSearchDialog/searchTipsArea';
import { useRealogramList } from './hooks/useRealogramList';
import { addMeToSelectItems, getDirectoryPageTitle } from './utils';

const queryList = ['owner_id', 'name'];

export const directoryTypes = [
  {
    label: 'すべて',
    value: 'all',
  },
  {
    label: '地域・店舗・什器',
    value: 'directory',
  },
  {
    label: '棚割実績',
    value: 'file',
  },
];
const tableCellHeight = 56;

export const Realograms: FC = () => {
  usePageTitle('スキャン結果一覧');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setSessionLastVisitedRealogramListURL] = useSessionStorage<string>(
    'sessionLastVisitedRealogramListURL'
  );
  const { selectedDirectoryId } = useAppSelector((state) => state.Scanner);
  const { isViewFolder } = useAppSelector((state) => state.SharePermission);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = useState(false);
  const [currentRealogramId, setCurrentRealogramId] = useState<number>();
  const ref = useRef<HTMLElement | Window | null>(null);
  const { pathname, search } = location;
  const currentUrl = pathname + search;
  const params = useParams();
  const directoryId = params.id;
  const { hasQueryParameters } = useQueryParameterPresence();
  const hasQueries = hasQueryParameters(queryList);
  const emptyTableMessage = hasQueries
    ? '条件を変更してください。'
    : '左上の「新規スキャン」ボタンから追加してください。';

  const [fetchStoreData] = useLazyListStoresQuery();

  const realogramScrollPos = useAppSelector(selectRealogramsScrollPos);
  const { showLicenseModal } = useSharePermissionModal();
  // make URL from search conditions, get sidebar data
  const {
    viewMode,
    condition,
    setViewMode,
    setCondition,
    conditionData: { userData, users },
    sidebarData: {
      sidebarValue,
      isFilteredByFavorite,
      isFilteredByLatest,
      isFilteredByViewed,
      defaultFirstOrderBySideBar,
    },
  } = useRerenderingScanner({
    directoryId,
  });
  const isGridMode = viewMode === 'grid';
  // get realograms list, and handle search realogram
  const {
    // not search
    directory,
    // search
    searchedData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,

    isSearching,
    realogramDirectories,
    isLoadingData,
    isFetchingData,
    isRoot,
    isShowBreadcrumbList,
    isDisplayLoadingSkeleton,
    hasCondition,
    isOpen,
    isShowFolderTip,

    folders,
    ownerIdCondition,
    setDirectoryIdCondition,
    setIsStarCondition,
    setOwnerIdCondition,
    setDirectoryTypeCondition,
    directoryTypeCondition,
    directoryIdCondition,
    isStarCondition,

    //handlers
    onSubmit,
    clearTextSearch,
    handleResetConditions,
    handleConditionModalOpen,
    setSearchText,
    handleDeleteTip,
    handleSubmitCondition,
    setIsOpen,
  } = useRealogramList({
    condition,
    userId: userData?.user.id,
    directoryId,
    isFilteredByLatest,
    isFilteredByFavorite,
    isFilteredByViewed,
    firstOrder: defaultFirstOrderBySideBar['all'],
    setCondition,
    ref,
    sidebarValue,
  });
  const directoryName = condition.directoryName ?? '';
  const noSearchResult =
    !(directory?.realogram_directories.length || searchedData?.length) &&
    !isLoadingData &&
    !isFetchingData &&
    isGridMode;
  const { mutateAsync: deleteRealogram } = useMutation({
    mutationFn: (id: number) => realogramsApi.deleteRealogram(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
    },
  });

  const { mutateAsync: addRealogramToFavorite } = useMutation({
    mutationFn: realogramsApi.addRealogramToFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });
  const { mutateAsync: deleteRealogramFromFavorite } = useMutation({
    mutationFn: realogramsApi.deleteRealogramFromFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });

  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });

  useEffect(() => {
    if (
      !selectedDirectoryId &&
      !isViewFolder &&
      directory &&
      directory.parent.type !== 'root'
    ) {
      dispatch(setSelectedDirectoryId(directory.parent.id));
    }
  }, [directory, selectedDirectoryId, dispatch, isViewFolder]);

  /**
   * Navigates to scanner page if the user has permission to view at least one store,
   * otherwise opens a permission dialog.
   */
  const navigateToScanner = async () => {
    dispatch(setRealogramsPageNumber(1));
    dispatch(updateLoadingIndicatorState(true));
    const stores = await fetchStoreData({
      filterByRole: 'editor',
    });
    if (stores.data?.stores?.length) {
      navigate(paths.actuals.scan);
    } else {
      setIsPermissionDialogOpen(true);
    }
    dispatch(updateLoadingIndicatorState(false));
  };
  /**
   * This function is used to handle the click event for a directory.
   * It takes in a RealogramDirectory as an argument and dispatches an action to update the selectedDirectoryId state.
   * It also dispatches an action to set the realogramsScrollPos state to 0 and scrolls the ref.current to the top of the page.
   * @param {RealogramDirectory} item - The RealogramDirectory that was clicked.
   */
  const handleClickDirectory = (item: RealogramDirectory) => {
    const directoryId = item.id;
    dispatch(setSelectedDirectoryId(directoryId));

    dispatch(setRealogramScrollPos(0));
    ref.current?.scrollTo({ top: 0 });
  };

  const handleScrollerRef = (refTable: HTMLElement | Window | null) => {
    if (!refTable) return;
    ref.current = refTable;
  };

  /**
   * This function is used to handle the click event for a realogram.
   * keep scroll position
   */
  const handleClickFile = () => {
    dispatch(
      setRealogramScrollPos((ref.current as HTMLElement)?.scrollTop ?? 0)
    );
    dispatch(setLastVisitedRealogramListURL(currentUrl));
    setSessionLastVisitedRealogramListURL(currentUrl);
  };

  const handleRowClick = (item: RealogramDirectory) => {
    if (item.type === 'directory') {
      handleClickDirectory(item);

      setCondition(
        {
          ...condition,
          isStar: undefined,
          isSearching: undefined,
          firstOrder: undefined,
        },
        { directoryId: item.id }
      );
    } else if (item.type === 'file' && item.realogram_candidate_id) {
      handleClickFile();
    }
  };
  const handleOpenDeleteDialog = (id: number) => {
    setDeleteDialogOpen(true);
    setCurrentRealogramId(id);
  };

  const handleDeleteRealogram = async () => {
    setDeleteDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteRealogram(currentRealogramId ?? 0);
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteScanResult,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteScanResult,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const handleClickMoveParentDirectory = (parentId?: string) => {
    const isBackToRootDirectory =
      directory?.breadcrumb?.length === 1 || !parentId;
    const directoryId = isBackToRootDirectory ? '' : parentId;

    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
    dispatch(setRealogramScrollPos(0));
    ref.current?.scrollTo({ top: 0 });
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isBackToRootDirectory = directory.type === 'root';
    const directoryId = isBackToRootDirectory ? '' : directory.id;
    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
    dispatch(setRealogramScrollPos(0));
    ref.current?.scrollTo({ top: 0 });
  };

  const afterHandleChangeBreadCrumb = (data: {
    isBackToRootDirectory: boolean;
    hasCondition: boolean;
    directoryId: string;
  }) => {
    const { directoryId, isBackToRootDirectory } = data;
    dispatch(setSelectedDirectoryId(directoryId));
    const newCondition = {
      ...condition,
      name: undefined,
    };
    if (isBackToRootDirectory) {
      setCondition(newCondition, {
        isToRootDirectory: true,
      });
    } else {
      setCondition(newCondition, {
        directoryId,
      });
    }
  };

  const handleChangeOrder = (firstOrder?: ListOrder) => {
    const newFirstOrder =
      isFilteredByViewed && !firstOrder
        ? defaultFirstOrderBySideBar['viewed']
        : firstOrder;
    setCondition({
      ...condition,
      firstOrder: newFirstOrder as RealogramListOrder,
    });
  };

  const handleFavoriteClick = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await deleteRealogramFromFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.removeStar,
          })
        );
      } else {
        await addRealogramToFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.addStar,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSidebar = (value: SidebarValue) => {
    const firstOrder = defaultFirstOrderBySideBar[value];
    dispatch(setSelectedDirectoryId(''));
    ref.current?.scrollTo({ top: 0 });
    if (isSearching) {
      handleResetConditions();
    }
    if (value === 'favorite') {
      setCondition(
        {
          name: undefined,
          isStar: true,
          ownerId: undefined,
          status: undefined,
          firstOrder,
          directoryType: undefined,
          isSearching: undefined,
        },
        {
          sideBarValue: value,
        }
      );
    } else {
      setCondition(
        {
          ...condition,
          name: undefined,
          ownerId: undefined,
          status: undefined,
          firstOrder: firstOrder,
          isStar: undefined,
          directoryType: undefined,
          isSearching: undefined,
        },
        {
          sideBarValue: value,
        }
      );
    }
  };

  const handleEndReached = (isAtBottom: boolean) => {
    const tableHeight =
      document.querySelector('.MuiTableBody-root')?.clientHeight ?? 0;
    if (
      !isAtBottom ||
      !hasNextPage ||
      isFetchingNextPage ||
      // NOTE: This condition is to prevent fetching next page when initializing table
      tableHeight <= tableCellHeight
    )
      return;
    fetchNextPage();
  };

  const handleSharePermission = (item: RealogramSharePermissionProps) => {
    if (!item.directoryId) return;
    dispatch(setDirectoryId(`${item?.directoryId}`));
    dispatch(setDirectoryType('realogram'));
    showLicenseModal({
      type: item.type,
    });
  };

  return (
    <>
      <AppBar title="棚割実績（棚スキャナ）" />
      <Box component="div" display="flex">
        <RealogramsSidebar
          navigateToScanner={navigateToScanner}
          sidebarValue={sidebarValue}
          onClickTabAll={() => handleClickSidebar('all')}
          onClickTabFavorite={() => handleClickSidebar('favorite')}
          onClickTabLatest={() => handleClickSidebar('latest')}
          onClickTabViewed={() => handleClickSidebar('viewed')}
          notActivate={!!isSearching}
        />
        <Box component="div" mt={2} px={3} flex={1}>
          {/*56px = 40 AppBar + 16px margin*/}
          <Box
            component="div"
            width="100%"
            height="calc(100vh - 57px)"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Box component="div" display="flex" justifyContent="space-between">
              <SearchText
                key={condition.name}
                searchText={condition.name}
                handleSearch={onSubmit}
                clearSearch={clearTextSearch}
                isShowSearch
                handleConditionModalOpen={handleConditionModalOpen}
                setSearchText={setSearchText}
              />
              <ShelvesViewToggle
                mode={viewMode}
                onChange={(mode) => {
                  setViewMode(mode);
                }}
              />
            </Box>

            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={-1}
            >
              {isShowBreadcrumbList && !isSearching ? (
                <Box component="div">
                  {!isRoot && (
                    <Box component="div" marginLeft={5}>
                      <BreadcrumbList
                        breadcrumbs={
                          directory?.breadcrumb as unknown as DirectoryBreadcrumbs
                        }
                        parentDirectory={
                          directory?.parent as unknown as ParentDirectory
                        }
                        screenType="Main"
                        fontSize="12px"
                        handleClickBreadcrumbs={handleClickBreadcrumbs}
                        directoryRootName="スキャン結果"
                      />
                    </Box>
                  )}
                  <Box component="div" display="flex" alignItems="center">
                    {!isRoot && (
                      <GoParentDirectoryButton
                        width={32}
                        height={32}
                        handleClick={() => {
                          handleClickMoveParentDirectory(
                            directory?.parent.parent_id
                          );
                        }}
                      />
                    )}
                    <Typography
                      fontSize="20px"
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                      mr={!isRoot ? '6px' : ''}
                    >
                      {getDirectoryPageTitle(
                        sidebarValue,
                        isRoot,
                        directory?.parent?.name ?? ''
                      )}
                    </Typography>
                    {!isRoot && (
                      <ActionVisible
                        isCanUpdate={isCanUpdate}
                        isCanRead={isCanRead}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  {isSearching && sidebarValue === 'all' ? (
                    <Box
                      component="div"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap="16px"
                      height="52px"
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        {getListPageTitle({
                          defaultTitle: pageTitle.planogram,
                          sidebarValue,
                          isSearched: isSearching,
                        })}
                      </Typography>
                      <SearchTipsArea
                        isShowFolderTip={isShowFolderTip}
                        directoryName={directoryName}
                        condition={condition}
                        users={users}
                        handleConditionModalOpen={handleConditionModalOpen}
                        handleDeleteTip={handleDeleteTip}
                      />
                    </Box>
                  ) : (
                    <Typography fontWeight="bold" fontSize="20px">
                      {getListPageTitle({
                        defaultTitle: pageTitle.realogram,
                        sidebarValue,
                        isSearched: !!condition?.name,
                      })}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            {isGridMode && (
              <GridModeSort
                sortOptions={getSortOptions(
                  'realogram',
                  sidebarValue === 'viewed'
                )}
                isLoadingData={isFetchingData || isLoadingData}
                firstOrder={condition?.firstOrder}
                handleChangeOrder={handleChangeOrder}
              />
            )}

            {noSearchResult ? (
              <Box component="div" height="calc(100% - 90px)">
                <EmptyResult
                  title="スキャン結果がありません"
                  message={emptyTableMessage}
                  isErrorIcon
                />
              </Box>
            ) : (
              <Box component="div" mb={2} height="100%">
                {!isGridMode ? (
                  <RealogramsDirectoryTable
                    isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
                    isEmpty={!realogramDirectories?.length && !isLoadingData}
                    isRefetching={isFetchingData}
                    realogramDirectories={
                      realogramDirectories as RealogramDirectory[]
                    }
                    isFilteredByViewed={isFilteredByViewed}
                    firstOrder={condition.firstOrder}
                    viewMode={viewMode}
                    handleRowClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleChangeOrder={handleChangeOrder}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                    emptyTableMessage={emptyTableMessage}
                    emptyTableTitle="スキャン結果がありません"
                    handleScrollerRef={handleScrollerRef}
                    scrollPos={realogramScrollPos}
                  />
                ) : (
                  <RealogramsDirectoryGrid
                    scrollPos={realogramScrollPos}
                    handleScrollerRef={handleScrollerRef}
                    isLoading={
                      (isLoadingData || isFetchingData) && !isFetchingNextPage
                    }
                    isRefetching={isFetchingNextPage}
                    realogramDirectories={
                      realogramDirectories as RealogramDirectory[]
                    }
                    viewMode={viewMode}
                    isFilteredByViewed={isFilteredByViewed}
                    handleCardClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <DeleteRealogramDialog
        open={deleteDialogOpen}
        handleClickCancelButton={() => setDeleteDialogOpen(false)}
        handleClickConfirmButton={handleDeleteRealogram}
      />
      <ScanPermissionDialog
        open={isPermissionDialogOpen}
        handleCancel={() => setIsPermissionDialogOpen(false)}
      />

      <RealogramSearchDialog
        handleSubmit={handleSubmitCondition}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        handleResetConditions={handleResetConditions}
        folders={folders}
        me={userData?.user}
        users={addMeToSelectItems(users, userData?.user)}
        setDirectoryIdCondition={setDirectoryIdCondition}
        setIsStarCondition={setIsStarCondition}
        setOwnerIdCondition={setOwnerIdCondition}
        setDirectoryTypeCondition={setDirectoryTypeCondition}
        directoryTypeCondition={directoryTypeCondition}
        directoryIdCondition={directoryIdCondition}
        isStarCondition={isStarCondition}
        ownerIdCondition={ownerIdCondition}
      />
    </>
  );
};
