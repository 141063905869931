import { ListRowOptionalItem } from '@components/organisms/realogramCandidatesList/fragments';
import { ShelfListItem } from '@components/organisms/shelfListItem/shelfListItem';
import { getProductDisplayValues } from '@utils/product';
import { MutableRefs } from '@utils/realogram';
import { FC, memo, useState } from 'react';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import { ProductReport } from 'types/products';
import { RealogramCandidateFace, RealogramSelectedItem } from 'types/realogram';
import { DeleteFaceDialog } from './deleteFaceDialog';

type Props = {
  facesLength: number;
  products?: Product[];
  realogramDetailView: ShelfDetailView;
  selectedItem?: RealogramSelectedItem;
  handleClick: (item: RealogramSelectedItem) => void;
  refs: MutableRefs;
  handleCorrectDrawerOpen: (item: RealogramSelectedItem) => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  handleClickDeleteFace: (item: RealogramSelectedItem) => void;
  profitTab: ProfitTab;
  productReport?: ProductReport;
  isCanEditFace?: boolean;
  isCanDeleteFace?: boolean;
  isSKU?: boolean;
  isBucket?: boolean;
  isSelected: boolean;
  shelfBoardId: string;
  compartmentId: string;
  item: RealogramCandidateFace;
};

export const defaultHeight = 56;

export const ListRow: FC<Props> = memo(
  ({
    facesLength,
    products,
    realogramDetailView,
    handleClick,
    refs,
    handleClickDeleteFace,
    handleCorrectDrawerOpen,
    handleDivisionModalOpen,
    profitTab,
    productReport,
    isCanEditFace,
    isCanDeleteFace,
    isSKU = false,
    isBucket,
    isSelected,
    shelfBoardId,
    compartmentId,
    item,
  }) => {
    const realogramItem = {
      shelfBoardId,
      compartmentId,
      item,
    };

    const primaryCandidate = item.primary_candidate;
    const product = products?.find(
      (p) => p.id === primaryCandidate?.product_id
    );
    const refKey = `${shelfBoardId}-${compartmentId}-${item.id}`;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const { name, eanCode, productCode } = getProductDisplayValues(
      item,
      product
    );

    return (
      <>
        <ShelfListItem
          isBucket={isBucket}
          refs={refs}
          view={realogramDetailView}
          face={item}
          refKey={refKey}
          product={product}
          isSelected={isSelected}
          handleClick={() => {
            handleClick(realogramItem);
          }}
          name={name}
          eanCode={eanCode}
          productCode={productCode}
          isSKU={isSKU}
        >
          <ListRowOptionalItem
            realogramDetailView={realogramDetailView}
            face={item}
            primaryCandidate={primaryCandidate}
            facesLength={facesLength}
            menuOpen={menuOpen}
            handleClick={() => {
              handleClick(realogramItem);
            }}
            handleMenuOpen={() => setMenuOpen(true)}
            handleMenuClose={() => setMenuOpen(false)}
            handleDialogOpen={() => {
              setDialogOpen(true);
              setMenuOpen(false);
            }}
            handleCorrectDrawerOpen={() =>
              handleCorrectDrawerOpen(realogramItem)
            }
            handleDivisionModalOpen={handleDivisionModalOpen}
            profitTab={profitTab}
            productReport={productReport}
            isCanEditFace={isCanEditFace}
            isCanDeleteFace={isCanDeleteFace}
            isSelected={isSelected}
            isSKU={isSKU}
            isBucket={isBucket}
          />
        </ShelfListItem>
        <DeleteFaceDialog
          open={dialogOpen}
          isCorrectable={
            item.in_stock || (!item.in_stock && !!item.primary_candidate)
          }
          handleClose={() => {
            setDialogOpen(false);
          }}
          handleCorrectDrawerOpen={() => handleCorrectDrawerOpen(realogramItem)}
          handleClickDeleteFace={() => handleClickDeleteFace(realogramItem)}
        />
      </>
    );
  }
);
ListRow.displayName = 'ListRow';
