import { Box } from '@mui/material';
import { ShelvesViewToggle } from '@components/molecules';
import { PlanogramSearchDialog } from './planogramSearchDialog';
import { ShelvesViewMode } from 'types/common';
import { User } from 'types/user';
import { SearchInput } from './searchInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import {
  BayPlanCodes,
  FilterCondition,
  FormData,
  OrganizationStatuses,
  schema,
} from '../types';

type SearchHeaderProps = {
  // SearchInput props
  filterCondition: FilterCondition;
  setFilterCondition: (
    value: FilterCondition,
    options?: { isToRootDirectory: boolean }
  ) => void;
  handleSearchText: (text: string) => void;
  handleConditionModalOpen: () => void;

  // PlanogramSearchDialog props
  isConditionModal: boolean;
  folders: { id: string; label: string }[];
  me?: User;
  users?: User[];
  bayPlanCodes?: BayPlanCodes;
  organizationStatuses?: {
    planogram_organization_statuses: OrganizationStatuses;
  };
  directoryIdCondition: string;
  setDirectoryIdCondition: (value: string) => void;
  isStarCondition: boolean;
  setIsStarCondition: (value: boolean) => void;
  ownerIdCondition?: number;
  setOwnerIdCondition: (value?: number) => void;
  bayPlanIdCondition?: number;
  setBayPlanIdCondition: (value?: number) => void;
  statusCondition?: number;
  setStatusCondition: (value?: number) => void;
  handleResetConditions: () => void;
  handleConditionSubmit: (keyword: string) => void;
  viewMode: ShelvesViewMode;
  setViewMode: (mode: ShelvesViewMode) => void;
  setIsConditionModal: (value: boolean) => void;
};

export const SearchHeader: React.FC<SearchHeaderProps> = ({
  // SearchInput props
  filterCondition,
  setFilterCondition,
  handleSearchText,
  handleConditionModalOpen,

  // PlanogramSearchDialog props
  isConditionModal,
  folders,
  me,
  users,
  bayPlanCodes,
  organizationStatuses,
  directoryIdCondition,
  setDirectoryIdCondition,
  isStarCondition,
  setIsStarCondition,
  ownerIdCondition,
  setOwnerIdCondition,
  bayPlanIdCondition,
  setBayPlanIdCondition,
  statusCondition,
  setStatusCondition,
  handleResetConditions,
  handleConditionSubmit,

  // ShelvesViewToggle props
  viewMode,
  setViewMode,

  // New props
  setIsConditionModal,
}) => {
  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: filterCondition?.name ?? undefined,
    },
  });

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleReset = () => {
    setIsFocused(false);
    setValue('searchText', '');
    setFilterCondition(
      {
        ...filterCondition,
        name: undefined,
      },
      {
        isToRootDirectory: false,
      }
    );
  };

  const usersOption = me
    ? [
        {
          id: me?.id,
          label: `自分 (${me?.fullname})`,
        },
        ...(users?.map((user) => ({
          id: user.id,
          label: user.fullname,
        })) ?? []),
      ]
    : users?.map((user) => ({
        id: user.id,
        label: user.fullname,
      })) ?? [];
  return (
    <Box
      width="100%"
      component="div"
      mb={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <SearchInput
        handleSearchText={handleSearchText}
        handleConditionModalOpen={handleConditionModalOpen}
        control={control}
        handleSubmit={handleSubmit}
        handleFocus={handleFocus}
        handleReset={handleReset}
        isFocused={isFocused}
      />
      <PlanogramSearchDialog
        isOpen={isConditionModal}
        folders={folders}
        me={me}
        users={usersOption}
        bayPlans={
          bayPlanCodes?.map((bay) => ({
            id: bay.id,
            label: bay.name,
          })) ?? []
        }
        status={organizationStatuses?.planogram_organization_statuses ?? []}
        directoryIdCondition={directoryIdCondition}
        setDirectoryIdCondition={setDirectoryIdCondition}
        isStarCondition={isStarCondition}
        setIsStarCondition={setIsStarCondition}
        ownerIdCondition={ownerIdCondition}
        setOwnerIdCondition={setOwnerIdCondition}
        bayPlanIdCondition={bayPlanIdCondition}
        setBayPlanIdCondition={setBayPlanIdCondition}
        statusCondition={statusCondition}
        setStatusCondition={setStatusCondition}
        handleClose={() => setIsConditionModal(false)}
        handleResetConditions={handleResetConditions}
        handleSubmit={() => handleConditionSubmit(getValues('searchText'))}
      />
      <ShelvesViewToggle
        mode={viewMode}
        onChange={(mode) => {
          setViewMode(mode);
        }}
      />
    </Box>
  );
};
