import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { ModelGondolasPatternList, Store, WeekModelGondolas } from '../types';
import { SakuraModal } from './modal';

export type PatternChoice = {
  patternLabel: string;
  choiceName: string;
};

type ModelGondolaPatternSelectorProps = {
  store: Store;
  patternList: ModelGondolasPatternList;
  chosenPattern: PatternChoice | undefined;
  setChosenPattern: (_: PatternChoice) => void;
};

const ModelGondolaPatternSelector = ({
  patternList,
  chosenPattern,
  setChosenPattern,
}: ModelGondolaPatternSelectorProps) => {
  return (
    <FormControl fullWidth key={patternList.label}>
      <InputLabel id={`select-pattern-label-${patternList.label}`}>
        {patternList.label}
      </InputLabel>
      <Select
        labelId={`select-pattern-label-${patternList.label}`}
        id={`select-pattern-${patternList.label}`}
        value={chosenPattern?.choiceName || ''}
        label={patternList.label}
        onChange={(e) => {
          const value = e.target.value as string;
          if (patternList.choices.find((choice) => choice.name === value)) {
            setChosenPattern({
              patternLabel: patternList.label,
              choiceName: value,
            });
          }
        }}
      >
        {patternList.choices.map((choice) => (
          <MenuItem key={choice.name} value={choice.name}>
            {choice.name}の構成
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type PatternSelectorModalProps = {
  store: Store;
  weekModelGondolas: WeekModelGondolas;
  patternChoices: PatternChoice[];
  setPatternChoices: (_: PatternChoice[]) => void;
};

export const PatternSelector = ({
  store,
  weekModelGondolas,
  patternChoices,
  setPatternChoices,
}: PatternSelectorModalProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tmpPatternChoices, setTmpPatternChoices] = useState<PatternChoice[]>(
    []
  );

  const setChosenPattern = (patternChoice: PatternChoice) => {
    const newPatternChoices = tmpPatternChoices.filter(
      (choice) => choice.patternLabel !== patternChoice.patternLabel
    );
    setTmpPatternChoices([...newPatternChoices, patternChoice]);
  };
  useEffect(() => {
    setTmpPatternChoices(patternChoices);
  }, [patternChoices]);

  const title = patternChoices
    .map((choice) => `${choice.patternLabel}：${choice.choiceName}の構成`)
    .join('、');

  return (
    <>
      <Button onClick={handleOpen}>什器構成 {title}</Button>
      <SakuraModal
        title="什器パターンの選択"
        open={open}
        handleClose={handleClose}
      >
        <Box component="div" width="400px">
          <Stack spacing={4}>
            <Typography variant="body3">
              複数の什器構成に基づくモデルゴンドラが配信されています。
              訪問店舗の什器構成に最も近いものを選択し閲覧することができます。
            </Typography>
            {weekModelGondolas.patternedGondolas.map((pattern) => {
              const chosenPattern = tmpPatternChoices.find(
                (choice) => choice.patternLabel === pattern.label
              );
              return (
                <ModelGondolaPatternSelector
                  key={pattern.label}
                  store={store}
                  patternList={pattern}
                  chosenPattern={chosenPattern}
                  setChosenPattern={setChosenPattern}
                />
              );
            })}
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  setPatternChoices(tmpPatternChoices);
                  handleClose();
                }}
              >
                確定
              </Button>
            </Stack>
          </Stack>
        </Box>
      </SakuraModal>
    </>
  );
};

export const filterGondolasByChosenPatterns = (
  weekModelGondolas: WeekModelGondolas,
  patternChoices: PatternChoice[]
) => {
  const chosenGondolas = patternChoices
    .map(
      (patternChoice) =>
        weekModelGondolas?.patternedGondolas
          .find((pattern) => pattern.label === patternChoice.patternLabel)
          ?.choices.find((choice) => choice.name === patternChoice.choiceName)
          ?.gondolas || []
    )
    .flat();
  const gondolas =
    (weekModelGondolas && [
      ...weekModelGondolas.commonGondolas,
      ...chosenGondolas,
    ]) ||
    [];
  return gondolas;
};
