import { StoreBayChangeButtonState } from '@components/pages/scannerDetails/hooks/useChangeStoreBay';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { theme } from 'theme';

type Props = {
  isChangeButtonActive?: StoreBayChangeButtonState;
  getPreviousStoreBay?: VoidFunction;
  getNextStoreBay?: VoidFunction;
};
export const StoreBayButton = ({
  isChangeButtonActive,
  getPreviousStoreBay,
  getNextStoreBay,
}: Props) => {
  return (
    <>
      <Box
        component="div"
        sx={{ position: 'absolute', bottom: '84px', left: '16px' }}
      >
        <IconButton
          edge="start"
          sx={{
            width: 40,
            height: 40,
            border: `1px solid ${theme.palette.dividerBlack.dark}`,
            backgroundColor: '#fff',
            borderRadius: 1,
            ml: 0,
            mr: 2,
            '&:hover': {
              backgroundColor: '#fff',
            },
            color: !isChangeButtonActive?.previous
              ? theme.palette.textBlack.disabled
              : theme.palette.textBlack.secondary,
          }}
          onClick={() =>
            isChangeButtonActive?.previous &&
            getPreviousStoreBay &&
            getPreviousStoreBay()
          }
        >
          <KeyboardArrowLeft />
        </IconButton>
      </Box>
      <Box
        component="div"
        sx={{ position: 'absolute', bottom: '84px', right: '16px' }}
      >
        <IconButton
          edge="start"
          sx={{
            width: 40,
            height: 40,
            border: `1px solid ${theme.palette.dividerBlack.dark}`,
            backgroundColor: '#fff',
            borderRadius: 1,
            ml: 0,
            mr: 2,
            '&:hover': {
              backgroundColor: '#fff',
            },
            color: !isChangeButtonActive?.next
              ? theme.palette.textBlack.disabled
              : theme.palette.textBlack.secondary,
          }}
          onClick={() =>
            isChangeButtonActive?.next && getNextStoreBay && getNextStoreBay()
          }
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </>
  );
};
