import { Box, Chip, List, Stack, Typography } from '@mui/material';
import { AssortmentTag, Product, ShelfDetailView } from 'types/common';
import {
  RealogramCandidate,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import {
  ModelGondolaWithPatternLabel,
  useModelGondolaAndAllProductsContext,
} from '../../utils/modelGondolaAndAllProductsProvider';
import { ShelfListHeader } from '@components/organisms/shelfListHeader/shelfListHeader';
import {
  AssortmentListRow,
  AssortmentListRowAbsentProduct,
} from './assortmentListRow';
import { MutableRefs } from '@utils/realogram';
import { useAllProductsContext } from '@utils/ProductsContext';
import { listModelGondolaProducts } from '@components/pages/sakura/sakuraUtils';
import { AssortmentDetailTabs } from './assortmentDetailTabs';
import { assortmentTags, getAssortmentTagName } from '@utils/const';
import { ModelGondola } from '@components/pages/sakura/types';
import { ReactNode, useState, useEffect } from 'react';
import { BoxWidthDivider } from '@components/organisms';
import { assortmentWidthList } from './constants';
import { gondolaPmas } from '@utils/gondolas.json';
import { theme } from 'theme';
import { ModelGondolasSimplifiedViewer } from './modelGondolasSimplifiedViewer';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { useIndexedPdfContext } from '@components/pages/sakura/fragments/pdf/indexedPdf';
import { useAppSelector } from '@store/index';
import { selectToken } from '@reducers/auth/selectors';
import { t } from 'i18next';

const getItemCdsFromRealogramCandidate = (
  realogramCandidate: RealogramCandidate,
  products: Product[]
): string[] => {
  const shelfBoards = realogramCandidate.detail?.products_shelves.shelf_boards;
  const itemCds = shelfBoards
    ?.flatMap((shelf) =>
      shelf.compartments.flatMap((compartment) =>
        compartment.faces.map(
          (face) =>
            products.find((p) => p.id === face.primary_candidate?.product_id)
              ?.detail?.organization_product_id
        )
      )
    )
    .filter((itemCd) => itemCd);
  return itemCds ? Array.from(new Set(itemCds as string[])) : [];
};

type Props = {
  assortmentTag: AssortmentTag;
  products?: Product[];
  shelfBoards?: RealogramShelfBoard[];
  selectedItem?: RealogramSelectedItem;
  refs: MutableRefs;
  realogramDetailView: ShelfDetailView;
  handleClick: (item: RealogramSelectedItem) => void;
  handleChangeAssortmentTag: (tag: AssortmentTag) => void;
  realogramCandidateId: number;
};

const rankShowLimit = 5; // 売り上げ順位を表示する上限

export const AssortmentDetail = ({
  assortmentTag,
  products,
  shelfBoards,
  selectedItem,
  refs,
  realogramDetailView,
  handleClick,
  handleChangeAssortmentTag,
  realogramCandidateId,
}: Props) => {
  const modelGondolaContext = useModelGondolaAndAllProductsContext();
  const externalInfo = modelGondolaContext?.externalInfo;
  const relevantModelGondolas = modelGondolaContext?.relevantModelGondolas;
  const productSales = modelGondolaContext?.productSales;

  // ゴンドラに載りうる全商品
  const gondolaPossibleProducts = useAllProductsContext()?.products;
  const gondolaPossibleItemCds =
    gondolaPossibleProducts?.map((p) => p.detail?.organization_product_id) ||
    [];

  // 販促情報などのPDFはロードに時間がかかるためプリロードする
  // 品揃えの商品一覧やモデゴンビューをクリックすると実際の表示が行われる
  const { addUrl } = useIndexedPdfContext();
  const authToken = useAppSelector(selectToken);
  useEffect(() => {
    if (!externalInfo) {
      return;
    }
    const assetIds = [
      ...externalInfo.newItems.map((newItem) => newItem.pdfS3Id),
      ...externalInfo.annotations.map((annot) => annot.pdfS3Id),
    ];
    new Set(assetIds).forEach((assetId) => {
      addUrl(`/api/v1/sakura/assets/${assetId}.pdf`, authToken);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [externalInfo, addUrl]);

  // 同じ日の同じ店の他のrealogramを全部みて、置いてある全商品とそれがどのrealogramにあるか見る
  // このゴンドラの対象にならないような商品は含まれない
  // （gondolaPossibleItemCdsでフィルタしたProduct[]に入ってるものだけ返すため）
  const realogramCandidatesForTheDay =
    modelGondolaContext?.realogramCandidatesForTheDay || [];
  const itemCdsFromRealogramCandidates = realogramCandidatesForTheDay.flatMap(
    (realogramCandidate) =>
      getItemCdsFromRealogramCandidate(
        realogramCandidate,
        gondolaPossibleProducts || []
      ).map((itemCd) => ({ itemCd, realogramCandidate }))
  );

  /*
   * 集計ユーリティティ
   */

  const findRealogramItem = (product: Product) => {
    for (const shelf of shelfBoards || []) {
      for (const compartment of shelf.compartments) {
        for (const face of compartment.faces) {
          if (face.primary_candidate?.product_id === product.id) {
            return {
              shelfBoardId: shelf.id,
              compartmentId: compartment.id,
              item: face,
            } as RealogramSelectedItem;
          }
        }
      }
    }
    return undefined;
  };

  const classifyItemCdPresence = (itemCd: string) => {
    const product = gondolaPossibleProducts.find(
      (p) => p.detail?.organization_product_id === itemCd
    );
    const realogramItem = product && findRealogramItem(product);
    if (!realogramItem) {
      // 今表示しているrealogram上においては未陳列な商品たち
      const itemOnOtherRealogram = itemCdsFromRealogramCandidates.find(
        (item) => item.itemCd === itemCd
      );
      if (itemOnOtherRealogram) {
        // 他のrealogramに陳列されている商品
        const realogramCandidate = itemOnOtherRealogram.realogramCandidate;
        return {
          presence: 'displayedElsewhere' as const,
          sortKey: 1,
          itemCd,
          realogramCandidate,
        };
      } else {
        return {
          presence: 'notDisplayed' as const,
          sortKey: 0,
          itemCd,
        };
      }
    }
    // 今表示しているrealogram上において陳列されている商品
    return {
      presence: 'displayedHere' as const,
      sortKey: 2,
      itemCd,
      realogramSelectedItem: realogramItem,
    };
  };

  // 商品の表示状態を表す型
  type ItemPresence =
    | {
        presence: 'displayedHere';
        sortKey: number;
        itemCd: string;
        realogramSelectedItem: RealogramSelectedItem;
      }
    | {
        presence: 'displayedElsewhere';
        sortKey: number;
        itemCd: string;
        realogramCandidate: RealogramCandidate;
      }
    | {
        presence: 'notDisplayed';
        sortKey: number;
        itemCd: string;
      };

  const countItemsAbsent = (itemCds: string[]) =>
    itemCds.filter(
      (itemCd) => classifyItemCdPresence(itemCd).presence === 'notDisplayed'
    ).length;

  /*
   * すべてのタグについて集計を行う。タブに表示する数字に必要なので。
   */
  type AssortmentTagStat = {
    tag: AssortmentTag;
    itemCdsNeedToBeOnSale: string[];
    numAbsent: number;
  };
  const tagStats = new Map<AssortmentTag, AssortmentTagStat>();

  // 販促の重要度とか基本商品とかによって、販促商品の重要度を決める
  const itemCdToHansokuImportance = new Map<string, number>();
  [
    ...(externalInfo?.newItems || []),
    ...(externalInfo?.annotations || []),
  ].forEach((item) => {
    let importance = itemCdToHansokuImportance.get(item.itemCd) || 0;
    if (item.kindImportant) {
      importance += 1;
    }
    const product = gondolaPossibleProducts?.find(
      (p) => p.detail?.organization_product_id === item.itemCd
    );
    if (product?.detail?.tags?.includes(t('attributes.base_product'))) {
      importance += 0.1;
    }
    itemCdToHansokuImportance.set(item.itemCd, importance);
  });

  const itemCdsNew =
    externalInfo?.newItems
      .map((item) => item.itemCd)
      .sort((a, b) => {
        const importanceA = itemCdToHansokuImportance.get(a) || 0;
        const importanceB = itemCdToHansokuImportance.get(b) || 0;
        if (importanceA !== importanceB) {
          return importanceB - importanceA;
        }

        const salesA = productSales?.find((sale) => sale.itemCd === a);
        const salesB = productSales?.find((sale) => sale.itemCd === b);
        return (salesB?.doAdoptionRate || 0) - (salesA?.doAdoptionRate || 0);
      })
      .filter((itemCd) => gondolaPossibleItemCds.includes(itemCd)) || [];
  tagStats.set(t('assortment.new_sales'), {
    tag: t('assortment.new_sales'),
    itemCdsNeedToBeOnSale: itemCdsNew,
    numAbsent: countItemsAbsent(itemCdsNew),
  });

  const itemCdsHansoku = Array.from(
    new Set(
      externalInfo?.annotations
        .filter(
          (item) =>
            item.type === '情報パッケージ' &&
            gondolaPossibleItemCds.includes(item.itemCd)
        )
        .sort((a, b) => {
          const importanceA = itemCdToHansokuImportance.get(a.itemCd) || 0;
          const importanceB = itemCdToHansokuImportance.get(b.itemCd) || 0;
          return importanceB - importanceA;
        })
        .map((item) => item.itemCd) || []
    )
  );
  tagStats.set('販促商品', {
    tag: '販促商品',
    itemCdsNeedToBeOnSale: itemCdsHansoku,
    numAbsent: countItemsAbsent(itemCdsHansoku),
  });

  const itemCdsSns = Array.from(
    new Set(
      externalInfo?.annotations
        .filter(
          (item) =>
            item.type === 'SNS' && gondolaPossibleItemCds.includes(item.itemCd)
        )
        .map((item) => item.itemCd) || []
    )
  );
  tagStats.set('SNS話題', {
    tag: 'SNS話題',
    itemCdsNeedToBeOnSale: itemCdsSns,
    numAbsent: countItemsAbsent(itemCdsSns),
  });

  const itemCdsKihon =
    gondolaPossibleProducts
      ?.filter((product) =>
        (product.detail?.tags || []).includes(t('attributes.base_product'))
      )
      .filter(
        (product) =>
          !(product.detail?.tags || []).includes(t('attributes.sales_ended'))
      )
      .filter(
        (product) =>
          !(externalInfo?.cutItems || []).includes(
            product.detail?.organization_product_id || ''
          )
      )
      .sort((a, b) => {
        const itemCdA = a.detail?.organization_product_id || '';
        const itemCdB = b.detail?.organization_product_id || '';
        const importanceA = itemCdToHansokuImportance.get(itemCdA) || 0;
        const importanceB = itemCdToHansokuImportance.get(itemCdB) || 0;
        if (importanceA !== importanceB) {
          return importanceB - importanceA;
        }

        const salesA = productSales?.find((sale) => sale.itemCd === itemCdA);
        const salesB = productSales?.find((sale) => sale.itemCd === itemCdB);
        return (salesB?.doAdoptionRate || 0) - (salesA?.doAdoptionRate || 0);
      })
      .map((product) => product.detail?.organization_product_id || '') || [];
  tagStats.set(t('assortment.base_product'), {
    tag: t('assortment.base_product'),
    itemCdsNeedToBeOnSale: itemCdsKihon,
    numAbsent: countItemsAbsent(itemCdsKihon),
  });

  const itemCdsModelGondola = Array.from(
    new Set(
      relevantModelGondolas?.flatMap((gondola) =>
        listModelGondolaProducts(gondola as ModelGondola).map(
          (product) => product.productCode
        )
      ) || []
    )
  );

  const productSalesInGondola =
    productSales
      ?.filter((sale) => {
        return itemCdsModelGondola.includes(sale.itemCd);
      })
      .sort((a, b) => {
        const importanceA = itemCdToHansokuImportance.get(a.itemCd) || 0;
        const importanceB = itemCdToHansokuImportance.get(b.itemCd) || 0;
        if (importanceA !== importanceB) {
          return importanceB - importanceA;
        }
        return b.doGondolaShare - a.doGondolaShare;
      })
      .map((sale, index) => ({ ...sale, rank: index + 1 })) || [];

  tagStats.set(`${t('assortment.shelf_plan')}掲載`, {
    tag: `${t('assortment.shelf_plan')}掲載`,
    itemCdsNeedToBeOnSale: productSalesInGondola
      .sort((a, b) => {
        const va =
          a.rank <= rankShowLimit ? 100 - a.rank : 0 + a.doAdoptionRate;
        const vb =
          b.rank <= rankShowLimit ? 100 - b.rank : 0 + b.doAdoptionRate;
        return vb - va;
        // return b.doGondolaShare - a.doGondolaShare;
      })
      .map((sale) => sale.itemCd),
    numAbsent: countItemsAbsent(itemCdsModelGondola),
  });

  // タブに表示するカウント
  const tagCounts = assortmentTags.map((tag) => ({
    tag: getAssortmentTagName(tag),
    numItemsTotal: tagStats.get(getAssortmentTagName(tag))
      ?.itemCdsNeedToBeOnSale.length,
    numItemsAbsent: tagStats.get(getAssortmentTagName(tag))?.numAbsent,
  }));

  /*
   * メインコンテンツとして表示される部分
   */
  const itemCdsNeedToBeOnSale =
    tagStats
      .get(assortmentTag)
      ?.itemCdsNeedToBeOnSale.filter((itemCd: string) =>
        gondolaPossibleItemCds.includes(itemCd)
      ) || [];
  // これは、「置くべき商品の中でこのゴンドラの対象になる商品」を固めたものになってる

  const shortenGondolaName = (gondolaName: string, lenLimit = 100) =>
    lenLimit < gondolaName.length
      ? gondolaName.slice(0, lenLimit) + '...'
      : gondolaName;

  const rows = itemCdsNeedToBeOnSale
    .map((itemCd: string) => classifyItemCdPresence(itemCd))
    .sort((a: ItemPresence, b: ItemPresence) => a.sortKey - b.sortKey)
    .map((presence: ItemPresence) => {
      let modelGondolapresenceChip: ReactNode | null = null;
      const sales = productSalesInGondola?.find(
        (sale) => sale.itemCd === presence.itemCd
      );

      if (assortmentTag === `${t('assortment.shelf_plan')}掲載`) {
        if (itemCdsModelGondola.includes(presence.itemCd)) {
          // この商品を含んでるモデゴンを探す
          const gondolasWithLabel = relevantModelGondolas?.filter(
            (gondola) =>
              listModelGondolaProducts(gondola as ModelGondola).find(
                (p) => p.productCode === presence.itemCd
              ) !== undefined
          );
          if (gondolasWithLabel && 0 < gondolasWithLabel.length) {
            // この商品を含むモデゴンのなかで一番小さいパターンに属する子を探す
            const gondolaWithLabel = gondolasWithLabel.sort(
              (a, b) =>
                a.patternMeta.sizeIndicator - b.patternMeta.sizeIndicator
            )[0];

            const gondolaPma = gondolaPmas.find(
              (gondolaPma) => gondolaPma.code === gondolaWithLabel.gondolaCode
            );
            const gondolaName =
              gondolaPma &&
              shortenGondolaName(`${gondolaPma.origCode}${gondolaPma.name}`);

            if (gondolaWithLabel.patternMeta.pattern === undefined) {
              modelGondolapresenceChip = (
                <>
                  <Stack>
                    <Typography variant="caption">{gondolaName}</Typography>
                  </Stack>
                </>
              );
            } else {
              const patternName = gondolaWithLabel.patternMeta.pattern;
              modelGondolapresenceChip = (
                <Stack>
                  <Typography variant="body3">{patternName}</Typography>
                  <Typography variant="caption">{gondolaName}</Typography>
                </Stack>
              );
            }
          }
        }
      }

      const realogramPresenseChip =
        presence.presence == 'displayedHere' ? (
          <Chip label="陳列あり" color="primary" />
        ) : presence.presence == 'displayedElsewhere' ? (
          <Chip
            label={`${shortenGondolaName(
              presence.realogramCandidate.store_bay.name
            )}に陳列`}
            color="success"
            sx={{ fontSize: '0.7rem' }}
          />
        ) : (
          // presence.presence == 'notDisplayed'
          <Chip label="未陳列" color="warning" />
        );

      const adaptRatio =
        (productSales?.find((sale) => sale.itemCd === presence.itemCd)
          ?.doAdoptionRate || 0) * 100;

      const secondaryText =
        assortmentTag == `${t('assortment.shelf_plan')}掲載` ||
        assortmentTag === t('assortment.new_sales') ? (
          <>
            <Box component="div" fontSize={10} fontWeight="bold">
              <Typography
                component="span"
                fontSize="inherit"
                fontWeight="inherit"
                color={
                  adaptRatio <= 50 || presence.presence !== 'notDisplayed'
                    ? theme.palette.textBlack.primary
                    : adaptRatio < 80
                    ? theme.palette.warning.dark
                    : theme.palette.error.dark
                }
              >
                {t('region')}導入率: {Math.round(adaptRatio * 10) / 10}%
              </Typography>
              {(sales?.rank || 1000) <= rankShowLimit && (
                <Typography
                  ml={1}
                  component="span"
                  fontSize="inherit"
                  fontWeight="inherit"
                  color={theme.palette.textBlack.primary}
                >
                  {t('region')}売り上げ: {sales?.rank}位
                </Typography>
              )}
            </Box>
          </>
        ) : null;

      // 販促商品または新商品でかつ、重要な種類の販促等である場合は表示する
      const newItemInfo = externalInfo?.newItems.find(
        (item) => item.itemCd === presence.itemCd
      );
      const annotInfo = externalInfo?.annotations.find(
        (item) => item.itemCd === presence.itemCd
      );
      const productNameSecondaryChip = (
        <>
          {newItemInfo?.kindImportant === true && (
            <ProductFlagLabel
              key={`${newItemInfo.kind}`}
              name={`${newItemInfo.kind}`}
              backgroundColor={theme.palette.primary.light}
              textColor={theme.palette.primary.contrastText}
            />
          )}
          {annotInfo?.kindImportant === true && (
            <ProductFlagLabel
              key={`${annotInfo.kind}`}
              name={`${annotInfo.kind}`}
              backgroundColor={theme.palette.primary.light}
              textColor={theme.palette.primary.contrastText}
            />
          )}
        </>
      );

      switch (presence.presence) {
        case 'displayedHere':
          return (
            <AssortmentListRow
              key={presence.itemCd}
              realogramItem={presence.realogramSelectedItem}
              products={products}
              realogramDetailView={realogramDetailView}
              selectedItem={selectedItem}
              handleClick={handleClick}
              refs={refs}
              realogramPresenseChip={realogramPresenseChip}
              secondaryText={secondaryText}
              productNameSecondaryChip={productNameSecondaryChip}
            />
          );
        case 'displayedElsewhere':
          return (
            <AssortmentListRowAbsentProduct
              key={presence.itemCd}
              itemCd={presence.itemCd}
              products={gondolaPossibleProducts}
              realogramDetailView={realogramDetailView}
              realogramPresenseChip={realogramPresenseChip}
              modelGondolaPresenseChip={modelGondolapresenceChip}
              secondaryText={secondaryText}
              productNameSecondaryChip={productNameSecondaryChip}
            />
          );
        case 'notDisplayed':
          return (
            <AssortmentListRowAbsentProduct
              key={presence.itemCd}
              itemCd={presence.itemCd}
              products={gondolaPossibleProducts}
              realogramDetailView={realogramDetailView}
              realogramPresenseChip={<Chip label="未陳列" color="warning" />}
              modelGondolaPresenseChip={modelGondolapresenceChip}
              secondaryText={secondaryText}
              productNameSecondaryChip={productNameSecondaryChip}
            />
          );
      }
    });

  const [showModelGondola, setShowModelGondola] = useState<
    ModelGondolaWithPatternLabel | undefined
  >(undefined);

  if (
    showModelGondola &&
    relevantModelGondolas &&
    relevantModelGondolas.length >= 1
  ) {
    if (showModelGondola.gondolaCode !== relevantModelGondolas[0].gondolaCode) {
      // モデゴンビュー表示中にゴンドラを左右移動などで切り替えた場合
      // 表示中のゴンドラに紐づくモデゴンを探し、見つかった場合それを表示する
      const newGondola = relevantModelGondolas.find((gondola) => {
        return (
          gondola.patternMeta.pattern === showModelGondola.patternMeta.pattern
        );
      });
      if (newGondola) {
        setShowModelGondola(newGondola);
      }
    }
  }

  const hasSelected = false;
  return (
    <>
      <AssortmentDetailTabs
        assortmentTag={assortmentTag}
        tagCounts={tagCounts}
        handleChangeAssortmentTag={handleChangeAssortmentTag}
        hasSelected={hasSelected}
        relevantModelGondolas={relevantModelGondolas}
        showModelGondola={showModelGondola}
        setShowModelGondola={setShowModelGondola}
        realogramCandidateId={realogramCandidateId}
      />

      {!showModelGondola ? (
        <>
          <ShelfListHeader>
            {/* {assortmentTag === 'モデゴン掲載' && ( */}
            {assortmentTag === `${t('assortment.shelf_plan')}掲載` && (
              <BoxWidthDivider width={assortmentWidthList.modelGondolaPresense}>
                <Stack>
                  {/* <Typography variant="body2">モデゴン掲載状況</Typography> */}
                  <Typography variant="body2">
                    {t('assortment.shelf_plan')}掲載状況
                  </Typography>
                  {/* <Typography variant="caption2">同分類の他モデゴンを含む</Typography> // 今は含まない！ */}
                </Stack>
              </BoxWidthDivider>
            )}
            <BoxWidthDivider width={assortmentWidthList.realogramPresense}>
              <Stack>
                <Typography variant="body2">陳列状況</Typography>
                <Typography variant="caption2">
                  当日撮影の棚割に基づく
                </Typography>
              </Stack>
            </BoxWidthDivider>
          </ShelfListHeader>
          <List
            sx={{ width: '100%', height: '100%', overflow: 'auto' }}
            subheader={<Box component="li" />}
          >
            {rows}
          </List>
        </>
      ) : (
        <ModelGondolasSimplifiedViewer
          modelGondola={showModelGondola}
          realogramCandidatesForTheDay={itemCdsFromRealogramCandidates}
        />
      )}
    </>
  );
};
