/**
 * 責務
 * UI操作によるクエリパラメータの更新
 * * 抽象化した関数だけを持ちます
 */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { usePersistStateSearchParam } from './usePersistStateSearchParam';
export type ManipulateQueriesData = {
  updateQueries?: { key: string; value: string }[];
  removeQueries?: string[];
};
export const useUrlQueryParams = () => {
  const location = useLocation();
  const { searchParams, setSearchParamsWithState: setSearchParams } =
    usePersistStateSearchParam({ state: location.state });
  const existingParams = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams]
  );

  const updateQueryParameter = (
    query: string,
    value: string,
    replace = false
  ) => {
    existingParams[query] = value;
    setSearchParams(existingParams, { replace });
  };

  /**
   * @deprecated
   */
  // TODO: 複数クエリに対応できるように、removeQueryParametersと統合する
  const removeQueryParameter = (
    query: string,
    options = {
      replace: false,
    }
  ) => {
    delete existingParams[query];
    setSearchParams(existingParams, { replace: !!options.replace });
  };

  const removeQueryParameters = (queries: string[], replace = true) => {
    const updatedSearchParams = Object.fromEntries(
      Object.entries(existingParams).filter(([key]) => !queries.includes(key))
    );
    setSearchParams(updatedSearchParams, { replace });
  };

  const allClearQueryParameter = () => {
    setSearchParams({}, { replace: false });
  };

  /**
   * @description
   * - Updating and deleting query parameters
   * - Note: The delete action's data overwrites the update actions' data
   * @returns
   * {
   *  newQueryParams: new query params (object) after manipulating
   *  newUrlSearchParams new query params (URL) after manipulating
   * }
   */
  const manipulateQueryParameters = (data: {
    updateQueries?: { key: string; value: string }[];
    removeQueries?: string[];
    urlParams?: URLSearchParams;
  }) => {
    const { updateQueries, removeQueries, urlParams = searchParams } = data;
    const newQueryParams = Object.fromEntries(urlParams);
    if (updateQueries?.length) {
      updateQueries.forEach(({ key, value }) => {
        newQueryParams[key] = value;
      });
    }
    if (removeQueries?.length) {
      removeQueries.forEach((key) => {
        delete newQueryParams[key];
      });
    }
    setSearchParams(newQueryParams, { replace: false });
    return {
      newQueryParams,
      newUrlSearchParams: new URLSearchParams(newQueryParams),
    };
  };

  return {
    updateQueryParameter,
    removeQueryParameter,
    removeQueryParameters,
    allClearQueryParameter,
    manipulateQueryParameters,
    existingParams,
    searchParams,
  };
};
