import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import {
  AvailableItem,
  ExternalInfo,
  FlatModelGondola,
  RegularModelGondola,
  StoreProductSale,
} from '../types';
import { normalizeProductName } from '@utils/product';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Product } from 'types/common';
import { ProductAnnotation } from '@reducers/shelfAppsApi/injections/sakuraApi';
import { ReactNode, useEffect, useRef, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { PdfViewer } from './pdf/pdfViewer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TableViewIcon from '@mui/icons-material/TableView';
import { UriageTableView } from './uriageTable/uriageTableView';
import {
  listModelGondolaProducts,
  locateModelGondolaProduct,
} from '../sakuraUtils';
import { useAppSelector } from '@store/index';
import { selectToken } from '@reducers/auth/selectors';
import { useIndexedPdfContext } from './pdf/indexedPdf';
import { ProductDetailsModal } from './productDetailsModal/productDetailsModal';
import { useModelGondolasContext } from './modelGondolasContext';
import { useAllProductsContext } from '@utils/ProductsContext';

type _ColumnFunctionCollection = Record<
  string,
  (_: Product) => JSX.Element | string
>;

const TableTypography = ({ children }: { children: ReactNode }) => (
  <Typography fontSize="8pt">{children}</Typography>
);

const checkSoldInStore = (
  product: Product,
  productSales: StoreProductSale[]
) => {
  const itemCd = product.detail!.organization_product_id!;
  const sale = productSales.find((s) => s.itemCd === itemCd);
  const isSold = sale !== undefined && 0 < sale.quantity;
  return isSold;
};

type SupplementaryTableProductNameCellProps = {
  product: Product;
  productSale: StoreProductSale;
  externalInfo: ExternalInfo;
};

const SupplementaryTableProductNameCell = ({
  product,
  productSale,
  externalInfo,
}: SupplementaryTableProductNameCellProps) => {
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const modelGondolas = useModelGondolasContext().value?.modelGondolas;
  const modelGondolaProductAssignment =
    modelGondolas &&
    locateModelGondolaProduct(
      product.detail!.organization_product_id!,
      modelGondolas
    );
  return (
    <>
      <Box
        component="div"
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!openProductDetails) {
            setOpenProductDetails(true);
          }
        }}
      >
        <Stack direction="row" spacing={2}>
          <ProductImage
            product={product}
            width="50"
            height="50"
            objectFit="contain"
            imageSizeType="tiny"
          />
          <Stack>
            <Typography fontSize="6pt" color="darkgrey">
              {product.detail!.organization_product_id || '不明'}
            </Typography>
            <TableTypography>
              {normalizeProductName(product.name)}
            </TableTypography>
          </Stack>
        </Stack>
      </Box>
      <ProductDetailsModal
        product={product}
        externalInfo={externalInfo}
        storeProductSale={productSale}
        modelGondolaProductAssignment={modelGondolaProductAssignment}
        open={openProductDetails}
        handleClose={() => setOpenProductDetails(false)}
      />
    </>
  );
};

type SupplementaryTableProps = {
  tableName: string;
  listedProducts: Product[];
  badgeNumber: number | undefined;
  columns: string[];
  productSales: StoreProductSale[];
  addColumnFunctions?: _ColumnFunctionCollection;
  highlightedAnnotation?: ProductAnnotation;
  expanded?: boolean;
  setExpanded?: (_: boolean) => void;
  externalInfo: ExternalInfo;
};

const SupplementaryTable = ({
  tableName,
  listedProducts,
  badgeNumber,
  columns,
  productSales,
  addColumnFunctions,
  highlightedAnnotation,
  expanded,
  setExpanded,
  externalInfo,
}: SupplementaryTableProps) => {
  const baseColumnFunctions: _ColumnFunctionCollection = {
    商品: (p: Product) => (
      <SupplementaryTableProductNameCell
        product={p}
        productSale={
          productSales.find(
            (s) => s.itemCd === p.detail?.organization_product_id
          )!
        }
        externalInfo={externalInfo}
      />
    ),
    // 新商品リスト用（仮）
    // あとで移す
    販売開始日: (p: Product) => {
      return p.detail?.sales_start_date || '';
    },
  };
  const columnFunctions: _ColumnFunctionCollection =
    addColumnFunctions !== undefined
      ? { ...baseColumnFunctions, ...addColumnFunctions }
      : baseColumnFunctions;
  const columnToCell = (col: JSX.Element | string) => {
    if (typeof col === 'string') {
      return <TableTypography>{col}</TableTypography>;
    }
    return col;
  };

  useEffect(() => {
    if (highlightedAnnotation !== undefined) {
      const i = listedProducts.findIndex(
        (p) =>
          p.detail?.organization_product_id === highlightedAnnotation.itemCd
      );
      if (0 <= i) {
        rowRefs.current[i]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
        return;
      }
      if (!expanded) {
        setExpanded?.(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [highlightedAnnotation]);

  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);
  const getHighlightRowStyle = (product: Product) => {
    if (
      highlightedAnnotation === undefined ||
      highlightedAnnotation.itemCd !== product.detail?.organization_product_id
    ) {
      return {};
    }
    return {
      bgcolor: '#eef',
    };
  };

  const onChange =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- MUI Accordion onChange first parameter lacks specific type definition
    setExpanded && ((_: any, isExpanded: boolean) => setExpanded(isExpanded));

  return (
    <Accordion defaultExpanded expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {badgeNumber !== undefined && badgeNumber !== 0 ? (
          <Badge badgeContent={badgeNumber} color="warning">
            <Typography>{tableName}</Typography>
          </Badge>
        ) : (
          <Typography>{tableName}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {listedProducts.length === 0 ? (
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100px"
          >
            <TableViewIcon sx={{ fontSize: '128', color: 'lightgrey' }} />
            <Typography variant="h6" color="lightgrey">
              このゴンドラに該当する商品はありません。
            </Typography>
          </Box>
        ) : (
          <TableContainer style={{ maxHeight: '40vh', overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      <TableTypography>{column}</TableTypography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listedProducts.map((product, i) => {
                  const reason =
                    '理由' in columnFunctions
                      ? columnFunctions['理由'](product)
                      : '';
                  const key = product.id + `${reason}`;
                  return (
                    <TableRow
                      key={key}
                      sx={getHighlightRowStyle(product)}
                      ref={(el) => {
                        rowRefs.current[i] = el;
                      }}
                    >
                      {columns.map((column, index) => (
                        <TableCell key={index}>
                          {columnToCell(columnFunctions[column](product))}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

type CutShinisujiListProps = {
  productSales: StoreProductSale[];
  // cutItemCds: string[];
  externalInfo: ExternalInfo;
  gondolaAvailableItems: AvailableItem[];
  numItemsStillSold: number;
  setNumItemsStillSold: (_: number) => void;
};

const CutItemsList = ({
  productSales,
  externalInfo,
  gondolaAvailableItems,
  numItemsStillSold,
  setNumItemsStillSold,
}: CutShinisujiListProps) => {
  const gondolaFilteredCutItemCds = externalInfo.cutItems.filter((itemCd) =>
    gondolaAvailableItems.find((item) => item.itemCd === itemCd)
  );
  const cutItems = useAllProductsContext(gondolaFilteredCutItemCds).products;

  useEffect(() => {
    const value = cutItems
      .map((p) => p.detail!.organization_product_id)
      .map((itemCd) => productSales.find((s) => s.itemCd == itemCd))
      .filter((s) => s !== undefined && 0 < s.quantity).length;
    setNumItemsStillSold(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [cutItems]);

  const cutItemsSorted = cutItems.sort(
    (a, b) =>
      (checkSoldInStore(a, productSales) ? 0 : 1) -
      (checkSoldInStore(b, productSales) ? 0 : 1)
  );

  const colFns = {
    店舗取扱: (p: Product) => {
      const isSold = checkSoldInStore(p, productSales);
      if (!isSold) {
        return <></>;
      }
      return (
        <Box component="div" minWidth="60px">
          <Stack alignItems="center">
            <ReportProblemIcon color="warning" sx={{ fontSize: '12pt' }} />
            <TableTypography>取扱あり</TableTypography>
          </Stack>
        </Box>
      );
    },
    理由: (p: Product) => {
      const itemCd = p.detail!.organization_product_id!;

      const cutItem = externalInfo.cutItems.find((cd) => cd === itemCd);
      if (cutItem !== undefined) {
        return `推奨取り消し（${p.detail?.sales_end_date || '終売日不明'}）`;
      }
      return <></>;
    },
  };

  return (
    <SupplementaryTable
      tableName="推奨取り消し"
      listedProducts={cutItemsSorted}
      badgeNumber={numItemsStillSold}
      columns={['商品', '店舗取扱']}
      productSales={productSales}
      addColumnFunctions={colFns}
      externalInfo={externalInfo}
    />
  );
};

type ItemsSoldButNotListedInModelGondolaProps = {
  modelGondola: RegularModelGondola | FlatModelGondola;
  productSales: StoreProductSale[];
  gondolaAvailableItems: AvailableItem[];
  externalInfo: ExternalInfo;
};

const ItemsSoldButNotListedInModelGondola = ({
  modelGondola,
  productSales,
  gondolaAvailableItems,
  externalInfo,
}: ItemsSoldButNotListedInModelGondolaProps) => {
  const gondolaItemCds = listModelGondolaProducts(modelGondola).map(
    (p) => p.productCode
  );
  const modelGondolas = useModelGondolasContext().value?.modelGondolas;

  // このモデゴンに掲載しうるが実際は載ってない商品の店舗での売上をリスト
  // 推奨取り消し済み商品は現れない
  const filteredProductSales = productSales.filter(
    (sale) =>
      gondolaAvailableItems.find((item) => item.itemCd === sale.itemCd) &&
      !gondolaItemCds.find((cd) => cd === sale.itemCd) &&
      locateModelGondolaProduct(sale.itemCd, modelGondolas || []) === undefined
  );

  const itemCds = filteredProductSales.map((sale) => sale.itemCd);
  const products = useAllProductsContext(itemCds).products;

  return (
    <SupplementaryTable
      tableName="店舗取扱があるがモデゴンには掲載のない商品"
      listedProducts={products}
      badgeNumber={undefined}
      columns={['商品']}
      productSales={productSales}
      externalInfo={externalInfo}
    />
  );
};

type AnnotatedProductsProps = {
  modelGondola: RegularModelGondola | FlatModelGondola;
  productSales: StoreProductSale[];
  // annotations: ProductAnnotation[];
  externalInfo: ExternalInfo;
  highlightedAnnotation?: ProductAnnotation;
  setHighlightedAnnotation?: (_?: ProductAnnotation) => void;
  onClickJumpToPdfDetail: (_: ProductAnnotation) => void;
  setPdfPage: (pdfS3Id: string, page: number) => void;
  numItemsNotSold: number;
  setNumItemsNotSold: (_: number) => void;
};

const AnnotatedItems = ({
  modelGondola,
  productSales,
  externalInfo,
  highlightedAnnotation,
  onClickJumpToPdfDetail,
  setPdfPage,
  numItemsNotSold,
  setNumItemsNotSold,
}: AnnotatedProductsProps) => {
  const annotations = externalInfo.annotations;
  const annots = listModelGondolaProducts(modelGondola)
    .map((p) => annotations.find((annot) => annot.itemCd == p.productCode))
    .filter((annot) => annot !== undefined) as ProductAnnotation[];

  const itemCds = annots.map((annot) => annot.itemCd);
  const annotatedProducts = useAllProductsContext(itemCds).products;
  useEffect(() => {
    const value = annotatedProducts
      .map((p) => p.detail!.organization_product_id)
      .map((itemCd) => productSales.find((s) => s.itemCd == itemCd))
      .filter((s) => s === undefined || s.quantity === 0).length;
    setNumItemsNotSold(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [annotatedProducts]);

  const colFns = {
    店舗取扱: (p: Product) => {
      const isSold = checkSoldInStore(p, productSales);
      if (isSold) {
        return <></>;
      }
      return (
        <Box component="div" minWidth="45px">
          <Stack alignItems="center">
            <ReportProblemIcon color="warning" sx={{ fontSize: '12pt' }} />
            <TableTypography>未取扱</TableTypography>
          </Stack>
        </Box>
      );
    },
    内容: (p: Product) => {
      const itemCd = p.detail!.organization_product_id!;
      const annots = annotations.filter((annot) => annot.itemCd === itemCd);
      if (annots.length === 0) {
        return <></>;
      }
      const pageUniqAnnots = annots.filter(
        (annot, i, self) =>
          self.findIndex((a) => a.pdfPage === annot.pdfPage) === i
      );
      return (
        <Box component="div" minWidth="100px">
          <Stack>
            {pageUniqAnnots.map((annot) => (
              <Stack
                key={`${annot.itemCd}-${annot.pdfS3Id}-${annot.pdfPage}`}
                direction="row"
                alignItems="baseline"
              >
                <Button
                  endIcon={
                    <OpenInNewIcon sx={{ fontSize: '12pt !important' }} />
                  }
                  onClick={() => {
                    onClickJumpToPdfDetail(annot);
                    setPdfPage(annot.pdfS3Id, annot.pdfPage);
                  }}
                >
                  <TableTypography>
                    {annot.name} p{annot.pdfPage}
                  </TableTypography>
                </Button>
              </Stack>
            ))}
          </Stack>
        </Box>
      );
    },
  };

  const [expanded, setExpanded] = useState(true);
  useEffect(() => {
    if (highlightedAnnotation !== undefined && expanded === false) {
      setExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [highlightedAnnotation]);

  const sortedAnnotatedProducts = annotatedProducts.sort(
    (a, b) =>
      (productSales.find((s) => s.itemCd === a.detail!.organization_product_id)
        ?.quantity || 0) -
      (productSales.find((s) => s.itemCd === b.detail!.organization_product_id)
        ?.quantity || 0)
  );

  return (
    <SupplementaryTable
      tableName="本部配信情報あり"
      listedProducts={sortedAnnotatedProducts}
      badgeNumber={numItemsNotSold}
      columns={['商品', '内容', '店舗取扱']}
      productSales={productSales}
      addColumnFunctions={colFns}
      highlightedAnnotation={highlightedAnnotation}
      expanded={expanded}
      setExpanded={setExpanded}
      externalInfo={externalInfo}
    />
  );
};

type SupplementaryTableViewProps = {
  modelGondola: RegularModelGondola | FlatModelGondola;
  externalInfo: ExternalInfo;
  productSales: StoreProductSale[];
  highlightedAnnotation?: ProductAnnotation;
  setHighlightedAnnotation?: (_?: ProductAnnotation) => void;
  maxHeight: React.CSSProperties['height'] | undefined;
  gondolaAvailableItems: AvailableItem[];
};

const uniq = <T,>(arr: T[], key: (_: T) => string) =>
  arr.filter(
    (item, i, self) => self.findIndex((elem) => key(elem) === key(item)) === i
  );

export const SupplementaryTableView = ({
  modelGondola,
  externalInfo,
  productSales,
  highlightedAnnotation,
  setHighlightedAnnotation,
  maxHeight,
  gondolaAvailableItems,
}: SupplementaryTableViewProps) => {
  // 商品詳細を開くとPDFViewのタブを開く
  const onClickJumpToPdfDetail = (annot: ProductAnnotation) => {
    const tab = tabs.find((tab) => tab.label === annot.name);
    if (tab === undefined) {
      return;
    }
    setTabIndex(tab.tabIndex);
  };

  const annotationSources = uniq(
    externalInfo?.annotations || [],
    (annot) => annot.name
  ).map((annot) => ({
    name: annot.name,
    pdfS3Id: annot.pdfS3Id,
    pdfUrl: `/api/v1/sakura/assets/${annot.pdfS3Id}.pdf`,
  }));

  const authToken = useAppSelector(selectToken);
  const { addUrl } = useIndexedPdfContext();
  useEffect(() => {
    if (0 < annotationSources.length) {
      annotationSources.map((source) => {
        addUrl(source.pdfUrl, authToken);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [annotationSources, addUrl]);

  const [pdfPages, setPdfPages] = useState<{ pdfS3Id: string; page: number }[]>(
    []
  );
  const setPdfPage = (pdfS3Id: string, page: number) => {
    const newPdfPages = [
      ...pdfPages.filter((pdf) => pdf.pdfS3Id !== pdfS3Id),
      { pdfS3Id, page },
    ];
    setPdfPages(newPdfPages);
  };

  const [numCutItemsStillSold, setCutNumItemsStillSold] = useState<number>(0);
  const [numAnnotatedItemsNotSold, setNumAnnotatedItemsNotSold] =
    useState<number>(0);

  type _Tab = {
    tabIndex: number;
    label: string;
    badgeNumber?: number;
    component: () => ReactNode;
  };

  const tabs: _Tab[] = [
    {
      tabIndex: 0,
      label: '品揃え情報',
      badgeNumber: numCutItemsStillSold + numAnnotatedItemsNotSold,
      component: () => (
        <>
          <AnnotatedItems
            modelGondola={modelGondola}
            externalInfo={externalInfo}
            productSales={productSales}
            highlightedAnnotation={highlightedAnnotation}
            setHighlightedAnnotation={setHighlightedAnnotation}
            onClickJumpToPdfDetail={onClickJumpToPdfDetail}
            setPdfPage={setPdfPage}
            numItemsNotSold={numAnnotatedItemsNotSold}
            setNumItemsNotSold={setNumAnnotatedItemsNotSold}
          />
          <CutItemsList
            externalInfo={externalInfo}
            productSales={productSales}
            gondolaAvailableItems={gondolaAvailableItems}
            numItemsStillSold={numCutItemsStillSold}
            setNumItemsStillSold={setCutNumItemsStillSold}
          />
          <ItemsSoldButNotListedInModelGondola
            modelGondola={modelGondola}
            productSales={productSales}
            gondolaAvailableItems={gondolaAvailableItems}
            externalInfo={externalInfo}
          />
        </>
      ),
    },
    {
      tabIndex: 1,
      label: '店舗売上・死筋',
      component: () => (
        <Box component="div" height={maxHeight || '90vh'}>
          <UriageTableView
            gondolaFilteredProductSales={productSales.filter((sale) =>
              gondolaAvailableItems.find((item) => item.itemCd === sale.itemCd)
            )}
            externalInfo={externalInfo}
          />
        </Box>
      ),
    },
    ...annotationSources.map((source, index) => ({
      tabIndex: index + 2,
      label: source.name,
      component: () => (
        <Box component="div" height={maxHeight || '90vh'}>
          {' '}
          {/* 自動でいい高さにするのがうまくいかない */}
          <PdfViewer
            url={source.pdfUrl}
            name={`${source.name}.pdf`}
            // indexedPdf={indexedPdf}
            initialPage={
              pdfPages.find((pdf) => pdf.pdfS3Id === source.pdfS3Id)?.page || 1
            }
          />
        </Box>
      ),
    })),
  ];
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box component="div" sx={{ width: '100%', height: '100%', px: 1 }}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => setTabIndex(value)}
        variant="scrollable"
      >
        {tabs.map((tab) =>
          tab.badgeNumber !== undefined ? (
            <Tab
              label={
                <Badge
                  badgeContent={tab.badgeNumber}
                  color="warning"
                  sx={{
                    // 小さめのbadge
                    '& .MuiBadge-badge': {
                      fontSize: '0.5rem',
                      height: '14px',
                      minWidth: '14px',
                      padding: '0',
                    },
                  }}
                >
                  {tab.label}
                </Badge>
              }
              key={tab.tabIndex}
            />
          ) : (
            <Tab label={tab.label} key={tab.tabIndex} />
          )
        )}
      </Tabs>
      {tabs.map((tab) => (
        <div
          key={tab.tabIndex}
          role="tabpanel"
          hidden={tabIndex !== tab.tabIndex}
          style={{ height: '100%' }}
        >
          {tabIndex === tab.tabIndex && tab.component()}
        </div>
      ))}
    </Box>
  );
};
