import { useState } from 'react';
import httpStatus from 'http-status';
import {
  useCreateRealogramCandidateImageMutation,
  useCreateRealogramCandidateImageTempFileUrlMutation,
} from '@reducers/shelfAppsApi';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { useAppDispatch } from '@store/index';
import { isApiResponse } from '@utils/const';
import { ChangeStep, RealogramCandidate, StepFiveInfo } from 'types/realogram';
import { BayPlanCode } from 'types/bayPlan';
import { RealogramFormData } from '../types/realogram';
import {
  setCurrentSelectedItem,
  updateSelectedItemId,
} from '@reducers/selectionStoreBayModal';

export const useRealogramFileUpload = (
  changeStep: ChangeStep,
  handleSetSkipRealogramStepThree: (isSkip: boolean) => void,
  isSkipStepThree: boolean,
  handleSetSkipFlatRealogramStepThree: (isSkip: boolean) => void,
  isFlatSkipStepThree: boolean,
  bayPlanCodes?: BayPlanCode[],
  setFormValue?: (type: 'storeId' | 'storeBayId', id: number) => void
) => {
  const dispatch = useAppDispatch();
  const [firstFile, setFirstFile] = useState<File>();
  const [secondFile, setSecondFile] = useState<File>();
  const [tempFileIds, setTempFileIds] = useState<string[]>([]);
  const [failedDialogOpen, setFailedDialogOpen] = useState(false);
  const [stepFiveInfo, setStepFiveInfo] = useState<StepFiveInfo>();
  const [failedDialogOpenForbidden, setFailedDialogOpenForbidden] =
    useState(false);
  const [createRealogramCandidateImage] =
    useCreateRealogramCandidateImageMutation();
  const [createRealogramCandidateImageTempFileUrl] =
    useCreateRealogramCandidateImageTempFileUrlMutation();
  const [firstFileUploadPromise, setFirstFileUploadPromise] = useState<Promise<
    boolean | void
  > | null>(null); // Store the upload promise for the first file
  const [secondFileUploadPromise, setSecondFileUploadPromise] =
    useState<Promise<boolean | void> | null>(null); // Store the upload promise for the second file

  const onFirstFileSelect = (file?: File) => {
    setFirstFile(file);
    const promise = preuploadFile(0, file) // Start the upload and store the promise
      .then()
      .catch((error) => {
        console.error('Error uploading first file:', error); // Handle error
      });

    setFirstFileUploadPromise(promise);
  };

  const onSecondFileSelect = (file?: File) => {
    setSecondFile(file);
    const promise = preuploadFile(1, file) // Start the upload and store the promise
      .then()
      .catch((error) => {
        console.error('Error uploading first file:', error); // Handle error
      });

    setSecondFileUploadPromise(promise);
  };

  const onSwapFile = async (file?: File, secondFile?: File) => {
    await Promise.all([
      onFirstFileSelect(secondFile),
      onSecondFileSelect(file),
    ]);
  };

  const onStepFiveInfo = (
    realogramCandidate: RealogramCandidate,
    data: RealogramFormData
  ) => {
    const bayPlan = bayPlanCodes?.find((d) => d.id === data.bayPlanId);
    setStepFiveInfo({
      id: realogramCandidate.id,
      storeName: realogramCandidate.store_bay.store.name,
      storeBayName: realogramCandidate.store_bay.name,
      bayPlanName: bayPlan?.name,
      firstFile: firstFile,
      secondFile: secondFile,
    });
  };

  const preuploadFile = async (index: number, file?: File) => {
    try {
      const { temp_file_id: tempFileId, upload_url: uploadUrl } =
        await createRealogramCandidateImageTempFileUrl().unwrap();
      if (!uploadUrl) throw new Error('Error: failed to generate upload url');

      const updateTempFileIds = [
        ...tempFileIds.slice(0, index),
        tempFileId,
        ...tempFileIds.slice(index + 1),
      ];
      setTempFileIds(updateTempFileIds);

      const response = await fetch(uploadUrl, {
        mode: 'cors',
        method: 'PUT',
        headers: { 'Content-Type': file?.type ?? '' },
        body: file,
      });

      if (!response.ok) {
        throw new Error('Failed to upload file: ' + response.statusText);
      }
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  const onSingleRealogramSubmit = async (data: RealogramFormData) => {
    setFailedDialogOpen(false);
    setFailedDialogOpenForbidden(false);
    dispatch(updateLoadingIndicatorState(true));

    try {
      const firstFileUploadResult = (await firstFileUploadPromise?.catch(
        (error) => {
          console.error('First file upload failed:', error);
          return false; // Return false if the upload fails
        }
      )) as boolean; // Ensure the result is treated as a boolean

      // Check promise status before proceeding
      if (!firstFileUploadResult) {
        throw new Error('Error: failed to upload image.');
      }

      const { realogram_candidate: realogramCandidate } =
        await createRealogramCandidateImage({
          storeBayId: Number(data.storeBayId),
          shotType: 'single_shelve',
          shotCount: 1,
          tempFileIds: tempFileIds,
        }).unwrap();

      onStepFiveInfo(realogramCandidate, data);
      setFormValue && setFormValue('storeBayId', 0);
      changeStep('four', true);
      handleSetSkipRealogramStepThree(isSkipStepThree);
      dispatch(updateSelectedItemId(undefined));
      dispatch(setCurrentSelectedItem(undefined));
    } catch (error) {
      console.log({ error });
      if (isApiResponse(error) && error?.status === httpStatus.FORBIDDEN)
        setFailedDialogOpenForbidden(true);
      else setFailedDialogOpen(true);
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const onFlatRealogramSubmit = async (data: RealogramFormData) => {
    setFailedDialogOpen(false);
    setFailedDialogOpenForbidden(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      // Wait for both file uploads to complete
      const [firstFileUploadResult, secondFileUploadResult] = await Promise.all(
        [
          firstFileUploadPromise ?? Promise.resolve(false), // Ensure a safe fallback
          secondFileUploadPromise ?? Promise.resolve(false), // Ensure a safe fallback
        ]
      ).catch((error) => {
        console.error('File upload failed:', error);
        return [false, false]; // Return false for both if the upload fails
      });

      // Check promise status before proceeding
      if (!firstFileUploadResult || !secondFileUploadResult) {
        throw new Error('Error: failed to upload one or both images.'); // Update error message
      }
      const { realogram_candidate: realogramCandidate } =
        await createRealogramCandidateImage({
          storeBayId: Number(data.storeBayId),
          shotType: 'split_bucket',
          shotCount: 2,
          tempFileIds: tempFileIds,
        }).unwrap();
      onStepFiveInfo(realogramCandidate, data);
      changeStep('four', true);
      handleSetSkipFlatRealogramStepThree(isFlatSkipStepThree);
    } catch (error) {
      console.log({ error });
      if (isApiResponse(error) && error?.status === httpStatus.FORBIDDEN)
        setFailedDialogOpenForbidden(true);
      else setFailedDialogOpen(true);
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  return {
    firstFile,
    onFirstFileSelect,
    secondFile,
    onSecondFileSelect,
    preuploadFile,
    tempFileIds,
    onSwapFile,
    onSingleRealogramSubmit,
    onFlatRealogramSubmit,
    stepFiveInfo,
    // ↓ダイアログ関連
    failedDialogOpen,
    failedDialogOpenForbidden,
    setFailedDialogOpen,
    setFailedDialogOpenForbidden,
  };
};
