import { Search, Close } from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, IconButton, TextField } from '@mui/material';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import { theme } from 'theme';
import { FormData } from '../types';

type SearchInputProps = {
  handleSearchText: (text: string) => void;
  handleConditionModalOpen: () => void;
  control: Control<FormData>;
  handleSubmit: UseFormHandleSubmit<FormData>;
  handleFocus: () => void;
  handleReset: () => void;
  isFocused: boolean;
};

export const SearchInput: React.FC<SearchInputProps> = ({
  handleSearchText,
  handleConditionModalOpen,
  control,
  handleSubmit,
  handleFocus,
  handleReset,
  isFocused,
}) => {
  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit((data) => handleSearchText(data.searchText))}
      noValidate
      display="flex"
    >
      <Controller
        name="searchText"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            placeholder="キーワードで検索"
            onFocus={handleFocus}
            size="small"
            sx={{
              width: '320px',
              'input::placeholder': {
                fontSize: '14px',
              },
            }}
            InputProps={{
              sx: {
                borderRadius: '40px',
                backgroundColor: '#F7F7F7',
              },
              startAdornment: (
                <Search
                  sx={{
                    color: theme.palette.icons.primary,
                    mr: '4px',
                  }}
                />
              ),
              endAdornment: (
                <>
                  {isFocused && (
                    <IconButton onClick={handleReset}>
                      <Close
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                          color: theme.palette.icons.primary,
                        }}
                      />
                    </IconButton>
                  )}
                  <IconButton onClick={handleConditionModalOpen}>
                    <TuneIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: theme.palette.icons.primary,
                      }}
                    />
                  </IconButton>
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
