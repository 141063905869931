import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Image from 'next/legacy/image';
import { Cancel } from '@mui/icons-material';
import { theme } from 'theme';

type Props = {
  isFlat: boolean;
  isMd: boolean;
  isXs: boolean;
};

export const BadExample: FC<Props> = ({ isFlat, isMd, isXs }) => {
  const subtitleType = isXs ? 'subtitle2' : 'subtitle1';
  const bodyType = isXs ? 'body2' : 'body1';
  return (
    <>
      {isFlat ? (
        <Box
          component="div"
          display="flex"
          justifyContent="top"
          alignItems="left"
          flexDirection="column"
          gap={5}
          py={1}
          height={{ xs: '378px', sm: '378px' }}
        >
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
            gap={1}
          >
            <Cancel
              sx={{
                color: theme.palette.warning.dark,
                height: 24,
                width: 24,
              }}
            />
            <Typography
              variant="headingXS"
              textAlign="left"
              color={theme.palette.warning.dark}
            >
              悪い例
            </Typography>
          </Box>
          {isMd ? (
            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              gap={{ xs: 4, sm: 4, md: 5 }}
            >
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={3}
                gap={3}
              >
                <Image
                  src="/instructions/slantedFlatGondra.png"
                  alt=""
                  width={132}
                  height={176}
                  objectFit="contain"
                />
                <Box
                  component="div"
                  gap={2}
                  width="220px"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="subtitle1" textAlign="left" gap="10px">
                    斜めから撮影している
                  </Typography>
                  <Typography variant="body2" textAlign="left">
                    できる限り、斜めではなく真上から撮影します
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={3}
                gap={3}
              >
                <Image
                  src="/instructions/cantSeeWholeFlatGondra.png"
                  alt=""
                  width={132}
                  height={176}
                  objectFit="cover"
                />
                <Box
                  component="div"
                  gap={2}
                  width="220px"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="subtitle1" textAlign="left" gap="10px">
                    カゴの四隅が見切れている
                  </Typography>
                  <Typography variant="body2" textAlign="left">
                    ゴンドラの半分のうち、カゴの四隅が見切れないように撮影します
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              gap={{ xs: 4, sm: 4, md: 5 }}
            >
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={2}
                gap={3}
              >
                <Image
                  src="/instructions/slantedFlatGondra.png"
                  alt=""
                  width={120}
                  height={160}
                  objectFit="contain"
                />
                <Box
                  component="div"
                  gap={2}
                  width="234px"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="subtitle1" textAlign="left" gap="10px">
                    斜めから撮影している
                  </Typography>
                  <Typography variant="body2" textAlign="left">
                    できる限り、斜めではなく真上から撮影します
                  </Typography>
                </Box>
              </Box>
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={2}
                gap={3}
              >
                <Image
                  src="/instructions/cantSeeWholeFlatGondra.png"
                  alt=""
                  width={120}
                  height={160}
                  objectFit="cover"
                />
                <Box
                  component="div"
                  gap={2}
                  width="234px"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="subtitle1" textAlign="left" gap="10px">
                    カゴの四隅が見切れている
                  </Typography>
                  <Typography variant="body2" textAlign="left">
                    ゴンドラの半分のうち、カゴの四隅が見切れないように撮影します
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          component="div"
          display="flex"
          justifyContent="top"
          alignItems="left"
          flexDirection="column"
          py={1}
          gap={{ xs: 2, sm: 2, md: 3 }}
          borderLeft={{
            sm: 'none',
            md: `1px solid ${theme.palette.dividerBlack.medium}`,
          }}
          borderTop={{
            xs: `1px solid ${theme.palette.dividerBlack.medium}`,
            sm: `1px solid ${theme.palette.dividerBlack.medium}`,
            md: 'none',
          }}
          flex={1}
        >
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            gap={1}
            pt="4px"
            pr="4px"
          >
            <Cancel
              sx={{
                color: theme.palette.warning.dark,
              }}
            />
            <Typography variant="headingXS" color={theme.palette.warning.dark}>
              悪い例
            </Typography>
          </Box>
          <Box
            component="div"
            display="flex"
            alignItems={{
              xs: 'flex-start',
              breakpoint: 'center',
            }}
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box
              component="div"
              display="inline"
              px={{
                xs: 0,
                sm: 3,
              }}
              mb={{ xs: '16px', sm: '16px', md: '44px' }}
            >
              <Box
                component="div"
                width={120}
                height={160}
                mr="20px"
                sx={{ float: 'left' }}
              >
                <Image
                  src="/instructions/cantSeeWholeGondra.png"
                  alt=""
                  width={120}
                  height={160}
                  objectFit="contain"
                />
              </Box>
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                py={3}
                gap={2}
              >
                <Typography variant={subtitleType}>
                  ゴンドラの端が見切れている
                </Typography>
                <Typography variant={bodyType}>
                  広角モードを使用してゴンドラの端が見切れないよう撮影します
                </Typography>
              </Box>
            </Box>
            <Box
              component="div"
              display="inline"
              alignItems="center"
              justifyContent="space-between"
              px={{
                xs: 0,
                sm: 3,
              }}
              gap={3}
            >
              <Box
                component="div"
                width={120}
                height={160}
                sx={{ float: 'left' }}
                mr="20px"
              >
                <Image
                  src="/instructions/doorIsClose.png"
                  alt=""
                  width={120}
                  height={160}
                  objectFit="contain"
                />
              </Box>
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                py={3}
                gap={2}
              >
                <Typography variant={subtitleType} textAlign="left">
                  ガラス扉が閉まっている
                </Typography>
                <Typography variant={bodyType} textAlign="left">
                  ガラス扉付きのゴンドラの場合は開けて撮影します
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
