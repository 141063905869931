import {
  addModegonProduct,
  removeModegonProduct,
  clearAddProductToModegonItemCd,
  updateModegonProduct,
  ModegonProduct,
  selectAddProductToModegon,
  selectProducts,
  selectModegonProducts,
  selectContext,
  StandardModegonProduct,
  BevModegonProduct,
  isStandardModegonProduct,
  isBevModegonProduct,
} from '../features/slices';
import { useState, FC, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '@store/index';
import { GondolaPmas } from '../features/constants';

const acceptSendByEnterClassName = 'accept-send-by-enter';

type TextFieldProps = {
  label: string;
  type: 'number' | 'text';
  defaultValue?: number;
  setter: CallableFunction;
  error: boolean;
  disabled?: boolean;
  value?: number;
  acceptSendByEnter?: boolean;
};

const DefaultTextField: FC<TextFieldProps> = (props) => {
  const {
    label,
    type,
    defaultValue,
    setter,
    error,
    disabled,
    value,
    acceptSendByEnter,
  } = props;

  return (
    <TextField
      inputProps={
        acceptSendByEnter
          ? { className: acceptSendByEnterClassName }
          : undefined
      }
      required
      disabled={disabled}
      sx={{ m: 1 }}
      label={label}
      type={type}
      error={error}
      defaultValue={defaultValue}
      value={value}
      onChange={(e) => {
        if (type == 'number') {
          setter(parseInt(e.target.value));
        } else {
          setter(e.target.value);
        }
      }}
    />
  );
};

type CustomModegonInputProps<T extends ModegonProduct, LayoutT> = {
  modegon: T;
  setModegon: CallableFunction;
  layout: LayoutT;
};

type StandardGondolaLayout = {
  rows: number;
};

const StandardModegonInputs: FC<
  CustomModegonInputProps<StandardModegonProduct, StandardGondolaLayout>
> = ({ modegon, setModegon, layout }) => {
  return (
    <>
      <div>
        <DefaultTextField
          label="段"
          type="number"
          acceptSendByEnter
          error={modegon.row < 1 || modegon.row >= layout.rows + 1}
          defaultValue={modegon.row}
          setter={(v: number) => {
            setModegon({
              ...modegon,
              ...{ row: v },
            });
          }}
        />
        <DefaultTextField
          label="列"
          type="number"
          acceptSendByEnter
          error={false}
          disabled={modegon.col < 0}
          value={modegon.col}
          setter={(v: number) => {
            setModegon({
              ...modegon,
              ...{ col: v },
            });
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={modegon.col < 0}
              onChange={(e) => {
                setModegon({
                  ...modegon,
                  ...{ col: e.target.checked ? -1 : 1 },
                });
              }}
            />
          }
          label="末尾"
        />
      </div>
      <Box component="div">
        <DefaultTextField
          label="フェイス数"
          type="number"
          acceptSendByEnter
          error={false}
          defaultValue={modegon.faces}
          setter={(v: number) => {
            setModegon({
              ...modegon!,
              ...{ faces: v },
            });
          }}
        />
      </Box>
    </>
  );
};

type BevModegonLayout = {
  rows: number;
  baskets: number;
};

const BevModegonInputs: FC<
  CustomModegonInputProps<BevModegonProduct, BevModegonLayout>
> = ({ modegon, setModegon, layout }) => {
  return (
    <>
      <Grid container>
        <Grid item alignSelf="center">
          <FormControl>
            <InputLabel>列</InputLabel>
            <Select
              label="列"
              value={`${modegon.row}`}
              onChange={(e: SelectChangeEvent) => {
                setModegon({ ...modegon, ...{ row: e.target.value } });
              }}
            >
              <MenuItem value={1}>1: OPケース側</MenuItem>
              <MenuItem value={2}>2: ゴンドラ側</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <DefaultTextField
            label="カゴ"
            type="number"
            acceptSendByEnter
            error={modegon.basket < 1 || modegon.basket >= layout.baskets + 1}
            defaultValue={modegon.basket}
            setter={(v: number) => {
              setModegon({
                ...modegon,
                ...{ basket: v },
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const AddProductToModegonModal: FC = () => {
  const dispatch = useAppDispatch();

  const context = useAppSelector(selectContext);
  const gondolaPma = GondolaPmas.find((p) => p.code == context.gondola);
  const targetItemCd = useAppSelector(selectAddProductToModegon);
  const targetProduct = useAppSelector(selectProducts).find(
    (p) => p.itemCd == targetItemCd
  );
  const modegonProduct = useAppSelector(selectModegonProducts).find(
    (p) => p.itemCd == targetItemCd
  );

  function initState() {
    if (targetItemCd.length == 0 || gondolaPma?.layoutType == undefined) {
      return undefined;
    } else {
      const state = {
        itemCd: targetItemCd,
        faces: 1,
        comment: '',
      };

      if (gondolaPma.layoutType == 'standard') {
        return {
          row: 1,
          col: -1,
          ...state,
        } as StandardModegonProduct;
      } else if (gondolaPma.layoutType == 'bev') {
        return {
          row: 1,
          basket: 1,
          index: 100, // 大きい値をセットすることで自動的に末尾に
          ...state,
        } as BevModegonProduct;
      }
    }
  }

  const currentInitState = initState();

  const [modegonState, setModegonState] = useState(currentInitState);
  const [isSendByEnterElementActive, setIsSendByEnterElementActive] =
    useState(false);

  useEffect(() => {
    if (modegonState == undefined && modegonProduct != undefined) {
      setModegonState(modegonProduct);
    } else if (modegonState == undefined && currentInitState != undefined) {
      setModegonState(currentInitState);
    }
  }, [modegonProduct, modegonState, currentInitState]);

  // dismiss this modal
  const dismissThis = () => {
    setIsSendByEnterElementActive(false);
    dispatch(clearAddProductToModegonItemCd());
    setModegonState(undefined);
  };

  const onAddButtonTapped = () => {
    if (modegonState != undefined) {
      dispatch(addModegonProduct({ ...modegonState }));
    }

    dismissThis();
  };

  const onUpdateButtonTapped = () => {
    if (modegonState != undefined) {
      dispatch(updateModegonProduct(modegonState));
    }

    dismissThis();
  };

  const onRemoveButtonTapped = () => {
    dispatch(removeModegonProduct(targetItemCd!));
    dismissThis();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //   width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const isInputValid = isStandardModegonProduct(modegonState)
    ? modegonState.row > 0 && modegonState.row < gondolaPma!.layout.rows + 1
    : isBevModegonProduct(modegonState)
    ? modegonState.row < gondolaPma!.layout.rows + 1 &&
      modegonState.basket > 0 &&
      modegonState.basket < gondolaPma!.layout.baskets! + 1
    : false;

  return (
    <div>
      <Modal
        // ariaHideApp={false}
        open={targetItemCd.length > 0}
        onClose={() => {
          setIsSendByEnterElementActive(false);
          dismissThis();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="div">
          <Typography variant="h6">
            {modegonProduct == undefined ? '商品追加' : '商品更新'}
          </Typography>

          <Stack>
            <Typography component="div">
              商品名: {targetProduct?.name}
            </Typography>
            <Typography component="div">
              商品コード: {targetProduct?.itemCd}
            </Typography>
          </Stack>

          <Box
            component="form"
            noValidate
            sx={{ m: 1 }}
            onKeyDown={(e) => {
              if (
                e.key == 'Enter' &&
                !e.nativeEvent.isComposing &&
                isInputValid &&
                isSendByEnterElementActive
              ) {
                if (modegonProduct == undefined) {
                  onAddButtonTapped();
                } else {
                  onUpdateButtonTapped();
                }
              }
            }}
            onFocus={(e) => {
              if (e.target.classList.contains(acceptSendByEnterClassName)) {
                setIsSendByEnterElementActive(true);
              }
            }}
            onBlur={(e) => {
              if (
                e.target.classList &&
                e.target.classList.contains(acceptSendByEnterClassName)
              ) {
                setIsSendByEnterElementActive(false);
              }
            }}
          >
            <Stack>
              <Box component="div">
                <Typography component="span">陳列</Typography>
                <Typography component="span" fontSize="12px">
                  {isBevModegonProduct(modegonState) ? '（末尾に追加）' : null}
                </Typography>
              </Box>
              {isStandardModegonProduct(modegonState) ? (
                <StandardModegonInputs
                  modegon={modegonState}
                  setModegon={setModegonState}
                  layout={gondolaPma!.layout as StandardGondolaLayout}
                />
              ) : isBevModegonProduct(modegonState) ? (
                <BevModegonInputs
                  modegon={modegonState}
                  setModegon={setModegonState}
                  layout={gondolaPma!.layout as BevModegonLayout}
                />
              ) : null}
              <Box component="div">
                <Typography>コメント</Typography>
                <TextField
                  sx={{ flexGrow: '1' }}
                  size="small"
                  fullWidth
                  multiline
                  maxRows={4}
                  margin="normal"
                  placeholder="コメントを追加"
                  value={modegonState?.comment || ''}
                  //defaultValue={modegonState?.comment || ''}
                  onChange={(e) => {
                    if (modegonState) {
                      setModegonState({
                        ...modegonState,
                        ...{ comment: e.target.value },
                      });
                    }
                  }}
                />
              </Box>
            </Stack>
          </Box>
          <Box component="div" height="20px">
            {' '}
          </Box>

          <Grid container spacing={2} alignItems="baseline">
            <Grid item>
              <Button
                variant="contained"
                disabled={!isInputValid}
                onClick={() => {
                  if (modegonProduct == undefined) {
                    onAddButtonTapped();
                  } else {
                    onUpdateButtonTapped();
                  }
                }}
              >
                {modegonProduct == undefined ? '追加' : '更新'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                disabled={modegonProduct == undefined}
                onClick={() => {
                  onRemoveButtonTapped();
                }}
              >
                削除
              </Button>
            </Grid>
            {isSendByEnterElementActive && isInputValid && (
              <Grid item>
                <Typography variant="body2" color="gray">
                  Enterで{modegonProduct == undefined ? '追加' : '更新'}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
