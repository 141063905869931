import { NotFound } from '@components/NotFound';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import { usePageTitle } from '@hooks/usePageTitle';
import { useProductCandidatesDrawer } from './hooks/useProductCandidatesDrawer';
import { useRealogramAnalyticsData } from '@hooks/useRealogramAnalyticsData';
import { Box } from '@mui/material';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import { useGetRealogramCandidateQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { paths, productTags, rateValues as rateValuesArr } from '@utils/const';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import httpStatus from 'http-status';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ProductTag,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { Status } from 'types/realogram';
import { timer } from './utils';
import {
  ModelGondolaAndAllProductsProvider,
  useLatestModelGondola,
} from './utils/modelGondolaAndAllProductsProvider';
import { RealogramPrintDetail } from './fragments/realogramPrint/realogramPrintDetail';

dayjs.extend(utc);
dayjs.extend(timezone);

export const PrintDetails = () => {
  const selectedItem = useAppSelector(selectRealogramSelectedItem);
  const params = useParams<{ id: string }>();
  const realogramCandidateId = parseInt(params.id ?? '0');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [productCandidateIds, setProductCandidateIds] = useState<string>();
  const [productTag, setProductTag] = useState<ProductTag>(productTags[0]);
  const [rateValue, setRateValue] = useState<Rate>(rateValuesArr[1]);
  const [realogramDetailView, setRealogramDetailView] =
    useState<ShelfDetailView>('default');
  const [realogramDetailMode, setRealogramDetailMode] =
    useState<ShelfDetailMode>('default');
  const [status, setStatus] = useState<Status>();
  const isComparedMode = realogramDetailMode === 'comparison';

  const {
    selector,
    operate,
    data: { isLoadingTenant, canReadDemo, isTenantSalesAnalytics },
  } = useRerenderingDetails();

  const {
    data,
    error: realogramCandidateError,
    isLoading,
    refetch: refetchRealogramCandidate,
  } = useGetRealogramCandidateQuery({
    realogramCandidateId,
  });
  const storeCd =
    data?.realogram_candidate.store_bay.store.organization_store_id;
  const gondolaName = data?.realogram_candidate.store_bay.name;
  const isBucketType = data?.realogram_candidate.shot_type === 'split_bucket';
  const isProcessed = status === 'processed';
  const realogramCandidate = data?.realogram_candidate;
  usePageTitle(`${realogramCandidate?.store_bay.name ?? ''} | スキャン結果`);
  const { analyticsData } = useRealogramAnalyticsData(
    realogramCandidateId,
    !isTenantSalesAnalytics ||
      realogramCandidateId === 0 ||
      (realogramDetailView !== 'profit' && !isComparedMode),
    realogramCandidate?.created_at
  );
  const modelGondolaContext = useLatestModelGondola(
    storeCd,
    gondolaName,
    realogramCandidate
  );
  const { filteredShelfBoards: shelfBoards, primaryCandidates } =
    useExtractRealogramData(
      realogramDetailView,
      productTag,
      analyticsData?.reports.at(0)?.products ?? [],
      rateValue,
      realogramCandidate?.detail?.products_shelves.shelf_boards,
      modelGondolaContext?.externalInfo
    );

  const [initRerenderingHeaderStatus, setInitRerenderingHeaderStatus] =
    useState(false); // 初回描画：ヘッダータブ更新フラグ
  const [initUpdateUrl, setInitUpdateUrl] = useState(false); // 初回描画：URLの更新フラグ

  const { product } = operate.getSelectedItemOfRealogram(shelfBoards);

  const shelfBoardsToDisplay = useMemo(() => {
    return isBucketType ? shelfBoards : shelfBoards?.slice().reverse();
  }, [isBucketType, shelfBoards]);

  const { setIsDrawerOpen, setSelectedProductChoice } =
    useProductCandidatesDrawer(
      realogramCandidateId,
      refetchRealogramCandidate,
      realogramCandidate,
      productCandidateIds,
      selectedItem
    );

  // ブラウザバックなどの操作時に発火する
  const updateStateByBrowserOperated = () => {
    setInitRerenderingHeaderStatus(false);
  };

  useBrowserOperate(updateStateByBrowserOperated);

  useEffect(() => {
    setStatus(data?.realogram_candidate?.status);
  }, [data]);

  // 初回描画：ヘッダータブ
  useEffect(() => {
    if (initRerenderingHeaderStatus || isLoadingTenant) return;

    // 通常モード・比較モード
    if (selector.modeQueryParams) {
      setRealogramDetailMode(selector.modeQueryParams);
    }

    // view=layout
    if (selector.viewQueryParams === 'default') {
      setRealogramDetailView(selector.viewQueryParams);
    }
    // view=attribute
    else if (selector.viewQueryParams === 'productFlag') {
      setRealogramDetailView(selector.viewQueryParams);
      setProductTag(
        selector.attributeQueryParams
          ? selector.attributeQueryParams
          : 'new_products_first_week'
      );
    }
    // view=analytics
    else if (selector.viewQueryParams === 'profit' && isTenantSalesAnalytics) {
      setRealogramDetailView(selector.viewQueryParams);
    }
    // view=evaluation
    else if (selector.viewQueryParams === 'rate' && canReadDemo) {
      setRealogramDetailView(selector.viewQueryParams);
      setRateValue(
        selector.evaluationQueryParams
          ? selector.evaluationQueryParams
          : '評価OK'
      );
    }
    setInitRerenderingHeaderStatus(true);
  }, [
    initRerenderingHeaderStatus,
    selector.attributeQueryParams,
    selector.evaluationQueryParams,
    selector.profitTab,
    selector.modeQueryParams,
    selector.viewQueryParams,
    isTenantSalesAnalytics,
    dispatch,
    isLoadingTenant,
    canReadDemo,
  ]);

  // 初回描画のみ：URLの更新
  useEffect(() => {
    if (
      initUpdateUrl ||
      !initRerenderingHeaderStatus ||
      isLoading ||
      isLoadingTenant
    )
      return;

    operate.updateUrlQueryParams({
      ...selector,
      isProduct: !!product,
    });
    setInitUpdateUrl(true);
  }, [
    initRerenderingHeaderStatus,
    initUpdateUrl,
    isLoading,
    isLoadingTenant,
    operate,
    product,
    selector,
  ]);

  useEffect(() => {
    /**
     * ブラウザバックなどで選択した商品がundefinedになっていたらドロワーを閉じる
     * productCandidatesの取得も不要
     */
    if (!selectedItem) {
      setIsDrawerOpen(false);
      setSelectedProductChoice(false);
      return;
    }
    //productCandidatesの一括取得
    const ids = Array.from(
      new Set(
        selectedItem?.item?.product_candidates
          ?.map((candidate) => candidate.product_id)
          .filter((v) => v !== undefined)
          .sort()
      )
    );
    setProductCandidateIds(ids.join(','));
  }, [selectedItem, setIsDrawerOpen, setSelectedProductChoice]);

  // Re-Call API getRealogramCandidate every 5s until the status changes to processing
  useEffect(() => {
    if (status !== 'processing' && status !== 'initialized') return;

    const intervalRefetchRealogramCandidate = setInterval(async () => {
      await refetchRealogramCandidate();
    }, timer);

    return () => {
      clearInterval(intervalRefetchRealogramCandidate);
    };
  }, [refetchRealogramCandidate, status]);

  const handleClickReUpLoadScan = () => {
    navigate(paths.actuals.scan);
  };

  if (
    Number.isNaN(realogramCandidateId) ||
    (!!realogramCandidateError &&
      'status' in realogramCandidateError &&
      realogramCandidateError.status === httpStatus.NOT_FOUND)
  ) {
    return <NotFound title="棚割実績（棚スキャナ）" />;
  }

  return (
    <ModelGondolaAndAllProductsProvider
      modelGondolaContext={modelGondolaContext}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          // A4サイズ(8.27in x 11.69in )を想定
          // 96dpiで換算すると794px x 1133px
          // マージンを踏まえて、720px x 1123px に設定
          height: '720px',
          width: '1123px',
          overflow: 'hidden',
        }}
        style={{
          // 印刷用レイアウト
          WebkitPrintColorAdjust: 'exact',
        }}
      >
        <RealogramPrintDetail
          status={status}
          isProcessed={isProcessed}
          realogramCandidate={realogramCandidate}
          primaryCandidates={primaryCandidates}
          realogramCandidateId={realogramCandidateId}
          shelfBoardsToDisplay={shelfBoardsToDisplay}
          handleClickReUpLoadScan={handleClickReUpLoadScan}
        />
      </Box>
    </ModelGondolaAndAllProductsProvider>
  );
};
