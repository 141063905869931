import { Draggable } from '@components/organisms/draggable/draggable';
import { useOverflows } from '@hooks/useOverflows';
import { Box } from '@mui/material';
import { updateOverflowState } from '@reducers/planogramEditor/reducer';
import { useAppDispatch } from '@store/index';
import { calculateJustifyMargin, convertMeterToPixel } from '@utils/planogram';
import { FC, memo, MouseEvent, useEffect } from 'react';
import {
  DndData,
  Mode,
  Product,
  ProductTag,
  ProfitTab,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import {
  BboxColors,
  BucketMode,
  Estimate,
  JustifyContent,
  PlanogramProductCompartment,
  ProductPosition,
} from 'types/planogram';
import { RealogramSelectedItem } from 'types/realogram';
import { ItemTypes } from '../../../types/rack';
import { DisplayableHighlight } from '../displayShelf/fragments/displayableHighlight';
import { ProductsGroup } from '../productsGroup/productsGroup';
import { getMaxValueInReport } from '../realogramImageAndBbox/utils';

type Item = {
  data: DndData | undefined;
  index: number;
  rowIndex: number;
};

type Props = {
  products?: Map<number, Product[]>;
  handleClickGroup: (
    e: MouseEvent<HTMLElement>,
    position: ProductPosition,
    product: Product
  ) => void;
  row: PlanogramProductCompartment[];
  rowIndex: number;
  selectedPosition?: ProductPosition;
  selectedProductCompartment?: Product;
  selectedRealogramItem?: RealogramSelectedItem;
  shelfHeight: number;
  shelfWidth: number;
  diffColor?: string;
  bboxColors: BboxColors;
  bboxEnabled?: boolean;
  detailView?: ShelfDetailView;
  detailMode?: ShelfDetailMode;
  justifyContent: JustifyContent;
  item?: Item;
  isEditor?: boolean;
  isCompared?: boolean;
  setIsAllChildRenndered?: (value: boolean) => void;
  selectedBucketId?: number;
  bucketMode: BucketMode;
  isDragProduct?: boolean;
  selectedBucketIdClone?: number;
  mode: Mode;
  productSalesReport?: Estimate;
  profitTab: ProfitTab;
  productTag: ProductTag;
  left: number;
  width: number;
  product?: Product;
  compartment: PlanogramProductCompartment;
  index: number;
  isComparisonCommonId: boolean;
  isDecreaseOpacity: boolean;
  productValue?: number;
  isSelected: boolean;
};

export const ProductCompartments: FC<Props> = memo(
  ({
    products,
    rowIndex,
    row,
    handleClickGroup,
    shelfHeight,
    shelfWidth,
    diffColor,
    bboxColors,
    bboxEnabled = true,
    detailView,
    detailMode,
    justifyContent,
    item,
    isEditor,
    isCompared = false,
    setIsAllChildRenndered,
    bucketMode,
    isDragProduct,
    mode,
    productSalesReport,
    profitTab,
    productTag,
    left,
    width,
    product,
    compartment,
    index,
    isComparisonCommonId,
    isDecreaseOpacity,
    productValue,
    isSelected,
  }) => {
    const dispatch = useAppDispatch();
    const { compartmentOverflows } = useOverflows(
      row,
      shelfHeight,
      shelfWidth,
      products
    );
    const isEditingBay = mode === 'BayEditor';

    const justifyMargin =
      justifyContent === 'space_evenly'
        ? calculateJustifyMargin(shelfWidth, row, products)
        : 0;

    useEffect(() => {
      if (!(mode === 'CompartmentEditor' && detailMode !== 'comparison'))
        return;
      dispatch(
        updateOverflowState({
          rowIndex,
          overflow: compartmentOverflows.some((o) => o),
        })
      );
    }, [dispatch, compartmentOverflows, rowIndex, mode, detailMode]);

    const isModeCompartmentEditor =
      detailMode === 'comparison' ? isEditor : mode === 'CompartmentEditor';

    const originalMaxThreshold = getMaxValueInReport(
      profitTab,
      productSalesReport?.products
    );
    const comparedMaxThreshold = getMaxValueInReport(profitTab, undefined);

    const maxThreshold =
      originalMaxThreshold > comparedMaxThreshold
        ? originalMaxThreshold
        : comparedMaxThreshold;

    return (
      <>
        {/* TODO: 重複する可能性があるので、keyを変更する */}
        <Box
          component="div"
          // key={`${compartment.product_id}-${index}`}
          sx={{ position: 'absolute' }}
          left={convertMeterToPixel(left)}
          width={convertMeterToPixel(width)}
        >
          {product && (
            <Draggable
              itemType={ItemTypes.ITEM_GROUP}
              data={{ compartment, product }}
              index={index}
              rowIndex={rowIndex}
              canDrag={isModeCompartmentEditor && !isCompared}
            >
              <Box
                component="div"
                width="100%"
                height="100%"
                position="relative"
              >
                <ProductsGroup
                  productTag={productTag}
                  editorMode={mode}
                  isDragProduct={!!isDragProduct}
                  isComparisonCommonId={!!isComparisonCommonId}
                  isDecreaseOpacity={!!isDecreaseOpacity}
                  bucketMode={bucketMode}
                  productValue={productValue}
                  maxThreshold={maxThreshold}
                  justifyMargin={justifyMargin}
                  rowIndex={rowIndex}
                  index={index}
                  isSelected={isSelected}
                  product={product}
                  lastIndex={row?.length - 1}
                  setIsAllChildRenndered={setIsAllChildRenndered}
                  isOverflown={
                    !isEditor ? false : compartmentOverflows.at(index) ?? false
                  }
                  handleClickGroup={handleClickGroup}
                  diffColor={diffColor}
                  bboxColors={bboxColors}
                  bboxEnabled={bboxEnabled}
                  view={detailView}
                  mode={detailMode}
                  isCompared={isCompared}
                  countX={compartment.count?.x ?? 0}
                  countY={compartment.count?.y ?? 0}
                  orientation={compartment.orientation}
                  faceFront={compartment.face_front}
                />
              </Box>
            </Draggable>
          )}
        </Box>
        <Box
          component="div"
          sx={{ position: 'absolute', left: convertMeterToPixel(left) }}
        >
          {(detailMode === 'comparison'
            ? isEditor && !isEditingBay
            : !isEditingBay) &&
            item?.data &&
            !isCompared && <DisplayableHighlight dndData={item.data} />}
        </Box>
      </>
    );
  }
);

ProductCompartments.displayName = 'ProductCompartments';
