import { ShelfListItem } from '@components/organisms/shelfListItem/shelfListItem';

import { FC, memo, MutableRefObject } from 'react';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import { ListOptional } from './listOptional';
import { PlanogramProductCompartment, ProductPosition } from 'types/planogram';

type Props = {
  view: ShelfDetailView;
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  showOrganizationProductId: boolean;
  profitTab: ProfitTab;
  isSelected: boolean;
  product?: Product;

  compartment: PlanogramProductCompartment;
  compartmentIndex: number;
  indexY: number;
  handleClickListItem: (position: ProductPosition, product?: Product) => void;
};

export const CompartmentsListItem: FC<Props> = memo(
  ({
    view,
    refs,
    showOrganizationProductId,
    profitTab,
    isSelected,
    product,

    compartment,
    compartmentIndex,
    indexY,
    handleClickListItem,
  }) => {
    const position = {
      indexX: 0,
      indexY, //配列をreverseしているためindexYも反転させる
      subPosition: {
        indexX: compartmentIndex,
        indexY: 0,
      },
    };
    return (
      <ShelfListItem
        refs={refs}
        view={view}
        refKey={JSON.stringify(position)}
        product={product}
        isSelected={isSelected}
        handleClick={() => handleClickListItem(position, product)}
        name={product?.name}
        organizationProductId={
          showOrganizationProductId
            ? product?.detail?.organization_product_id
            : ''
        }
        eanCode={product?.detail?.ean_code}
        productCode={product?.detail?.organization_product_id}
      >
        <ListOptional
          compartment={compartment}
          handleClick={() => handleClickListItem(position, product)}
          profitTab={profitTab}
        />
      </ShelfListItem>
    );
  }
);

CompartmentsListItem.displayName = 'CompartmentsListItem';
