import { useSelectedItem } from '@components/pages/scannerDetails/hooks/useSelectedItem';
import { selectProductState } from '@reducers/product/selector';
import { useAppSelector } from '@store/index';
import { withOpacity } from '@utils/colors';
import {
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import { getAnalyticsLevel, isInferredAsProduct } from '@utils/product';
import { isCompartmentMode } from '@utils/realogram';
import { FC, Fragment, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductReport } from 'types/products';
import {
  BboxColors,
  RealogramCandidateFace,
  RealogramSelectedItem,
  RealogramShelfBoard,
  UnknownProduct,
} from 'types/realogram';
import { theme } from '../../../../theme';
import { Bbox } from './bbox';

type Props = {
  ratio: number;
  shelfBoards: RealogramShelfBoard[];
  bboxEnabled: boolean;
  markerEnabled: boolean;
  defaultBboxColor?: BboxColors;
  handleClickBbox?: (
    selectedItem: RealogramSelectedItem,
    isComparingBox: boolean
  ) => void;
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  rate?: Rate;
  maxThreshold?: number;
  profitTab?: ProfitTab;
  productReports?: ProductReport[];
  comparisonSameIdsSet?: Set<number>;
  unknownProductsInfo?: UnknownProduct[];
  isUnknownProductsReviseMode?: boolean;
  isComparingBox: boolean;
  allShelfBoards: RealogramShelfBoard[];
};

export const FlatBboxes: FC<Props> = ({
  ratio,
  shelfBoards,
  bboxEnabled,
  markerEnabled,
  view,
  mode,
  handleClickBbox,
  rate,
  maxThreshold,
  profitTab,
  productReports,
  comparisonSameIdsSet,
  unknownProductsInfo,
  isUnknownProductsReviseMode = false,
  defaultBboxColor,
  isComparingBox,
  allShelfBoards,
}) => {
  const { selectedItem: selectedRealogramItem } = useSelectedItem(
    allShelfBoards,
    isComparingBox
  );

  const comparisonSameIds = useAppSelector(selectProductState);
  const { pathname } = useLocation();
  const isActual = pathname.includes('actuals');
  const refHandleClickBbox =
    useRef<(item: RealogramSelectedItem, isComparingBox: boolean) => void>();
  useEffect(() => {
    refHandleClickBbox.current = handleClickBbox;
  });

  const memoHandleClickBbox = useCallback(
    (item: RealogramSelectedItem, isComparingBox: boolean) =>
      refHandleClickBbox.current?.(item, isComparingBox),
    []
  );
  return (
    <>
      {shelfBoards.map(({ id: shelfBoardId, compartments, shot_index }) => {
        return compartments.map((compartment, compartment_index) => {
          return compartment.faces.map((face, faceIndex) => {
            if (isCompartmentMode(view) && faceIndex > 0) {
              //show each compartment
              return (
                <Fragment
                  key={`${shelfBoardId}-${compartment.id}-${face.id}`}
                />
              );
            }

            const { x, y, width, height } = isCompartmentMode(view)
              ? compartment.bbox
              : face.bbox;

            const isSelected =
              selectedRealogramItem?.shelfBoardId === shelfBoardId;

            let productValue = 0;
            if (compartments.length > 0) {
              compartments.map((com) => {
                com.faces.map((face) => {
                  const productReport = productReports?.find(
                    (product) =>
                      product.product_id === face.primary_candidate?.product_id
                  );
                  const productValueTmp = getProductReportValue(
                    profitTab,
                    productReport
                  );
                  if (productValueTmp && productValueTmp > productValue) {
                    productValue = productValueTmp;
                  }
                });
              });
            }

            const isLocatedInPlanogram =
              !face.is_unknown &&
              comparisonSameIdsSet?.has(
                face.primary_candidate?.product_id ?? 0
              );
            const { color, bgColor } =
              defaultBboxColor ??
              getBboxColor(
                mode,
                view,
                isSelected,
                face,
                comparisonSameIds,
                isActual,
                rate,
                maxThreshold,
                productValue,
                profitTab,
                isLocatedInPlanogram,
                selectedRealogramItem?.isBboxClicked
              );

            const isUnknownProduct = unknownProductsInfo?.some(
              (unknownProduct) => unknownProduct.id === face.id
            );
            const isShowBorder =
              (isUnknownProduct && isUnknownProductsReviseMode) ||
              !isUnknownProductsReviseMode;

            return (
              <Bbox
                isComparingBox={isComparingBox}
                shelfBoardId={shelfBoardId}
                compartmentId={compartment.id}
                shotIndex={shot_index ?? 1}
                handleClickBbox={memoHandleClickBbox}
                key={`${shelfBoardId}-${compartment.id}-${face.id}`}
                bboxEnabled={bboxEnabled}
                ratio={ratio}
                markerEnabled={markerEnabled}
                x={x}
                y={y}
                width={width}
                height={height}
                isSelected={isSelected}
                color={color}
                bgColor={compartment_index === 0 ? bgColor : 'none'}
                isShowBorder={isShowBorder}
                face={face}
              />
            );
          });
        });
      })}
    </>
  );
};

export const getProductReportValue = (
  profitTab?: ProfitTab,
  product?: ProductReport
) => {
  if (!product || profitTab === undefined) return;
  switch (profitTab) {
    case profitTabSales:
      return product.gross_sales_price;
    case profitTabQuantity:
      return product.sales_count;
    case profitTabGrossProfit:
      return product.gross_profit_price;
    default:
      return 0;
  }
};

const divideNum = 5;

const getBboxColor = (
  mode: ShelfDetailMode,
  view: ShelfDetailView,
  isSelected: boolean,
  face: RealogramCandidateFace,
  comparisonSameIds: number[],
  isCompared?: boolean,
  rate?: Rate,
  maxThreshold?: number,
  productValue?: number,
  profitTab?: ProfitTab,
  isLocatedInPlanogram?: boolean,
  isBboxClicked?: boolean
) => {
  const basicColor = theme.palette.white.primary;
  const basicColorOpacity = 0.2;
  const basicColorWithOpacity = withOpacity(basicColor, basicColorOpacity);

  switch (view) {
    case 'default':
    case 'productFlag':
      switch (mode) {
        case 'comparison': {
          if (
            (isInferredAsProduct(face) &&
              face.primary_candidate?.product_id &&
              comparisonSameIds.includes(face.primary_candidate.product_id)) ||
            (!isCompared && isLocatedInPlanogram)
          ) {
            return { color: basicColor, bgColor: basicColorWithOpacity };
          }
          return {
            color: theme.palette.confidence.low,
            bgColor: theme.palette.confidence.lowOverlay,
          };
        }
        case 'default': {
          if (isSelected && !isBboxClicked) {
            const opacity = 0.4;
            return {
              color: theme.palette.primary.main,
              bgColor: withOpacity(theme.palette.primary.main, opacity),
            };
          }
          return { color: basicColor, opacity: basicColorWithOpacity };
        }
        default:
          return { color: basicColor, opacity: basicColorWithOpacity };
      }
    case 'profit': {
      // 「荒利」の場合、ヒートマップを表示しない
      if (!maxThreshold || !productValue || profitTab === profitTabGrossProfit)
        return {
          color: 'transparent',
          bgColor: 'transparent',
        };
      const dividedValue = maxThreshold / divideNum;
      const level = getAnalyticsLevel(productValue, dividedValue);

      return {
        color: theme.palette.heatmap[`0${level}_line`],
        bgColor: theme.palette.heatmap[`0${level}_bg_scan`],
      };
    }
    case 'rate':
      return rate === '評価OK'
        ? {
            color: theme.palette.confidence.high,
            bgColor: theme.palette.confidence.highOverlay,
          }
        : {
            color: theme.palette.confidence.low,
            bgColor: theme.palette.confidence.lowOverlay,
          };
    default:
      return { color: basicColor, opacity: basicColorWithOpacity };
  }
};
