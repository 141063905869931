import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { RealogramShelfBoard } from 'types/realogram';

export const useSelectedItem = (
  shelfBoards?: RealogramShelfBoard[],
  isComparingBox = false
) => {
  const {
    operate: { getSelectedItemOfRealogram },
  } = useRerenderingDetails();

  const { product, isEmpty } = getSelectedItemOfRealogram(
    shelfBoards,
    isComparingBox
  );

  return {
    selectedItem: isEmpty ? undefined : product,
    getSelectedItemOfRealogram,
  };
};
