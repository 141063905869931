import React, { useEffect, useRef, useState } from 'react';
import {
  GlobalWorkerOptions,
  PDFDocumentProxy,
  getDocument,
  version,
} from 'pdfjs-dist';

import { useIndexedPdfContext } from './indexedPdf';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;

const backgroundColor = '#aaa';
const fitScaleCoeff = 0.95;

type PdfPageProps = {
  pdfDocument: PDFDocumentProxy;
  pageNumber: number;
  scalePercent: number;
  visible: boolean;
};

const PdfPage: React.FC<PdfPageProps> = ({
  pdfDocument,
  pageNumber,
  scalePercent,
  visible,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isRendering = useRef(false);

  const containerWidth = containerRef.current?.offsetWidth;

  useEffect(() => {
    const renderPage = async () => {
      if (!visible) return;

      if (isRendering.current) return;

      isRendering.current = true;
      const page = await pdfDocument.getPage(pageNumber);

      const viewportTmp = page.getViewport({ scale: 1 });
      const fitScale = containerWidth
        ? (containerWidth / viewportTmp.width) * fitScaleCoeff
        : 1;
      const viewport = page.getViewport({ scale: fitScale });

      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');

      if (canvas && context) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport }).promise;
      }
      isRendering.current = false;
    };

    renderPage();
  }, [pdfDocument, pageNumber, scalePercent, containerWidth, visible]);

  return (
    <div
      ref={containerRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor,
        padding: '20px',
      }}
    >
      <canvas ref={canvasRef} style={{ backgroundColor: 'white' }} />
    </div>
  );
};

type PdfViewerProps = {
  url: string;
  name: string;
  initialPage?: number;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
};
export const PdfViewer: React.FC<PdfViewerProps> = ({
  url,
  initialPage = 1,
  width = '100%',
  height = '100%',
}) => {
  const { getPdfState } = useIndexedPdfContext();
  const indexedPdf = getPdfState(url);

  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy | null>(null);
  const [pageHeight, setPageHeight] = useState<number | undefined>(undefined);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState<number>(initialPage);
  const scalePercent = 100;

  useEffect(() => {
    if (pageHeight && initialPage > 0 && pageRefs.current[initialPage - 1]) {
      const targetPage = pageRefs.current[initialPage - 1];
      const container = containerRef.current;

      if (targetPage && container) {
        const targetOffsetTop = targetPage.offsetTop;
        const containerOffsetTop = container.offsetTop;
        const adjustedScrollTop = targetOffsetTop - containerOffsetTop;

        container.scrollTo({
          behavior: 'smooth',
          top: adjustedScrollTop,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [initialPage, pageRefs.current[initialPage - 1], pageHeight]);

  useEffect(() => {
    const loadPdf = async () => {
      if (indexedPdf && indexedPdf.data && 0 < indexedPdf.data.byteLength) {
        const pdf = await getDocument({
          data: new Uint8Array(indexedPdf.data), // 内部で空にされるのでコピーを渡す
          cMapUrl: `https://unpkg.com/pdfjs-dist@${version}/cmaps/`,
          cMapPacked: true,
        }).promise;
        setPdfDocument(pdf);

        // PDFの最初のページを取得して正確な高さを計算
        const firstPage = await pdf.getPage(1);
        const viewport = firstPage.getViewport({ scale: 1 });
        if (containerRef.current) {
          // should always be true though
          const containerWidth = containerRef.current.offsetWidth!;
          const fitScale = (containerWidth / viewport.width) * fitScaleCoeff;
          setPageHeight(viewport.height * fitScale);
        }
      }
    };

    loadPdf();
  }, [url, indexedPdf]);

  const [visiblePages, setVisiblePages] = useState<Set<number>>(new Set()); // 現在表示中のページ番号を保持
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setVisiblePages((prevVisiblePages) => {
          const updatedVisiblePages = new Set(prevVisiblePages);

          entries.forEach((entry) => {
            const pageIndex = Number(entry.target.getAttribute('data-page'));
            if (entry.isIntersecting) {
              updatedVisiblePages.add(pageIndex);
            } else {
              updatedVisiblePages.delete(pageIndex);
            }
          });

          return updatedVisiblePages;
        });
      },
      {
        root: containerRef.current,
        rootMargin: '0px',
        threshold: 0.1, // ページが10%以上表示されたら可視とみなす
      }
    );

    pageRefs.current.forEach((pageRef) => {
      if (pageRef) observer.observe(pageRef);
    });

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [containerRef.current, pageRefs.current]);

  const handleScroll = () => {
    if (!containerRef.current || !pdfDocument) return;

    const containerTop = containerRef.current.getBoundingClientRect().top;

    pageRefs.current.forEach((pageRef, index) => {
      if (pageRef) {
        const pageTop = pageRef.getBoundingClientRect().top;
        const pageHeight = pageRef.getBoundingClientRect().height;

        if (pageTop <= containerTop && pageTop + pageHeight > containerTop) {
          setPage(index + 1);
        }
      }
    });
  };

  return (
    <div
      style={{
        width,
        height,
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          height: '30px',
          width: '100%',
          backgroundColor: '#eee',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '40%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px',
          }}
        >
          <input
            type="number"
            value={page}
            onChange={(e) => {
              setPage(Number(e.target.value));
            }}
            style={{
              appearance: 'textfield',
              width: '50px',
            }}
          />
          <span style={{ marginLeft: '5pt' }}>/ {pdfDocument?.numPages}</span>
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div
        // {...pinchBind()}
        style={{
          overflowY: 'scroll',
          width: '100%',
          height: '100%',
          backgroundColor,
          padding: '10px',
          touchAction: 'auto',
        }}
        ref={containerRef}
        onScroll={handleScroll}
      >
        {pdfDocument &&
          Array.from({ length: pdfDocument.numPages }, (_, index) => (
            <div
              ref={(el) => {
                if (el) pageRefs.current[index] = el;
              }}
              data-page={index + 1}
              key={index}
              style={{
                height: pageHeight ? `${pageHeight}px` : 'auto',
              }}
            >
              <PdfPage
                pdfDocument={pdfDocument}
                pageNumber={index + 1}
                scalePercent={scalePercent}
                visible={visiblePages.has(index + 1)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
