import { Box, Divider, Modal, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const SectionDivider = () => (
  <Divider sx={{ marginTop: '10pt', marginBottom: '10pt' }} />
);

type SakuraModalProps = {
  title?: string;
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  closeButton?: boolean;
};

export const SakuraModal = ({
  title,
  open,
  handleClose,
  children,
  closeButton = false,
}: SakuraModalProps) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box component="div" sx={style}>
          <Stack>
            {title && (
              <Box
                component="div"
                minWidth="300px"
                sx={{
                  borderBottom: '1px solid lightgrey',
                  paddingLeft: 3,
                  paddingRight: 2,
                  py: 2,
                  backgroundColor: '#f4f4f4',
                }}
              >
                <Stack direction="row" alignItems="baseline">
                  <Typography variant="body2" color="#555">
                    {title}
                  </Typography>
                  <Box component="div" flexGrow={1} />
                  {closeButton && (
                    <Box
                      component="div"
                      onClick={handleClose}
                      sx={{ cursor: 'pointer' }}
                    >
                      <CloseIcon sx={{ fontSize: '12pt' }} />
                    </Box>
                  )}
                </Stack>
              </Box>
            )}
            <Box
              component="div"
              sx={{
                px: 4,
                py: 2,
              }}
            >
              {children}
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};
