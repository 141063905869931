import { useListProductsNoCacheQuery } from '@reducers/shelfAppsApi';
import {
  Dispatch,
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Product } from 'types/common';
import sakuraPatchedProducts from './sakuraPatchedProducts.json';

/*
 * 商品情報を取得するAPI (/api/v1/products)に商品CDを多数指定して呼ぶことがある
 * しかし、多くの商品CDを指定するとHTTPのリクエストサイズ制限（API Gatewayでは10KB）を超える
 * そのため、引数となる商品CDリスト自体を分割して複数回APIを呼ぶ必要がある
 * ReduxのRTK APIでフック化されたラッパーの場合、分割されたそれぞれのリクエストに対応する形で
 * コンポーネントが必要。
 * ProductsContextはそのためのコンポーネントを用意し、また読み込んだ商品情報を
 * コンテキストとして子componentから扱えるようにする。
 */

// 1ページ分の商品情報を取得してきてstateに積み重ねる薄いラッパーコンポーネント

type QueryProductsProps = {
  itemCds: string[];
  setProducts: Dispatch<SetStateAction<Product[]>>;
  reportComplete: () => void;
  skip: boolean;
};

const QueryProducts = ({
  itemCds,
  setProducts,
  reportComplete,
  skip,
}: QueryProductsProps) => {
  const res = useListProductsNoCacheQuery(
    {
      organizationProductId: itemCds,
      limit: 100,
      offset: 0,
      detail: true,
    },
    {
      skip: skip === true || itemCds.length === 0,
    }
  );

  useEffect(() => {
    if (skip || itemCds.length === 0 || !res || !res.data || res.isLoading) {
      return;
    }
    const pagerTotal = res.data.pager.total;
    const pagerLimit = res.data.pager.limit;
    if (pagerTotal && pagerLimit && pagerLimit < pagerTotal) {
      console.error('Pagination should not occur (not implemented)');
    }
    if (res.data.products.length !== 0) {
      setProducts((prev) => [...prev, ...(res.data?.products || [])]);
    }
    reportComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [res]);

  return <></>;
};

export type ProductsContextType = {
  products: Product[];
  isLoading: boolean;
};

const AllProductsContext = createContext<ProductsContextType | undefined>(
  undefined
);

type AllProductsProvider = {
  children: React.ReactNode;
  itemCds: string[];
  skip: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Actually used as a context provider component
export const AllProductsProvider = ({
  children,
  itemCds,
  skip,
}: AllProductsProvider) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [numCompletedWorkers, setNumCompletedWorkers] = useState<number>(0);

  // productsはlimit=100まで受け付けられるが、itemCdには重複がある（同じitemCdで複数のレコードが返る）
  // その分を見込んだ安全マージンが必要（あるいはページネートが必要だが未実装）
  const numPerPage = 50;
  const uniqItemCds = Array.from(new Set(itemCds));
  const itemCdsChunks = Array.from(
    { length: Math.ceil(uniqItemCds.length / numPerPage) },
    (_, i) => uniqItemCds.slice(i * numPerPage, (i + 1) * numPerPage)
  );

  const isLoading = skip !== true && numCompletedWorkers < itemCdsChunks.length;

  // product.detail!.organization_product_id!が重複している場合、
  // product.idを比較して最大のものを残してそれ以外を削除する
  const uniqConcatProducts: Product[] = Array.from(
    products
      .reduce((map, product) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know detail and organization_product_id exist at this point in the code flow
        const itemCd = product.detail!.organization_product_id!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know detail and organization_product_id exist at this point in the code flow
        if (!map.has(itemCd) || map.get(itemCd)!.id < product.id) {
          map.set(itemCd, product);
        }
        return map;
      }, new Map<string, Product>())
      .values()
  );

  // 130048の沖縄・全国版問題の雑な対処。
  // クエリには含まれてるがAPIで取れなかった商品のうち、patchリストに入っているものは、レスポンスに追加する
  const itemCdsNotFound = uniqItemCds.filter(
    (itemCd) =>
      uniqConcatProducts.find(
        (product) => product.detail?.organization_product_id === itemCd
      ) === undefined
  );
  const patchedItems = itemCdsNotFound
    .map((itemCd) =>
      sakuraPatchedProducts.sakuraPatchedProducts.find(
        (product) => product.detail.organization_product_id === itemCd
      )
    )
    .filter((p) => p !== undefined) as Product[];
  if (0 < patchedItems.length) {
    console.log('patchedItems', patchedItems);
  }

  const outProducts = [...uniqConcatProducts, ...patchedItems];
  const ctxValue = { products: outProducts, isLoading };
  return (
    <AllProductsContext.Provider value={ctxValue}>
      {itemCdsChunks.map((itemCdsChunk) => (
        <QueryProducts
          key={itemCdsChunk.join()}
          itemCds={itemCdsChunk}
          setProducts={setProducts}
          skip={skip}
          reportComplete={() => {
            setNumCompletedWorkers((p) => p + 1);
          }}
        />
      ))}
      {children}
    </AllProductsContext.Provider>
  );
};

export const useAllProductsContext = (
  itemCds?: string[]
): ProductsContextType => {
  const ctx = useContext(AllProductsContext);
  if (!ctx) {
    throw new Error('AllProductsContext is not provided');
  }
  if (itemCds === undefined) {
    return ctx;
  }

  const uniqItemCds = Array.from(new Set(itemCds));

  // itemCdsの各項目について、ctx.productsのproducts.detail!.organization_product_id!に
  // 含まれているものについてのproductを返す。
  const itemCdsNotFound: string[] = [];
  const products = uniqItemCds
    .map((itemCd) => {
      const product = ctx.products.find(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We have verified existence of this property in the data model
        (p) => p.detail!.organization_product_id === itemCd
      );
      if (!product) {
        itemCdsNotFound.push(itemCd);
      }
      return product;
    })
    .filter((p) => p !== undefined) as Product[];

  if (!ctx.isLoading && 0 < itemCdsNotFound.length) {
    console.log(
      'Product(s) queried but not found in the response',
      itemCdsNotFound
    );
  }
  return { ...ctx, products };
};
