import { SharePermissionButton } from '@components/molecules/sharePermissionButton/sharePermissionButton';
import {
  ModeToggle,
  ViewToggle,
} from '@components/organisms/viewModeToggle/fragments';
import { Box } from '@mui/material';
import { FC } from 'react';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { Scope } from 'types/sharePermission';

type Props = {
  mode: ShelfDetailMode;
  view: ShelfDetailView;
  handleChangeMode: (value: ShelfDetailMode) => void;
  handleChangeView: (value: ShelfDetailView) => void;
  handleSharePermission?: () => void;
  isActual?: boolean;
  scope?: Scope;
  disabled?: boolean;
  isShowAnalyticsButton?: boolean;
  isBucketType?: boolean;
};

export const ViewModeToggle: FC<Props> = ({
  mode,
  view,
  handleChangeMode,
  handleChangeView,
  handleSharePermission,
  scope,
  isActual = false,
  disabled = false,
  isShowAnalyticsButton = false,
  isBucketType = false,
}) => {
  return (
    <Box component="div" display="flex" gap={1}>
      <ViewToggle
        onChange={handleChangeView}
        value={view}
        isActual={isActual}
        disabled={disabled}
        isShowAnalyticsButton={isShowAnalyticsButton}
        isBucketType={isBucketType}
      />
      <ModeToggle
        onChange={handleChangeMode}
        value={mode}
        disabled={disabled}
      />
      <SharePermissionButton
        scope={scope}
        handleClickButton={handleSharePermission}
        disabled={disabled}
      />
    </Box>
  );
};
