import { realogramsApi } from '@api/services/realogramDirectories';
import { GetRealogramDirectoryApiV1RealogramDirectoriesGetParams } from '@api/types/generated';
import { realogramDirectoriesQueryKey } from '@components/pages/storeBays';
import { useQuery } from '@tanstack/react-query';
import { Condition, RealogramOrder } from 'types/realogram';

type Params = GetRealogramDirectoryApiV1RealogramDirectoriesGetParams & {
  enabled?: boolean;
  directoryId?: string;
  condition: Condition;
};
export const useRealogramDirectories = ({
  enabled = false,
  directoryId,
  condition,
}: Params) => {
  const { data, isLoading, isFetching } = useQuery({
    enabled,
    queryKey: [realogramDirectoriesQueryKey, directoryId, condition.firstOrder],
    queryFn: () =>
      realogramsApi.getRealogramDirectories({
        id: directoryId,
        status: condition?.status ? condition.status : undefined,
        first_order: condition?.firstOrder as RealogramOrder,
      }),
  });
  return {
    data,
    isLoading,
    isFetching,
  };
};
