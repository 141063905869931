import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  useGetAvailableItemsQuery,
  useGetExternalInfoQuery,
  useGetProductSaleQuery,
  useGetSakuraStoresQuery,
  useGetSakuraUsersQuery,
  useListExternalInfoQuery,
} from '@reducers/shelfAppsApi/injections/sakuraApi';
import { ModelGondolaViewer } from './fragments/modelGondolasViewer';
import { DO, ExternalInfoItem, ModelGondolaItem, Store, ZO } from './types';
import { SakuraAppBar } from './fragments/sakuraAppBar';
import { usePageTitle } from '@hooks/usePageTitle';
import { useGetUserQuery } from '@reducers/shelfAppsApi';
import { filterAvailableDoZoStores } from '@utils/sakuraUsers';
import {
  PatternSelector,
  PatternChoice,
  filterGondolasByChosenPatterns,
} from './fragments/patternSelector';
import { useModelGondola, useModelGondolaItems } from './sakuraUtils';
import { ModelGondolasContext } from './fragments/modelGondolasContext';
import { IndexedPdfProvider } from './fragments/pdf/indexedPdf';
import { ActionMemoFAB } from './fragments/storeActionMemo/fab';
import { ActionMemoProvider } from './fragments/storeActionMemo/storeActionMemoContext';
import { SelectBacknumberButton } from './fragments/selectModegonBacknumbersButton';
import SupportedGondolas from '@utils/gondolas.json';

export const Sakura: FC = () => {
  const navigate = useNavigate();

  const { data: meRes } = useGetUserQuery({ userId: 'me' });
  const { data: sakuraStoresRes } = useGetSakuraStoresQuery();
  const { data: sakuraUsersRes } = useGetSakuraUsersQuery();

  const sakuraUser =
    meRes &&
    sakuraUsersRes?.users.find((user) => user.email === meRes.user.email);

  const sakuraStoresFiltered = filterAvailableDoZoStores(
    sakuraStoresRes,
    sakuraUser
  );

  const { doCd, zoCd, storeCd } = useParams();
  const [_do, setDo] = useState<DO | undefined>(undefined);
  const [zo, setZo] = useState<ZO | undefined>(undefined);
  const [store, setStore] = useState<Store | undefined>(undefined);
  useEffect(() => {
    if (!sakuraStoresFiltered) {
      setZo(undefined);
      setDo(undefined);
      setStore(undefined);
    } else {
      const store = sakuraStoresFiltered.stores.find(
        (s) => s.storeCd === storeCd
      );
      if (!store) {
        navigate('/sakura/403');
        return;
      }

      setZo(sakuraStoresFiltered.zos.find((z) => z.zoCd === zoCd));
      setDo(sakuraStoresFiltered.dos.find((d) => d.doCd === doCd));
      setStore(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [sakuraStoresFiltered]);

  usePageTitle(`モデルゴンドラ ${store?.name || storeCd}店`);

  // この店に該当するモデゴン一覧を取得する。デフォルトでは最新を表示
  const [currentModelGondolaItem, setCurrentModelGondolaItem] = useState<
    ModelGondolaItem | undefined
  >(undefined);
  const { weekModelGondolas: currentWeekModelGondolas } = useModelGondola(
    currentModelGondolaItem
  );
  const { modelGondolaItems } = useModelGondolaItems(store);
  useEffect(() => {
    if (!modelGondolaItems) {
      return;
    }
    // デフォルトでは最新
    if (currentModelGondolaItem === undefined && modelGondolaItems.length > 0) {
      setCurrentModelGondolaItem(
        modelGondolaItems[modelGondolaItems.length - 1]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [modelGondolaItems]);

  // 商品アノテーション（情報パッケージ由来など）をリストし、とりあえず最新のものを取ってくる
  const { data: listExternalInfoRes } = useListExternalInfoQuery();
  const [externalInfoItem, setExternalInfoItem] = useState<
    ExternalInfoItem | undefined
  >(undefined);
  const { data: externalInfo } = useGetExternalInfoQuery(
    {
      path: { name: externalInfoItem?.name || 'dummy' },
    },
    { skip: !externalInfoItem }
  );
  useEffect(() => {
    if (
      !listExternalInfoRes ||
      listExternalInfoRes.items.length === 0 ||
      !currentModelGondolaItem
    ) {
      setExternalInfoItem(undefined);
      return;
    }
    // // モデゴンに対応する配信情報は、end_weekがモデゴン配信週の直前になっているもの
    // const extInfoBeforeModegonIssued = listExternalInfoRes.items
    //   .filter((item) => item.endWeek <= currentModelGondolaItem.week)
    //   .sort((a, b) => a.endWeek.localeCompare(b.endWeek));
    // if (extInfoBeforeModegonIssued.length === 0) {
    //   setExternalInfoItem(undefined);
    //   return;
    // }
    // const extInfo =
    //   extInfoBeforeModegonIssued[extInfoBeforeModegonIssued.length - 1];

    // ではなく、最新の配信情報を使う
    setExternalInfoItem(
      listExternalInfoRes.items[listExternalInfoRes.items.length - 1]
    );
  }, [listExternalInfoRes, currentModelGondolaItem]);

  const filteredExternalInfo = externalInfo && {
    ...externalInfo,
    annotations: externalInfo.annotations.filter((annot) => {
      if (!annot.effectiveRegion) {
        return true;
      }
      const regionType = annot.effectiveRegion.effectiveRegionType;
      const regionCd = annot.effectiveRegion.regionCode;
      if (regionType === 'DO') {
        return regionCd === doCd;
      } else if (regionType === 'ZO') {
        return regionCd === zoCd;
      } else {
        return false;
      }
    }),
  };

  // 売上
  const { data: productSalesRes } = useGetProductSaleQuery(
    {
      path: { storeCd: storeCd || '' },
    },
    { skip: storeCd === undefined }
  );

  // モデゴンごとの、取り扱いうる全商品（いわゆるPMA全商品）をリストする
  const { data: availableItems } = useGetAvailableItemsQuery();

  const [searchParams, setSearchParams] = useSearchParams();

  // パターン選択
  const patternIdx = Number(searchParams.get('pattern') || '0');
  const patternChoices =
    currentWeekModelGondolas?.patternedGondolas.map((g) => ({
      patternLabel: g.label,
      choiceName: g.choices[patternIdx].name,
    })) || [];
  const setPatternChoices = (choices: PatternChoice[]) => {
    const patternedGondolas = currentWeekModelGondolas?.patternedGondolas;
    if (patternedGondolas && 0 < patternedGondolas.length) {
      const choiceIdx = patternedGondolas[0].choices.findIndex(
        (choice) => choice.name === choices[0].choiceName
      );
      setSearchParams({
        gondolaCode,
        pattern: choiceIdx.toString(),
      });
    }
  };
  const filteredGondolas = currentWeekModelGondolas
    ? filterGondolasByChosenPatterns(currentWeekModelGondolas, patternChoices)
    : [];

  const modelGondolasContextValue = {
    value:
      currentWeekModelGondolas && storeCd && filteredExternalInfo
        ? {
            storeCd,
            modelGondolaWeek: currentWeekModelGondolas.week,
            modelGondolas: filteredGondolas,
          }
        : undefined,
  };

  // ゴンドラ名のURLパラメタ
  const gondolaCode = searchParams.get('gondolaCode') || '';
  const [currentGondolaCode, setCurrentGondolaCode] = useState<string>('');
  useEffect(() => {
    if (filteredGondolas.length) {
      const g = filteredGondolas.find((g) => g.gondolaCode === gondolaCode);
      if (!g) {
        // F1_2を開いたが今のパターンにない場合、F1_2のorigCodeをみてそれがあればそっちを開く
        // 縦置き3本・リーチイン1本なのにF1_2を開いたとき、114デフォルトに戻るんでなく、F1を開くと親切
        const origCode = SupportedGondolas.gondolaPmas.find(
          (g) => g.code === gondolaCode
        )?.origCode;
        const gOrigCode = filteredGondolas.find(
          (g) => g.gondolaCode === origCode
        );
        if (gOrigCode) {
          setSearchParams({
            gondolaCode: gOrigCode.gondolaCode,
            pattern: patternIdx.toString(),
          });
        } else {
          setSearchParams({
            gondolaCode: filteredGondolas[0].gondolaCode,
            pattern: patternIdx.toString(),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [gondolaCode, filteredGondolas]);
  useEffect(() => {
    if (currentGondolaCode !== '') {
      setSearchParams({
        gondolaCode: currentGondolaCode,
        pattern: patternIdx.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [currentGondolaCode]);

  // 管理者だったら古い版も出せる
  const canSeeBacknumbers =
    sakuraUser?.role === 'admin' || sakuraUser?.role === 'editor';

  return (
    <ModelGondolasContext.Provider value={modelGondolasContextValue}>
      <ActionMemoProvider>
        <IndexedPdfProvider>
          <Stack spacing={2}>
            <SakuraAppBar
              title="進化版モデルゴンドラ"
              breadcrumbs={[
                <Typography key="zo" fontSize={14}>
                  {zo?.name}ZO
                </Typography>,
                <Typography key="do" fontSize={14}>
                  {_do?.name}DO
                </Typography>,
                <Typography key="store" fontSize={14}>
                  {store?.name || storeCd}
                  {currentWeekModelGondolas &&
                    `（${currentWeekModelGondolas.week}版）`}

                  {
                    // 管理者だったら古い版も出せる
                    canSeeBacknumbers && modelGondolaItems && (
                      <SelectBacknumberButton
                        modelGondolaItems={modelGondolaItems}
                        setCurrentModelGondolaItem={setCurrentModelGondolaItem}
                      />
                    )
                  }
                </Typography>,
              ]}
              headerRightElements={
                store &&
                currentWeekModelGondolas && [
                  <PatternSelector
                    key="pattern-selector"
                    store={store}
                    weekModelGondolas={currentWeekModelGondolas}
                    patternChoices={patternChoices}
                    setPatternChoices={setPatternChoices}
                  />,
                ]
              }
            />
            <Stack spacing={2}>
              {currentWeekModelGondolas && storeCd && filteredExternalInfo && (
                <ModelGondolaViewer
                  gondolas={filteredGondolas}
                  externalInfo={filteredExternalInfo}
                  organizationStoreId={storeCd}
                  availableItems={availableItems}
                  storeProductSales={productSalesRes?.sales || []}
                  gondolaCode={gondolaCode}
                  setGondolaCode={setCurrentGondolaCode}
                />
              )}

              {!currentWeekModelGondolas && (
                <Box
                  component="div"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                >
                  <VisibilityOffIcon
                    sx={{ fontSize: '128', color: 'lightgrey' }}
                  />
                  <Typography variant="h6" color="lightgrey">
                    配信されているモデルゴンドラがありません。
                  </Typography>
                </Box>
              )}
            </Stack>
          </Stack>
        </IndexedPdfProvider>
        {filteredExternalInfo && productSalesRes && availableItems && (
          <ActionMemoFAB
            externalInfo={filteredExternalInfo}
            storeProductSales={productSalesRes.sales}
            availableItems={availableItems}
          />
        )}
      </ActionMemoProvider>
    </ModelGondolasContext.Provider>
  );
};
