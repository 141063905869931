import { ModalInnerProps } from '@components/modal';
import { HistoryIcon } from '@components/molecules/historyIcon/historyIcon';
import { SelectionModalActions } from '@components/molecules/selectionModalActions/selectionModalActions';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { Folder } from '@mui/icons-material';
import {
  setCurrentRealogramDirectoryId,
  updateRealogramSearch,
  updateSelectedItemId,
} from '@reducers/selectionStoreModal';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import {
  useGetUserQuery,
  useListRealogramCandidatesQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { AppAbility } from '@utils/abac';
import { FC, useEffect, useMemo, useState } from 'react';
import { RealogramDirectory, RealogramDirectorySearch } from 'types/realogram';
import { BaseModal } from '../baseModal/baseModal';
import { generateRealogramDirectories } from './fragments/generateRealogramDirectories';
import { SelectionRealograms } from './fragments/selectionRealograms';
import { useRealogramDirectoriesSearch } from '@api/index';
import { useRealogramDirectories } from '@api/hooks/useRealogramDirectories';

const sideBarList = [
  {
    icon: <Folder />,
    label: 'すべて',
    value: 'all',
  },
  {
    icon: <StarIcon />,
    label: 'スター付き',
    value: 'favorite',
  },
  {
    icon: <HistoryIcon />,
    label: '履歴',
    value: 'recent',
  },
];

const sideBarAll = sideBarList[0].value;
const sideBarFavorite = sideBarList[1].value;
const sideBarRecent = sideBarList[2].value;
type SelectionStoreModalProps = {
  handleChangeSelectedStoreModal: (selectedItem?: RealogramDirectory) => void;
  ability: AppAbility;
  hideStoreBay?: boolean;
};
type Props = ModalInnerProps<SelectionStoreModalProps>;

export const SelectionStoreModal: FC<Props> = ({ handleClose, data }) => {
  const { ability, handleChangeSelectedStoreModal } = data;
  const {
    currentSelectedItem,
    currentRealogramDirectoryId,
    currentSelectedItemId,
  } = useAppSelector(selectionStoreModal);
  const [search, setSearch] = useState('');
  const [sideBarValue, setSideBarValue] = useState('recent');
  const isFilteredByFavorite = sideBarValue === sideBarFavorite;
  const isFilterByAll = sideBarValue === sideBarAll;
  const isFilterByRecent = sideBarValue === sideBarRecent;
  const [offset, setOffset] = useState(0);
  const dispatch = useAppDispatch();
  const [realogramDirectoryId, setRealogramDirectoryId] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<
    RealogramDirectory | undefined
  >(currentSelectedItem);
  const { data: userData } = useGetUserQuery({
    userId: 'me',
  });

  const isSkipListRealogramDirectoriesQuery = !!search || isFilteredByFavorite;

  const {
    data: listRealogramDirectoryData,
    isLoading: isListRealogramDirectoryDataLoading,
    isFetching: isListRealogramDirectoryDataFetching,
  } = useRealogramDirectories({
    enabled: !isSkipListRealogramDirectoriesQuery,
    directoryId:
      currentRealogramDirectoryId && !realogramDirectoryId
        ? currentRealogramDirectoryId
        : realogramDirectoryId,
    condition: {},
  });

  const {
    data: searchedData,
    fetchNextPage,
    isFetching: isSearchFetching,
    isLoading: isSearchLoading,
    hasNextPage,
  } = useRealogramDirectoriesSearch({
    enabled: isSkipListRealogramDirectoriesQuery,
    name: search,
    first_order: 'created_at_desc',
    limit: 50,
    favorite_owner_id:
      isFilteredByFavorite && userData?.user.id
        ? [userData.user.id]
        : undefined,
    directory_type: 'directory',
  });

  const {
    data: recentRealogramCandidates,
    isFetching: isRecentFetching,
    isLoading: isRecentLoading,
  } = useListRealogramCandidatesQuery(
    {
      ownerId:
        isFilterByRecent && userData?.user.id ? [userData.user.id] : undefined,
      firstOrder: 'created_at_desc',
    },
    {
      skip: !isFilterByRecent,
    }
  );

  const isFetching =
    isSearchFetching ||
    isListRealogramDirectoryDataFetching ||
    isRecentFetching;

  const isLoading =
    isSearchLoading || isListRealogramDirectoryDataLoading || isRecentLoading;
  const searchedRealograms = searchedData?.filter((el) => {
    if (data.hideStoreBay) {
      return el.store_bay_id === null;
    }
    return true;
  });

  const candidates = Object.values(
    generateRealogramDirectories(
      recentRealogramCandidates?.realogram_candidates
    )
  );
  const realogramDirectories = useMemo(() => {
    if (isFilterByRecent) return candidates;
    const items =
      search || isFilteredByFavorite
        ? (searchedRealograms as RealogramDirectorySearch[])
        : (listRealogramDirectoryData?.realogram_directories as RealogramDirectory[]);
    return (items || []).filter((item) => item.type !== 'file');
  }, [
    search,
    isFilteredByFavorite,
    listRealogramDirectoryData,
    searchedRealograms,
    isFilterByRecent,
    candidates,
  ]);
  const handleClickSideBarChip = (value: string) => {
    clearSearchText();
    setSideBarValue(value);
    setOffset(0);
    setRealogramDirectoryId(undefined);
    dispatch(setCurrentRealogramDirectoryId(undefined));
  };

  const handleClickBack = () => {
    if (!listRealogramDirectoryData?.parent?.parent_id) return;
    setRealogramDirectoryId(listRealogramDirectoryData.parent.parent_id);
  };
  const clearSearchText = () => {
    setOffset(0);
    setSearch('');
    setRealogramDirectoryId(undefined);
  };
  const handleSearchText = (text: string) => {
    setOffset(0);
    setSideBarValue('all');
    setSearch(text);
  };

  const displayName = () => {
    if (isFilteredByFavorite) return 'スター付き';
    if (isFilterByRecent) return '店舗一覧';
    const { name, type } = listRealogramDirectoryData?.parent ?? {};
    if (type !== 'root' && name && !search) {
      return name;
    }
    return '店舗一覧';
  };

  const handleClickBreadcrumbs = (item: RealogramDirectory) => {
    if (item.type === 'root' || item.type === 'directory') {
      setOffset(0);
      setRealogramDirectoryId(item.id);
    }
  };

  const handleClickListItem = (item: RealogramDirectory) => {
    if (item.store_id) {
      setSelectedItem(item);
      dispatch(updateSelectedItemId(item.store_id.toString()));
      return;
    }
    setRealogramDirectoryId(item.id);
    setSideBarValue('all');
    setSearch('');
    setOffset(0);
    dispatch(updateRealogramSearch(undefined));
  };

  const threshold = 10;
  const shouldFetch =
    !!search &&
    realogramDirectories &&
    // continuous fetching if the length of the filtered data is less than 10
    realogramDirectories.length < threshold &&
    hasNextPage &&
    !isSearchFetching &&
    !isSearchLoading;

  useEffect(() => {
    if (shouldFetch) {
      void fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should be fire only if realogramSearchText is changed because this effect is use for continuous fetching
  }, [dispatch, realogramDirectories]);

  return (
    <BaseModal
      isUnderRoot={
        listRealogramDirectoryData?.parent &&
        listRealogramDirectoryData?.parent?.type !== 'root' &&
        isFilterByAll
      }
      listHeaderTitle={displayName()}
      handleClickSideBarChip={handleClickSideBarChip}
      sideBarHiddenValue=""
      sideBarValues={sideBarList}
      sideBarValue={sideBarValue}
      clearSearchText={clearSearchText}
      modalTitle="店舗"
      modalSubtitle="を選択"
      search={search}
      searchPlaceholder="キーワードで検索"
      handleSearchText={handleSearchText}
      handleClickBack={handleClickBack}
      list={
        <SelectionRealograms
          isLoading={isLoading}
          isFetching={isFetching}
          shouldFetch={shouldFetch}
          realogramOffset={offset}
          realogramDirectories={realogramDirectories}
          realogramBreadcrumbs={
            isFilterByAll
              ? (listRealogramDirectoryData?.breadcrumb as RealogramDirectory[])
              : undefined
          }
          realogramDirectoryParent={
            isFilterByAll
              ? (listRealogramDirectoryData?.parent as RealogramDirectory)
              : undefined
          }
          setRealogramOffset={setOffset}
          handleClickBreadcrumbs={handleClickBreadcrumbs}
          handleClickListItem={handleClickListItem}
          userId={userData?.user.id}
          textSearch={search}
          ability={ability}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      }
      footer={
        <SelectionModalActions
          handleClick={() => {
            handleChangeSelectedStoreModal(selectedItem);
          }}
          handleClose={() => {
            dispatch(updateSelectedItemId(currentSelectedItemId));
            if (selectedItem?.parent_id) {
              dispatch(setCurrentRealogramDirectoryId(selectedItem?.parent_id));
            }
            handleClose();
          }}
          disabledSelect={!selectedItem?.store_id}
        />
      }
    />
  );
};
