import { Product } from 'types/common';
import { ExternalInfo, StoreProductSale } from '../../types';
import {
  ModelGondolaAssignmentLocationFlat,
  ModelGondolaAssignmentLocationRegular,
  ModelGondolaProductAssignment,
} from '../../sakuraUtils';
import { Box, Stack, Typography } from '@mui/material';
import GondolaPmas from '@utils/gondolas.json';
import CommentIcon from '@mui/icons-material/Comment';
import Image from 'next/image';
import { useState } from 'react';
import { PopupPdfViewerModal } from './popupPdfViewerModal';
import Grid from '@mui/material/Unstable_Grid2';

type GondolaExistenceProps = {
  modelGondolaProductAssignment: ModelGondolaProductAssignment | undefined;
  sale: StoreProductSale;
};

const GondolaExistence = ({
  modelGondolaProductAssignment,
  sale,
}: GondolaExistenceProps) => {
  const gondola =
    modelGondolaProductAssignment &&
    GondolaPmas.gondolaPmas.find(
      (pma) => pma.code === modelGondolaProductAssignment.gondolaCode
    );
  const isInGondola = !!gondola;
  const isSoldInTheStore = 0 < sale.quantity;
  const isSoldInTheStoreText = isSoldInTheStore
    ? '店舗取扱あり（前週販売実績）'
    : '店舗取扱なし（前週販売実績）';

  let locStr = '';
  if (modelGondolaProductAssignment) {
    if (modelGondolaProductAssignment.location.type === 'regular') {
      const loc =
        modelGondolaProductAssignment.location as ModelGondolaAssignmentLocationRegular;
      locStr = `${loc.boardIndex + 1}段目`;
    } else {
      const loc =
        modelGondolaProductAssignment.location as ModelGondolaAssignmentLocationFlat;
      locStr = `${loc.containerName}`;
    }
  }
  // TODO: 当該モデゴンへのリンクも出せると嬉しい
  return (
    <Stack>
      {isInGondola ? (
        <Typography variant="caption2">
          モデルゴンドラ掲載：「{gondola.name}」の{locStr}
        </Typography>
      ) : (
        <Typography variant="caption2">モデルゴンドラ非掲載</Typography>
      )}
      {isInGondola === isSoldInTheStore ? (
        <Typography variant="caption2">{isSoldInTheStoreText}</Typography>
      ) : (
        <Typography variant="caption2" color="red" fontWeight="bold">
          {isSoldInTheStoreText}
        </Typography>
      )}
    </Stack>
  );
};

type ModelGondolaProductCommentProps = {
  modelGondolaProductAssignment: ModelGondolaProductAssignment | undefined;
};

export const ModelGondolaProductComment = ({
  modelGondolaProductAssignment,
}: ModelGondolaProductCommentProps) => {
  if (!modelGondolaProductAssignment) {
    return <></>;
  }
  const comment = modelGondolaProductAssignment.modelGondolaProduct.comment;
  if (!comment || comment.length === 0) {
    return <></>;
  }

  return (
    <Stack spacing={0.5}>
      <Box
        component="div"
        sx={{
          border: '1px solid lightgrey',
          borderRadius: '10px',
          backgroundColor: '#f9f9f9',
          padding: 1,
        }}
      >
        <Stack direction="row" spacing={0.7} paddingBottom={1}>
          <CommentIcon sx={{ fontSize: '12pt', color: '#666' }} />
          <Typography fontSize="8pt" color="#555">
            本部からの補足
          </Typography>
        </Stack>
        <Typography
          fontSize="9pt"
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {comment}
        </Typography>
      </Box>
    </Stack>
  );
};

type IconType = 'SNS' | '情報パッケージ' | '情報パッケージ（重要）';
type ExternalInfoItemProps = {
  product: Product;
  iconType: IconType;
  kind?: string;
  sourceName: string;
  pdfS3Id: string;
  pdfPage: number;
};

const ExternalInfoItem = ({
  product,
  iconType,
  kind,
  sourceName,
  pdfS3Id,
  pdfPage,
}: ExternalInfoItemProps) => {
  const imageSrc =
    iconType === 'SNS'
      ? '/sakura/sakura/sns_info.png'
      : '/sakura/sakura/wikimedia_pdf.png';
  const isImportant = iconType === '情報パッケージ（重要）';

  const [openPdf, setOpenPdf] = useState(false);
  const hasKind = !!(kind && 0 < kind.length);

  const kindSection = hasKind ? (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {iconType !== 'SNS' && (
        <Image
          src={
            isImportant
              ? '/sakura/sakura/info_icon_important.png'
              : '/sakura/sakura/info_icon.png'
          }
          alt={sourceName}
          width={12}
          height={12}
          // なんかbaselineが下のTypographyと一致してくれないので。。。
          style={{ marginBottom: '2px' }}
        />
      )}
      <Typography
        fontSize="9pt"
        fontWeight={isImportant ? 'bold' : 'normal'}
        color={isImportant ? '#a00' : '#000'}
      >
        {kind}
      </Typography>
    </Stack>
  ) : null;

  return (
    <>
      <Box
        component="div"
        sx={{
          width: '180px',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!openPdf) {
            setOpenPdf(true);
          }
        }}
      >
        <Stack direction="row" spacing={1}>
          <Image src={imageSrc} alt={sourceName} width={32} height={32} />

          <Stack>
            {kindSection}
            <Typography fontSize="7pt" color={hasKind ? '#555' : '#000'}>
              {sourceName} {pdfPage}ページ目
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <PopupPdfViewerModal
        product={product}
        open={openPdf}
        handleClose={() => {
          setOpenPdf(false);
        }}
        name={sourceName}
        pdfS3Id={pdfS3Id}
        pdfPage={pdfPage}
        additionalInfo={iconType !== 'SNS' ? kindSection : null}
      />
    </>
  );
};

type ExternalInfoListProps = {
  product: Product;
  externalInfo: ExternalInfo;
};

export const ExternalInfoList = ({
  product,
  externalInfo,
}: ExternalInfoListProps) => {
  const itemCd = product.detail!.organization_product_id!;
  const annots = externalInfo.annotations.filter(
    (annot) => annot.itemCd === itemCd
  );
  const newItems = externalInfo.newItems.filter(
    (item) => item.itemCd === itemCd
  );

  if (annots.length === 0 && newItems.length === 0) {
    return <></>;
  }

  const dateToStr = (dateStr: string) => {
    const date = new Date(dateStr);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  const items = [
    ...annots.map((annot) => ({
      iconType:
        annot.type === 'SNS'
          ? 'SNS'
          : annot.kindImportant
          ? '情報パッケージ（重要）'
          : '情報パッケージ',
      kind: annot.kind,
      sourceName: `${annot.name}(${dateToStr(annot.week)})`,
      pdfS3Id: annot.pdfS3Id,
      pdfPage: annot.pdfPage,
    })),
    ...newItems.map((newItem) => ({
      iconType: newItem.kindImportant
        ? '情報パッケージ（重要）'
        : '情報パッケージ',
      kind: 0 < newItem.kind.length ? newItem.kind : '新規',
      sourceName: `写真案内(${dateToStr(newItem.week)})`,
      pdfS3Id: newItem.pdfS3Id,
      pdfPage: newItem.pdfPage,
    })),
  ];

  return (
    <Box
      component="div"
      sx={{
        border: '1px solid lightgrey',
        borderRadius: '10px',
        p: 1,
      }}
    >
      <Stack spacing={1}>
        <Typography fontSize="8pt" sx={{ color: '#555' }}>
          本部配信情報
        </Typography>

        <Grid container spacing={1} maxWidth="400px">
          {items.map((item) => (
            <Grid xs={6} key={item.sourceName}>
              <ExternalInfoItem
                product={product}
                iconType={item.iconType as IconType}
                kind={item.kind}
                sourceName={item.sourceName}
                pdfS3Id={item.pdfS3Id}
                pdfPage={item.pdfPage}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

type Props = {
  product: Product;
  sale: StoreProductSale;
  externalInfo: ExternalInfo;
  modelGondolaProductAssignment: ModelGondolaProductAssignment | undefined;
};

export const ModelGondolaSection = ({
  product,
  sale,
  externalInfo,
  modelGondolaProductAssignment,
}: Props) => {
  return (
    <Stack spacing={1}>
      <Box component="div" width="300px">
        <GondolaExistence
          modelGondolaProductAssignment={modelGondolaProductAssignment}
          sale={sale}
        />
      </Box>

      <ModelGondolaProductComment
        modelGondolaProductAssignment={modelGondolaProductAssignment}
      />
      <ExternalInfoList product={product} externalInfo={externalInfo} />
    </Stack>
  );
};
