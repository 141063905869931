import { Box, Divider, Typography } from '@mui/material';
import { Product } from 'types/common';
import {
  RealogramCandidate,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { useModelGondolaAndAllProductsContext } from '../../utils/modelGondolaAndAllProductsProvider';
import { useAllProductsContext } from '@utils/ProductsContext';
import { listModelGondolaProducts } from '@components/pages/sakura/sakuraUtils';
import { ModelGondola } from '@components/pages/sakura/types';
import { theme } from 'theme';
import { ListSubheader } from '@components/molecules/listSubheader/listSubheader';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { FC } from 'react';
import { t } from 'i18next';

const getItemCdsFromRealogramCandidate = (
  realogramCandidate: RealogramCandidate,
  products: Product[]
): string[] => {
  const shelfBoards = realogramCandidate.detail?.products_shelves.shelf_boards;
  const itemCds = shelfBoards
    ?.flatMap((shelf) =>
      shelf.compartments.flatMap((compartment) =>
        compartment.faces.map(
          (face) =>
            products.find((p) => p.id === face.primary_candidate?.product_id)
              ?.detail?.organization_product_id
        )
      )
    )
    .filter((itemCd) => itemCd);
  return itemCds ? Array.from(new Set(itemCds as string[])) : [];
};

type ProductFlagPrintLabelProps = {
  name: string;
  backgroundColor?: string;
  textColor?: string;
};

export const ProductFlagPrintLabel: FC<ProductFlagPrintLabelProps> = ({
  name,
  backgroundColor,
  textColor,
}) => {
  return (
    <Box
      component="div"
      sx={{
        height: 10,
        padding: '2px 4px',
        backgroundColor:
          backgroundColor || theme.palette.backgroundBlack.active,
        borderRadius: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: 6, color: textColor || '#444444' }}>
        {name}
      </Typography>
    </Box>
  );
};

type Props = {
  products?: Product[];
  shelfBoards?: RealogramShelfBoard[];
};

export const RealogramPrintItems = ({ products, shelfBoards }: Props) => {
  const modelGondolaContext = useModelGondolaAndAllProductsContext();
  const externalInfo = modelGondolaContext?.externalInfo;
  const relevantModelGondolas = modelGondolaContext?.relevantModelGondolas;
  const productSales = modelGondolaContext?.productSales;

  // ゴンドラに載りうる全商品
  const gondolaPossibleProducts = useAllProductsContext()?.products;
  const gondolaPossibleItemCds =
    gondolaPossibleProducts?.map((p) => p.detail?.organization_product_id) ||
    [];

  // 同じ日の同じ店の他のrealogramを全部みて、置いてある全商品とそれがどのrealogramにあるか見る
  // このゴンドラの対象にならないような商品は含まれない
  // （gondolaPossibleItemCdsでフィルタしたProduct[]に入ってるものだけ返すため）
  const realogramCandidatesForTheDay =
    modelGondolaContext?.realogramCandidatesForTheDay || [];
  const itemCdsFromRealogramCandidates = realogramCandidatesForTheDay.flatMap(
    (realogramCandidate) =>
      getItemCdsFromRealogramCandidate(
        realogramCandidate,
        gondolaPossibleProducts || []
      ).map((itemCd) => ({ itemCd, realogramCandidate }))
  );

  /*
   * 集計ユーリティティ
   */

  const findRealogramItem = (product: Product) => {
    for (const shelf of shelfBoards || []) {
      for (const compartment of shelf.compartments) {
        for (const face of compartment.faces) {
          if (face.primary_candidate?.product_id === product.id) {
            return {
              shelfBoardId: shelf.id,
              compartmentId: compartment.id,
              item: face,
            } as RealogramSelectedItem;
          }
        }
      }
    }
    return undefined;
  };

  const classifyItemCdPresence = (itemCd: string) => {
    const product = gondolaPossibleProducts.find(
      (p) => p.detail?.organization_product_id === itemCd
    );
    const realogramItem = product && findRealogramItem(product);
    if (!realogramItem) {
      // 今表示しているrealogram上においては未陳列な商品たち
      const itemOnOtherRealogram = itemCdsFromRealogramCandidates.find(
        (item) => item.itemCd === itemCd
      );
      if (itemOnOtherRealogram) {
        // 他のrealogramに陳列されている商品
        const realogramCandidate = itemOnOtherRealogram.realogramCandidate;
        return {
          presence: 'displayedElsewhere' as const,
          sortKey: 1,
          itemCd,
          realogramCandidate,
        };
      } else {
        return {
          presence: 'notDisplayed' as const,
          sortKey: 0,
          itemCd,
        };
      }
    }
    // 今表示しているrealogram上において陳列されている商品
    return {
      presence: 'displayedHere' as const,
      sortKey: 2,
      itemCd,
      realogramSelectedItem: realogramItem,
    };
  };

  // 新規推奨
  const itemCdsNew = Array.from(
    new Set(
      externalInfo?.newItems
        .map((item) => item.itemCd)
        .filter((itemCd) => gondolaPossibleItemCds.includes(itemCd)) || []
    )
  );

  const itemCdsHansoku =
    externalInfo?.annotations
      .filter(
        (item) =>
          item.type === '情報パッケージ' &&
          gondolaPossibleItemCds.includes(item.itemCd)
      )
      .map((item) => item.itemCd) || [];

  const itemCdsSns = Array.from(
    new Set(
      externalInfo?.annotations
        .filter(
          (item) =>
            item.type === 'SNS' && gondolaPossibleItemCds.includes(item.itemCd)
        )
        .map((item) => item.itemCd) || []
    )
  );

  const itemCdsKihon =
    gondolaPossibleProducts
      ?.filter((product) =>
        (product.detail?.tags || []).includes(t('attributes.base_product'))
      )
      .filter(
        (product) =>
          !(product.detail?.tags || []).includes(t('attributes.sales_ended'))
      )
      .filter(
        (product) =>
          !(externalInfo?.cutItems || []).includes(
            product?.detail?.organization_product_id || ''
          )
      )
      .map((product) => product.detail?.organization_product_id) || [];

  const itemCdsModelGondola = Array.from(
    new Set(
      relevantModelGondolas?.flatMap((gondola) =>
        listModelGondolaProducts(gondola as ModelGondola).map(
          (product) => product.productCode
        )
      ) || []
    )
  );

  // 商品を全てまとめて重みをつける
  const allItems = Array.from(
    new Set([
      ...(products?.map((p) => p.detail?.organization_product_id) || []),
      ...itemCdsNew,
      ...itemCdsHansoku,
      ...itemCdsSns,
      ...itemCdsKihon,
      ...itemCdsModelGondola,
    ])
  )
    .sort((a, b) => {
      const salesA =
        productSales?.find((p) => p.itemCd === a)?.doAdoptionRate || 0;
      const salesB =
        productSales?.find((p) => p.itemCd === b)?.doAdoptionRate || 0;
      return salesB - salesA;
    })
    .map((itemCd) =>
      gondolaPossibleProducts.find(
        (p) => p.detail?.organization_product_id === itemCd
      )
    )
    .filter((p) => p !== undefined)
    .map((p) => {
      const tags = [];
      let weight = 0;
      const itemCd = p?.detail?.organization_product_id || '';
      if (itemCdsNew.includes(itemCd)) {
        const info = externalInfo?.newItems.find(
          (item) => item.itemCd === itemCd
        );

        tags.push(info?.kind || t('assortment.new_sales'));
        weight += 0.5;
      }
      if (itemCdsHansoku.includes(itemCd)) {
        const anno = externalInfo?.annotations.find(
          (item) => item.itemCd === itemCd
        );

        tags.push(anno?.kind || '販促商品');
        weight += 1;
      }
      if (itemCdsSns.includes(itemCd)) {
        tags.push('SNS話題商品');
        weight += 0.5;
      }
      if (itemCdsKihon.includes(itemCd)) {
        tags.push(t('assortment.base_product'));
        weight += 1.0;
      }
      if (itemCdsModelGondola.includes(itemCd)) {
        weight += 0.25;
      }

      if (
        externalInfo?.cutItems.includes(itemCd) ||
        (p?.detail?.tags || []).includes(t('attributes.sales_ended'))
      ) {
        tags.push(t('attributes.sales_ended'));
        weight = -1;
      }

      return {
        product: p,
        weight: weight,
        tags: tags,
        presence: classifyItemCdPresence(itemCd),
      };
    })
    .filter((item) => {
      return (
        (item.presence.presence === 'displayedHere' && item.weight < 0) ||
        (item.presence.presence === 'notDisplayed' && item.weight > 0)
      );
    });

  type ItemT = (typeof allItems)[number];
  const renderRow = (item: ItemT) => {
    const product = item.product;
    const name = product?.name.replace(/\s\s+/g, '　') || '';

    return (
      <Box
        component="div"
        key={item.presence.itemCd}
        width="140px"
        display="flex"
        flexDirection="row"
      >
        <Box
          component="span"
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
          minWidth={28}
          minHeight={28}
          position="relative"
          margin="0 6px 0 6px"
        >
          <ProductImage
            product={product}
            layout="fill"
            objectFit="contain"
            width={28}
            height={28}
          />
        </Box>

        <Box component="div" display="flex" flexDirection="column">
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="1px"
            flexWrap="wrap"
          >
            <Typography variant="caption" fontSize="7px">
              {item.presence.itemCd}
            </Typography>
            {item.tags.map((tag) => {
              // 重みが負 = カット推奨で現在は推奨取消のみ
              if (tag == t('attributes.sales_ended')) {
                const endDateStr = product?.detail?.sales_end_date;
                const endDate = endDateStr && new Date(endDateStr);

                const mmdd =
                  endDate && `${endDate.getMonth() + 1}/${endDate.getDate()}`;

                return (
                  <ProductFlagPrintLabel
                    key={tag}
                    name={
                      mmdd ? `${t('attributes.sales_ended')}済(${mmdd})` : tag
                    }
                    backgroundColor={theme.palette.error.light}
                    textColor={theme.palette.error.contrastText}
                  />
                );
              } else {
                const textColor =
                  tag === t('assortment.new_sales') ||
                  tag === '販促商品' ||
                  tag === t('assortment.base_product')
                    ? theme.palette.textBlack.primary
                    : theme.palette.primary.contrastText;

                const bgColor =
                  tag === t('assortment.new_sales') ||
                  tag === '販促商品' ||
                  tag === t('assortment.base_product')
                    ? theme.palette.grey[300]
                    : theme.palette.primary.light;

                return (
                  <ProductFlagPrintLabel
                    key={tag}
                    name={tag}
                    backgroundColor={bgColor}
                    textColor={textColor}
                  />
                );
              }
            })}
          </Box>

          <Typography
            variant="body1"
            fontWeight="bold"
            fontSize="7px"
            component="span"
          >
            {name}
          </Typography>
        </Box>
      </Box>
    );
  };

  const cutItems = allItems.filter((item) => item.weight < 0);
  const addItems = allItems
    .filter((item) => item.weight > 0)
    .sort((a, b) => {
      return b.weight - a.weight;
    })
    .filter((item, i) => {
      const cutItemRows = Math.ceil(cutItems.length / 5);
      const row = Math.ceil(i / 5) + cutItemRows;
      return row < 15;
    });

  return (
    <>
      <Box component="div" mb={3}>
        <ListSubheader
          title="カット推奨（陳列商品のみ）"
          backgroundColor={theme.palette.backgroundBlack.active}
          color={theme.palette.textBlack.primary}
          sx={{
            fontWeight: 400,
            height: '30px',
            justifyContent: 'space-between',
          }}
          isRealogram
          isCanEditFace={false}
        />
        <Divider />
        <Box
          component="div"
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          gap={1}
        >
          {cutItems.map(renderRow)}
        </Box>
      </Box>
      <Divider />
      <Box component="div">
        <ListSubheader
          title="追加推奨（未陳列商品のみ）"
          backgroundColor={theme.palette.backgroundBlack.active}
          color={theme.palette.textBlack.primary}
          sx={{
            fontWeight: 400,
            height: '30px',
            justifyContent: 'space-between',
          }}
          isRealogram
        />

        <Divider />
        <Box
          component="div"
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          gap="8px"
          marginTop="10px"
        >
          {addItems.map(renderRow)}
        </Box>
      </Box>
    </>
  );
};
