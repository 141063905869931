import { NotFound } from '@components/NotFound';
import { MoveBucketButtons } from '@components/molecules/moveBucketButtons/moveBucketButtons';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { headerHeight } from '@components/organisms/header/header';
import { PlanogramHeader } from '@components/organisms/planogramHeader/planogramHeader';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { ZoomableFlatPlanogram } from '@components/organisms/zoomableFlatPlanogram/zoomableFlatPlanogram';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { FlatComparison } from '@components/pages/planogramEditor/flatPlanogram/fragments/flatComparison';
import { RightSideArea } from '@components/pages/planogramEditor/fragments/rightSideArea';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import {
  ManipulateQueriesData,
  useUrlQueryParams,
} from '@hooks/useUrlQueryParams';
import { useZoomController } from '@hooks/useZoomController';
import { Box, ToggleButton } from '@mui/material';
import { updateBottomBucketsNum } from '@reducers/flatPlan';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  changeEditorMode,
  changeIsRotateTransitionFlatPlanogram,
  forget as forgetPlanogramEditorState,
  rotateGondola,
  selectBayPartId,
  setSelectedBucketId,
  setSelectedBucketIdClone,
  updateBucketMode,
  updateDetailMode,
  updateIsShowBayPartDetail,
  updateIsShowProductDetail,
  updateSelectedProductCompartmentClone,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  fullHeight,
  fullRotation,
  getDisplayValue,
  getProfitsMenu,
  planogramRightSideHeight,
  pointersDummy,
  rotationAngleFlatPlanogram,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  getBucketsProductIds,
  getFlatPlanogramAreaWidthAndCenter,
  isPlanogramBucketPlan,
} from '@utils/planogram';
import httpStatus from 'http-status';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'theme';
import { BucketMode, Planogram, Position } from 'types/planogram';
import { PreviewDragLayer } from '../fragments/previewDragLayer';
import { ThreeDPreview } from '../fragments/threeDPreview';

const defaultDelayTouchStart = 50; /* ms */

type Props = {
  planogram?: Planogram;
  planogramId: number;
  isLoading: boolean;
  isFetching: boolean;
  delay?: string;
  error?: FetchBaseQueryError | SerializedError;
  isForbidden?: boolean;
};

export const FlatPlanogram: FC<Props> = ({
  planogram,
  planogramId,
  isLoading,
  isFetching,
  delay,
  error,
  isForbidden,
}) => {
  const dispatch = useAppDispatch();
  const {
    zoomScale,
    handleIncrementZoom,
    handleDecrementZoom,
    setZoomScale,
    handleTriggerZoom,
  } = useZoomController();
  const { removeQueryParameter, manipulateQueryParameters } =
    useUrlQueryParams();
  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogram,
    isPlanogram: true,
    isCan: true,
  });

  const [name, setName] = useState('');
  const [bayPlanCodeId, setBayPlanCodeId] = useState(
    planogram?.bay_plan_code_id
  );
  const [organizationStatusId, setOrganizationStatusId] = useState(
    planogram?.organization_status_id
  );
  const [bboxEnabled, setBboxEnabled] = useState(true);

  const { plan, isDirty, mark, updateBucketProductPosition, changeFront } =
    useFlatPlanogramPlan(
      isPlanogramBucketPlan(planogram?.plan) ? planogram?.plan : undefined
    );

  const {
    planogramEstimatedData,
    handleChangeProfit,
    storeAreaType,
    profit,
    handleRecalculate,
    isLoadingEstimate,
  } = useEstimatedProfit({ planogram: planogram, editedPlan: plan });

  const {
    selectedBayPartId,

    mode,

    bucketMode,

    isTooltipOpen,
    selectedBucketIdClone,
    selectedProductCompartmentClone,
    detailMode,
    isSwappingBayPartMode,
    rotateDeg,
  } = useAppSelector(selectPlanogramEditorState);

  const {
    selector,
    data: { isTenantSalesAnalytics },
  } = useRerenderingDetails();
  const productIds = isPlanogramBucketPlan(planogram?.plan)
    ? planogram?.plan.frame.detail.buckets
        ?.flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
        .join(',')
    : undefined;
  const { data: productsBulk } = useListProductsBulkQuery(
    { productIds: productIds, shape: true, detail: true },
    { skip: !productIds }
  );

  const { selectedProductCompartment, selectedBucketId, position } =
    useSelectedPlanogramItem({
      products: productsBulk?.products,
    });
  const productPosition = position as Position[];

  useEffect(() => {
    // compare
    if (selector.modeQueryParams) {
      dispatch(updateDetailMode(selector.modeQueryParams));
    }
  }, [dispatch, selector]);

  useEffect(() => {
    return () => {
      dispatch(updateIsShowProductDetail(false));
      dispatch(updateIsShowBayPartDetail(false));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateBottomBucketsNum(0));
      dispatch(forgetPlanogramEditorState());
    };
  }, [dispatch]);

  const handleClickAwayPlanogram = () => {
    if ((selectedBucketId || selectedBayPartId) && !isSwappingBayPartMode) {
      dispatch(selectBayPartId(undefined));
      dispatch(updateIsShowBayPartDetail(false));
    }
    if (
      (productPosition || selectedProductCompartment) &&
      selectedBucketId?.toString()
    ) {
      if (bucketMode === 'area') dispatch(setSelectedBucketId(undefined));

      manipulateQueryParameters({
        removeQueries: ['item', 'compareItem', 'position'],
      });
      dispatch(updateIsShowProductDetail(false));
      dispatch(updateSelectedProductCompartmentClone(undefined));
    }

    if (
      !productPosition &&
      !selectedProductCompartment &&
      selectedBucketId?.toString() &&
      !isTooltipOpen &&
      !isSwappingBayPartMode
    ) {
      dispatch(setSelectedBucketId(undefined));
      dispatch(updateIsShowProductDetail(false));
      dispatch(updateSelectedProductCompartmentClone(undefined));
    }

    if (selectedBucketId === undefined && bucketMode === 'area') {
      dispatch(setSelectedBucketIdClone(undefined));
    }

    removeQueryParameter('item');
    updateBucketProductPosition(undefined);
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeBayPlanCodeId = (value?: number) => {
    setBayPlanCodeId(value);
  };

  const handleChangeOrganizationStatusId = (value?: number) => {
    setOrganizationStatusId(value);
  };

  const handleChangeBucketMode = (value: BucketMode) => {
    if (value !== null) dispatch(updateBucketMode(value));
    if (value === 'area') {
      if (selectedProductCompartment) {
        dispatch(
          updateSelectedProductCompartmentClone(selectedProductCompartment)
        );
        dispatch(updateIsShowProductDetail(false));

        removeQueryParameter('item');
      } else {
        dispatch(setSelectedBucketIdClone(undefined));
      }
    } else {
      const queryParams: ManipulateQueriesData = {
        removeQueries: [],
        updateQueries: [],
      };
      if (selectedBucketIdClone !== undefined) {
        queryParams.updateQueries?.push({
          key: 'selectedBucketId',
          value: String(selectedBucketIdClone),
        });

        dispatch(setSelectedBucketIdClone(undefined));
      }

      if (selectedProductCompartmentClone) {
        dispatch(updateIsShowProductDetail(true));

        queryParams.updateQueries?.push({
          key: 'item',
          value: String(selectedProductCompartmentClone.id),
        });
      }

      if (selectedProductCompartment) dispatch(updateIsShowProductDetail(true));

      if (
        !productPosition &&
        !selectedProductCompartment &&
        selectedBucketId?.toString() &&
        !isTooltipOpen &&
        !selectedProductCompartmentClone
      ) {
        queryParams.removeQueries?.push('position');
      }
      manipulateQueryParameters(queryParams);
    }
  };

  useEffect(() => {
    if (!planogram) return;
    setName(planogram.name);
    setBayPlanCodeId(planogram.bay_plan_code_id);
    setOrganizationStatusId(planogram.organization_status_id);
  }, [planogram]);

  const { areaWidth, displayPosition } = useMemo(() => {
    return getFlatPlanogramAreaWidthAndCenter(plan.frame.detail);
  }, [plan.frame.detail]);

  const [width, setWidth] = useState(0);

  const scrollRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(displayPosition, 0);
    }
  }, [scrollRef, displayPosition]);

  useEffect(() => {
    const handleResize = () => {
      if (scrollRef?.current) {
        const newWidth = scrollRef.current.offsetWidth;
        setWidth(newWidth);
      }
    };

    // コンポーネントがマウントされた時に幅を取得
    handleResize();

    // ウィンドウのリサイズ時に幅を取得
    window.addEventListener('resize', handleResize);

    // クリーンアップ関数でイベントリスナーを削除
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isLarger } = useBreakpoint();

  // todo: shelfと共通化できるか検討。（共通化すると表示不備が起こるため原因調査)
  if (!!error && 'status' in error && error.status === httpStatus.NOT_FOUND) {
    return <NotFound title="棚割計画（棚エディタ）" />;
  }
  if (mode === 'preview' && planogram) {
    return (
      <ThreeDPreview planogram={planogram} plan={plan} isDirty={isDirty} />
    );
  }

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};

  const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  const changeFrontTime = 500;
  const handleChangeFront = () => {
    dispatch(updateLoadingIndicatorState(true));
    dispatch(changeIsRotateTransitionFlatPlanogram(false));
    changeFront();
    dispatch(
      rotateGondola(
        rotateDeg % fullRotation === 0 ? rotationAngleFlatPlanogram : 0
      )
    );
    setTimeout(() => {
      dispatch(updateLoadingIndicatorState(false));
    }, changeFrontTime);
  };

  return (
    <Box
      component="div"
      sx={{
        height: fullHeight,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      <PlanogramHeader
        name={name}
        bayPlanCodeId={bayPlanCodeId}
        organizationStatusId={organizationStatusId}
        planogram={planogram}
        planogramIsLoading={isLoading}
        handleChangeName={handleChangeName}
        handleChangeBayPlanCodeId={handleChangeBayPlanCodeId}
        handleChangeOrganizationStatusId={handleChangeOrganizationStatusId}
        planogramIsFetching={isFetching}
        plan={plan}
        isDirty={isDirty}
        mark={mark}
        setZoomScale={setZoomScale}
      />
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={{ delayTouchStart: Number(delay ?? defaultDelayTouchStart) }}
      >
        <PlanogramProvider value={{ products: productsBulk?.products }}>
          {detailMode === 'comparison' ? (
            <FlatComparison
              handleChangeBucketMode={handleChangeBucketMode}
              handleClickAwayPlanogram={handleClickAwayPlanogram}
              planogram={planogram}
              plan={plan}
              profit={profit}
              planogramEstimatedData={planogramEstimatedData}
              storeAreaType={storeAreaType}
              handleRecalculate={handleRecalculate}
              handleChangeProfit={handleChangeProfit}
              handleChangeFront={handleChangeFront}
              isTenantSalesAnalytics={isTenantSalesAnalytics}
              compareQueryParams={selector.compareQueryParams}
            />
          ) : (
            <>
              <Box
                component="div"
                position="relative"
                sx={{
                  display: 'flex',
                  height: `calc(${fullHeight} - ${headerHeight}px)`,
                  flexDirection: { xs: 'column', breakpoint: 'row' },
                }}
              >
                <Box
                  data-planogram-container="data-planogram-container"
                  ref={scrollRef}
                  component="div"
                  height={{
                    xs: `calc(${fullHeight} - ${headerHeight}px - ${planogramRightSideHeight}px)`,
                    md: '100%',
                  }}
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  sx={{
                    overflowX: 'scroll',
                    background: theme.palette.shelf.backgroundTana,
                  }}
                >
                  <Box
                    component="div"
                    height="100%"
                    width={`${areaWidth * zoomScale}px`}
                    position="relative"
                    display="flex"
                    flexDirection="column"
                  >
                    {planogram?.store_id && isTenantSalesAnalytics && (
                      <Box
                        component="div"
                        p="8px 16px"
                        position="fixed"
                        width={`${width}px`}
                        sx={{
                          backgroundColor: theme.palette.white.primary,
                        }}
                      >
                        <StatisticsValueSelector
                          value={getDisplayValue(
                            profit,
                            planogramEstimatedData?.estimate.summary,
                            t('gross_profit')
                          )}
                          profits={getProfitsMenu(t('gross_profit'))}
                          pointers={pointersDummy}
                          selectedProfitType={profit}
                          selectedPointerType={storeAreaType}
                          handleChangeProfitValue={handleChangeProfit}
                          handleRefreshValue={handleRecalculate}
                          hasRefreshButton
                          category="シミュレーション"
                          term={term}
                          disabled={isSwappingBayPartMode}
                          isLoading={isLoadingEstimate}
                          detailMode={detailMode}
                        />
                      </Box>
                    )}

                    <ZoomableFlatPlanogram
                      bboxEnabled={bboxEnabled}
                      plan={plan}
                      scale={zoomScale}
                      handleClickAway={handleClickAwayPlanogram}
                      zoomScale={zoomScale}
                      setZoomScale={setZoomScale}
                      handleChangeFront={handleChangeFront}
                    />
                  </Box>
                </Box>
                {(selectedBucketId?.toString() ||
                  (selectedBucketId === undefined &&
                    bucketMode === 'area' &&
                    selectedBucketIdClone !== undefined)) &&
                  !isSwappingBayPartMode && (
                    <Box
                      component="div"
                      position="absolute"
                      bottom={48}
                      width={width}
                      px={3}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MoveBucketButtons
                        handleNavigateBefore={() => console.log('before')}
                        handleNavigateNext={() => console.log('next')}
                      />
                      <StyledToggleButtonGroup
                        orientation="horizontal"
                        value={bucketMode}
                        exclusive
                        sx={{ background: theme.palette.white.primary }}
                        onChange={(_, value: BucketMode) =>
                          handleChangeBucketMode(value)
                        }
                      >
                        <ToggleButton value="compartment">商品</ToggleButton>
                        <ToggleButton value="area">エリア</ToggleButton>
                      </StyledToggleButtonGroup>
                    </Box>
                  )}

                <Box
                  component="div"
                  sx={{
                    maxWidth: { xs: '100%', breakpoint: '506px' },
                    width: '100%',
                    boxSizing: 'border-box',
                    background: '#FFF',
                    borderLeft: '1px solid #EDEDED',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: {
                      xs: planogramRightSideHeight,
                      breakpoint: '100%',
                    },
                    height: '100%',
                  }}
                >
                  <RightSideArea
                    bayPlanId={planogram?.bay_plan_id ?? 0}
                    isProductDetailRow={!isLarger}
                    selectedBucketId={selectedBucketId}
                    selectedProductCompartment={selectedProductCompartment}
                  />
                </Box>
              </Box>
              <PreviewDragLayer scale={zoomScale} />
            </>
          )}
        </PlanogramProvider>
      </DndProvider>
      {detailMode !== 'comparison' && !isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top={24}
          zIndex={5}
          sx={{ transform: `translate(0px, 24px)` }}
        >
          <PlanButtonGroup
            bboxEnabled={bboxEnabled}
            handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
            handleChangeView={() => dispatch(changeEditorMode('preview'))}
            isOrientationModalOpen={false}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleTriggerZoom={handleTriggerZoom}
            zoomScale={zoomScale}
            isEditor
            isFlatPlanogram
          />
        </Box>
      )}
      <NotPermittedModal
        id={planogramId}
        open={!(isLoading || (planogram && isCanUpdate)) || !!isForbidden}
        errorMessage="この棚割の編集権限がありません"
        buttonMessage="棚割詳細へ戻る"
      />
    </Box>
  );
};
