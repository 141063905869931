import { ProductImage } from '@components/organisms/productImage/productImage';
import { ImageBox } from '@components/organisms/shelfListItem/fragments';
import { Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';
import {
  ProductCandidate,
  RealogramSelectedItem,
  ProductCandidateWithName,
} from 'types/realogram';
import { FailedGetMostHighScoreCard } from './failedGetMostHighScoreCard';

type Props = {
  selectedItem: RealogramSelectedItem;
  mostHighScoreProduct?: Product;
  mostHighScore?: ProductCandidate;
  isCanNotEditProductAndCompartment?: boolean;
  handleModalOpen: () => void;
  handleChangeCandidate?: (
    mostHighScoreProduct: ProductCandidateWithName
  ) => void;
  slideButtons?: ReactNode;
};

export const ConfidentProductCard: FC<Props> = ({
  selectedItem,
  mostHighScoreProduct,
  mostHighScore,
  isCanNotEditProductAndCompartment,
  handleModalOpen,
  handleChangeCandidate,
  slideButtons,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      width="100%"
      alignItems="flex-end"
      gap={2}
    >
      <Box
        component="div"
        mt="16px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '4px',
        }}
      >
        <Box component="div">
          <Typography fontWeight={500} fontSize={12} lineHeight="18px">
            最も確信度の高い商品
          </Typography>
        </Box>
        {selectedItem.item.primary_candidate ? (
          <Box
            component="div"
            display="flex"
            justifyContent="space-evenly"
            flexDirection="row"
            alignItems="center"
            sx={{
              height: '60px',
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              borderRadius: '4px',
              padding: '8px 12px',
              gap: '8px',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <ImageBox>
              <ProductImage
                product={mostHighScoreProduct}
                layout="fill"
                objectFit="contain"
              />
            </ImageBox>
            <Typography
              component="p"
              variant="body3"
              textAlign="left"
              sx={{
                wordBreak: 'break-all',
                maxWidth: '248px',
                minWidth: '108px',
                display: '-webkit-box',
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitBoxOrient: 'vertical',
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitLineClamp: 3,
                textOverflow: 'ellipsis',
              }}
            >
              {mostHighScoreProduct?.name}
            </Typography>
            <Button
              sx={{
                backgroundColor: `${theme.palette.primary.main}`,
                height: '28px',
                minWidth: '40px',
                p: '0 8px',
                ':hover': {
                  backgroundColor: theme.palette.primary.mainHover,
                },
              }}
              onClick={() =>
                mostHighScore &&
                handleChangeCandidate &&
                handleChangeCandidate({ ...mostHighScore, sourceType: 'top10' })
              }
            >
              <Typography
                component="p"
                variant="buttonText2"
                color="#fff"
                fontSize="0.7rem"
              >
                決定
              </Typography>
            </Button>
          </Box>
        ) : (
          <FailedGetMostHighScoreCard cardMinHeight="60px" />
        )}
        <Box component="div">
          <Button
            disabled={isCanNotEditProductAndCompartment}
            variant="text"
            onClick={handleModalOpen}
            sx={{
              marginLeft: selectedItem?.item.is_unknown ? 0 : '16px',
            }}
            startIcon={<Edit fontSize="small" />}
          >
            <Typography variant="buttonText2">その他の商品に訂正</Typography>
          </Button>
        </Box>
      </Box>
      <Box component="div" height="100%" ml="auto">
        {slideButtons}
      </Box>
    </Box>
  );
};
