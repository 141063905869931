import { Box, Button, Typography } from '@mui/material';
import { paths } from '@utils/const';
import Image from 'next/legacy/image';
import { ChangeEvent, FC } from 'react';
import { theme } from 'theme';
import { StepFiveInfo } from 'types/realogram';
import { useImagePreview } from '../../hooks/useImagePreview';
import { t } from 'i18next';

type Props = {
  isFlat: boolean;
  stepFiveInfo?: StepFiveInfo;
  handleNavigate: (nextPath: string) => void;
  isXs?: boolean;
  handleChangeFile: (e: ChangeEvent<HTMLInputElement>) => void;
};

const buttonSx = {
  height: '48px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.dividerBlack.dark}`,
  px: '20px',
  gap: 1,
  backgroundColor: theme.palette.white.primary,
  color: theme.palette.textBlack.primary,
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '16px',
};

export const RealogramDetail: FC<Props> = ({
  isFlat,
  stepFiveInfo,
  handleNavigate,
  isXs = false,
  handleChangeFile,
}) => {
  const { src: firstFileSrc } = useImagePreview(stepFiveInfo?.firstFile);
  const { src: secondFileSrc } = useImagePreview(stepFiveInfo?.secondFile);

  const flatDimensions = {
    width: 240,
    height: 180,
  };

  const regularDimensions = {
    width: 180,
    height: 240,
  };
  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.backgroundBlack.primary,
        flexWrap: {
          xs: 'wrap',
          sm: 'nowrap',
        },
      }}
      justifyContent={{ xs: 'center', sm: 'flex-start' }}
      flexDirection={isFlat ? 'column' : 'row'}
    >
      {firstFileSrc && (
        <Box component="div" display="flex" flexDirection="row" gap="4px">
          <Image
            src={firstFileSrc}
            alt=""
            width={isFlat ? flatDimensions.width : regularDimensions.width}
            height={isFlat ? flatDimensions.height : regularDimensions.height}
            loading="lazy"
            objectFit="contain"
          />
          {isFlat && secondFileSrc && (
            <Image
              src={secondFileSrc}
              alt=""
              width={flatDimensions.width}
              height={flatDimensions.height}
              loading="lazy"
              objectFit="contain"
            />
          )}
        </Box>
      )}
      <Box
        component="div"
        gap={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={{
          xs: '100%',
          sm: 'auto',
        }}
        alignItems={{ xs: 'center', sm: isFlat ? 'center' : 'flex-start' }}
        padding={3}
      >
        <Box
          component="div"
          gap="12px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            component="div"
            gap={2}
            display="flex"
            justifyContent="flex-start"
          >
            <Typography variant="subtitle2" width="85px">
              店舗
            </Typography>
            <Typography variant="body2">{stepFiveInfo?.storeName}</Typography>
          </Box>
          <Box
            component="div"
            gap={2}
            display="flex"
            justifyContent="flex-start"
          >
            <Typography variant="subtitle2" width="85px">
              什器
            </Typography>
            <Typography variant="body2">
              {stepFiveInfo?.storeBayName}
            </Typography>
          </Box>
          {stepFiveInfo?.bayPlanName && (
            <Box
              component="div"
              gap={2}
              display="flex"
              justifyContent="flex-start"
            >
              <Typography variant="subtitle2" width="85px">
                {t('shelf_type')}
              </Typography>
              <Typography variant="body2">
                {stepFiveInfo?.bayPlanName}
              </Typography>
            </Box>
          )}
        </Box>
        {!isXs && (
          <Box component="div" display="flex" gap={1} pr="20px">
            <Button
              sx={buttonSx}
              onClick={() =>
                handleNavigate(paths.actuals.id(String(stepFiveInfo?.id)))
              }
            >
              この棚割を見る
            </Button>
            <Button
              variant="outlined"
              aria-describedby="menuButton"
              component="label"
              sx={{
                ...buttonSx,
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
            >
              <input
                type="file"
                accept="image/jpeg"
                onChange={handleChangeFile}
                hidden
              />
              続けてスキャン
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
