import { CircularSpinner } from '@components/molecules';
import { ComparisonStatistics } from '@components/organisms/comparisonStatistics/comparisonStatistics';
import { headerHeight } from '@components/organisms/header/header';
import { SearchModal } from '@components/organisms/searchModal/searchModal';
import { useSelectedItem } from '@components/pages/scannerDetails/hooks/useSelectedItem';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { useRealogramAnalyticsData } from '@hooks/useRealogramAnalyticsData';
import { useSelectedPlanogramItem } from '@hooks/useSelectedPlanogramItem';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';
import {
  CurrentSelectedType,
  setCurrentTab,
  updateCurrentSelectedItemId,
  updateCurrentSelectedType,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { selectModalProps } from '@reducers/modal/selector';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import {
  useGetProductQuery,
  useListProductsBulkQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  ComparisonTabModal,
  fullHeight,
  planogramRightSideHeight,
} from '@utils/const';
import {
  calcFlatPlanStatistics,
  getBucketsProductIds,
  getProductsLayout,
  isPlanogramBucketPlan,
  isPlanogramShelvesDetail,
} from '@utils/planogram';
import { isInferredAsProduct } from '@utils/product';
import { calculateRealogramStatistics } from '@utils/realogram';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'theme';
import { Product, ShelfDetailView } from 'types/common';
import {
  BucketPlanogramPlan,
  CompartmentBucketPosition,
  Planogram,
} from 'types/planogram';
import { getBucketCompartmentsByView } from '../utils';
import { FlatPlanogramComparisonProduct } from './flatPlanogramComparisonProduct';
import { FlatPlanogramImages } from './flatPlanogramImages';
import { ProductPosition } from '@reducers/flatPlan';
type Props = {
  planogram?: Planogram;
  plan: BucketPlanogramPlan;
  products?: Product[];
  positions?: CompartmentBucketPosition[][];
  isTenantSalesAnalytics?: boolean;

  compareQueryParams?: {
    id: number;
    type: CurrentSelectedType;
  };
};

export const FlatComparison: FC<Props> = ({
  planogram,
  products,
  plan,
  positions,
  isTenantSalesAnalytics,

  compareQueryParams,
}) => {
  const [searchParams] = useSearchParams();
  const { manipulateQueryParameters } = useUrlQueryParams();
  const dispatch = useAppDispatch();
  const { isLarger } = useBreakpoint();
  const { open: isOpenModal } = useSelector(selectModalProps);
  const {
    detailView,
    detailMode,
    rateValue,
    productTag,

    profitTab,
  } = useAppSelector(selectPlanogramEditorState);
  const originPlanogramId = planogram?.link?.origin_planogram_id;
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const originRealogramCandidateId =
    planogram?.link?.origin_realogram_candidate_id;
  const {
    isLoading,
    comparedPlanogram,
    comparedRealogram,
    isForbidden,
    fetchGetPlanogram,
  } = useComparisonItemModal(
    currentSelectedType === 'actual' ||
      (originRealogramCandidateId && !currentSelectedItemId?.toString())
      ? 'actual'
      : ''
  );
  const comparedRealogramShelfboards =
    comparedRealogram?.detail?.products_shelves.shelf_boards;
  const { selectedItem: selectedRealogramItem } = useSelectedItem(
    comparedRealogramShelfboards,
    true
  );

  const { data: selectedRealogramProduct } = useGetProductQuery(
    {
      productId: selectedRealogramItem?.item.primary_candidate?.product_id ?? 0,
    },
    { skip: !selectedRealogramItem?.item.primary_candidate?.product_id }
  );

  const handleClickBack = () => {
    manipulateQueryParameters({
      removeQueries: ['item', 'compareItem', 'position', 'selectedBucketId'],
    });
  };
  const { updateModeQueryParams } = useUpdateUrlQueryParamsOfDetailPages();
  const [modalOpen, setModalOpen] = useState(false);
  const hasDivider = (isLarger: boolean, view: ShelfDetailView) => {
    if (!isLarger) return false;
    if (view === 'default') return true;
    return false;
  };

  const comparisonProductIds = () => {
    if (comparedPlanogram) {
      if (isPlanogramBucketPlan(comparedPlanogram.plan)) {
        return (comparedPlanogram.plan.frame.detail.buckets ?? [])
          .flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
          .sort()
          .join(',');
      }
      if (isPlanogramShelvesDetail(comparedPlanogram.plan)) {
        return getProductsLayout(comparedPlanogram.plan)
          .flatMap(({ row }) => row.map(({ product_id }) => product_id))
          .sort()
          .join(',');
      }
    }
    if (comparedRealogramShelfboards) {
      return comparedRealogramShelfboards
        .flatMap((shelfboard) =>
          shelfboard.compartments.flatMap((compartment) =>
            compartment.faces
              .filter((face) => isInferredAsProduct(face))
              .map((face) => face.primary_candidate?.product_id)
          )
        )
        .sort()
        .join(',');
    }
    return '';
  };

  const { data: comparisonProductsBulk } = useListProductsBulkQuery(
    { productIds: comparisonProductIds(), shape: true, detail: true },
    { skip: !comparisonProductIds() }
  );

  const isCompared = searchParams.get('item') ? false : true;
  const { selectedProductCompartment, position } = useSelectedPlanogramItem({
    products: isCompared ? comparisonProductsBulk?.products : products,
    isCompared,
  });

  const productPosition = position as ProductPosition;

  const { planogramEstimatedData: comparedPlanogramEstimatedData } =
    useEstimatedProfit({
      planogram: comparedPlanogram,
    });

  const { planogramEstimatedData } = useEstimatedProfit({
    planogram: planogram,
  });
  const {
    analyticsDataTerm: comparedAnalyticsDataTerm,
    displayAnalyticsData: displayComparedAnalyticsData,
    isLoadingAnalyticsData: isLoadingComparedAnalyticsData,
    disableChangeToPreviousWeek: disableChangeToComparedPreviousWeek,
    disableChangeToNextWeek: disableChangeToComparedNextWeek,
    handleNextWeekTerm: handleComparedNextWeekTerm,
    handlePreviousWeekTerm: handleComparedPreviousWeekTerm,
  } = useRealogramAnalyticsData(
    comparedRealogram?.id ?? 0,
    !comparedRealogram || !isTenantSalesAnalytics || comparedRealogram?.id === 0
  );

  const { filteredShelfBoards: comparedShelfboards } = useExtractRealogramData(
    detailView,
    productTag,
    displayComparedAnalyticsData?.products ?? [],
    rateValue,
    comparedRealogramShelfboards
  );

  const statistics = useMemo(
    () => calcFlatPlanStatistics(plan, detailView, productTag, products),
    [plan, productTag, detailView, products]
  );

  const comparisonStatistics = useMemo(() => {
    if (comparedPlanogram) {
      return calcFlatPlanStatistics(
        comparedPlanogram.plan,
        detailView,
        productTag,
        comparisonProductsBulk?.products
      );
    }
    if (comparedRealogramShelfboards) {
      return calculateRealogramStatistics(
        comparedRealogramShelfboards,
        comparisonProductsBulk?.products
      );
    }
  }, [
    comparedPlanogram,
    comparedRealogramShelfboards,
    detailView,
    productTag,
    comparisonProductsBulk?.products,
  ]);

  const comparedReport = useMemo(() => {
    // add planogram report after api is ready
    if (comparedPlanogram) {
      return comparedPlanogramEstimatedData?.estimate;
    }
    if (comparedRealogram) {
      return displayComparedAnalyticsData;
    }
  }, [
    comparedPlanogram,
    comparedRealogram,
    displayComparedAnalyticsData,
    comparedPlanogramEstimatedData?.estimate,
  ]);

  useEffect(() => {
    if (originRealogramCandidateId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedType('actual'));
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        detailView,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `actual-${originRealogramCandidateId}`,
        }
      );
      void fetchGetPlanogram(originRealogramCandidateId, 'actual');
    } else if (originPlanogramId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedType('plan'));
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        detailView,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `plan-${originPlanogramId}`,
        }
      );
      void fetchGetPlanogram(originPlanogramId, 'plan');
    } else if (currentSelectedItemId?.toString() && currentSelectedType) {
      void fetchGetPlanogram(currentSelectedItemId, currentSelectedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- updateModeQueryParams only updates queryParams the first time the page loads, Avoid falling into an infinite loop
  }, [
    currentSelectedItemId,
    currentSelectedType,
    detailMode,
    detailView,
    dispatch,
    fetchGetPlanogram,
    originPlanogramId,
    originRealogramCandidateId,
    productTag,
    profitTab,
    rateValue,
  ]);

  // First drawing: Item information is compared
  useEffect(() => {
    const { id: queryId, type: queryType } = compareQueryParams || {};
    const shouldSkip = !queryId || !queryType || isOpenModal;
    if (shouldSkip) return;

    if (queryType === 'plan') {
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
    } else if (queryType === 'actual') {
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
    }
    dispatch(updateSelectedItemId(queryId));
    dispatch(updateCurrentSelectedItemId(queryId));
    dispatch(updateCurrentSelectedType(queryType));
  }, [compareQueryParams, isOpenModal, dispatch]);

  return (
    <>
      <Divider orientation="horizontal" flexItem />
      <Box
        component="div"
        display="flex"
        flexDirection={{ xs: 'column', breakpoint: 'row' }}
        height={`calc(${fullHeight} - ${headerHeight}px)`}
      >
        <Box
          component="div"
          flex={{ xs: 'unset', breakpoint: 1 }}
          height={{
            xs: `calc(${fullHeight} - ${headerHeight}px - 170px)`,
            breakpoint: `calc(${fullHeight} - ${headerHeight}px)`,
          }}
          overflow="hidden"
        >
          <Box component="div" display="flex" flex={1} height="100%">
            <PlanogramProvider
              value={{
                products,
                comparisonProducts: comparisonProductsBulk?.products,
                comparisonProductSalesReport:
                  comparedPlanogramEstimatedData?.estimate,
                productSalesReport: planogramEstimatedData?.estimate,
              }}
            >
              <FlatPlanogramImages
                planogram={planogram}
                comparedPlanogram={comparedPlanogram}
                comparedRealogram={comparedRealogram}
                isLoading={isLoading}
                isForbidden={isForbidden}
                currentSelectedType={currentSelectedType}
                comparedShelfboards={comparedShelfboards}
                comparisonProductIds={comparisonProductIds()}
                isBucketType={false} // To make header style direction is always 'column'
                isTenantSalesAnalytics={isTenantSalesAnalytics}
                comparedAnalyticsData={displayComparedAnalyticsData}
                comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
                isLoadingComparedAnalyticsData={isLoadingComparedAnalyticsData}
                disableChangeToComparedPreviousWeek={
                  disableChangeToComparedPreviousWeek
                }
                disableChangeToComparedNextWeek={
                  disableChangeToComparedNextWeek
                }
                handleComparedNextWeekTerm={handleComparedNextWeekTerm}
                handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
              />
            </PlanogramProvider>
          </Box>
        </Box>
        <Divider orientation={isLarger ? 'vertical' : 'horizontal'} flexItem />
        <Box
          component="div"
          p={2}
          minWidth={{ xs: 'unset', breakpoint: '254px' }}
          maxWidth={{ xs: 'unset', breakpoint: '254px' }}
          maxHeight={{
            xs: planogramRightSideHeight,
            breakpoint: '100%',
          }}
          height="100%"
          overflow="auto"
        >
          {selectedProductCompartment ||
          productPosition ||
          (selectedRealogramItem &&
            comparedRealogram?.shot_type !== 'split_bucket') ? (
            <Box component="div">
              <Button
                variant="outlined"
                sx={{
                  width: '72px',
                  height: '32px',
                  padding: '0 12px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  '& .MuiButton-startIcon': { margin: '0' },
                }}
                startIcon={
                  <ArrowBackIos
                    sx={{
                      color: theme.palette.primary.main,
                      width: '1rem',
                      height: '1rem',
                    }}
                    fontSize="small"
                  />
                }
                onClick={handleClickBack}
              >
                戻る
              </Button>
              {(selectedProductCompartment ||
                selectedRealogramProduct ||
                selectedRealogramItem) && (
                <>
                  <FlatPlanogramComparisonProduct
                    view={detailView}
                    product={
                      selectedProductCompartment ||
                      selectedRealogramProduct?.product
                    }
                    positions={positions}
                    comparisonPositions={getBucketCompartmentsByView(
                      detailView,
                      productTag,
                      isPlanogramBucketPlan(comparedPlanogram?.plan)
                        ? comparedPlanogram?.plan
                        : undefined,
                      comparisonProductsBulk?.products
                    )}
                    selectedRealogramItem={selectedRealogramItem}
                    comparisonPlanogram={comparedPlanogram}
                    comparisonRealogram={comparedRealogramShelfboards}
                  />
                  {hasDivider(isLarger, detailView) && (
                    <Divider sx={{ mx: -2, mt: 2 }} />
                  )}
                </>
              )}
            </Box>
          ) : (
            <Box component="div" height="100%">
              {isLoading && (
                <CircularSpinner
                  sx={{ display: 'flex', alignItems: 'center' }}
                />
              )}
              {!isLoading && (
                <ComparisonStatistics
                  statistics={statistics}
                  comparisonStatistics={comparisonStatistics}
                  isComparedWithPlanogram={!comparedRealogram}
                  comparedReport={comparedReport}
                  report={planogramEstimatedData?.estimate}
                  isPlanPage
                  isFlatPlanogram
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <SearchModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};
