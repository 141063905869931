import { Box, List, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { embed } from '@utils/const';
import { hasProductTag } from '@utils/product';
import { FC, MutableRefObject, useEffect, useState } from 'react';
import { Product, ProfitTab } from 'types/common';
import { PlanogramPlan, ProductPosition } from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { ShelfListHeader } from '../shelfListHeader/shelfListHeader';
import { CompartmentsList } from './fragments/compartmentsList';
import { HeaderOptional } from './fragments/headerOptional';

type Props = {
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  products?: Product[];
  productsLayout: PlanogramPlan['products_layout'];
  handleClickListItem: (position: ProductPosition, product?: Product) => void;
  profitTab: ProfitTab;
  productSales?: ProductReportProduct[];
  productPosition?: ProductPosition;
};

export const CompartmentsTable: FC<Props> = ({
  refs,
  products,
  productsLayout,
  handleClickListItem,
  profitTab,
  productSales,
  productPosition,
}) => {
  const { detailView: view, productTag } = useAppSelector(
    selectPlanogramEditorState
  );
  const [showOrganizationProductId, setShowOrganizationProductId] =
    useState(false);

  useEffect(() => {
    const embedParam = new URLSearchParams(window.location.search).get('embed');
    if (embedParam === embed) setShowOrganizationProductId(true);
  }, []);

  return (
    <>
      <ShelfListHeader>
        <HeaderOptional profitTab={profitTab} />
      </ShelfListHeader>
      <List
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        subheader={<Box component="li" />}
      >
        {!!productsLayout.length && //[]の時(ロード中)にemptyを表示しない
          //TODO: リファクタ
          (productsLayout.every(({ row }) => !row.length) ||
            ((view === 'productFlag' || view === 'rate') &&
              productsLayout.every(({ row }) => {
                return row.every(({ product_id }) => {
                  const product = products?.find((p) => p.id === product_id);
                  return !hasProductTag(productTag, product?.detail?.tags);
                });
              }))) &&
          view !== 'default' &&
          view !== 'rate' && (
            <Typography sx={{ color: '#999999', mt: 5, textAlign: 'center' }}>
              該当する区画がありません
            </Typography>
          )}
        {productsLayout.map(({ row }, shelfIndex) => {
          return (
            <CompartmentsList
              key={shelfIndex}
              shelfIndex={shelfIndex}
              view={view}
              indexY={productsLayout.length - shelfIndex - 1}
              productTag={productTag}
              products={products}
              productSales={productSales}
              refs={refs}
              showOrganizationProductId={showOrganizationProductId}
              productPosition={productPosition}
              profitTab={profitTab}
              row={row}
              handleClickListItem={handleClickListItem}
            />
          );
        })}
      </List>
    </>
  );
};
