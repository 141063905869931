import { ListSubheader } from '@components/molecules/listSubheader/listSubheader';
import { EmptyCompartment } from '@components/organisms/realogramCandidatesList/fragments';
import { findProductInSales, isSelectedCompartment } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import {
  FC,
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { theme } from 'theme';
import { Product, ProductTag, ProfitTab, ShelfDetailView } from 'types/common';
import { PlanogramProductCompartment, ProductPosition } from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { CompartmentsListItem } from './compartmentsListItem';

type Props = {
  shelfIndex: number;
  view: ShelfDetailView;
  indexY: number;
  products?: Product[];
  productTag: ProductTag;
  productSales?: ProductReportProduct[];
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  showOrganizationProductId: boolean;
  productPosition?: ProductPosition;
  profitTab: ProfitTab;
  row: PlanogramProductCompartment[];
  handleClickListItem: (position: ProductPosition, product?: Product) => void;
};

export const CompartmentsList: FC<Props> = ({
  shelfIndex,
  view,
  indexY,
  productTag,
  products,
  productSales,
  refs,
  showOrganizationProductId,
  productPosition,
  profitTab,
  row,
  handleClickListItem,
}) => {
  const handleClickRef =
    useRef<(position: ProductPosition, product?: Product) => void>();
  const memoizedHandleClick = useCallback(
    (position: ProductPosition, product?: Product) => {
      handleClickRef.current?.(position, product);
    },
    []
  );
  useEffect(() => {
    handleClickRef.current = handleClickListItem;
  });
  return (
    <Fragment>
      {view === 'default' && (
        <ListSubheader
          title={`${shelfIndex + 1}段目`}
          backgroundColor={theme.palette.backgroundBlack.active}
          color={theme.palette.textBlack.primary}
          sx={{ fontWeight: 400, height: 30 }}
          isSKU={false}
          handleChangeSKU={() => void 0}
        />
      )}
      {view === 'default' && !row.length ? (
        <EmptyCompartment
          emptyText="棚に配置されている商品はありません"
          // eslint-disable-next-line @typescript-eslint/no-empty-function -- TODOリファクタリング
          handleClickDeleteShelfBoard={() => {}}
          isSKU={false}
        />
      ) : (
        row.map((compartment, compartmentIndex) => {
          const product = products?.find(
            (p) => p.id === compartment.product_id
          );
          const position = {
            indexX: 0,
            indexY, //配列をreverseしているためindexYも反転させる
            subPosition: {
              indexX: compartmentIndex,
              indexY: 0,
            },
          };
          if (
            view === 'productFlag' &&
            !hasProductTag(productTag, product?.detail?.tags)
          ) {
            return <Fragment key={compartmentIndex} />;
          }
          if (
            view === 'profit' &&
            !findProductInSales(compartment.product_id, productSales)
          ) {
            return <Fragment key={compartmentIndex} />;
          }
          const isSelected = isSelectedCompartment(position, productPosition);
          return (
            <CompartmentsListItem
              key={compartmentIndex}
              view={view}
              refs={refs}
              showOrganizationProductId={showOrganizationProductId}
              profitTab={profitTab}
              isSelected={isSelected}
              product={product}
              indexY={indexY}
              compartmentIndex={compartmentIndex}
              compartment={compartment}
              handleClickListItem={memoizedHandleClick}
            />
          );
        })
      )}
    </Fragment>
  );
};
