import {
  useGetModelGondolaQuery,
  useListModelGondolaItemsQuery,
} from '@reducers/shelfAppsApi/injections/sakuraApi';
import {
  FlatModelGondola,
  RegularModelGondola,
  ModelGondolaItem,
  ModelGondolaProduct,
  Store,
  WeekModelGondolas,
} from './types';
import { useEffect, useState } from 'react';
import { Product } from 'types/common';

export const listModelGondolaProducts = (
  modelGondola: RegularModelGondola | FlatModelGondola
) => {
  if (modelGondola.type === 'flat') {
    return (modelGondola as FlatModelGondola).containers
      .map((container) =>
        container.baskets.map(
          (basket) => basket.products as ModelGondolaProduct[]
        )
      )
      .flat(2); // dual flat
  } else if (modelGondola.type === 'regular') {
    const g = modelGondola as RegularModelGondola;
    return g.boards
      .map((board) => board.products as ModelGondolaProduct[])
      .flat();
  }
  throw new Error('Invalid modelGondola type');
};

export const useModelGondolaItems = (
  store: Store | undefined
): {
  isLoading: boolean;
  modelGondolaItems: ModelGondolaItem[] | undefined;
} => {
  const listModelGondolaItemsRes = useListModelGondolaItemsQuery();
  const [modelGondolaItems, setModelGondolaItems] = useState<
    ModelGondolaItem[] | undefined
  >(undefined);

  useEffect(() => {
    if (
      !store ||
      !listModelGondolaItemsRes.data ||
      listModelGondolaItemsRes.data.items.length === 0
    ) {
      setModelGondolaItems(undefined);
      return;
    }

    const items = listModelGondolaItemsRes.data.items;
    const regionFilteredItems = items.filter(
      (item) => item.doCd === store.doCd && item.zoCd === store.zoCd
    );
    setModelGondolaItems(regionFilteredItems);
  }, [listModelGondolaItemsRes.data, store]);

  return {
    modelGondolaItems: modelGondolaItems?.sort((a, b) =>
      a.week.localeCompare(b.week)
    ),
    isLoading: listModelGondolaItemsRes.isLoading,
  };
};

export const useModelGondola = (
  modelGondolaItem: ModelGondolaItem | undefined
): {
  isLoading: boolean;
  weekModelGondolas: WeekModelGondolas | undefined;
} => {
  const getModelGondolaResponse = useGetModelGondolaQuery(
    {
      path: {
        week: modelGondolaItem?.week || 'dummy',
        doCd: modelGondolaItem?.doCd || 'dummy',
        zoCd: modelGondolaItem?.zoCd || 'dummy',
      },
    },
    { skip: modelGondolaItem === undefined }
  );
  return {
    weekModelGondolas: getModelGondolaResponse.data,
    isLoading: getModelGondolaResponse.isLoading,
  };
};

export type RecommendationStatus = {
  salesStartDate: Date;
  salesEndDate?: Date;
  nDaysSinceRecommendationStarted: number;
  nWeeksSinceRecommendationStarted: number;
};

export const getRecommendation = (product: Product): RecommendationStatus => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain -- Null check handled in preceding conditions
  const salesStartDate = new Date(product.detail?.sales_start_date!);
  const salesEndDate = product.detail?.sales_end_date
    ? new Date(product.detail?.sales_end_date)
    : undefined;
  const today = new Date();
  const diffDays = Math.floor(
    (today.getTime() - salesStartDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  const diffWeeks = Math.floor(diffDays / 7);
  return {
    salesStartDate,
    salesEndDate,
    nDaysSinceRecommendationStarted: diffDays,
    nWeeksSinceRecommendationStarted: diffWeeks,
  };
};

export type ModelGondolaAssignmentLocationRegular = {
  type: 'regular';
  boardIndex: number;
  columnIndex: number;
};

export type ModelGondolaAssignmentLocationFlat = {
  type: 'flat';
  containerName: string;
  basketIndex: number;
};

export type ModelGondolaProductAssignment = {
  modelGondolaProduct: ModelGondolaProduct;
  gondolaCode: string;
  location:
    | ModelGondolaAssignmentLocationRegular
    | ModelGondolaAssignmentLocationFlat;
};

export const locateModelGondolaProduct = (
  itemCd: string,
  modelGondolas: (RegularModelGondola | FlatModelGondola)[]
): ModelGondolaProductAssignment | undefined => {
  for (const modelGondola of modelGondolas) {
    if (modelGondola.type === 'regular') {
      const g = modelGondola as RegularModelGondola;
      for (let boardIndex = 0; boardIndex < g.boards.length; boardIndex++) {
        const board = g.boards[boardIndex];
        for (
          let columnIndex = 0;
          columnIndex < board.products.length;
          columnIndex++
        ) {
          if (board.products[columnIndex].productCode === itemCd) {
            return {
              modelGondolaProduct: board.products[columnIndex],
              gondolaCode: g.gondolaCode,
              location: {
                type: 'regular',
                boardIndex,
                columnIndex,
              },
            };
          }
        }
      }
    } else {
      const g = modelGondola as FlatModelGondola;
      for (
        let containerIndex = 0;
        containerIndex < g.containers.length;
        containerIndex++
      ) {
        const container = g.containers[containerIndex];
        for (
          let basketIndex = 0;
          basketIndex < container.baskets.length;
          basketIndex++
        ) {
          const basket = container.baskets[basketIndex];
          for (
            let productIndex = 0;
            productIndex < basket.products.length;
            productIndex++
          ) {
            if (basket.products[productIndex].productCode === itemCd) {
              return {
                modelGondolaProduct: basket.products[productIndex],
                gondolaCode: g.gondolaCode,
                location: {
                  type: 'flat',
                  containerName: container.name,
                  basketIndex,
                },
              };
            }
          }
        }
      }
    }
  }
  return undefined;
};

export const dateToMonthDate = (date: Date | string) => {
  const date2 = date instanceof Date ? date : new Date(date);
  return `${date2.getMonth() + 1}月${date2.getDate()}日`;
};

export const splitListIntoEqualParts = <T>(list: T[], n: number): T[][] => {
  const numFullGroups = Math.ceil(list.length / n);
  const baseGroupSize = Math.floor(list.length / numFullGroups);
  const remainder = list.length % numFullGroups;

  const result: T[][] = [];
  let currentIndex = 0;

  for (let i = 0; i < numFullGroups; i++) {
    const currentGroupSize = baseGroupSize + (i < remainder ? 1 : 0);
    result.push(list.slice(currentIndex, currentIndex + currentGroupSize));
    currentIndex += currentGroupSize;
  }

  return result;
};

export const uniq = <T>(arr: T[], key: (_: T) => string) =>
  arr.filter(
    (item, i, self) => self.findIndex((elem) => key(elem) === key(item)) === i
  );
