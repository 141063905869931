import { BucketProductCompartments } from '@components/organisms/bucketProductCompatments/bucketProductCompartments';
import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { Box } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { Product } from 'types/common';
import { BucketArea as BucketAreaType, Position } from 'types/planogram';

type Props = {
  bucketArea: BucketAreaType;
  handleClickCompartment: (
    e: MouseEvent<HTMLElement>,
    position: Position[],
    product: Product,
    bucketIndex: number
  ) => void;
  path: Position[];
  anchorEl: HTMLElement | null;
  resetState: () => void;
  bucketIndex: number;
  isDisabledBucket: boolean;
  parentAreaWidth: number;
  parentAreaHeight: number;
  bboxEnabled: boolean;
  isCompared?: boolean;
  isEditor?: boolean;
};

export const BucketCompartment: FC<Props> = ({
  bucketArea,
  handleClickCompartment,
  path,
  anchorEl,
  resetState,
  bucketIndex,
  isDisabledBucket,
  parentAreaWidth,
  parentAreaHeight,
  bboxEnabled,
  isCompared = false,
  isEditor = false,
}) => {
  const data = usePlanogramContext();
  const originProduct = data?.productsBulk
    ?.get(bucketArea.product_id ?? 0)
    ?.at(0);
  const comparedProduct = data?.comparisonProductsBulk
    ?.get(bucketArea.product_id ?? 0)
    ?.at(0);
  const product = isCompared ? comparedProduct : originProduct;
  const products = isCompared ? data?.comparisonProducts : data?.products;
  return (
    <Box component="div" position="relative" width="100%" height="100%">
      <BucketProductCompartments
        products={products}
        product={product}
        compartmentArea={bucketArea}
        index={bucketIndex}
        handleClickCompartment={(e) => {
          e.stopPropagation();
          if (path.length === 0 || !product) return;
          handleClickCompartment(e, path, product, bucketIndex);
        }}
        areaPath={path}
        bboxEnabled={bboxEnabled}
        anchorEl={anchorEl}
        resetState={resetState}
        isDisabledBucket={isDisabledBucket}
        areaWidth={parentAreaWidth}
        areaHeight={parentAreaHeight}
        isCompared={isCompared}
        isEditor={isEditor}
      />
    </Box>
  );
};
