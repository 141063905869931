import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { useAppSelector } from '@store/index';
import { filteringQueryParams } from '@utils/urlQueryParams';
import { useLocation } from 'react-router-dom';
import {
  ProductTag,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
  ProfitTab,
} from 'types/common';
import { usePersistStateSearchParam } from './usePersistStateSearchParam';
import {
  profitTabSales,
  profitTabQuantity,
  profitTabGrossProfit,
  profitTabSalesQueryParamater,
  profitTabQuantityQueryParamater,
  profitTabGrossProfitQueryParamater,
} from '@utils/const';

const convertProfitTabToParams = (profitTab: ProfitTab) => {
  switch (profitTab) {
    case profitTabSales:
      return profitTabSalesQueryParamater;
    case profitTabQuantity:
      return profitTabQuantityQueryParamater;
    case profitTabGrossProfit:
      return profitTabGrossProfitQueryParamater;
    default:
      return profitTabGrossProfitQueryParamater;
  }
};

const convertRateToParams = (rateValue: Rate | undefined) => {
  switch (rateValue) {
    case 'フェイスアップNG':
      return 'faceup_ng';

    case '不明な商品フェイス数':
      return 'unknown';

    case '欠品区画数':
      return 'stockout';

    case '評価OK':
      return 'ok';

    default:
      return undefined;
  }
};

// NOTE: ScannerDetailsのHeaderとplanogramDetailのHeaderのクリックイベントは条件式が多いため、専用にカスタムフックに切り出します
// NOTE: 区画IDは共通のクエリパラメータ更新の関数に任せる（useControlQueryParameter）
export const useUpdateUrlQueryParamsOfDetailPages = () => {
  const location = useLocation();
  const { searchParams, setSearchParamsWithState: setSearchParams } =
    usePersistStateSearchParam({ state: location.state });

  const existingParams = Object.fromEntries(searchParams);
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  // 棚割の表示内容によるURLクエリパラメータの更新
  const updateViewQueryParams = (
    view: ShelfDetailView,
    productTag: ProductTag,
    profitTab: ProfitTab,
    rateValue?: Rate
  ) => {
    // view=layout
    if (view === 'default') {
      const result = filteringQueryParams(
        ['view', 'compare', 'item'],
        existingParams
      );
      result.view = 'layout';
      setSearchParams(result, { replace: false });
    }
    // view=attribute
    else if (view === 'productFlag') {
      const result = filteringQueryParams(
        ['view', 'compare', 'item', 'attribute'],
        existingParams
      );
      result.view = 'attribute';
      result.attribute = productTag;
      setSearchParams(result, { replace: false });
    }
    // view=analytics
    else if (view === 'profit') {
      const result = filteringQueryParams(
        ['view', 'compare', 'item', 'index'],
        existingParams
      );
      result.view = 'analytics';
      result.index = convertProfitTabToParams(profitTab);
      setSearchParams(result, { replace: false });
    }
    // view=evaluation
    else if (view === 'rate') {
      const result = filteringQueryParams(
        ['view', 'compare', 'item', 'evaluation'],
        existingParams
      );
      result.view = 'evaluation';
      const evaluationParam = convertRateToParams(rateValue);
      evaluationParam
        ? (result.evaluation = evaluationParam)
        : delete result.evaluation;

      setSearchParams(result, { replace: false });
    }
    // view=assortment
    else if (view === 'assortment') {
      const result = filteringQueryParams(
        ['view', 'compare', 'item'],
        existingParams
      );
      result.view = 'assortment';
      setSearchParams(result, { replace: false });
    }
  };

  // 通常・比較モードによるURLクエリパラメータの更新
  const updateModeQueryParams = (
    modeParams: ShelfDetailMode,
    viewParams: ShelfDetailView,
    attributeParams: ProductTag,
    indexParams: ProfitTab,
    evaluationParams?: Rate,
    compareValue?: {
      selectedItem?: string;
    }
  ) => {
    if (modeParams === 'default') {
      const result = filteringQueryParams(
        ['view', 'attribute', 'item', 'index', 'evaluation'],
        existingParams
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- オブジェクトの該当プロパティを削除するため。
      const { compare, ...newObj } = result;

      // view=layout
      if (viewParams == 'default') {
        setSearchParams(newObj, { replace: false });
      }
      // view=attribute
      else if (viewParams == 'productFlag') {
        newObj.attribute = attributeParams;
        setSearchParams(newObj, { replace: false });
      }
      // view=analytics
      else if (viewParams === 'profit') {
        newObj.index = convertProfitTabToParams(indexParams);
        setSearchParams(newObj, { replace: false });
      }
      // view=evaluation
      else if (viewParams === 'rate') {
        const evaluationParam = convertRateToParams(evaluationParams);
        evaluationParam
          ? (newObj.evaluation = evaluationParam)
          : delete newObj.evaluation;
        setSearchParams(newObj, { replace: false });
      }
    }
    // compareが存在する時
    else if (modeParams === 'comparison') {
      const result = filteringQueryParams(
        ['view', 'compare', 'item', 'index', 'attribute', 'evaluation'],
        existingParams
      );

      result.compare = compareValue?.selectedItem
        ? compareValue.selectedItem
        : currentSelectedItemId && currentSelectedType
        ? `${currentSelectedType}-${currentSelectedItemId}`
        : '';

      if (viewParams === 'profit') {
        result.index = convertProfitTabToParams(indexParams);
      }
      setSearchParams(result, { replace: false });
    }
  };

  // 商品属性によるURLクエリパラメータの更新
  const updateAttributeQueryParams = (productTag: ProductTag) => {
    const result = filteringQueryParams(
      ['view', 'compare', 'item', 'attribute'],
      existingParams
    );
    result.attribute = productTag;
    setSearchParams(result, { replace: false });
  };

  // 分析によるURLクエリパラメータの更新
  const updateAnalyticsQueryParams = (profitTab: ProfitTab) => {
    const result = filteringQueryParams(
      ['view', 'compare', 'item', 'index'],
      existingParams
    );
    result.index = convertProfitTabToParams(profitTab);
    setSearchParams(result, { replace: false });
  };

  // 分析によるURLクエリパラメータの更新
  const updateEvaluationQueryParams = (value: Rate) => {
    const result = filteringQueryParams(
      ['view', 'compare', 'item', 'evaluation'],
      existingParams
    );
    const evaluationParam = convertRateToParams(value);
    evaluationParam
      ? (result.evaluation = evaluationParam)
      : delete result.evaluation;
    setSearchParams(result, { replace: false });
  };

  return {
    updateViewQueryParams,
    updateModeQueryParams,
    updateAttributeQueryParams,
    updateAnalyticsQueryParams,
    updateEvaluationQueryParams,
  };
};
