import {
  AppBar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import {
  EliminateProduct,
  ModegonContext,
  ModegonProduct,
  EditorProduct,
  serializeData,
  setContext,
  selectContext,
  selectModegonProducts,
  selectEliminationCandidate,
  selectProducts,
} from '../features/slices';

import {
  useGetProductMasterQuery,
  useGetAvailableProductMasterQuery,
} from '@reducers/shelfAppsApi/injections/sakuraApi';

import { DoList, GondolaPmas } from '../features/constants';
import { useAppDispatch, useAppSelector } from '@store/index';
import { SimpleYesNoDialog, SimpleYesNoDialogProps } from './CommonComponents';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type SakuraStore = {
  context: ModegonContext;
  products: EditorProduct[];
  modegonProducts: ModegonProduct[];
  eliminationCandidate: EliminateProduct[];
};

function getCurrentSakuraStore(
  appSelector: typeof useAppSelector
): SakuraStore {
  return {
    context: appSelector(selectContext),
    products: appSelector(selectProducts),
    modegonProducts: appSelector(selectModegonProducts),
    eliminationCandidate: appSelector(selectEliminationCandidate),
  };
}

const DoAndGondolaSelection: FC = () => {
  const dispatch = useAppDispatch();
  const storeData = getCurrentSakuraStore(useAppSelector);

  const onDoChange = (e: SelectChangeEvent) => {
    const newDo = DoList.find((p) => e.target.value == p.code);
    if (!newDo) {
      console.error('Unknown do selected. ' + e.target.value);
    } else {
      dispatch(setContext({ ...storeData.context, ...{ doCode: newDo.code } }));
    }
  };

  const onGondolaChange = (e: SelectChangeEvent) => {
    const newGondola = GondolaPmas.find((p) => e.target.value == p.code);
    if (!newGondola) {
      console.error('Unknown gondola selected. ' + e.target.value);
    } else {
      dispatch(
        setContext({
          ...storeData.context,
          ...{ gondola: newGondola.code },
        })
      );
    }
  };

  // 商品一覧(productMaster) の取得はここで行われる。
  // データのパースは sakuraEditorSliceのextraReducer内で行われ、
  // stateが更新することで再度レンダリングが行われる。そのためここではデータの取得を行わない
  useGetProductMasterQuery(
    {
      path: {
        date: storeData.context.date || '',
        doCode: storeData.context.doCode || '',
        gondola: storeData.context.gondola || '',
      },
    },
    {
      skip:
        !storeData.context.date ||
        !storeData.context.doCode ||
        !storeData.context.gondola,
    }
  );

  const { data: availableProductMaster } = useGetAvailableProductMasterQuery();

  useEffect(() => {
    if (!availableProductMaster) return;
    if (availableProductMaster.editorProducts.length == 0) {
      console.error('No product master found');
      return;
    }
    if (storeData.context.date != undefined) return;

    const date = [...availableProductMaster.editorProducts].sort((a, b) =>
      b.localeCompare(a)
    )[0];

    dispatch(
      setContext({
        ...storeData.context,
        ...{ date: date },
      })
    );
  }, [availableProductMaster, dispatch, storeData.context]);

  return (
    <>
      <FormControl sx={{ minWidth: 170 }} size="small">
        <InputLabel>DOを選択</InputLabel>
        <Select
          label="DOを選択"
          value={storeData.context.doCode || ''}
          onChange={(e: SelectChangeEvent) => onDoChange(e)}
        >
          {DoList.map(({ code, name }) => {
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 170 }} size="small">
        <InputLabel>ゴンドラを選択</InputLabel>
        <Select
          label="ゴンドラを選択"
          value={storeData.context.gondola || ''}
          onChange={(e: SelectChangeEvent) => onGondolaChange(e)}
        >
          {GondolaPmas.map(({ code, name, origCode }) => {
            return (
              <MenuItem key={code} value={code}>
                <Typography variant="button" color="GrayText" display="inline">
                  {origCode}
                </Typography>
                <Typography
                  marginLeft={1}
                  display="inline"
                >{`: ${name}`}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export const TopAppBar: FC = () => {
  const storeData = getCurrentSakuraStore(useAppSelector);

  const [dialogProps] = useState<SimpleYesNoDialogProps>({
    showDialog: false,
    title: '確認',
    message: '',
    callback: () => {
      // blank callback
    },
  });

  const onSaveButtonTapped = () => {
    const data = serializeData(
      storeData.context,
      storeData.products || [],
      storeData.modegonProducts || [],
      storeData.eliminationCandidate || []
    );

    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    const downloadUrl = URL.createObjectURL(blob);
    const doName = DoList.find((p) => p.code == storeData.context.doCode)?.name;
    const gondolaName = GondolaPmas.find(
      (p) => p.code == storeData.context.gondola
    )?.name;
    const date = storeData.context.date;

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `モデゴン_${doName}_${gondolaName}_${date}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} component="div">
          <Typography variant="h6" component="span" padding="20px">
            モデゴン作成アプリ(体験版)
          </Typography>
          <DoAndGondolaSelection />
          <Typography component="span" padding="20px">
            {storeData.context.date
              ? `マスタ連携日 ${storeData.context.date}`
              : 'データ受信中'}
          </Typography>
        </Box>
        <Box sx={{ margin: 2 }} component="div">
          <Button
            variant="contained"
            sx={{ margin: '5px' }}
            onClick={() => {
              onSaveButtonTapped();
            }}
          >
            保存
          </Button>
          <Button component="label" variant="contained">
            読み込み
            <VisuallyHiddenInput
              type="file"
              // onChange={(e) => onLoadButtonTapped(e)}
            />
          </Button>
        </Box>
      </Toolbar>

      <SimpleYesNoDialog
        showDialog={dialogProps.showDialog}
        title={dialogProps.title}
        message={dialogProps.message}
        callback={dialogProps.callback}
      />
    </AppBar>
  );
};
