import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Image from 'next/legacy/image';
import { CheckCircle } from '@mui/icons-material';
import { theme } from 'theme';

type Props = {
  isFlat: boolean;
  isMd: boolean;
  isXs: boolean;
};

export const GoodExample: FC<Props> = ({ isFlat, isMd, isXs }) => {
  console.log(isMd);
  const subtitleType = isXs ? 'subtitle2' : 'subtitle1';
  return (
    <>
      {isFlat ? (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          py={1}
          gap={5}
          borderRight={{
            sm: 'none',
            md: `1px solid ${theme.palette.dividerBlack.medium}`,
          }}
          borderBottom={{
            xs: `1px solid ${theme.palette.dividerBlack.medium}`,
            sm: `1px solid ${theme.palette.dividerBlack.medium}`,
            md: 'none',
          }}
        >
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            gap={1}
            width="442px"
          >
            <CheckCircle
              sx={{
                color: theme.palette.primary.main,
                height: 24,
                width: 24,
              }}
            />
            <Typography
              variant="headingXS"
              textAlign="left"
              color={theme.palette.primary.main}
            >
              良い例
            </Typography>
          </Box>
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            gap={{ xs: 0, sm: 0, md: 3 }}
            px={3}
          >
            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src="/instructions/correctFlatGondra.png"
                alt=""
                width={`${isMd ? 360 : 290}`}
                height={`${isMd ? 240 : 195}`}
                // 画像きたらcontainに戻す
                objectFit="cover"
              />
            </Box>
            <Box component="div" py={2} gap={2}>
              <Typography variant="subtitle1" textAlign="left">
                ・真上から撮影している
              </Typography>
              <Typography variant="subtitle1" textAlign="left">
                ・カゴの四隅が収まっている
              </Typography>
              <Typography variant="subtitle1" textAlign="left">
                ・フタが開いている（フタがある場合のみ）
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems={{
            xs: 'flex-start',
            sm: 'center',
          }}
          py={1}
          gap={{ xs: 2, sm: 2, md: 3 }}
          flex={1}
          pb={{
            xs: '124px',
            sm: 0,
          }}
        >
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            gap={1}
            pt="4px"
            pr="4px"
            width="100%"
          >
            <CheckCircle
              sx={{
                color: theme.palette.primary.main,
              }}
            />
            <Typography variant="headingXS" color={theme.palette.primary.main}>
              良い例
            </Typography>
          </Box>
          {isMd ? (
            <Box component="div" px={3} gap={2}>
              <Box component="div" mx="auto" width="210px" height="280px">
                <Image
                  src="/instructions/correctGondra.png"
                  alt=""
                  width={210}
                  height={280}
                  objectFit="contain"
                />
              </Box>
              <Box
                component="div"
                textAlign="left"
                py={2}
                gap={2}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="subtitle1">
                  ・ゴンドラの全体が収まっている
                </Typography>
                <Typography variant="subtitle1">
                  ・ガラス扉が開いている（ガラス扉がある場合のみ）
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              component="div"
              px={{
                xs: 0,
                sm: 3,
              }}
              gap={2}
              display="flex"
              height={{
                xs: '160px',
                breakpoint: '260px',
              }}
            >
              <Box
                component="div"
                mx="auto"
                width={{
                  xs: '120px',
                  breakpoint: '195px',
                }}
                height={{
                  xs: '160px',
                  breakpoint: '260px',
                }}
                display="flex"
              >
                <Image
                  src="/instructions/correctGondra.png"
                  alt=""
                  width={195}
                  height={260}
                  objectFit="contain"
                />
              </Box>
              <Box
                component="div"
                textAlign="left"
                py={2}
                gap={2}
                display="flex"
                flexDirection="column"
                flex={1}
              >
                <Typography variant={subtitleType}>
                  ・ゴンドラの全体が収まっている
                </Typography>
                <Typography variant={subtitleType}>
                  ・ガラス扉が開いている（ガラス扉がある場合のみ）
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
