import { Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuButton } from '../../menuButton/menuButton';
import { DeleteShelfBoardDialog } from './deleteShelfBoardDialog';
import { ShelfBoardMenuList } from './shelfBoardMenuList';

type Props = {
  handleClickDeleteShelfBoard: () => void;
  emptyText?: string;
  isCanDeleteBaypart?: boolean;
  isSKU: boolean;
};

export const EmptyCompartment: FC<Props> = ({
  handleClickDeleteShelfBoard,
  emptyText,
  isCanDeleteBaypart,
  isSKU,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  return (
    <>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        p={1.5}
        pr={0.5}
      >
        <Box
          component="div"
          sx={{ display: 'flex', alignItems: 'center', color: '#B5B5B5' }}
        >
          <Error sx={{ mr: 1 }} />
          <Typography variant="body1">{emptyText}</Typography>
        </Box>
        {pathname.includes('actuals') && !isSKU && (
          <MenuButton
            handleMenuOpen={() => setMenuOpen(true)}
            handleMenuClose={() => setMenuOpen(false)}
            menuOpen={menuOpen}
          >
            <ShelfBoardMenuList
              handleDeleteDialogOpen={() => {
                setDialogOpen(true);
                setMenuOpen(false);
              }}
              isCanDeleteBaypart={isCanDeleteBaypart}
            />
          </MenuButton>
        )}
      </Box>
      <DeleteShelfBoardDialog
        open={dialogOpen}
        handleClose={() => {
          setDialogOpen(false);
        }}
        handleClickDeleteShelfBoard={handleClickDeleteShelfBoard}
      />
    </>
  );
};
