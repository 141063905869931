import { ZoomIn } from '@components/molecules/zoomInOut/zoomIn';
import { ZoomOut } from '@components/molecules/zoomInOut/zoomOut';
import {
  StopPropagationButton,
  StyledButtonGroup,
} from '@components/organisms';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { maxRealogramZoomScale, minRealogramZoomScale } from '@utils/const';
import { FC, useState } from 'react';
import { useControls, useTransformEffect } from 'react-zoom-pan-pinch';

const zoomStep = 0.3;
export const RealogramZoomController: FC = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  const [scale, setScale] = useState(1);

  useTransformEffect(({ state }) => {
    setScale(state.scale);
  });

  return (
    <StyledButtonGroup
      orientation="horizontal"
      sx={{
        flexDirection: 'row-reverse',
        width: '146px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        height: '40px',
        marginLeft: '10px',
      }}
    >
      <StopPropagationButton
        onClick={() => resetTransform()}
        disabled={scale === 1}
        sx={{
          height: '40px !important',
          width: '48px',
          padding: '8px',
        }}
      >
        <ZoomOutMapIcon sx={{ opacity: 0.65 }} />
      </StopPropagationButton>
      <StopPropagationButton
        onClick={() => zoomIn(zoomStep)}
        disabled={scale === maxRealogramZoomScale}
        sx={{
          height: '40px !important',
          width: '48px',
          padding: '8px',
        }}
      >
        <ZoomIn />
      </StopPropagationButton>
      <StopPropagationButton
        onClick={() => zoomOut(zoomStep)}
        disabled={scale === minRealogramZoomScale}
        sx={{
          height: '40px !important',
          width: '48px',
          padding: '8px',
        }}
      >
        <ZoomOut />
      </StopPropagationButton>
    </StyledButtonGroup>
  );
};
