import { Chip } from '@mui/material';
import { Product } from 'types/common';
import { t } from 'i18next';

type Props = {
  product: Product | undefined;
};

export const KihonShohinChipIfNeeded = ({ product }: Props) => {
  const isKihonShohin =
    (product?.detail?.tags || []).find(
      (tag) => tag === `${t('assortment.base_product')}`
    ) !== undefined;
  if (!isKihonShohin) {
    return <></>;
  }
  return (
    <Chip
      key={t('assortment.base_product')}
      sx={{
        fontSize: '7pt',
        height: '10pt',
        margin: '1pt',
        '& .MuiChip-label': {
          padding: '4px',
        },
      }}
      label={t('assortment.base_product')}
      color="primary"
      variant="outlined"
    />
  );
};
