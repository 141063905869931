import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useEffect, useRef, useState } from 'react';

import {
  AvailableItems,
  ExternalInfo,
  RegularModelGondola,
  FlatModelGondola,
  StoreProductSale,
} from '../types';
import { ModelGondolaContent } from './modelGondolaContent';
import { ProductAnnotation } from '@reducers/shelfAppsApi/injections/sakuraApi';
import { SupplementaryTableView } from './supplementaryTableView';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import SupportedGondolas from '@utils/gondolas.json';
import { TakeGondolaPhotoButton } from './takeGondolaPhoto';
import { useIndexedPdfContext } from './pdf/indexedPdf';
import { useAppSelector } from '@store/index';
import { selectToken } from '@reducers/auth/selectors';
import { AllProductsProvider } from '@utils/ProductsContext';
import { listModelGondolaProducts, uniq } from '../sakuraUtils';

type ModelGondolaViewerPageProps = {
  gondola: RegularModelGondola | FlatModelGondola;
  externalInfo: ExternalInfo;
  organizationStoreId: string | undefined;
  availableItems: AvailableItems | undefined;
  storeProductSales: StoreProductSale[];
};

export const ModelGondolaViewerPage = ({
  gondola,
  externalInfo,
  availableItems,
  storeProductSales,
}: ModelGondolaViewerPageProps) => {
  const [highlightedAnnotation, setHighlightedAnnotation] = useState<
    ProductAnnotation | undefined
  >(undefined);

  const modelGondolaViewRef = useRef<HTMLDivElement>();
  const supplementaryTableViewHeight =
    modelGondolaViewRef.current?.offsetHeight;

  const gondolaAvailableItems =
    availableItems?.items.filter((item) =>
      item.gondolaCodes.includes(gondola.gondolaCode)
    ) || [];

  const possibleItemCds = [
    ...gondolaAvailableItems.map((item) => item.itemCd),
    ...externalInfo.cutItems,
    ...externalInfo.newItems.map((item) => item.itemCd),
    ...listModelGondolaProducts(gondola).map((product) => product.productCode),
  ];

  return (
    <AllProductsProvider
      itemCds={possibleItemCds}
      skip={possibleItemCds.length === 0}
    >
      <Box component="div" sx={{ width: '100vw' }}>
        <PanelGroup direction="horizontal">
          <Panel defaultSize={70} minSize={50}>
            <Box
              component="div"
              ref={modelGondolaViewRef}
              sx={{
                width: '100%',
                overflowX: 'auto',
                px: 1,
              }}
            >
              <ModelGondolaContent
                modelGondola={gondola}
                productSales={storeProductSales}
                externalInfo={externalInfo}
                highlightedAnnotation={highlightedAnnotation}
                setHighlightedAnnotation={setHighlightedAnnotation}
              />
            </Box>
          </Panel>
          <PanelResizeHandle>
            <Box
              component="div"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '1px',
                height: '100%',
                backgroundColor: 'lightgrey',
              }}
            >
              <Box
                component="div"
                sx={{
                  position: 'fixed',
                  top: '50%',
                  width: '2px',
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <DragIndicatorIcon
                  sx={{ fontSize: '20px', color: 'lightgrey' }}
                />
              </Box>
            </Box>
          </PanelResizeHandle>
          <Panel defaultSize={30} maxSize={50}>
            <Box
              component="div"
              sx={{
                width: '100%',
                minHeight: '80vh',
              }}
            >
              <SupplementaryTableView
                modelGondola={gondola}
                externalInfo={externalInfo}
                productSales={storeProductSales}
                highlightedAnnotation={highlightedAnnotation}
                setHighlightedAnnotation={setHighlightedAnnotation}
                maxHeight={supplementaryTableViewHeight}
                gondolaAvailableItems={gondolaAvailableItems}
              />
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
    </AllProductsProvider>
  );
};

type ModelGondolaViewerProps = {
  gondolas: (RegularModelGondola | FlatModelGondola)[];
  externalInfo: ExternalInfo;
  organizationStoreId: string | undefined;
  availableItems: AvailableItems | undefined;
  storeProductSales: StoreProductSale[];
  gondolaCode: string;
  setGondolaCode: (_: string) => void;
};

export const ModelGondolaViewer = ({
  gondolas,
  externalInfo,
  organizationStoreId,
  availableItems,
  storeProductSales,
  gondolaCode,
  setGondolaCode,
}: ModelGondolaViewerProps) => {
  const [currentTabIndex, setCurrentTabId] = useState<number>(0);

  useEffect(() => {
    if (gondolaCode) {
      const index = gondolas.findIndex((g) => g.gondolaCode === gondolaCode);
      if (index !== -1) {
        setCurrentTabId(index);
      } else {
        setCurrentTabId(0);
      }
    } else {
      setCurrentTabId(0);
    }
  }, [gondolaCode, gondolas]);

  gondolas.sort((a, b) => (a.gondolaCode < b.gondolaCode ? -1 : 1));

  // FIXME: PDFのプリロード、他のannotationについてはsupplemenataryTableViewで行っていてバラけてる
  const { addUrl } = useIndexedPdfContext();
  const authToken = useAppSelector(selectToken);
  useEffect(() => {
    const uniqNewItems = uniq(
      externalInfo.newItems,
      (newItem) => newItem.pdfS3Id
    );
    uniqNewItems.forEach((newItem) => {
      addUrl(`/api/v1/sakura/assets/${newItem.pdfS3Id}.pdf`, authToken);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are stable within parent component and don't need to trigger rerenders
  }, [externalInfo]);

  return (
    <Box component="div" sx={{ width: '100vw', px: 1 }}>
      <Stack direction="row">
        <Tabs
          value={currentTabIndex}
          onChange={(_, value: number) => {
            setCurrentTabId(value);
            setGondolaCode(gondolas[value].gondolaCode);
          }}
          variant="scrollable"
        >
          {gondolas.map((gondola, index) => {
            const gondolaPma = SupportedGondolas.gondolaPmas.find(
              (g) => g.code === gondola.gondolaCode
            );
            const gondolaName = gondolaPma?.name || gondola.gondolaCode;
            const isShown = currentTabIndex === index;

            let shownGondolaName = gondolaName;
            const lenLimit = 12;
            if (!isShown && lenLimit < gondolaName.length) {
              shownGondolaName = shownGondolaName.slice(0, lenLimit) + '...';
            }
            return (
              <Tab
                key={index}
                label={
                  <Stack>
                    {gondola.gondolaCode !== '7TP' ? (
                      <Typography fontSize="10pt">
                        {gondolaPma?.origCode || gondola.gondolaCode}
                      </Typography>
                    ) : null}
                    <Typography variant="body2">{shownGondolaName}</Typography>
                  </Stack>
                }
                sx={{
                  whiteSpace: 'nowrap',
                }}
              />
            );
          })}
        </Tabs>
        <Box component="div" sx={{ flexGrow: 1 }} />
        <TakeGondolaPhotoButton />
      </Stack>
      <Box
        component="div"
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          width: '100vw',
        }}
      >
        {gondolas.map((gondola, index) => {
          return (
            <div
              key={gondola.gondolaCode}
              role="tabpanel"
              hidden={currentTabIndex !== index}
              id={`tab-${index}`}
            >
              {currentTabIndex === index && (
                <ModelGondolaViewerPage
                  gondola={gondola}
                  externalInfo={externalInfo}
                  organizationStoreId={organizationStoreId}
                  availableItems={availableItems}
                  storeProductSales={storeProductSales}
                />
              )}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};
