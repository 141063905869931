import { SearchDialogLayout } from '@components/organisms/search/searchDialogLayout';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { directoryTypes } from '../..';
import { components } from 'types/api';

type Option = {
  id: string | number;
  label: string;
};

type Props = {
  isOpen: boolean;
  folders: Option[];
  users: Option[];
  directoryIdCondition: string;
  ownerIdCondition?: number;
  isStarCondition: boolean;
  directoryTypeCondition?: string;
  me?: components['schemas']['UserInfo'];
  setDirectoryTypeCondition: (value?: string) => void;
  handleClose: VoidFunction;
  handleResetConditions: VoidFunction;
  handleSubmit: VoidFunction;
  setOwnerIdCondition: (id?: number) => void;
  setDirectoryIdCondition: (directoryIdCondition: string) => void;
  setIsStarCondition: (isStarCondition: boolean) => void;
};

export const RealogramSearchDialog: FC<Props> = ({
  isOpen,
  folders,
  users,
  ownerIdCondition,
  isStarCondition,
  directoryIdCondition,
  directoryTypeCondition,
  me,
  setDirectoryTypeCondition,
  handleClose,
  handleResetConditions,
  handleSubmit,
  setOwnerIdCondition,
  setDirectoryIdCondition,
  setIsStarCondition,
}) => {
  return (
    <SearchDialogLayout
      isOpen={isOpen}
      handleClose={handleClose}
      handleResetConditions={handleResetConditions}
      handleSubmit={handleSubmit}
    >
      <Box display="flex" component="div" flexDirection="column" gap="4px">
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            width="88px"
            color={theme.palette.textBlack.secondary}
          >
            場所
          </Typography>

          <FormControl>
            <InputLabel>場所</InputLabel>
            <Select
              defaultValue="0"
              onChange={(e) => {
                setDirectoryIdCondition(e.target.value);
              }}
              size="small"
              label="場所"
              value={directoryIdCondition}
              sx={{
                width: 332,
              }}
            >
              {folders.map((folder) => (
                <MenuItem value={folder.id} key={folder.id}>
                  <Typography variant="body1">{folder.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '85px',
          }}
        >
          <Checkbox
            checked={isStarCondition}
            onChange={(e, newValue) => setIsStarCondition(newValue)}
          />
          <Typography variant="body1" textAlign="center">
            スター付き
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body2"
          width="88px"
          color={theme.palette.textBlack.secondary}
        >
          作成者
        </Typography>
        <Autocomplete
          options={users || []}
          size="small"
          fullWidth
          noOptionsText="選択肢がありません"
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          sx={{
            width: 332,
          }}
          onChange={(e, newValue) => {
            if (!newValue) setOwnerIdCondition(undefined);
            else setOwnerIdCondition(+newValue.id);
          }}
          value={users.find((user) => user.id === ownerIdCondition) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="作成者"
              sx={{
                '& .MuiInputBase-root': {
                  py: 0,
                },
              }}
            />
          )}
          renderOption={(props, option) => {
            if (option.id === me?.id && option.label === me?.fullname) return;
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
        />
      </Box>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body2"
          width="88px"
          color={theme.palette.textBlack.secondary}
        >
          種類
        </Typography>
        <FormControl>
          <InputLabel>種類</InputLabel>
          <Select
            defaultValue="all"
            onChange={(e) => {
              setDirectoryTypeCondition(e.target.value);
            }}
            size="small"
            label="種類"
            value={directoryTypeCondition}
            sx={{
              width: 332,
            }}
          >
            {directoryTypes.map((directoryType) => (
              <MenuItem value={directoryType.value} key={directoryType.value}>
                <Typography variant="body1">{directoryType.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </SearchDialogLayout>
  );
};
