import { SxProps } from '@mui/material';
import { ProductOrderBy } from 'types/products';
import { t } from 'i18next';

type ColumnProps = {
  headerName: string;
  headerSubName?: string;
  orderBy?: ProductOrderBy;
  headerSx?: SxProps;
  hasDivider?: boolean;
};

const headerSx = {
  padding: '0px',
};

export const columns: ColumnProps[] = [
  {
    headerName: '',
    headerSx: { width: '64px' },
  },
  {
    headerName: '商品名',
    headerSubName: 'カテゴリ',
    orderBy: 'name',
    headerSx: { ...headerSx, color: 'gray' },
  },
  {
    headerName: 'JAN',
    headerSubName: t('product_code'),
    orderBy: 'ean_code',
    headerSx: { ...headerSx, width: '200px' },
    hasDivider: true,
  },
  {
    headerName: '更新日時',
    orderBy: 'updated_at',
    headerSx: { ...headerSx, width: '200px' },
    hasDivider: true,
  },
];
