import { MutableRefs } from '@utils/realogram';
import { FC, memo, useCallback } from 'react';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import { ProductReport } from 'types/products';
import { RealogramCandidateFace, RealogramSelectedItem } from 'types/realogram';
import { ListRow } from './listRow';

type Props = {
  handleClick: (item: RealogramSelectedItem) => void;
  handleCorrectDrawerOpen: (item: RealogramSelectedItem) => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  handleClickDeleteFace: (item: RealogramSelectedItem) => void;
  refs: MutableRefs;
  selectedItem?: RealogramSelectedItem;
  isSelected: boolean;
  shelfBoardId: string;
  compartmentId: string;
  products?: Product[];
  realogramDetailView: ShelfDetailView;
  profitTab: ProfitTab;
  productReport?: ProductReport;
  isCanEditFace?: boolean;
  isBucketType?: boolean;
  shotIndex: number;
  face: RealogramCandidateFace;
  facesLength: number;
};

export const SelectedViewListRow: FC<Props> = memo(
  ({
    isSelected,
    shelfBoardId,
    compartmentId,
    products,
    selectedItem,
    refs,
    handleClickDeleteFace,
    realogramDetailView,
    handleCorrectDrawerOpen,
    profitTab,
    productReport,
    handleDivisionModalOpen,
    isCanEditFace,
    isBucketType,
    shotIndex,
    handleClick,
    face,
    facesLength,
  }) => {
    const memoizedHandleClick = useCallback(
      (item: RealogramSelectedItem) =>
        handleClick({ ...item, shotIndex, isBboxClicked: false }),
      [shotIndex, handleClick]
    );

    return (
      <ListRow
        isSelected={isSelected}
        shelfBoardId={shelfBoardId}
        compartmentId={compartmentId}
        item={face}
        facesLength={facesLength}
        products={products}
        selectedItem={selectedItem}
        handleClick={memoizedHandleClick}
        refs={refs}
        handleClickDeleteFace={handleClickDeleteFace}
        realogramDetailView={realogramDetailView}
        handleCorrectDrawerOpen={handleCorrectDrawerOpen}
        profitTab={profitTab}
        productReport={productReport}
        handleDivisionModalOpen={handleDivisionModalOpen}
        isCanEditFace={isCanEditFace}
        isBucket={isBucketType}
      />
    );
  }
);
SelectedViewListRow.displayName = 'SelectedViewListRow';
