import { ErrorOutlined } from '@mui/icons-material';
import { Box, keyframes } from '@mui/material';
import { withOpacity } from '@utils/colors';
import {
  changeSidesByFaceFront,
  convertMeterToPixel,
  orientationToDegree as getDegree,
  isClickableCompartment,
} from '@utils/planogram';
import {
  getAnalyticsLevel,
  getProductDisplaySize,
  getProductSize,
} from '@utils/product';
import { range } from 'lodash';
import { FC, memo, MouseEvent, useEffect } from 'react';
import {
  FaceFrontId,
  Mode,
  Product,
  ProductTag,
  ShelfDetailMode,
  ShelfDetailView,
  FaceOrientationID,
} from 'types/common';
import { BboxColors, BucketMode, ProductPosition } from 'types/planogram';
import { theme } from '../../../theme';
import { ProductImage } from '../productImage/productImage';

type Props = {
  rowIndex?: number;
  index?: number;
  isSelected: boolean;
  handleClickGroup: (
    e: MouseEvent<HTMLElement>,
    position: ProductPosition,
    product: Product
  ) => void;
  isOverflown: boolean;
  product: Product;
  diffColor?: string; //TODO: propsで受け取るべきか要検討
  bboxColors: BboxColors;
  bboxEnabled: boolean; //TODO: propsで受け取るべきか要検討
  mode?: ShelfDetailMode;
  view?: ShelfDetailView;
  justifyMargin: number;
  margin?: number;
  isCompared?: boolean;
  lastIndex?: number;
  setIsAllChildRenndered?: (value: boolean) => void;
  productValue?: number;
  maxThreshold?: number;
  productTag?: ProductTag;
  editorMode: Mode;
  isDragProduct: boolean;
  isComparisonCommonId: boolean;
  isDecreaseOpacity: boolean;
  bucketMode: BucketMode;
  countX: number;
  countY: number;
  orientation: FaceOrientationID;
  faceFront: FaceFrontId;
};
const border = 2;
const opacity = 0.2;
const profitOpacity = 0.65;
const productGroupBgOpacity = 0.4;

export const ProductsGroup: FC<Props> = memo(
  ({
    orientation,
    faceFront,
    handleClickGroup,
    isSelected,
    isOverflown,
    product,
    diffColor,
    rowIndex = -1,
    index = -1,
    bboxEnabled,
    mode,
    view,
    justifyMargin,
    margin = 2,
    isCompared = false,
    lastIndex,
    setIsAllChildRenndered,
    productValue,
    maxThreshold,
    productTag,
    editorMode,
    isDragProduct,
    isComparisonCommonId,
    isDecreaseOpacity,
    bucketMode,
    countX,
    countY,
  }) => {
    const position = {
      indexX: 0,
      indexY: rowIndex,
      subPosition: {
        indexX: index,
        indexY: 0,
      },
    };

    // const { x: countX, y: countY } = count ?? { x: 0, y: 0 };
    const size = getProductSize(product);
    const faceFrontSize = changeSidesByFaceFront(size, faceFront);
    const displaySize = getProductDisplaySize(product, faceFront, orientation);
    const isProductFlagView = view === 'productFlag';
    const hasTargetTag = product.detail?.tags?.includes(
      productTag as ProductTag
    );
    const isSkippingColorChange = isProductFlagView && !hasTargetTag;
    const isPreviewMode = editorMode === 'preview';
    const isEditorMode = editorMode === 'CompartmentEditor';

    const { borderColor, bgColor } = getActivityColors(
      isOverflown,
      isSelected,
      !isPreviewMode && bboxEnabled,
      isComparisonCommonId,
      isSkippingColorChange,
      diffColor,
      view,
      mode,
      isEditorMode,
      isDragProduct,
      productValue,
      maxThreshold
    );

    const blink = createKeyframes(theme.palette.white.primary);
    const productImageElement = (
      <Box
        component="div"
        data-mark="product-image"
        sx={{
          transform: `rotate(${getDegree(orientation)}deg)`,
        }}
        left="50%"
        bottom="50%"
        width={convertMeterToPixel(faceFrontSize.width)}
        height={convertMeterToPixel(faceFrontSize.height)}
        position="absolute"
        // eslint-disable-next-line no-magic-numbers -- 中央揃えのため
        marginLeft={`${convertMeterToPixel(-faceFrontSize.width / 2)}px`}
        // eslint-disable-next-line no-magic-numbers -- 中揃えのため
        marginBottom={`${convertMeterToPixel(-faceFrontSize.height / 2)}px`}
      >
        <ProductImage
          product={product}
          width={convertMeterToPixel(faceFrontSize.width)}
          height={convertMeterToPixel(faceFrontSize.height)}
          faceFrontId={faceFront}
          layout="fill"
          objectFit="contain"
          objectPosition="bottom"
          imageSizeType={isPreviewMode ? 'medium' : 'tiny'}
          baseSize={convertMeterToPixel(
            Math.max(faceFrontSize.height, faceFrontSize.width)
          )}
        />
      </Box>
    );

    useEffect(() => {
      if (lastIndex && setIsAllChildRenndered) {
        if (+index === +lastIndex) {
          setIsAllChildRenndered(true);
        }
      }
    }, [lastIndex, index, setIsAllChildRenndered]);

    return (
      <Box
        component="div"
        data-group-position={
          !isPreviewMode && !isCompared
            ? `group-position-${rowIndex}-${index}`
            : undefined
        }
        onClick={(e) => handleClickGroup(e, position, product)}
        position="relative"
        width="100%"
        height={convertMeterToPixel(displaySize.height * countY)}
      >
        <Box
          component="div"
          borderRadius="4px"
          boxSizing="content-box"
          width="100%"
          height={convertMeterToPixel(displaySize.height * countY)}
          margin={`-${margin}px`}
          position="absolute"
        >
          <Box component="div" position="relative" width="100%" height="100%">
            {range(countY).map((i) => {
              return (
                <Box
                  component="div"
                  key={i}
                  sx={{
                    opacity: isDecreaseOpacity
                      ? // eslint-disable-next-line no-magic-numbers -- 半透明にするため
                        0.2
                      : getOpacity(
                          productTag as ProductTag,
                          isSelected,
                          product,
                          view
                        ),
                  }}
                  position="absolute"
                  width={convertMeterToPixel(displaySize.width)}
                  height={convertMeterToPixel(displaySize.height)}
                  bottom={convertMeterToPixel(displaySize.height * i)}
                >
                  <Box
                    component="div"
                    position="relative"
                    width="100%"
                    height="100%"
                  >
                    {range(countX).map((j) => (
                      <Box
                        component="div"
                        key={j}
                        width={convertMeterToPixel(displaySize.width)}
                        height={convertMeterToPixel(displaySize.height)}
                        position="absolute"
                        left={convertMeterToPixel(
                          (displaySize.width + justifyMargin) * j
                        )}
                        bottom="0"
                      >
                        <Box
                          component="div"
                          position="relative"
                          width="100%"
                          height="100%"
                        >
                          {productImageElement}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            })}
            <Box
              component="div"
              borderRadius="6px"
              boxSizing="content-box"
              width="100%"
              height="100%"
              position="absolute"
              margin={`-${border}px`}
              {...(bucketMode !== 'area' && {
                border: `${border}px solid ${borderColor}`,
              })}
              sx={{
                animation:
                  isSelected && !isSkippingColorChange && !isDragProduct
                    ? `${blink} 1.2s infinite`
                    : '',
              }}
              className="haha"
            >
              <Box
                component="div"
                bgcolor={
                  isSelected
                    ? withOpacity(bgColor, productGroupBgOpacity)
                    : bgColor
                }
                border={
                  isSelected && !isDragProduct
                    ? `${border}px solid ${borderColor}`
                    : undefined
                }
                borderRadius="4px"
                boxSizing="content-box"
                width={isSelected ? 'calc(100% - 4px)' : '100%'}
                height={isSelected ? 'calc(100% - 4px)' : '100%'}
              />
            </Box>
          </Box>
        </Box>
        {isOverflown && !isPreviewMode && (
          <Box
            component="div"
            sx={{
              width: 11,
              height: 11,
              backgroundColor: bgColor,
              position: 'absolute',
              top: '-5px',
              right: '-7px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '20px',
            }}
          >
            <ErrorOutlined
              sx={{ fill: theme.palette.error.main, width: 15, height: 15 }}
            />
          </Box>
        )}
      </Box>
    );
  }
);

const createKeyframes = (borderColor: string) => keyframes`
  0%,100% {
    border-color:${borderColor};
  }
  50% {
    border-color:transparent;
  }
`;

const getOpacity = (
  productTag: ProductTag,
  isSelected: boolean,
  product: Product,
  view?: ShelfDetailView
) => {
  if (!view) return 1;
  if (view === 'profit') return profitOpacity;
  return isClickableCompartment(view, product, productTag) ? 1 : opacity;
};

const getActivityColors = (
  isOverflown: boolean,
  isSelected: boolean,
  bboxEnabled: boolean,
  isComparisonCommonId: boolean,
  isSkippingColorChange: boolean,
  diffColor?: string,
  view?: ShelfDetailView,
  mode?: ShelfDetailMode,
  isEditorMode?: boolean,
  isDragProduct?: boolean,
  productValue?: number,
  maxThreshold?: number
) => {
  if (!bboxEnabled || isSkippingColorChange) {
    return { borderColor: 'transparent', bgColor: 'transparent' };
  }

  if (view === 'profit') {
    // 「荒利」の場合、ヒートマップを表示しない
    if (!maxThreshold || !productValue)
      return {
        color: 'transparent',
        bgColor: 'transparent',
      };
    const divideNum = 5;
    const dividedValue = maxThreshold / divideNum;
    const level = getAnalyticsLevel(productValue, dividedValue);

    return {
      borderColor: theme.palette.heatmap[`0${level}_line`],
      bgColor: theme.palette.heatmap[`0${level}_bg_scan`],
    };
  }

  if (isOverflown) {
    return {
      borderColor: theme.palette.error.main,
      bgColor: withOpacity(theme.palette.error.main, opacity),
    };
  }

  if (isSelected && !isDragProduct) {
    return {
      borderColor: theme.palette.primary.main,
      bgColor: theme.palette.primary.main,
    };
  }

  if (mode === 'comparison' && !isEditorMode) {
    switch (view) {
      case 'default':
      case 'productFlag':
        if (isComparisonCommonId) {
          return { borderColor: theme.palette.white.primary, bgColor: 'unset' };
        }
        if (!isComparisonCommonId) {
          return {
            borderColor: theme.palette.error.main,
            bgColor: withOpacity(theme.palette.error.main, opacity),
          };
        }
        return {
          borderColor: 'transparent',
          bgColor: 'transparent',
        };

      default:
        return {
          borderColor: diffColor ?? '#C6DAFC',
          bgColor: withOpacity(diffColor ?? '', profitOpacity),
        };
    }
  }

  return { borderColor: '#C6DAFC', bgColor: 'unset' };
};

ProductsGroup.displayName = 'ProductsGroup';
