import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { ModelGondolaItem } from '../types';

type Props = {
  modelGondolaItems: ModelGondolaItem[];
  setCurrentModelGondolaItem: (item: ModelGondolaItem) => void;
};

export const SelectBacknumberButton = ({
  modelGondolaItems,
  setCurrentModelGondolaItem,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <ManageAccountsIcon sx={{ fontSize: '12pt', margin: '0px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Typography sx={{ fontSize: '8pt', px: '10px' }}>
          過去のモデゴン
          <br />※ モデゴンチームのみ閲覧可
        </Typography>
        {modelGondolaItems.map((item) => (
          <MenuItem
            key={item.week}
            onClick={() => {
              setCurrentModelGondolaItem(item);
              setAnchorEl(null);
            }}
          >
            {item.week}版
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
