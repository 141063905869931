import {
  EditorProduct,
  ModegonProduct,
  StandardModegonProduct,
  BevModegonProduct,
  selectModegonProducts,
  selectProducts,
  selectContext,
  ItemCd,
} from '../features/slices';
import React, { useState, FC } from 'react';
import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import NextImage from 'next/legacy/image';

import { useAppSelector } from '@store/index';

import { GondolaPmas } from '../features/constants';
import { useProductImage } from '@hooks/useImage';
import {
  GondolaProductCard,
  ProductColumnHeight,
  ProductColumnWidth,
} from './GondolaCardItem';

const BayColumnWidth = 105; // 'px'

const BevMaxItemsPerColumn = 4;

const ShelfInfoStyle: React.CSSProperties = {
  backgroundColor: '#222222',
  color: 'white',
  textAlign: 'center',
} as const;

const ShelfColumnStyle: React.CSSProperties = {
  top: 0,
  position: 'sticky',
} as const;

// MARK: SimpleProductImageGrid
const SimpleProductImageGrid: FC<{ product: EditorProduct; faces: number }> = ({
  product,
  faces,
}) => {
  const { image } = useProductImage(
    {
      productId: parseInt(product.shelfAppsProductId || ''),
      faceFront: product.faceDirection || 'front',
      size: 'small',
    },
    {
      skip: product.shelfAppsProductId == null,
    }
  );

  // 1cm = 5px になるよう設定 (width, heightはmm単位)
  const size = {
    width: product.width / 2,
    height: product.height / 2,
  };

  return (
    <>
      {[...Array(faces)].map((e, i) => {
        return (
          <Grid key={i} item style={{ ...size }}>
            {image && (
              <NextImage
                src={image}
                alt={product.name}
                width={size.width}
                height={size.height}
                objectFit="contain"
                style={{ aspectRatio: 'auto' }}
              />
            )}
          </Grid>
        );
      })}
    </>
  );
};

// MARK: RenderRow
type RenderStandardRowProps = {
  row: number;
  products: {
    product: EditorProduct;
    modegon: StandardModegonProduct;
  }[];
  simpleLayoutMode: boolean;
};

const RenderStandardRow: FC<RenderStandardRowProps> = ({
  row,
  products,
  simpleLayoutMode,
}) => {
  const gondolaRenderWidth = simpleLayoutMode
    ? 600
    : products.length * (ProductColumnWidth + 20);
  const totalRenderWidth = gondolaRenderWidth + BayColumnWidth;

  const totalFaces = products.reduce((prev, prod) => {
    return prev + prod.modegon.faces;
  }, 0);

  const totalGondolaWidth = products.reduce((prev, prod) => {
    return prev + (prod.modegon.faces * prod.product.width) / 10;
  }, 0);

  return (
    <Grid
      key={`row-${row}`}
      container
      direction="row"
      columns={1 + products.length}
      minWidth={totalRenderWidth + 'px'}
      maxWidth={totalRenderWidth + 'px'}
      //maxHeight="30vh"
      wrap="nowrap"
    >
      {/* 什器情報 */}
      <Grid
        minWidth={BayColumnWidth + 'px'}
        maxWidth={BayColumnWidth + 'px'}
        style={{
          backgroundColor: '#222222',
          color: 'white',
          textAlign: 'center',
        }}
        margin={1}
      >
        <Typography>{row} 段目</Typography>
        <br />
        <Typography fontSize={12}>総フェイス数: {totalFaces}</Typography>
        <Typography fontSize={12}>
          幅合計: {totalGondolaWidth.toFixed(1)} cm
        </Typography>
      </Grid>

      {/* 各商品 */}

      {simpleLayoutMode ? (
        <Grid
          container
          item
          columns={products.length}
          width={gondolaRenderWidth + 'px'}
          margin={1}
        >
          {products
            .sort((a, b) => a.modegon.col - b.modegon.col)
            .map((p, c) => (
              <SimpleProductImageGrid
                key={c}
                product={p.product}
                faces={p.modegon.faces}
              />
            ))}
        </Grid>
      ) : (
        <Grid
          container
          item
          columns={products.length}
          width={gondolaRenderWidth + 'px'}
          margin={1}
          wrap="nowrap"
        >
          {products
            .sort((a, b) => a.modegon.col - b.modegon.col)
            .map((p, c) => (
              <GondolaProductCard
                key={`${p.modegon.itemCd}-${row}-${c}`}
                product={p.product}
                modegon={p.modegon}
                sameShelfItemCds={products.map((p) => p.product.itemCd)}
              />
            ))}
        </Grid>
      )}
    </Grid>
  );
};

type RenderBevRowProps = {
  row: number;
  baskets: {
    basket: number;
    width: number;
    height: number;
    products: {
      product: EditorProduct;
      modegon: BevModegonProduct;
    }[];
  }[];
  simpleLayoutMode: boolean;
};

const RenderBevRow: FC<RenderBevRowProps> = ({
  row,
  baskets,
  simpleLayoutMode,
}) => {
  const gondolaRenderWidth = simpleLayoutMode
    ? 600
    : baskets.reduce((sum, p) => sum + p.width, 0.0);

  const totalRenderWidth = BayColumnWidth + gondolaRenderWidth;

  return (
    <Grid
      key={`row-${row}`}
      container
      direction="row"
      columns={1 + baskets.length}
      minWidth={totalRenderWidth + 'px'}
      maxWidth={totalRenderWidth + 'px'}
      wrap="nowrap"
    >
      {/* 什器情報 */}
      <Grid
        minWidth={BayColumnWidth + 'px'}
        maxWidth={BayColumnWidth + 'px'}
        style={ShelfInfoStyle}
        margin={1}
        display="table"
      >
        <Typography
          display="table-cell"
          sx={{ verticalAlign: 'middle', writingMode: 'vertical-rl' }}
        >
          レジカウンター側
        </Typography>
      </Grid>

      {/* カゴ */}
      {simpleLayoutMode ? null : (
        <Grid
          container
          item
          direction="row"
          columns={baskets.length}
          width={gondolaRenderWidth + 'px'}
          margin={1}
          wrap="nowrap"
        >
          {baskets.map((basket) => {
            return (
              <Grid
                key={`basket-${basket.basket}`}
                container
                item
                direction="column"
                columns={BevMaxItemsPerColumn}
                maxHeight={basket.height + 'px'}
                width={basket.width + 'px'}
                wrap="wrap"
              >
                {basket.products.length > 0 ? (
                  basket.products
                    .sort((a, b) => a.modegon.index - b.modegon.index)
                    .map((p) => {
                      return (
                        <GondolaProductCard
                          key={`${p.modegon.basket}-${p.modegon.index}`}
                          product={p.product}
                          modegon={p.modegon}
                          sameShelfItemCds={basket.products.map(
                            (p) => p.product.itemCd
                          )}
                        />
                      );
                    })
                ) : (
                  <Box
                    component="div"
                    key={`${basket.basket}-empty`}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      border: 1,
                      borderColor: '#777777',
                      borderStyle: 'dashed',
                    }}
                    height="250px"
                    width={ProductColumnWidth + 'px'}
                  >
                    <Typography>NO ITEM</Typography>
                  </Box>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

type StandardGondolaLayoutProps = {
  gondolaCode: string;
  products: {
    product: EditorProduct;
    modegon: StandardModegonProduct;
  }[];
  isLayoutMode: boolean;
};

export const StandardGondolaLayout: FC<StandardGondolaLayoutProps> = ({
  gondolaCode,
  products,
  isLayoutMode,
}) => {
  const gondola = GondolaPmas.find((p) => p.code == gondolaCode);
  const rows = [...Array(gondola?.layout.rows || 0)].map((v, i) => i + 1);

  return (
    <>
      {rows.map((row) => {
        return (
          <RenderStandardRow
            key={`row-${row}`}
            row={row}
            products={products.filter((p) => p.modegon.row == row)}
            simpleLayoutMode={isLayoutMode}
          />
        );
      })}
    </>
  );
};

function computeBevBasketData(
  numRows: number,
  numBaskets: number,
  products: {
    product: EditorProduct;
    modegon: BevModegonProduct;
  }[]
) {
  const rows = [...Array(numRows)].map((v, i) => i);
  const baskets = [...Array(numBaskets)].map((v, i) => i);

  const items = rows.map((ri) => {
    return baskets.map((bi) => {
      return products.filter(
        (p) => p.modegon.row == ri + 1 && p.modegon.basket == bi + 1
      );
    });
  });

  return rows.map((ri) => {
    return {
      row: ri + 1,
      baskets: baskets.map((bi) => {
        const numItems = rows.map((i) => items[i][bi].length);
        const basketItems = Math.max(...numItems);
        const cols = Math.max(1, Math.ceil(basketItems / BevMaxItemsPerColumn));

        return {
          basket: bi + 1,
          width: cols * ProductColumnWidth + (cols - 1) * 16,
          height:
            Math.max(1, Math.min(items[ri][bi].length, BevMaxItemsPerColumn)) *
            ProductColumnHeight,
          products: items[ri][bi],
        };
      }),
    };
  });
}

type BevGondolaLayoutProps = {
  gondolaCode: string;
  products: {
    product: EditorProduct;
    modegon: BevModegonProduct;
  }[];
  isLayoutMode: boolean;
};

export const BevGondolaLayout: FC<BevGondolaLayoutProps> = ({
  gondolaCode,
  products,
  isLayoutMode,
}) => {
  const gondola = GondolaPmas.find((p) => p.code == gondolaCode);
  const numBaskets = gondola?.layout.baskets || 0;
  const numRows = gondola?.layout.rows || 0;

  const basketData = computeBevBasketData(numRows, numBaskets, products);

  const totalRenderWidth =
    BayColumnWidth +
    Math.max(
      ...basketData.map((rowData) =>
        rowData.baskets.reduce((prev, d) => prev + d.width, 0.0)
      )
    );

  return (
    <>
      <Grid
        style={ShelfInfoStyle}
        width={totalRenderWidth + 12 + 'px'}
        margin="0px 8px"
      >
        <Typography>OPケース側</Typography>
      </Grid>
      <Grid
        container
        direction="row"
        columns={1 + numBaskets}
        minWidth={totalRenderWidth + 'px'}
        maxWidth={totalRenderWidth + 'px'}
        wrap="nowrap"
        style={ShelfColumnStyle}
      >
        {/* 什器情報 */}
        <Grid
          width={BayColumnWidth + 'px'}
          style={ShelfInfoStyle}
          margin="4px 16px 8px 8px"
        >
          <Box component="div" width={BayColumnWidth + 'px'}>
            -
          </Box>
        </Grid>
        {basketData[0].baskets.map((data) => {
          return (
            <Grid
              key={`header-${data.basket}`}
              item
              minWidth={data.width - 8 + 'px'}
              style={ShelfInfoStyle}
              margin="4px 4px 8px 4px"
            >
              <Typography>{`カゴ ${data.basket}`}</Typography>
            </Grid>
          );
        })}
      </Grid>

      {basketData.map((rowData) => {
        return (
          <RenderBevRow
            key={`row-${rowData.row}`}
            row={rowData.row}
            baskets={rowData.baskets}
            simpleLayoutMode={isLayoutMode}
          />
        );
      })}
      <Grid
        style={ShelfInfoStyle}
        width={totalRenderWidth + 12 + 'px'}
        margin="0px 8px"
      >
        <Typography>ゴンドラ側</Typography>
      </Grid>
    </>
  );
};

// MARK: GondolaLayout
export const GondolaLayout: FC = () => {
  const modegonProducts = useAppSelector(selectModegonProducts);
  const products: EditorProduct[] = useAppSelector(selectProducts);
  const modegonContext = useAppSelector(selectContext);

  const modegonProductMap: Record<ItemCd, ModegonProduct> =
    modegonProducts.reduce((m, p) => {
      m[p.itemCd] = p;
      return m;
    }, {} as Record<ItemCd, ModegonProduct>);

  const productsInModegon = products.filter(
    (p) => p.itemCd in modegonProductMap
  );

  const [isLayoutMode, setIsLayoutMode] = useState(false);

  const isContextReady = modegonContext.gondola && modegonContext.doCode;
  const layoutType = GondolaPmas.find(
    (p) => p.code == modegonContext.gondola
  )?.layoutType;

  return (
    <>
      {isContextReady ? (
        <Grid container>
          <Grid
            container
            direction="row"
            minWidth={BayColumnWidth * 2 + 'px'}
            maxWidth={BayColumnWidth * 2 + 'px'}
            wrap="nowrap"
            margin={1}
          >
            {/* 什器情報 */}
            <Grid
              minWidth={BayColumnWidth + 'px'}
              maxWidth={BayColumnWidth + 'px'}
              style={ShelfInfoStyle}
              display="table"
            >
              <Typography display="table-cell" sx={{ verticalAlign: 'middle' }}>
                什器情報
              </Typography>
            </Grid>
            <Grid padding="0px 16px" width="500px">
              <FormControlLabel
                control={
                  <Switch
                    checked={isLayoutMode}
                    onChange={(e) => {
                      setIsLayoutMode(e.target.checked);
                    }}
                  />
                }
                label="陳列表示"
                sx={{ width: 'max-content' }}
              />
            </Grid>
          </Grid>

          <Grid container direction="column">
            {layoutType == 'standard' ? (
              <StandardGondolaLayout
                gondolaCode={modegonContext.gondola || ''}
                products={productsInModegon.map((p) => {
                  return {
                    product: p,
                    modegon: modegonProductMap[
                      p.itemCd
                    ] as StandardModegonProduct,
                  };
                })}
                isLayoutMode={isLayoutMode}
              />
            ) : layoutType == 'bev' ? (
              <BevGondolaLayout
                gondolaCode={modegonContext.gondola || ''}
                products={productsInModegon.map((p) => {
                  return {
                    product: p,
                    modegon: modegonProductMap[p.itemCd] as BevModegonProduct,
                  };
                })}
                isLayoutMode={isLayoutMode}
              />
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
