import {
  BoxWidthDivider,
  ProfitHeaderOptional,
  widthList,
} from '@components/organisms';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { ProfitTab, ShelfDetailView } from 'types/common';

type Props = {
  type: ShelfDetailView;
  profitTab: ProfitTab;
  handleClick: () => void;
  isAscending?: boolean;
  isBucket?: boolean;
};

export const ListHeaderOptionalItem: FC<Props> = ({
  type,
  profitTab,
  handleClick,
  isAscending = false,
  isBucket = false,
}) => {
  switch (type) {
    case 'profit':
      return (
        <ProfitHeaderOptional
          profitTab={profitTab}
          handleClick={handleClick}
          isAscending={isAscending}
          isBucket={isBucket}
        />
      );
    case 'rate':
    case 'productFlag':
      return (
        <>
          {!isBucket && (
            <BoxWidthDivider width={widthList.face}>
              <Typography variant="body2">フェイス</Typography>
            </BoxWidthDivider>
          )}
        </>
      );
    case 'default':
      return (
        <>
          <BoxWidthDivider width={widthList.scoreWidth}>
            <Typography variant="body2">確信度</Typography>
          </BoxWidthDivider>
          <Box component="div" width={widthList.menuWidth} />
        </>
      );
    default: {
      console.error('Unexpected value: ', type);
      return <></>;
    }
  }
};
