import { useCallback, useEffect, useRef } from 'react';
import { RealogramCandidateFace, RealogramSelectedItem } from 'types/realogram';

type Props = {
  handleClick: (item: RealogramSelectedItem) => void;
  handleClickDeleteShelfBoard: (shelfBoardId: string) => void;
  handleCorrectDrawerOpen: (item: RealogramSelectedItem) => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  handleClickDeleteFace: (item: RealogramSelectedItem) => void;
  handleClickOutSideRealogramBbox?: () => void;
  handleSort: () => void;
  handleAddFaceMode: (shelfBoardIndex: number) => void;
};

export const useMemoizedHandlers = ({
  handleClick,
  handleClickDeleteShelfBoard,
  handleCorrectDrawerOpen,
  handleDivisionModalOpen,
  handleClickDeleteFace,
  handleClickOutSideRealogramBbox,
  handleSort,
  handleAddFaceMode,
}: Props) => {
  const refHandleClick = useRef<(item: RealogramSelectedItem) => void>();
  const refDeleteShelfBoard = useRef<(shelfBoardId: string) => void>();
  const refCorrectDrawerOpen = useRef<(item: RealogramSelectedItem) => void>();
  const refDivisionModalOpen = useRef<(face: RealogramCandidateFace) => void>();
  const refClickDeleteFace = useRef<(item: RealogramSelectedItem) => void>();
  const refClickOutSideRealogramBbox = useRef<VoidFunction>();
  const refAddFaceMode = useRef<(shelfBoardIndex: number) => void>();
  const refHandleSort = useRef<VoidFunction>();
  const memoHandleClick = useCallback(
    (item: RealogramSelectedItem) => refHandleClick.current?.(item),
    []
  );
  const memoHandleClickDeleteShelfBoard = useCallback(
    (shelfBoardId: string) => refDeleteShelfBoard.current?.(shelfBoardId),
    []
  );

  const memoHandleCorrectDrawerOpen = useCallback(
    (item: RealogramSelectedItem) => refCorrectDrawerOpen.current?.(item),
    []
  );

  const memoHandleDivisionModalOpen = useCallback(
    (face: RealogramCandidateFace) => refDivisionModalOpen.current?.(face),
    []
  );

  const memoHandleClickDeleteFace = useCallback(
    (item: RealogramSelectedItem) => refClickDeleteFace.current?.(item),
    []
  );

  const memoHandleClickOutSideRealogramBbox = useCallback(
    () => refClickOutSideRealogramBbox.current?.(),
    []
  );

  const memoHandleAddFaceMode = useCallback(
    (shelfBoardIndex: number) => refAddFaceMode.current?.(shelfBoardIndex),
    []
  );

  const memoHandleSort = useCallback(() => refHandleSort.current?.(), []);

  useEffect(() => {
    refHandleClick.current = handleClick;
    refDeleteShelfBoard.current = handleClickDeleteShelfBoard;
    refCorrectDrawerOpen.current = handleCorrectDrawerOpen;
    refDivisionModalOpen.current = handleDivisionModalOpen;
    refClickDeleteFace.current = handleClickDeleteFace;
    refClickOutSideRealogramBbox.current = handleClickOutSideRealogramBbox;
    refAddFaceMode.current = handleAddFaceMode;
    refHandleSort.current = handleSort;
  });

  return {
    memoHandleClickDeleteShelfBoard,
    memoHandleCorrectDrawerOpen,
    memoHandleDivisionModalOpen,
    memoHandleClickDeleteFace,
    memoHandleClickOutSideRealogramBbox,
    memoHandleAddFaceMode,
    memoHandleSort,
    memoHandleClick,
  };
};
