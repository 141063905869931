import { Box, IconButton, Stack, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { Product } from 'types/common';

import { useState } from 'react';
import { AvailableItems, ExternalInfo, StoreProductSale } from '../../types';
import { ProductDetailsModal } from '../productDetailsModal/productDetailsModal';
import { useModelGondolasContext } from '../modelGondolasContext';
import { dateToMonthDate, locateModelGondolaProduct } from '../../sakuraUtils';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { normalizeProductName } from '@utils/product';
import GondolaPmas from '@utils/gondolas.json';
import { abbrevGondolaName, findPossibleGondolasForItem } from './utils';
import { MemoItem } from './storeActionMemoContext';

type Props = {
  memoItem: MemoItem;
  product: Product;
  externalInfo: ExternalInfo;
  storeProductSale: StoreProductSale;
  removeMemoItem: (itemCd: string) => void;
  availableItems: AvailableItems;
};

export const MemoViewItem = ({
  memoItem,
  product,
  externalInfo,
  removeMemoItem,
  storeProductSale,
  availableItems,
}: Props) => {
  const [openProductDetails, setOpenProductDetails] = useState(false);
  // Chipがあるとだいぶごちゃごちゃする。ここでの必要性は低いかもしれない
  // const chips = [
  //   <KihonShohinChipIfNeeded key="基本商品" product={product} />,
  //   <InfoPackageChipIfNeeded
  //     key="本部情報"
  //     product={product}
  //     externalInfo={externalInfo}
  //   />,
  //   <SnsInfoChipIfNeeded
  //     key="SNS情報"
  //     product={product}
  //     externalInfo={externalInfo}
  //   />,
  //   <NewItemChipIfNeeded
  //     key="新商品"
  //     product={product}
  //     externalInfo={externalInfo}
  //   />,
  // ];

  const itemCd = product.detail!.organization_product_id!;
  const modelGondolas = useModelGondolasContext().value?.modelGondolas;
  const modelGondolaProductAssignment =
    modelGondolas && locateModelGondolaProduct(itemCd, modelGondolas);

  const modelGondolaWithThisItem =
    modelGondolaProductAssignment &&
    GondolaPmas.gondolaPmas.find(
      (gondolaPma) =>
        gondolaPma.code === modelGondolaProductAssignment.gondolaCode
    );
  const possibleGondolas = findPossibleGondolasForItem(itemCd, availableItems);
  return (
    <>
      <Box
        component="div"
        sx={{
          py: 1,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Box
            component="div"
            onClick={() => {
              if (!openProductDetails) {
                setOpenProductDetails(true);
              }
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Stack direction="row" spacing={2}>
              {/* 商品画像の列 */}
              <ProductImage
                product={product}
                width="24"
                height="24"
                objectFit="contain"
              />

              {/* 商品情報の列 */}
              <Box component="div" width="100pt">
                <Stack spacing={0}>
                  <Typography fontSize="6pt" color="grey">
                    {dateToMonthDate(memoItem.date)}にメモ
                  </Typography>
                  <Typography fontSize="8pt">
                    {normalizeProductName(product.name)}
                  </Typography>
                </Stack>
              </Box>

              {/* ゴンドラの列 */}
              <Box component="div" display="flex" width="140pt">
                <Stack>
                  <Typography fontSize="6pt" color="grey">
                    ゴンドラ
                  </Typography>
                  {possibleGondolas.map((gondolaPma) => {
                    const isIncluded =
                      modelGondolaWithThisItem?.code === gondolaPma.code;
                    return (
                      <Typography
                        key={gondolaPma.code}
                        fontSize="7pt"
                        fontWeight={isIncluded ? 'bold' : 'normal'}
                        sx={{
                          textDecoration: isIncluded ? 'underline' : 'none',
                        }}
                      >
                        {gondolaPma.origCode !== '7TP'
                          ? gondolaPma.origCode
                          : ''}{' '}
                        {abbrevGondolaName(gondolaPma.name, 8)}
                        {isIncluded && '（モデゴン掲載）'}
                      </Typography>
                    );
                  })}
                </Stack>
              </Box>

              {/* 商品CDの列 */}
              <Box component="div" display="flex" width="40pt">
                <Stack>
                  <Typography fontSize="6pt" color="grey">
                    商品CD
                  </Typography>
                  <Typography fontSize="10pt">
                    {product.detail!.organization_product_id!}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>

          {/* 削除アイコン */}
          <IconButton
            color="warning"
            onClick={() => {
              removeMemoItem(itemCd);
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Stack>
      </Box>
      <ProductDetailsModal
        open={openProductDetails}
        handleClose={() => setOpenProductDetails(false)}
        product={product}
        modelGondolaProductAssignment={modelGondolaProductAssignment}
        externalInfo={externalInfo}
        storeProductSale={storeProductSale}
      />
    </>
  );
};
