import { Close, Search } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { theme } from 'theme';
import { t } from 'i18next';

type Props = {
  control: Control<{ searchText: string }>;
  isFocused: boolean;
  onClearClick: VoidFunction;
};

const defaultInputHeight = 32;

export const SearchInput: FC<Props> = ({
  control,
  isFocused,
  onClearClick,
}) => {
  return (
    <Controller
      name="searchText"
      control={control}
      render={({ field }) => (
        <TextField
          id="searchedTextId"
          key="searchedTextFullWidth"
          {...field}
          placeholder={`商品名・JAN・${t('product_code')}で検索`}
          size="small"
          sx={{
            width: '100%',
            'input::placeholder': {
              fontSize: '14px',
            },
            '& .MuiInputBase-input': {
              height: '15px',
              px: 1,
            },
          }}
          InputProps={{
            sx: {
              borderRadius: '40px',
              backgroundColor: '#F7F7F7',
              height: defaultInputHeight,
            },
            startAdornment: (
              <Search
                sx={{
                  color: theme.palette.icons.primary,
                  mr: '4px',
                }}
              />
            ),
            endAdornment: isFocused && (
              <IconButton onClick={onClearClick}>
                <Close
                  sx={{
                    width: '18px',
                    height: '18px',
                    cursor: 'pointer',
                    color: theme.palette.icons.primary,
                  }}
                />
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
};
