import { HeaderStyleDirection } from '@components/molecules/comparisionBayHeader/comparisionBayHeader';
import { SelectComparison } from '@components/molecules/selectComparison/selectComparison';
import { PlanogramComparison } from '@components/pages/planogramDetail/fragments/planogramComparison';
import { EstimateForPlanogramResponse } from '@reducers/shelfAppsApi';
import { ComparisonTabModal, getComparisonDiffColor } from '@utils/const';
import { Format, format } from '@utils/date';
import {
  isPlanogramBucketPlan,
  isPlanogramShelvesDetail,
} from '@utils/planogram';
import { FC } from 'react';
import { theme } from 'theme';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { Planogram } from 'types/planogram';
import { StoreArea } from 'types/products';
import { RealogramCandidate, RealogramSelectedItem } from 'types/realogram';
import { FlatPlanogramComparison } from '../flatPlanogram/fragments/flatPlanogramComparison';
type Props = {
  planogram?: Planogram;
  comparedPlanogram: Planogram;
  handleOpenComparisonItemModal: (args: {
    defaultTab?: ComparisonTabModal;
    originPlanogramId?: number;
    originRealogramId?: number;
  }) => void;
  detailView: ShelfDetailView;
  detailMode: ShelfDetailMode;
  handleClickAwayPlanogram: () => void;
  showDevFeatureDialog: () => void;
  comparedPlanogramEstimatedData?: EstimateForPlanogramResponse;
  handleChangeComparedProfit: (value: string) => void;
  storeAreaType: StoreArea;
  profitCompared: string;
  handleRecalculate: () => void;
  headerStyleDirection?: HeaderStyleDirection;
  isSwappingBayPartMode?: boolean;
  isLoadingEstimate?: boolean;
  isComparingBox?: boolean;
  realogramCandidate?: RealogramCandidate;
  isTenantSalesAnalytics?: boolean;
  isEditor?: boolean;
  selectedRealogramItem?: RealogramSelectedItem;
};

export const FlatOrShelfPlanogramComparison: FC<Props> = ({
  comparedPlanogram,
  handleOpenComparisonItemModal,
  detailMode,
  detailView,
  handleClickAwayPlanogram,
  showDevFeatureDialog,
  comparedPlanogramEstimatedData,
  handleChangeComparedProfit,
  storeAreaType,
  profitCompared,
  handleRecalculate,
  planogram,
  headerStyleDirection,
  isSwappingBayPartMode,
  isLoadingEstimate,
  realogramCandidate,
  isTenantSalesAnalytics,
  isEditor = false,
  selectedRealogramItem,
}) => {
  if (isPlanogramBucketPlan(comparedPlanogram?.plan)) {
    return (
      <FlatPlanogramComparison
        key={comparedPlanogram.id}
        handleOpenSearchModal={() =>
          handleOpenComparisonItemModal({
            defaultTab: ComparisonTabModal.PLANOGRAM_TAB,
            originPlanogramId: planogram?.id,
            originRealogramId: realogramCandidate?.id,
          })
        }
        detailView={detailView}
        detailMode={detailMode}
        isEditor={isEditor}
        planogram={comparedPlanogram}
        flatPlan={comparedPlanogram.plan}
        handleClickAwayPlanogram={handleClickAwayPlanogram}
        bboxColors={{
          borderColor: theme.palette.beforeAfter.before,
          backgroundColor: theme.palette.beforeAfter.beforeOverlay,
        }}
        diffColor={getComparisonDiffColor(detailView)}
        backgroundColor={theme.palette.shelf.backgroundTanaDark}
        onClickNext={showDevFeatureDialog}
        onClickPrev={showDevFeatureDialog}
        createdAt={format(
          comparedPlanogram.created_at,
          Format.datetimeWithWeekday
        )}
        handleChangeBucketMode={() => void 0}
        isCompared
        planogramEstimatedData={comparedPlanogramEstimatedData}
        handleChangeProfit={handleChangeComparedProfit}
        storeAreaType={storeAreaType}
        profit={profitCompared}
        handleRecalculate={handleRecalculate}
        plan={comparedPlanogram.plan}
        headerStyleDirection={headerStyleDirection}
        targetCompareText="比較対象"
        isLoadingEstimate={isLoadingEstimate}
        isTenantSalesAnalytics={isTenantSalesAnalytics}
        selectedRealogramItem={selectedRealogramItem}
      />
    );
  } else if (isPlanogramShelvesDetail(comparedPlanogram.plan)) {
    return (
      <PlanogramComparison
        key={comparedPlanogram.id}
        bboxColors={{
          borderColor: theme.palette.beforeAfter.before,
          backgroundColor: theme.palette.beforeAfter.beforeOverlay,
        }}
        onClickNext={showDevFeatureDialog}
        onClickPrev={showDevFeatureDialog}
        diffColor={getComparisonDiffColor(detailView)}
        handleOpenSearchModal={() =>
          handleOpenComparisonItemModal({
            defaultTab: ComparisonTabModal.PLANOGRAM_TAB,
            originPlanogramId: planogram?.id,
            originRealogramId: realogramCandidate?.id,
          })
        }
        backgroundColor={theme.palette.shelf.backgroundTanaDark}
        detailView={detailView}
        detailMode={detailMode}
        createdAt={format(
          comparedPlanogram?.created_at,
          Format.datetimeWithWeekday
        )}
        planogram={comparedPlanogram}
        isCompared
        headerStyleDirection={headerStyleDirection}
        targetCompareText="比較対象"
        isSwappingBayPartMode={isSwappingBayPartMode}
        isTenantSalesAnalytics={isTenantSalesAnalytics}
        isEditor={isEditor}
        selectedRealogramItem={selectedRealogramItem}
      />
    );
  }
  return (
    <SelectComparison
      handleOpenModal={() =>
        handleOpenComparisonItemModal({
          defaultTab: ComparisonTabModal.PLANOGRAM_TAB,
          originPlanogramId: planogram?.id,
          originRealogramId: realogramCandidate?.id,
        })
      }
      isDisabled={isSwappingBayPartMode}
    />
  );
};
