import * as _gondola from '../../../../utils/gondolas.json';

export const Gondola = _gondola;
export const GondolaPmas = Gondola.gondolaPmas;

export const DoList = [
  // { code: '731', name: '熊本南' },
  { code: '732', name: '熊本北' },
  { code: '733', name: '熊本中央' },
  // { code: '734', name: '熊本東' },
  // { code: '735', name: '熊本西' },
  // { code: '740', name: '宮崎北' },
  // { code: '741', name: '宮崎南' },
  // { code: '742', name: '宮崎中央' },
  // { code: '750', name: '大分北' },
  // { code: '751', name: '大分南' },
  // { code: '752', name: '大分中央' },
  // { code: '760', name: '鹿児島東' },
  // { code: '761', name: '鹿児島西' },
  // { code: '762', name: '鹿児島北' },
];
