import React, { createContext, useContext, useState } from 'react';

type IndexedPdf = {
  data: Uint8Array | undefined;
  loading: boolean;
  error: unknown | undefined;
  progress: number | undefined;
  downloadedBytes: number | undefined;
  totalBytes: number | undefined;
};

type IndexedPdfContextType = {
  getPdfState: (url: string) => IndexedPdf | undefined;
  addUrl: (url: string, authToken?: string) => void;
};

const IndexedPdfContext = createContext<IndexedPdfContextType | undefined>(
  undefined
);

const openIndexedDB = async (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('pdfCache', 1);
    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains('pdfs')) {
        db.createObjectStore('pdfs');
      }
    };
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

const saveToIndexedDB = async (url: string, data: ArrayBuffer) => {
  const db = await openIndexedDB();
  const transaction = db.transaction('pdfs', 'readwrite');
  const store = transaction.objectStore('pdfs');
  store.put(data, url);
};

const loadFromIndexedDB = async (
  url: string
): Promise<ArrayBuffer | undefined> => {
  const db = await openIndexedDB();
  const transaction = db.transaction('pdfs', 'readonly');
  const store = transaction.objectStore('pdfs');
  return new Promise((resolve, reject) => {
    const request = store.get(url);
    request.onsuccess = () => resolve(request.result ?? undefined);
    request.onerror = () => reject(request.error);
  });
};

const IndexedPdfProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pdfStates, setPdfStates] = useState<Map<string, IndexedPdf>>(
    new Map()
  );
  const downloadPromises = new Map<string, Promise<void>>();

  const addUrl = (url: string, authToken?: string) => {
    if (pdfStates.has(url)) return;

    setPdfStates((prev) => {
      const updated = new Map(prev);
      updated.set(url, {
        data: undefined,
        loading: true,
        error: undefined,
        progress: undefined,
        downloadedBytes: undefined,
        totalBytes: undefined,
      });
      return updated;
    });

    const fetchPdf = async () => {
      try {
        const cachedData = await loadFromIndexedDB(url);
        if (cachedData) {
          setPdfStates((prev) => {
            const updated = new Map(prev);
            updated.set(url, {
              data: new Uint8Array(cachedData),
              loading: false,
              error: undefined,
              progress: 100,
              downloadedBytes: cachedData.byteLength,
              totalBytes: cachedData.byteLength,
            });
            return updated;
          });
          return;
        }

        const header = authToken
          ? { Authorization: `Bearer ${authToken}` }
          : undefined;
        const response = await fetch(url, { headers: header });
        if (!response.ok) {
          throw new Error(`Failed to fetch PDF: ${response.statusText}`);
        }

        const contentLength = response.headers.get('Content-Length');
        const total = contentLength ? parseInt(contentLength, 10) : undefined;

        const reader = response.body?.getReader();
        const chunks: Uint8Array[] = [];
        let receivedLength = 0;

        if (reader) {
          for (;;) {
            const { done, value } = await reader.read();
            if (done) break;
            if (value) {
              chunks.push(value);
              receivedLength += value.length;

              setPdfStates((prev) => {
                const updated = new Map(prev);
                const state = updated.get(url);
                if (state) {
                  updated.set(url, {
                    ...state,
                    downloadedBytes: receivedLength,
                    progress: total
                      ? Math.round((receivedLength / total) * 100)
                      : undefined,
                  });
                }
                return updated;
              });
            }
          }
        }

        const pdfData = new Uint8Array(receivedLength);
        let position = 0;
        for (const chunk of chunks) {
          pdfData.set(chunk, position);
          position += chunk.length;
        }

        await saveToIndexedDB(url, pdfData.buffer);

        setPdfStates((prev) => {
          const updated = new Map(prev);
          updated.set(url, {
            data: pdfData,
            loading: false,
            error: undefined,
            progress: 100,
            downloadedBytes: pdfData.length,
            totalBytes: total,
          });
          return updated;
        });
      } catch (err) {
        setPdfStates((prev) => {
          const updated = new Map(prev);
          const state = updated.get(url);
          if (state) {
            updated.set(url, { ...state, loading: false, error: err });
          }
          return updated;
        });
      }
    };

    if (!downloadPromises.has(url)) {
      const promise = fetchPdf();
      downloadPromises.set(url, promise);
      promise.finally(() => downloadPromises.delete(url));
    }
  };

  const getPdfState = (url: string) => pdfStates.get(url);

  return (
    <IndexedPdfContext.Provider value={{ getPdfState, addUrl }}>
      {children}
    </IndexedPdfContext.Provider>
  );
};

export const useIndexedPdfContext = () => {
  const context = useContext(IndexedPdfContext);
  if (!context) {
    throw new Error(
      'useIndexedPdfContext must be used within an IndexedPdfProvider'
    );
  }
  return context;
};

export { IndexedPdfProvider };
