import Image from 'next/image';
import { headerHeight } from '@components/organisms/header/header';
import { Box, Button, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import {
  PrimaryCandidates,
  RealogramCandidate,
  RealogramShelfBoard,
} from 'types/realogram';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { RealogramCandidateError } from '../realogramCandidateError/realogramCandidateError';
import { RealogramCandidateProcessing } from '../realogramCandidateProcessing/realogramCandidateProcessing';
import { RealogramPrintItems } from './realogramPrintItems';
// import { PDFWorker } from 'pdfjs-dist';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

type Props = {
  status?: 'error' | 'initialized' | 'processing' | 'processed';
  isProcessed: boolean;
  realogramCandidate?: RealogramCandidate;
  primaryCandidates?: PrimaryCandidates;
  realogramCandidateId: number;
  shelfBoardsToDisplay?: RealogramShelfBoard[];
  handleClickReUpLoadScan: () => void;
};

export const RealogramPrintDetail: FC<Props> = ({
  status,
  isProcessed,
  realogramCandidate,
  primaryCandidates,
  realogramCandidateId,
  shelfBoardsToDisplay,
  handleClickReUpLoadScan,
}) => {
  const { image: firstImage } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 1,
    },
    { skip: false, isNoCache: true }
  );

  const { image: secondImage } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 2,
    },
    { skip: false, isNoCache: true }
  );

  const renderRealogramImageElement = () => {
    const imgs = [firstImage, secondImage].filter((img) => img !== undefined);

    return (
      <>
        {imgs.map((img, index) => {
          //
          return (
            <Box
              component="div"
              position="relative"
              width={imgs.length === 2 ? '50%' : '100%'}
              height="100%"
              key={`img-${index}`}
            >
              <Box component="div">
                <Image
                  src={img ?? ''}
                  layout="fill"
                  objectFit="contain"
                  alt=""
                />
                <Box
                  component="div"
                  bgcolor="rgba(255, 255, 255, 0.5)"
                  width="100%"
                  height="100%"
                  position="absolute"
                >
                  {' '}
                </Box>
              </Box>
            </Box>
          );
        })}
      </>
    );
  };

  const realogramInformation = () => {
    switch (status) {
      case 'error':
        return (
          <RealogramCandidateError
            handleOpenModal={() => {
              // コメントを追加して空の関数の目的を説明
              // 現在このモーダルは使用されていませんが、将来的に実装予定
            }}
            handleClickReUpLoadScan={handleClickReUpLoadScan}
          />
        );
      case 'processing':
      case 'initialized':
        return <RealogramCandidateProcessing />;
      case 'processed':
        return (
          <RealogramPrintItems
            products={primaryCandidates?.products}
            shelfBoards={shelfBoardsToDisplay}
          />
        );
    }
  };

  return (
    <Box component="div" display="flex" flexDirection="column" width="100%">
      {/*  Header */}
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        height="32px"
        alignItems="center"
        p={2}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="baseline"
          paddingRight={4}
          gap={2}
        >
          <Typography
            variant="subtitle1"
            color={theme.palette.textBlack.primary}
          >
            {realogramCandidate?.store_bay.name}
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.textBlack.secondary}
          >
            {realogramCandidate?.store_bay.store.name}
            {'  '}
            {realogramCandidate?.created_at &&
              new Date(realogramCandidate?.created_at).toLocaleString()}
          </Typography>
        </Box>

        <Button
          variant="contained"
          size="small"
          onClick={async () => {
            const body = document.getElementsByTagName('body');
            console.log(body);
            // if (body) {
            //   const canvas = await html2canvas(body[0] as HTMLElement, {
            //     width: 1133,
            //     height: 794,
            //   });
            //   console.log(canvas.width, canvas.height);
            //   const imgData = canvas.toDataURL('image/png');
            //   const pdf = new jsPDF({
            //     orientation: 'landscape',
            //     unit: 'px',
            //     format: [canvas.width, canvas.height],
            //   });
            //   pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            //   const fileName = `${realogramCandidate?.store_bay.store.name}_${realogramCandidate?.store_bay.name}.pdf`;
            //   pdf.save(fileName);
            // }
          }}
        >
          PDFダウンロード
        </Button>
      </Box>
      <Divider />

      {/*  Main Content */}
      <Box component="div" display="flex" flexDirection="row">
        {/*  Realogram Image */}
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          position="relative"
          width="390px"
        >
          {renderRealogramImageElement()}
        </Box>

        {/*  Item List */}
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          flex={{ xs: 5, breakpoint: 5.5 }}
          borderLeft={`1px solid ${theme.palette.dividerBlack.light}`}
          height={{ breakpoint: window.innerHeight - headerHeight - 1 }}
          borderTop={{
            xs: !isProcessed
              ? `1px solid ${theme.palette.dividerBlack.medium}`
              : 'none',
            breakpoint: 'none',
          }}
          overflow="hidden"
        >
          {realogramInformation()}
        </Box>
      </Box>
    </Box>
  );
};
